import React, { useContext } from "react"
import { Divider, FormControlLabel, FormGroup, Grid, Typography, useTheme } from "@mui/material"
import { OrbikSwitch } from "@components/common/forms/index"
import { StyledBox } from "@components/common/Box/BoxContainer"
import { I18nContext } from "I18nProvider"
import dataGenerator from "../../mockData/identityChart"

const dataInstance = dataGenerator()
const dataInstance2 = dataGenerator()

const Devices: React.FC = () => {
    const theme = useTheme()
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    return (
        <>
            <Grid item container>
                <StyledBox>
                    <Grid container flexDirection="column" spacing="20px">
                        <Grid paddingTop="15px !important" item>
                            <Typography fontSize="34px" fontFamily="Griff" color="primary" fontWeight="bolder">{context.t.translate("identity_status")}</Typography>
                        </Grid>

                        <Grid item>
                            {/* <SunburstChart data={dataInstance} legend={
                                <Grid item container flexDirection="row">
                                    <Grid item xs={6} container flexDirection="column" spacing="5px">
                                        <Grid item><Typography component="span" fontWeight="700" fontSize="16px">Identity Status</Typography></Grid>

                                        <Grid item container spacing="8px" flexDirection="column">
                                            <Grid item><Grid container alignItems="center" spacing="4px" flexDirection="row"><Grid item><span style={{ height: "15px", width: "15px", backgroundColor: "#999999", borderRadius: "50%", display: "inline-block" }}></span></Grid><Grid item><Typography component="span" fontSize="15px">None</Typography></Grid></Grid></Grid>
                                            <Grid item><Grid container alignItems="center" spacing="4px" flexDirection="row"><Grid item><span style={{ height: "15px", width: "15px", backgroundColor: "#4DB04A", borderRadius: "50%", display: "inline-block" }}></span></Grid><Grid item><Typography component="span" fontSize="15px">Provisioning</Typography></Grid></Grid></Grid>
                                            <Grid item><Grid container alignItems="center" spacing="4px" flexDirection="row"><Grid item><span style={{ height: "15px", width: "15px", backgroundColor: "#377EB9", borderRadius: "50%", display: "inline-block" }}></span></Grid><Grid item><Typography component="span" fontSize="15px">Reprovisioning</Typography></Grid></Grid></Grid>
                                            <Grid item><Grid container alignItems="center" spacing="4px" flexDirection="row"><Grid item><span style={{ height: "15px", width: "15px", backgroundColor: "#E51A1D", borderRadius: "50%", display: "inline-block" }}></span></Grid><Grid item><Typography component="span" fontSize="15px">Out</Typography></Grid></Grid></Grid>
                                            <Grid item><Grid container alignItems="center" spacing="4px" flexDirection="row"><Grid item><span style={{ height: "15px", width: "15px", backgroundColor: "#974EA2", borderRadius: "50%", display: "inline-block" }}></span></Grid><Grid item><Typography component="span" fontSize="15px">Decommisioned</Typography></Grid></Grid></Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} container flexDirection="column" spacing="5px">
                                        <Grid item><Typography component="span" fontWeight="700" fontSize="16px">Device groups</Typography></Grid>

                                        <Grid item container spacing="8px" flexDirection="column">
                                            <Grid item><Grid container alignItems="center" spacing="4px" flexDirection="row"><Grid item><span style={{ height: "15px", width: "15px", backgroundColor: "#B3DE68", borderRadius: "50%", display: "inline-block" }}></span></Grid><Grid item><Typography component="span" fontSize="15px">Wind turbines</Typography></Grid></Grid></Grid>
                                            <Grid item><Grid container alignItems="center" spacing="4px" flexDirection="row"><Grid item><span style={{ height: "15px", width: "15px", backgroundColor: "#FFFFB3", borderRadius: "50%", display: "inline-block" }}></span></Grid><Grid item><Typography component="span" fontSize="15px">HMIs</Typography></Grid></Grid></Grid>
                                            <Grid item><Grid container alignItems="center" spacing="4px" flexDirection="row"><Grid item><span style={{ height: "15px", width: "15px", backgroundColor: "#8DD3C7", borderRadius: "50%", display: "inline-block" }}></span></Grid><Grid item><Typography component="span" fontSize="15px">Control units</Typography></Grid></Grid></Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }/> */}
                        </Grid>

                        <Grid item>
                            <Divider sx={{ height: "1px", width: "100%", background: theme.palette.primary.main }} />
                        </Grid>

                        <Grid item>
                            <Typography component="span" fontSize="16px" fontWeight="bolder" paddingBottom="10px">{context.t.translate("breakdown_levels")}</Typography>

                            <FormGroup style={{ paddingLeft: "27px", paddingBottom: "18px", display: "inline-block" }} row>
                                <FormControlLabel sx={{ paddingRight: "30px" }} control={<OrbikSwitch defaultChecked />} label={context.t.translate("product_categories")}/>
                                <FormControlLabel sx={{ paddingRight: "30px" }} control={<OrbikSwitch defaultChecked />} label={context.t.translate("manufacturers")} />
                            </FormGroup>
                        </Grid>
                    </Grid>
                </StyledBox>
            </Grid>

            <Grid item container>
                <StyledBox>
                    <Grid container flexDirection="column" spacing="20px">
                        <Grid paddingTop="15px !important" item>
                            <Typography fontSize="34px" fontFamily="Griff" color="primary" fontWeight="bolder">{context.t.translate("update_status")}</Typography>
                        </Grid>

                        <Grid item>
                            {/* <SunburstChart data={dataInstance2} legend={
                                <Grid item container flexDirection="row">
                                    <Grid item xs={6} container flexDirection="column" spacing="5px">
                                        <Grid item><Typography component="span" fontWeight="700" fontSize="16px">Update Status</Typography></Grid>

                                        <Grid item container spacing="8px" flexDirection="column">
                                            <Grid item><Grid container alignItems="center" spacing="4px" flexDirection="row"><Grid item><span style={{ height: "15px", width: "15px", backgroundColor: "#999999", borderRadius: "50%", display: "inline-block" }}></span></Grid><Grid item><Typography component="span" fontSize="15px">None</Typography></Grid></Grid></Grid>
                                            <Grid item><Grid container alignItems="center" spacing="4px" flexDirection="row"><Grid item><span style={{ height: "15px", width: "15px", backgroundColor: "#4DB04A", borderRadius: "50%", display: "inline-block" }}></span></Grid><Grid item><Typography component="span" fontSize="15px">Ok</Typography></Grid></Grid></Grid>
                                            <Grid item><Grid container alignItems="center" spacing="4px" flexDirection="row"><Grid item><span style={{ height: "15px", width: "15px", backgroundColor: "#377EB9", borderRadius: "50%", display: "inline-block" }}></span></Grid><Grid item><Typography component="span" fontSize="15px">Downloaded</Typography></Grid></Grid></Grid>
                                            <Grid item><Grid container alignItems="center" spacing="4px" flexDirection="row"><Grid item><span style={{ height: "15px", width: "15px", backgroundColor: "#FF8000", borderRadius: "50%", display: "inline-block" }}></span></Grid><Grid item><Typography component="span" fontSize="15px">Installation pending</Typography></Grid></Grid></Grid>
                                            <Grid item><Grid container alignItems="center" spacing="4px" flexDirection="row"><Grid item><span style={{ height: "15px", width: "15px", backgroundColor: "#F782C1", borderRadius: "50%", display: "inline-block" }}></span></Grid><Grid item><Typography component="span" fontSize="15px">Applying</Typography></Grid></Grid></Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} container flexDirection="column" spacing="5px">
                                        <Grid item><Typography component="span" fontWeight="700" fontSize="16px">Device groups</Typography></Grid>

                                        <Grid item container spacing="8px" flexDirection="column">
                                            <Grid item><Grid container alignItems="center" spacing="4px" flexDirection="row"><Grid item><span style={{ height: "15px", width: "15px", backgroundColor: "#B3DE68", borderRadius: "50%", display: "inline-block" }}></span></Grid><Grid item><Typography component="span" fontSize="15px">Wind turbines</Typography></Grid></Grid></Grid>
                                            <Grid item><Grid container alignItems="center" spacing="4px" flexDirection="row"><Grid item><span style={{ height: "15px", width: "15px", backgroundColor: "#FFFFB3", borderRadius: "50%", display: "inline-block" }}></span></Grid><Grid item><Typography component="span" fontSize="15px">HMIs</Typography></Grid></Grid></Grid>
                                            <Grid item><Grid container alignItems="center" spacing="4px" flexDirection="row"><Grid item><span style={{ height: "15px", width: "15px", backgroundColor: "#8DD3C7", borderRadius: "50%", display: "inline-block" }}></span></Grid><Grid item><Typography component="span" fontSize="15px">Control units</Typography></Grid></Grid></Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }/> */}
                        </Grid>
                        <Grid item>
                            <Divider sx={{ height: "1px", width: "100%", background: theme.palette.primary.main }} />
                        </Grid>

                        <Grid item>
                            <Typography component="span" fontSize="16px" fontWeight="bolder" paddingBottom="10px">{context.t.translate("breakdown_levels")}</Typography>

                            <FormGroup style={{ paddingLeft: "27px", paddingBottom: "18px", display: "inline-block" }} row>
                                <FormControlLabel sx={{ paddingRight: "30px" }} control={<OrbikSwitch defaultChecked />} label={context.t.translate("product_categories")} />
                                <FormControlLabel sx={{ paddingRight: "30px" }} control={<OrbikSwitch defaultChecked />} label={context.t.translate("manufacturers")} />
                            </FormGroup>
                        </Grid>
                    </Grid>
                </StyledBox>
            </Grid>
        </>
    )
}

export { Devices }
export default Devices
