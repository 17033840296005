import React, { useContext, useEffect, useState } from "react"
import { Box, Button, Divider, Grid, Link, styled, Tab, Tabs, ToggleButton, ToggleButtonGroup, ToggleButtonProps, Typography, useTheme } from "@mui/material"
import BaseColorGauge from "@components/charts/gauge/BaseColorGauge"
import GradiantGauge from "@components/charts/gauge/GradientGauge"
import EnvironmentalColorGauge from "@components/charts/gauge/EnvironmentalColorGauge"
import TemporalColorGauge from "@components/charts/gauge/TemporalColorGauge"
import HelpIcon from "@mui/icons-material/Help"
import CVSS3Metric from "@models/CVSS"
import CVSS3TemporalMetric from "@models/CVSSTemporal"
import CVSS3EnvironmentalMetric from "@models/CVSSEnvironmental"
import Vulnerability, { VulnerabilityState } from "@models/Vulnerability"
import { useNavigate, useParams } from "react-router-dom"
import ServicesContext from "@context/ServicesContext"
import { I18nContext } from "I18nProvider"

const initValue: Vulnerability = {
    id: "",
    identifier: "",
    score: 0,
    revised_score: 0,
    priority: 0,
    epss: 0,
    percentile: 0,
    kev: false,
    supressed: false,
    vector: "",
    description: "",
    state: VulnerabilityState.Detected,
    justification: null,
    response: [],
    revised_severity: -1,
    time_spent: -1,
    cmp_name: undefined,
    cmp_version: undefined,
    assessment: { id: "" },
    product: { id: "" },
    client: { id: "" },
    origin: "",
    metadata: undefined,
    rank: 0
}

const CVSSCalcultor: React.FC = () => {
    const theme = useTheme()
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const [tabIndex, setTabIndex] = React.useState(0)

    const example = {
        baseMetrics: {
            exploitability: 1.5,
            impact: 9.0
        },
        temporalMetrics: 5.5, // EF7A24
        environmentalMetrics: {
            exploitability: 1.5,
            impact: 9.0
        }
    }
    const handleTabChange = (event: any, newIndex: React.SetStateAction<number>) => {
        setTabIndex(newIndex)
    }
    type CVSS3MetricValues = {
        label: string
        metric: string
        value: number
    }
    type CVSS3MetricI = {
        AV: CVSS3MetricValues | null
        AC: CVSS3MetricValues | null
        PR: {
            label: string
            metric: string
            value: number | { U: number, C: number }
        } | null
        UI: CVSS3MetricValues | null
        S: CVSS3MetricValues | null
        C: CVSS3MetricValues | null
        I: CVSS3MetricValues | null
        A: CVSS3MetricValues | null
    }

    type CVSS3TemporalMetricI = {
        E: CVSS3MetricValues | null
        RL: CVSS3MetricValues | null
        RC: CVSS3MetricValues | null
    }

    type CVSS3EnvironmentalMetricI = {
        MAV: CVSS3MetricValues | null
        MAC: CVSS3MetricValues | null
        MPR: {
            label: string
            metric: string
            value: number | { U: number, C: number }
        } | null
        MUI: CVSS3MetricValues | null
        MS: CVSS3MetricValues | null
        MC: CVSS3MetricValues | null
        MI: CVSS3MetricValues | null
        MA: CVSS3MetricValues | null
        CR: CVSS3MetricValues | null
        IR: CVSS3MetricValues | null
        AR: CVSS3MetricValues | null
    }
    // Almacena los value de cada metrica

    const [CVSS3Metrics, setCVSS3Metrics] = useState<CVSS3MetricI>({
        AV: CVSS3Metric.AV.values.N,
        AC: CVSS3Metric.AC.values.L,
        PR: CVSS3Metric.PR.values.N,
        UI: CVSS3Metric.UI.values.N,
        S: CVSS3Metric.S.values.U,
        C: CVSS3Metric.C.values.N,
        I: CVSS3Metric.I.values.N,
        A: CVSS3Metric.A.values.N
    })

    const [CVSS3TemporalMetrics, setCVSS3TemporalMetrics] = useState<CVSS3TemporalMetricI>({
        E: CVSS3TemporalMetric.E.values.X,
        RL: CVSS3TemporalMetric.RL.values.X,
        RC: CVSS3TemporalMetric.RC.values.X
    })

    const [CVSS3EnvironmentalMetrics, setCVSS3EnvironmentalMetrics] = useState<CVSS3EnvironmentalMetricI>({
        MAV: CVSS3EnvironmentalMetric.MAV.values.X,
        MAC: CVSS3EnvironmentalMetric.MAC.values.X,
        MPR: CVSS3EnvironmentalMetric.MPR.values.X,
        MUI: CVSS3EnvironmentalMetric.MUI.values.X,
        MS: CVSS3EnvironmentalMetric.MS.values.X,
        MC: CVSS3EnvironmentalMetric.MC.values.X,
        MI: CVSS3EnvironmentalMetric.MI.values.X,
        MA: CVSS3EnvironmentalMetric.MA.values.X,
        CR: CVSS3EnvironmentalMetric.CR.values.X,
        IR: CVSS3EnvironmentalMetric.IR.values.X,
        AR: CVSS3EnvironmentalMetric.AR.values.X
    })

    const vulnerabilityService = useContext(ServicesContext).vulnerabilityService
    const { id } = useParams<{ id: string }>()
    const [data, setData] = useState<Vulnerability>(initValue)
    const [error, setError] = useState<Error | null>(null)

    const [CVSS3Vector, setCVSS3Vector] = React.useState("")
    const [CVSS3Score, setCVSS3Score] = React.useState(0)
    const [CVSS3BaseScore, setCVSS3BaseScore] = React.useState(0)
    const [CVSS3ImpactScore, setCVSS3ImpactScore] = React.useState(0)
    const [CVSS3ExplotailabilityScore, setCVSS3ExplotailabilityScore] = React.useState(0)
    const [CVSS3ModifiedImpactScore, setCVSS3ModifiedImpactScore] = React.useState(0)
    const [CVSS3TemporalScore, setCVSS3TemporalScore] = React.useState(0)
    const [CVSS3EnvironmentalScore, setCVSS3EnvironmentalScore] = React.useState(0)
    const [CVSS3OverallScore, setCVSS3OverallScore] = React.useState(0)

    const enable = Object.values(CVSS3Metrics).some(metric => metric === null)
    const enableSaveButton = data.vector !== CVSS3Vector

    // First render to get the data
    useEffect(() => {
        const fecthData = async () => {
            try {
                const val = await vulnerabilityService.get(id as string)
                setData(val)
            } catch (e: any) {
                setError({ message: e.error } as Error)
            }
        }
        fecthData()
    }, [])
    // --------------------------------------------

    // Importa el vector CVSS3
    const importCVSS3 = (cvss: string) => {
        cvss.split("/").forEach((metric) => {
            const [key, value] = metric.split(":")
            if (key in CVSS3Metric) {
                setCVSS3Metrics((prevState) => ({
                    ...prevState,
                    [key]: Object.values(CVSS3Metric[key as keyof typeof CVSS3Metric].values).find((v) => v.metric === value)
                }))
            }
            if (key in CVSS3TemporalMetric) {
                setCVSS3TemporalMetrics((prevState) => ({
                    ...prevState,
                    [key]: Object.values(CVSS3TemporalMetric[key as keyof typeof CVSS3TemporalMetric].values).find((v) => v.metric === value)
                }))
            }
            if (key in CVSS3EnvironmentalMetric) {
                setCVSS3EnvironmentalMetrics((prevState) => ({
                    ...prevState,
                    [key]: Object.values(CVSS3EnvironmentalMetric[key as keyof typeof CVSS3EnvironmentalMetric].values).find((v) => v.metric === value)
                }))
            }
        })
    }
    useEffect(() => {
        importCVSS3(data.vector)
    }, [data])
    // --------------------------------------------

    // Funcion para redondear el score
    function roundUp (input: number): number {
        const intInput = Math.round(input * 100000)
        if ((intInput % 10000) === 0) {
            return intInput / 100000.0
        }
        return (Math.floor(intInput / 10000) + 1) / 10.0
    }
    // --------------------------------------------

    // Calcula el score de la metrica base
    const getCVSS3BaseScore = () => {
        const ISS = 1 - ((1 - CVSS3Metrics.C!.value) * (1 - CVSS3Metrics.I!.value) * (1 - CVSS3Metrics.A!.value))
        const Impact = (CVSS3Metrics.S!.metric === "U" ? 6.42 * ISS : 7.52 * (ISS - 0.029) - 3.25 * Math.pow((ISS - 0.02), 15))
        const Exploitability = 8.22 * CVSS3Metrics.AV!.value * CVSS3Metrics.AC!.value * (typeof CVSS3Metrics.PR?.value === "object" ? 0.85 : CVSS3Metrics.PR!.value) * CVSS3Metrics.UI!.value
        const BaseScore = Impact <= 0 ? 0 : (CVSS3Metrics.S!.metric === "U" ? Math.min((Impact + Exploitability), 10) : Math.min(1.08 * (Impact + Exploitability), 10))
        setCVSS3BaseScore(roundUp(BaseScore))
        setCVSS3ImpactScore(roundUp(Impact))
        setCVSS3ExplotailabilityScore(roundUp(Exploitability))
        return roundUp(BaseScore)
    }

    const getCVSS3TemporalScore = () => {
        if (Object.values(CVSS3TemporalMetrics).every(metric => metric?.metric === "X")) {
            return 0
        }
        const ISS = 1 - ((1 - CVSS3Metrics.C!.value) * (1 - CVSS3Metrics.I!.value) * (1 - CVSS3Metrics.A!.value))
        const Impact = (CVSS3Metrics.S!.metric === "U" ? 6.42 * ISS : 7.52 * (ISS - 0.029) - 3.25 * Math.pow((ISS - 0.02), 13))
        const Exploitability = 8.22 * CVSS3Metrics.AV!.value * CVSS3Metrics.AC!.value * (typeof CVSS3Metrics.PR?.value === "object" ? 0.85 : CVSS3Metrics.PR!.value) * CVSS3Metrics.UI!.value
        const BaseScore = Impact <= 0 ? 0 : (CVSS3Metrics.S!.metric === "U" ? Math.min((Impact + Exploitability), 10) : Math.min(1.08 * (Impact + Exploitability), 10))
        return roundUp(BaseScore * CVSS3TemporalMetrics.E!.value * CVSS3TemporalMetrics.RC!.value * CVSS3TemporalMetrics.RL!.value)
    }

    const getCVSS3EnvironmentalScore = () => {
        if (Object.values(CVSS3EnvironmentalMetrics).every(metric => metric?.metric === "X")) {
            return 0
        }
        const MISS = Math.min(1 - ((1 - CVSS3EnvironmentalMetrics.CR!.value * CVSS3EnvironmentalMetrics.MC!.value) * (1 - CVSS3EnvironmentalMetrics.IR!.value * CVSS3EnvironmentalMetrics.MI!.value) * (1 - CVSS3EnvironmentalMetrics.AR!.value * CVSS3EnvironmentalMetrics.MA!.value)), 0.915)
        const ModifiedImpact = (CVSS3EnvironmentalMetrics.MS!.metric === "U" ? 6.42 * MISS : 7.52 * (MISS - 0.029) - 3.25 * Math.pow(MISS * 0.9731 - 0.02, 13))
        const ModifiedExploitability = 8.22 * CVSS3EnvironmentalMetrics.MAV!.value * CVSS3EnvironmentalMetrics.MAC!.value * (typeof CVSS3EnvironmentalMetrics.MPR?.value === "object" ? 0.85 : CVSS3EnvironmentalMetrics.MPR!.value) * CVSS3EnvironmentalMetrics.MUI!.value
        const EnvironmentalScore = ModifiedImpact <= 0 ? 0 : (CVSS3EnvironmentalMetrics.MS!.metric === "U" ? roundUp(roundUp(Math.min((ModifiedImpact + ModifiedExploitability), 10)) * CVSS3TemporalMetrics.E!.value * CVSS3TemporalMetrics.RC!.value * CVSS3TemporalMetrics.RL!.value) : roundUp(roundUp(Math.min(1.08 * (ModifiedImpact + ModifiedExploitability), 10) * CVSS3TemporalMetrics.E!.value * CVSS3TemporalMetrics.RC!.value * CVSS3TemporalMetrics.RL!.value)))
        setCVSS3ModifiedImpactScore(roundUp(ModifiedImpact))
        return roundUp(EnvironmentalScore)
    }
    // --------------------------------------------

    // Calcula el score de la metrica base
    useEffect(() => {
        let tmp = Object.entries(CVSS3Metrics)
            .filter(([key, value]) => value !== null && typeof value === "object")
            .map(([key, value]) => {
                if (typeof value === "object" && value !== null && "metric" in value) {
                    return `${key}:${value.metric}`
                }
                return ""
            })
            .join("/")
        if (!Object.values(CVSS3TemporalMetrics).every(metric => metric?.metric === "X")) {
            const temporal = Object.entries(CVSS3TemporalMetrics)
                .filter(([key, value]) => value !== null && typeof value === "object")
                .map(([key, value]) => {
                    if (typeof value === "object" && value !== null && "metric" in value) {
                        return `${key}:${value.metric}`
                    }
                    return ""
                })
                .join("/")
            tmp = tmp + "/" + temporal
        }
        if (!Object.values(CVSS3EnvironmentalMetrics).every(metric => metric?.metric === "X")) {
            const environmental = Object.entries(CVSS3EnvironmentalMetrics)
                .filter(([key, value]) => value !== null && typeof value === "object")
                .map(([key, value]) => {
                    if (typeof value === "object" && value !== null && "metric" in value) {
                        return `${key}:${value.metric}`
                    }
                    return ""
                })
                .join("/")
            tmp = tmp + "/" + environmental
        }
        setCVSS3Vector(tmp)
        if (enable) {
            setCVSS3Score(0)
            setCVSS3TemporalScore(0)
            setCVSS3EnvironmentalScore(0)
            setCVSS3BaseScore(0)
            setCVSS3ExplotailabilityScore(0)
            setCVSS3ImpactScore(0)
            setCVSS3ModifiedImpactScore(0)
            setCVSS3OverallScore(0)
        } else {
            setCVSS3BaseScore(getCVSS3BaseScore())
            setCVSS3TemporalScore(getCVSS3TemporalScore())
            setCVSS3EnvironmentalScore(getCVSS3EnvironmentalScore())
        }
    }
    , [CVSS3Metrics, CVSS3TemporalMetrics, CVSS3EnvironmentalMetrics])
    // --------------------------------------------

    // Calcula el score general
    useEffect(() => {
        CVSS3EnvironmentalScore > 0 ? setCVSS3OverallScore(CVSS3EnvironmentalScore) : CVSS3TemporalScore > 0 ? setCVSS3OverallScore(CVSS3TemporalScore) : setCVSS3OverallScore(CVSS3BaseScore)
    }, [CVSS3BaseScore, CVSS3TemporalScore, CVSS3EnvironmentalScore])
    // --------------------------------------------

    // Funcion para cambiar el valor de las metricas
    const handleCVSSChange = (metric: keyof CVSS3MetricI, newValue: any) => {
        if (newValue === null) {
            return
        }
        if (metric === "PR" || metric === "S") {
            if (metric === "PR") {
                if (CVSS3Metrics.S !== null && newValue !== null && newValue.metric !== "N" && typeof newValue.value === "object") {
                    newValue.value = CVSS3Metrics.S?.metric === "C" ? newValue.value.C : newValue.value.U
                }
            } else {
                if (CVSS3Metrics.PR !== null && CVSS3Metrics.PR.metric !== "L" && typeof CVSS3Metrics.PR.value === "object") {
                    if (newValue === "U") {
                        CVSS3Metrics.PR.value = CVSS3Metrics.PR.value.U
                    } else {
                        CVSS3Metrics.PR.value = CVSS3Metrics.PR.value.C
                    }
                }
            }
        }

        setCVSS3Metrics((prevState) => ({
            ...prevState,
            [metric]: newValue
        }))
    }

    const handleTemporalCVSSChange = (metric: keyof CVSS3TemporalMetricI, newValue: any) => {
        if (newValue === null) {
            return
        }
        setCVSS3TemporalMetrics((prevState) => ({
            ...prevState,
            [metric]: newValue
        }))
    }

    const handleEnvironmentalCVSSChange = (metric: keyof CVSS3EnvironmentalMetricI, newValue: any) => {
        if (newValue === null) {
            return
        }
        if (metric === "MPR" || metric === "MS") {
            if (metric === "MPR") {
                if (CVSS3EnvironmentalMetrics.MS !== null && newValue !== null && newValue.metric !== "N" && typeof newValue.value === "object") {
                    newValue.value = CVSS3EnvironmentalMetrics.MS?.metric === "C" ? newValue.value.C : newValue.value.U
                }
            } else {
                if (CVSS3EnvironmentalMetrics.MPR !== null && CVSS3EnvironmentalMetrics.MPR.metric !== "L" && typeof CVSS3EnvironmentalMetrics.MPR.value === "object") {
                    if (newValue === "U") {
                        CVSS3EnvironmentalMetrics.MPR.value = CVSS3EnvironmentalMetrics.MPR.value.U
                    } else {
                        CVSS3EnvironmentalMetrics.MPR.value = CVSS3EnvironmentalMetrics.MPR.value.C
                    }
                }
            }
        }
        setCVSS3EnvironmentalMetrics((prevState) => ({
            ...prevState,
            [metric]: newValue
        }))
    }
    // --------------------------------------------

    //
    const navigate = useNavigate()
    const handlerSave = async () => {
        try {
            data.revised_score = CVSS3OverallScore
            data.vector = CVSS3Vector
            // Verificar si el vector no comienza con "CVSS", en caso afirmativo, añadir "CVSS:3.0/"
            if (!data.vector.startsWith("CVSS")) {
                data.vector = `CVSS:3.0/${data.vector}`
            }
            await vulnerabilityService.update(id, data)
            navigate(-1)
        } catch (e: any) {
            setError({ message: e.error } as Error)
        }
    }
    //

    const StyledToggleButton = styled(ToggleButton)<ToggleButtonProps>(({ theme }) => ({
        borderRadius: "8px", // Añadir borde redondeado
        "&.Mui-selected": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.secondary.contrastText,
            border: `1px solid ${theme.palette.primary.main}`,
            "&:hover": {
                backgroundColor: theme.palette.primary.main
            }
        },
        "&:not(.Mui-selected)": {
            backgroundColor: theme.palette.secondary.contrastText,
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
            "&:hover": {
                backgroundColor: theme.palette.secondary.contrastText
            }
        }
    }))

    return (
        <Grid item xs container flexDirection="column">
            <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                {context.t.translate("cvvs_3_1_calculator")}
            </Typography>
            <Box sx={{ backgroundColor: theme.palette.secondary.contrastText, color: "white", padding: "20px" }}>
                <Grid container xs={12} display="flex" flexDirection="row" spacing={3}>
                    <Grid item xs={9}>
                        <Box sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "10px",
                            backgroundColor: "#1e1e1f",
                            borderRadius: "10px",
                            border: "1px solid #6D6C6F"
                        }}>
                            <Grid container xs={4} flexDirection="row" justifyItems="center" justifyContent="center" alignItems="center">
                                <Grid item xs={12} textAlign="center">
                                    <Typography fontSize="34px" fontFamily="Griff" color={theme.palette.primary.contrastText} fontWeight="bolder">
                                        {context.t.translate("base")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} display="flex" justifyContent="center">
                                    <BaseColorGauge baseMetrics={{ exploitability: CVSS3ExplotailabilityScore, impact: CVSS3ImpactScore }} />
                                </Grid>
                            </Grid>
                            <Divider orientation="vertical" flexItem />
                            <Grid container xs={4} flexDirection="row" justifyItems="center" justifyContent="center" alignItems="center" >
                                <Grid item xs={12} textAlign="center">
                                    <Typography fontSize="34px" fontFamily="Griff" color={theme.palette.primary.contrastText} fontWeight="bolder">
                                        {context.t.translate("temporal")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} display="flex" justifyContent="center" >
                                    <TemporalColorGauge temporal={CVSS3TemporalScore} />
                                </Grid>
                            </Grid>

                            <Divider orientation="vertical" flexItem />
                            <Grid container xs={4} flexDirection="row" justifyItems="center" justifyContent="center" alignItems="center">
                                <Grid item xs={12} textAlign="center">
                                    <Typography fontSize="34px" fontFamily="Griff" color={theme.palette.primary.contrastText} fontWeight="bolder">
                                        {context.t.translate("environmental")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} display="flex" justifyContent="center" >
                                    <EnvironmentalColorGauge environmentalMetrics={{ exploitability: CVSS3EnvironmentalScore, impact: CVSS3ModifiedImpactScore }} />
                                </Grid>
                            </Grid>

                        </Box>
                    </Grid>

                    <Grid item xs={3}>

                        <Box sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "10px",
                            backgroundColor: "#1e1e1f",
                            borderRadius: "10px",
                            border: `1px solid ${theme.palette.primary.main}`,
                            marginBottom: "20px"
                        }}>

                            <Grid container xs={12} flexDirection="row" justifyItems="center" justifyContent="center">
                                <Grid container xs={12} flexDirection="row" justifyItems="center" justifyContent="center" alignItems="center">
                                    <Grid item xs={12} textAlign="center">
                                        <Typography fontSize="34px" fontFamily="Griff" color={theme.palette.primary.contrastText} fontWeight="bolder">
                                            {context.t.translate("overall")}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} display="flex" justifyContent="center">
                                        <GradiantGauge value={CVSS3OverallScore} />
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} sx={{ marginTop: "-120px", zIndex: 200 }} justifyItems="center" justifyContent="center">
                                    <Grid item xs={12} textAlign="center">
                                        <Typography fontSize="34px" fontFamily="Griff" color={theme.palette.primary.contrastText} fontWeight="bolder">
                                            {context.t.translate("cvss_vector")}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} textAlign="center">
                                        <Typography fontSize="18px" fontFamily="Griff" color="#4ea7c3" sx={{ wordWrap: "break-word" }}>
                                            {CVSS3Vector}
                                        </Typography>
                                    </Grid>
                                    <Grid item container xs={12} alignItems="center" justifyContent="center">
                                        <Grid item>
                                            <HelpIcon style={{ color: "#4ea7c3", marginRight: "5px", width: "15px" }} />
                                        </Grid>
                                        <Grid item>
                                            <Link
                                                href="https://nvd.nist.gov/vuln-metrics/cvss/v3-calculator"
                                                style={{
                                                    fontSize: "14px",
                                                    fontFamily: "Griff",
                                                    color: "#4ea7c3",
                                                    textDecoration: "none"
                                                }}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <Typography
                                                    style={{
                                                        fontSize: "inherit",
                                                        fontFamily: "inherit",
                                                        color: "inherit",
                                                        display: "inline"
                                                    }}
                                                >
                                                    {context.t.translate("information_about")}
                                                </Typography>
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container xs={12} display="flex" flexDirection="row" spacing="20px" mt="10px" justifyContent="center">
                    <Grid xs={9} justifyContent="space-around">
                        <Tabs value={tabIndex} onChange={handleTabChange} centered>
                            <Tab label= {context.t.translate("base_score")} />
                            <Tab label= {context.t.translate("temporal_score")} />
                            <Tab label= {context.t.translate("environmental_score")} />
                        </Tabs>
                    </Grid>
                    <Grid xs={3}>
                        <Button disabled={!enableSaveButton} variant="contained" fullWidth sx={{ color: "black", fontWeight: "bold" }} onClick={handlerSave}>{context.t.translate("save")}</Button>
                    </Grid>
                </Grid>
                <Grid container flexDirection="column" >
                    <Grid item xs={12} padding="30px">
                        {tabIndex === 0 && (
                            <Grid container spacing="20px" my={2} alignItems="flex-start">
                                <Grid container my={2} alignItems="flex-start" style={{ paddingLeft: "20px" }}>
                                    <Grid container item xs={12} spacing="15px" sx={{ paddingBottom: "25px" }}>
                                        <Grid item xs={12}>
                                            <Typography fontSize="24px" fontFamily="Griff" color={theme.palette.primary.contrastText} fontWeight="bolder">{context.t.translate("exploitability")}</Typography>
                                        </Grid>
                                        <Grid item container xs={12} md={8}>
                                            <Grid item xs={12} style={{ marginBottom: "5px" }}>
                                                <Typography fontSize="16px" fontFamily="Griff" color={theme.palette.primary.contrastText} >{context.t.translate("attack_vector")}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <ToggleButtonGroup fullWidth value={CVSS3Metrics.AV} exclusive>
                                                    {Object.entries(CVSS3Metric.AV.values).map(([key, value]) => (
                                                        <StyledToggleButton key={key} value={value}>
                                                            {value.label} (AV:{value.metric})
                                                        </StyledToggleButton >
                                                    ))}
                                                </ToggleButtonGroup>
                                            </Grid>
                                        </Grid>
                                        <Grid item container xs={12} md={4}>
                                            <Grid item xs={12} style={{ marginBottom: "5px" }}>
                                                <Typography fontSize="16px" fontFamily="Griff" color={theme.palette.primary.contrastText} >{context.t.translate("attack_complexity")}</Typography>
                                            </Grid>
                                            <Grid item xs={12} justifySelf="flex-end">
                                                <ToggleButtonGroup fullWidth value={CVSS3Metrics.AC} exclusive>
                                                    {Object.entries(CVSS3Metric.AC.values).map(([key, value]) => (
                                                        <StyledToggleButton key={key} value={value}>
                                                            {value.label} (AC:{value.metric})
                                                        </StyledToggleButton>
                                                    ))}
                                                </ToggleButtonGroup>
                                            </Grid>
                                        </Grid>
                                        <Grid item container xs={12} md={4}>
                                            <Grid item xs={12} style={{ marginBottom: "5px" }}>
                                                <Typography fontSize="16px" fontFamily="Griff" color={theme.palette.primary.contrastText} >{context.t.translate("pribileges_required")}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <ToggleButtonGroup fullWidth value={CVSS3Metrics.PR} exclusive>
                                                    {Object.entries(CVSS3Metric.PR.values).map(([key, value]) => (
                                                        <StyledToggleButton key={key} value={value}>
                                                            {value.label} (PR:{value.metric})
                                                        </StyledToggleButton>
                                                    ))}
                                                </ToggleButtonGroup>
                                            </Grid>
                                        </Grid>
                                        <Grid item container xs={12} md={4}>
                                            <Grid item xs={12} style={{ marginBottom: "5px" }}>
                                                <Typography fontSize="16px" fontFamily="Griff" color={theme.palette.primary.contrastText} >{context.t.translate("user_interaction")}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <ToggleButtonGroup fullWidth value={CVSS3Metrics.UI} exclusive>
                                                    {Object.entries(CVSS3Metric.UI.values).map(([key, value]) => (
                                                        <StyledToggleButton key={key} value={value}>
                                                            {value.label} (UI:{value.metric})
                                                        </StyledToggleButton>
                                                    ))}
                                                </ToggleButtonGroup>
                                            </Grid>
                                        </Grid>
                                        <Grid item container xs={12} md={4}>
                                            <Grid item xs={12} style={{ marginBottom: "5px" }}>
                                                <Typography fontSize="16px" fontFamily="Griff" color={theme.palette.primary.contrastText} >{context.t.translate("scope")}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <ToggleButtonGroup fullWidth value={CVSS3Metrics.S} exclusive>
                                                    {Object.entries(CVSS3Metric.S.values).map(([key, value]) => (
                                                        <StyledToggleButton key={key} value={value}>
                                                            {value.label} (S:{value.metric})
                                                        </StyledToggleButton>
                                                    ))}
                                                </ToggleButtonGroup>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Divider sx={{ width: "100%", border: "1px solid #6D6C6F" }} />
                                    <Grid container item xs={12} spacing="25px" alignItems="flex-start" sx={{ paddingTop: "25px" }} >
                                        <Grid item xs={12}>
                                            <Typography fontSize="24px" fontFamily="Griff" color={theme.palette.primary.contrastText} fontWeight="bolder">{context.t.translate("impact_metrics")}</Typography>
                                        </Grid>
                                        <Grid item container xs={4}>
                                            <Grid item xs={12} style={{ marginBottom: "5px" }}>
                                                <Typography fontSize="16px" fontFamily="Griff" color={theme.palette.primary.contrastText} >{context.t.translate("confidentiality_imparct")}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <ToggleButtonGroup fullWidth value={CVSS3Metrics.C} exclusive>
                                                    {Object.entries(CVSS3Metric.C.values).map(([key, value]) => (
                                                        <StyledToggleButton key={key} value={value}>
                                                            {value.label} (C:{value.metric})
                                                        </StyledToggleButton>
                                                    ))}
                                                </ToggleButtonGroup>
                                            </Grid>
                                        </Grid>
                                        <Grid item container xs={4}>
                                            <Grid item xs={12} style={{ marginBottom: "5px" }}>
                                                <Typography fontSize="16px" fontFamily="Griff" color={theme.palette.primary.contrastText} >{context.t.translate("integrity_impact")}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <ToggleButtonGroup fullWidth value={CVSS3Metrics.I} exclusive>
                                                    {Object.entries(CVSS3Metric.I.values).map(([key, value]) => (
                                                        <StyledToggleButton key={key} value={value}>
                                                            {value.label} (I:{value.metric})
                                                        </StyledToggleButton>
                                                    ))}
                                                </ToggleButtonGroup>
                                            </Grid>
                                        </Grid>
                                        <Grid item container xs={4}>
                                            <Grid item xs={12} style={{ marginBottom: "5px" }}>
                                                <Typography fontSize="16px" fontFamily="Griff" color={theme.palette.primary.contrastText} >{context.t.translate("availability_impact")}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <ToggleButtonGroup fullWidth value={CVSS3Metrics.A} exclusive>
                                                    {Object.entries(CVSS3Metric.A.values).map(([key, value]) => (
                                                        <StyledToggleButton key={key} value={value}>
                                                            {value.label} (A:{value.metric})
                                                        </StyledToggleButton>
                                                    ))}
                                                </ToggleButtonGroup>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                        {tabIndex === 1 && (
                            <Grid container spacing="20px" my={2} alignItems="flex-start">
                                <Grid container item xs={12} spacing="15px">
                                    <Grid item container >
                                        <Grid item xs={12} style={{ marginBottom: "5px" }}>
                                            <Typography fontSize="16px" fontFamily="Griff" color={theme.palette.primary.contrastText}>{context.t.translate("exploit_code")}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid item xs={12}>
                                                <ToggleButtonGroup fullWidth value={CVSS3TemporalMetrics.E} exclusive onChange={(event, newValue) => handleTemporalCVSSChange("E", newValue)}>
                                                    {Object.entries(CVSS3TemporalMetric.E.values).map(([key, value]) => (
                                                        <StyledToggleButton key={key} value={value}>
                                                            {value.label} (E:{value.metric})
                                                        </StyledToggleButton>
                                                    ))}
                                                </ToggleButtonGroup>
                                            </Grid>

                                        </Grid>
                                        <Grid item container>
                                            <Grid item xs={12} style={{ marginBottom: "5px", marginTop: "15px" }}>
                                                <Typography fontSize="16px" fontFamily="Griff" color={theme.palette.primary.contrastText}>{context.t.translate("remediation_level")}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <ToggleButtonGroup fullWidth value={CVSS3TemporalMetrics.RL} exclusive onChange={(event, newValue) => handleTemporalCVSSChange("RL", newValue)}>
                                                    {Object.entries(CVSS3TemporalMetric.RL.values).map(([key, value]) => (
                                                        <StyledToggleButton key={key} value={value}>
                                                            {value.label} (RL:{value.metric})
                                                        </StyledToggleButton>
                                                    ))}
                                                </ToggleButtonGroup>
                                            </Grid>
                                        </Grid>
                                        <Grid item container>
                                            <Grid item xs={12} style={{ marginBottom: "5px", marginTop: "15px" }}>
                                                <Typography fontSize="16px" fontFamily="Griff" color={theme.palette.primary.contrastText}>{context.t.translate("report_confidence")}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <ToggleButtonGroup fullWidth value={CVSS3TemporalMetrics.RC} exclusive onChange={(event, newValue) => handleTemporalCVSSChange("RC", newValue)}>
                                                    {Object.entries(CVSS3TemporalMetric.RC.values).map(([key, value]) => (
                                                        <StyledToggleButton key={key} value={value}>
                                                            {value.label} (RC:{value.metric})
                                                        </StyledToggleButton>
                                                    ))}
                                                </ToggleButtonGroup>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                        {tabIndex === 2 && (
                            <Grid container spacing="20px" my={2} alignItems="flex-start">
                                <Grid container my={2} alignItems="flex-start" style={{ paddingLeft: "20px" }}>
                                    <Grid container item xs={12} spacing="15px" sx={{ paddingBottom: "25px" }} justifyContent="space-between">
                                        <Grid item xs={12}>
                                            <Typography fontSize="24px" fontFamily="Griff" color={theme.palette.primary.contrastText} fontWeight="bolder">{context.t.translate("exploitability_metrics")}</Typography>
                                        </Grid>
                                        <Grid item container>
                                            <Grid item xs={12} style={{ marginBottom: "5px" }}>
                                                <Typography fontSize="16px" fontFamily="Griff" color={theme.palette.primary.contrastText} >{context.t.translate("attack_vector_m")}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid item xs={12}>
                                                    <ToggleButtonGroup fullWidth value={CVSS3EnvironmentalMetrics.MAV} exclusive onChange={(event, newValue) => handleEnvironmentalCVSSChange("MAV", newValue)}>
                                                        {Object.entries(CVSS3EnvironmentalMetric.MAV.values).map(([key, value]) => (
                                                            <StyledToggleButton key={key} value={value}>
                                                                {value.label} (MAV:{value.metric})
                                                            </StyledToggleButton>
                                                        ))}
                                                    </ToggleButtonGroup>
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                        <Grid item container>
                                            <Grid item xs={12} style={{ marginBottom: "5px" }}>
                                                <Typography fontSize="16px" fontFamily="Griff" color={theme.palette.primary.contrastText} >{context.t.translate("attack_complexity_m")}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <ToggleButtonGroup fullWidth value={CVSS3EnvironmentalMetrics.MAC} exclusive onChange={(event, newValue) => handleEnvironmentalCVSSChange("MAC", newValue)}>
                                                    {Object.entries(CVSS3EnvironmentalMetric.MAC.values).map(([key, value]) => (
                                                        <StyledToggleButton key={key} value={value}>
                                                            {value.label} (MAC:{value.metric})
                                                        </StyledToggleButton>
                                                    ))}
                                                </ToggleButtonGroup>
                                            </Grid>
                                        </Grid>
                                        <Grid item container xs={4}>
                                            <Grid item xs={12} style={{ marginBottom: "5px" }}>
                                                <Typography fontSize="16px" fontFamily="Griff" color={theme.palette.primary.contrastText} >{context.t.translate("privileges_required")}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <ToggleButtonGroup fullWidth value={CVSS3EnvironmentalMetrics.MPR} exclusive onChange={(event, newValue) => handleEnvironmentalCVSSChange("MPR", newValue)}>
                                                    {Object.entries(CVSS3EnvironmentalMetric.MPR.values).map(([key, value]) => (
                                                        <StyledToggleButton key={key} value={value}>
                                                            {value.label} (MPR:{value.metric})
                                                        </StyledToggleButton>
                                                    ))}
                                                </ToggleButtonGroup>
                                            </Grid>
                                        </Grid>
                                        <Grid item container xs={4}>
                                            <Grid item xs={12} style={{ marginBottom: "5px" }}>
                                                <Typography fontSize="16px" fontFamily="Griff" color={theme.palette.primary.contrastText} >{context.t.translate("user_interaction_m")}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <ToggleButtonGroup fullWidth value={CVSS3EnvironmentalMetrics.MUI} exclusive onChange={(event, newValue) => handleEnvironmentalCVSSChange("MUI", newValue)}>
                                                    {Object.entries(CVSS3EnvironmentalMetric.MUI.values).map(([key, value]) => (
                                                        <StyledToggleButton key={key} value={value}>
                                                            {value.label} (MUI:{value.metric})
                                                        </StyledToggleButton>
                                                    ))}
                                                </ToggleButtonGroup>
                                            </Grid>
                                        </Grid>
                                        <Grid item container xs={4}>
                                            <Grid item xs={12} style={{ marginBottom: "5px" }}>
                                                <Typography fontSize="16px" fontFamily="Griff" color={theme.palette.primary.contrastText} >{context.t.translate("scope_ms")}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <ToggleButtonGroup fullWidth value={CVSS3EnvironmentalMetrics.MS} exclusive onChange={(event, newValue) => handleEnvironmentalCVSSChange("MS", newValue)}>
                                                    {Object.entries(CVSS3EnvironmentalMetric.MS.values).map(([key, value]) => (
                                                        <StyledToggleButton key={key} value={value}>
                                                            {value.label} (MS:{value.metric})
                                                        </StyledToggleButton>
                                                    ))}
                                                </ToggleButtonGroup>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Divider sx={{ width: "100%", border: "1px solid #6D6C6F" }} />
                                    <Grid container item xs={12} spacing="25px" alignItems="flex-start" sx={{ paddingTop: "25px", marginBottom: "25px" }} >
                                        <Grid item xs={12}>
                                            <Typography fontSize="24px" fontFamily="Griff" color={theme.palette.primary.contrastText} fontWeight="bolder">{context.t.translate("impact_metrics")}</Typography>
                                        </Grid>
                                        <Grid item container xs={4}>
                                            <Grid item xs={12} style={{ marginBottom: "5px" }}>
                                                <Typography fontSize="16px" fontFamily="Griff" color={theme.palette.primary.contrastText} >{context.t.translate("confidentiality_imparct_m")}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <ToggleButtonGroup fullWidth value={CVSS3EnvironmentalMetrics.MC} exclusive onChange={(event, newValue) => handleEnvironmentalCVSSChange("MC", newValue)}>
                                                    {Object.entries(CVSS3EnvironmentalMetric.MC.values).map(([key, value]) => (
                                                        <StyledToggleButton key={key} value={value}>
                                                            {value.label} (MC:{value.metric})
                                                        </StyledToggleButton>
                                                    ))}
                                                </ToggleButtonGroup>
                                            </Grid>
                                        </Grid>
                                        <Grid item container xs={4}>
                                            <Grid item xs={12} style={{ marginBottom: "5px" }}>
                                                <Typography fontSize="16px" fontFamily="Griff" color={theme.palette.primary.contrastText} >{context.t.translate("integrity_impact_m")}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <ToggleButtonGroup fullWidth value={CVSS3EnvironmentalMetrics.MI} exclusive onChange={(event, newValue) => handleEnvironmentalCVSSChange("MI", newValue)}>
                                                    {Object.entries(CVSS3EnvironmentalMetric.MI.values).map(([key, value]) => (
                                                        <StyledToggleButton key={key} value={value}>
                                                            {value.label} (MI:{value.metric})
                                                        </StyledToggleButton>
                                                    ))}
                                                </ToggleButtonGroup>
                                            </Grid>
                                        </Grid>
                                        <Grid item container xs={4}>
                                            <Grid item xs={12} style={{ marginBottom: "5px" }}>
                                                <Typography fontSize="16px" fontFamily="Griff" color={theme.palette.primary.contrastText} >{context.t.translate("availability_impact_m")}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <ToggleButtonGroup fullWidth value={CVSS3EnvironmentalMetrics.MA} exclusive onChange={(event, newValue) => handleEnvironmentalCVSSChange("MA", newValue)}>
                                                    {Object.entries(CVSS3EnvironmentalMetric.MA.values).map(([key, value]) => (
                                                        <StyledToggleButton key={key} value={value}>
                                                            {value.label} (MA:{value.metric})
                                                        </StyledToggleButton>
                                                    ))}
                                                </ToggleButtonGroup>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Divider sx={{ width: "100%", border: "1px solid #6D6C6F" }} />
                                    <Grid container item xs={12} spacing="25px" alignItems="flex-start" sx={{ paddingTop: "25px" }} >
                                        <Grid item xs={12}>
                                            <Typography fontSize="24px" fontFamily="Griff" color={theme.palette.primary.contrastText} fontWeight="bolder">{context.t.translate("impact_subscore")}</Typography>
                                        </Grid>
                                        <Grid item container xs={4}>
                                            <Grid item xs={12} style={{ marginBottom: "5px" }}>
                                                <Typography fontSize="16px" fontFamily="Griff" color={theme.palette.primary.contrastText} >{context.t.translate("confidentiality_requirement")}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <ToggleButtonGroup fullWidth value={CVSS3EnvironmentalMetrics.CR} exclusive onChange={(event, newValue) => handleEnvironmentalCVSSChange("CR", newValue)}>
                                                    {Object.entries(CVSS3EnvironmentalMetric.CR.values).map(([key, value]) => (
                                                        <StyledToggleButton key={key} value={value}>
                                                            {value.label} (CR:{value.metric})
                                                        </StyledToggleButton>
                                                    ))}
                                                </ToggleButtonGroup>
                                            </Grid>
                                        </Grid>
                                        <Grid item container xs={4}>
                                            <Grid item xs={12} style={{ marginBottom: "5px" }}>
                                                <Typography fontSize="16px" fontFamily="Griff" color={theme.palette.primary.contrastText} >{context.t.translate("integrity_requirement")}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <ToggleButtonGroup fullWidth value={CVSS3EnvironmentalMetrics.IR} exclusive onChange={(event, newValue) => handleEnvironmentalCVSSChange("IR", newValue)}>
                                                    {Object.entries(CVSS3EnvironmentalMetric.IR.values).map(([key, value]) => (
                                                        <StyledToggleButton key={key} value={value}>
                                                            {value.label} (I:{value.metric})
                                                        </StyledToggleButton>
                                                    ))}
                                                </ToggleButtonGroup>
                                            </Grid>
                                        </Grid>
                                        <Grid item container xs={4}>
                                            <Grid item xs={12} style={{ marginBottom: "5px" }}>
                                                <Typography fontSize="16px" fontFamily="Griff" color={theme.palette.primary.contrastText} >{context.t.translate("availability_requirement")}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <ToggleButtonGroup fullWidth value={CVSS3EnvironmentalMetrics.AR} exclusive onChange={(event, newValue) => handleEnvironmentalCVSSChange("AR", newValue)}>
                                                    {Object.entries(CVSS3EnvironmentalMetric.AR.values).map(([key, value]) => (
                                                        <StyledToggleButton key={key} value={value}>
                                                            {value.label} (AR:{value.metric})
                                                        </StyledToggleButton>
                                                    ))}
                                                </ToggleButtonGroup>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Box>

        </Grid>
    )
}

export default CVSSCalcultor
