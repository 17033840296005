import React from "react"
import { MenuItem, Select, Typography } from "@mui/material"

interface SectionSelectorProps {
    selectedSection: string
    onSectionChange: (section: string) => void
    sections: string[]
}

const SectionSelector: React.FC<SectionSelectorProps> = ({
    selectedSection,
    onSectionChange,
    sections
}) => {
    return (
        <div style={{ marginBottom: "20px" }}>
            <Typography variant="subtitle1" gutterBottom>
                Select a section to edit:
            </Typography>
            <Select
                value={selectedSection}
                onChange={(e) => onSectionChange(e.target.value)}
                fullWidth
                variant="filled"
            >
                {sections.map((section, index) => (
                    <MenuItem key={index} value={section}>
                        {section}
                    </MenuItem>
                ))}
            </Select>
        </div>
    )
}

export default SectionSelector
