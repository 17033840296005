import { CustomAction } from "@components/common/tables"
import { Can } from "@components/permissions"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import { VulnReportDownloader, VulnReportUploader } from "@components/report/VulnReportUpDownloader"
import { AssessmentExt, AssessmentStatus, AssessmentType } from "@models/Assessment"
import { AlertColor, Box, CircularProgress, Grid, IconButton, Tooltip } from "@mui/material"
import { GridRowParams } from "@mui/x-data-grid"
import { BiSolidComponent } from "react-icons/bi"
import CalculateIcon from "@mui/icons-material/Calculate"
import React, { useContext, useState } from "react"
import { TbAnalyze } from "react-icons/tb"
import { I18nContext } from "I18nProvider"
import GenericModal from "@components/common/modals/GenericModal"
import AchillesForm from "@views/achilles/AchillesForm"

export const generateCustomActions = (
    params: GridRowParams<AssessmentExt>,
    navigate: (path: string) => void,
    RequestAnalyze: (id: string) => Promise<void>,
    setError: React.Dispatch<React.SetStateAction<Error | null>>,
    handleOpenComponents: (id: string) => Promise<void>,
    // handleOpenTemplates: (id: string) => void,
    ability: any, // Pasa la capacidad del contexto AbilityContext si es necesario
    reloadTableData?: () => void,
    handleSnackbar?: (message: string, severity: AlertColor) => void,
    openDialog?: () => void
): CustomAction[] => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const actions: CustomAction[] = []

    // Condiciones adicionales para añadir acciones
    if (
        params.row &&
        params.row.status === AssessmentStatus.Planned &&
        params.row.type !== AssessmentType.LogicalPentest &&
        params.row.type !== AssessmentType.CRT
    ) {
        actions.push({

            icon: (
                <Can I="create" an="File" key="vexUploader">
                    <VulnReportUploader
                        setError={setError}
                        simplified={false}
                        assessment={params.row}
                        onUploadSuccess={() => {
                            if (handleSnackbar) {
                                handleSnackbar(context.t.translate("file_uploaded"), context.t.translate("succes"))
                            }
                        }}
                    />
                </Can>
            ),
            visualize: true,
            title: context.t.translate("upload_sbom")
        })
    }

    if (
        params.row &&
        params.row.status === AssessmentStatus.Planned &&
        params.row.type === AssessmentType.CRT
    ) {
        const [openThisModal, setOpenThisModal] = useState<boolean>(false)
        actions.push({

            icon: (
                <Can I="create" an="File" key="vexUploader">
                    <Tooltip title="Upload CRT">
                        <IconButton
                            size="small"
                            component="label"
                            onClick={() => setOpenThisModal(true)}
                            disabled={false}
                        >
                            <CloudUploadIcon />
                        </IconButton>
                    </Tooltip>
                    <GenericModal open={openThisModal} onClose={() => { setOpenThisModal(false) }} title="">
                        <AchillesForm
                            assessmentID={params.row.id}
                            onUploadSuccess={() => setOpenThisModal(false)}
                        />
                    </GenericModal>
                </Can>
            ),
            visualize: true,
            title: "Upload multiples Achilles"
        })
    }

    if (params.row && params.row.status === AssessmentStatus.Ended) {
        actions.push({
            icon: (
                <Can I="read" an="Vulnerability" key="vexDownloader">
                    <VulnReportDownloader
                        setError={setError}
                        simplified={false}
                        assessment={params.row}
                        handleSnackbar={(message, severity) => {
                            if (handleSnackbar) {
                                handleSnackbar(message, severity)
                            }
                        }}
                    />
                </Can>
            ),
            visualize: true
        })
    }

    if (
        params.row &&
      params.row.status === AssessmentStatus.Ended &&
      params.row.type === AssessmentType.SCA
    ) {
        actions.push(
            {
                icon: (
                    <Grid container style={{ margin: "0px 20px" }}>
                        <Box
                            key="analyze"
                            sx={{
                                position: "relative",
                                display: "inline-flex",
                                height: "100%",
                                width: "100%",
                                top: "0px"
                            }}
                        >
                            <CircularProgress
                                variant="determinate"
                                value={
                                    params.row.analysis_status === undefined
                                        ? 0
                                        : params.row.analysis_status! * 16.6666
                                }
                                size={32}
                                style={{
                                    position: "absolute" as "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)"
                                }}
                            />
                            <Box
                                sx={{
                                    top: 0,
                                    left: 0,
                                    bottom: 0,
                                    right: 0,
                                    position: "absolute",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}
                            >
                                <Tooltip title={context.t.translate("reanalyze")}>
                                    <IconButton
                                        key="analyze"
                                        onClick={() => RequestAnalyze(params.row.id)}
                                        sx={{ width: 33, height: 33 }}
                                    >
                                        <TbAnalyze size={20} />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Box>
                    </Grid>
                ),
                onClick: () => RequestAnalyze(params.row.id),
                visualize: false,
                title: context.t.translate("reanalyze")
            },
            {
                icon: (
                    <Can I="create" an="File" key="score">
                        <Tooltip title={context.t.translate("SBOM_Metrics")}>
                            <IconButton size="small" onClick={() => navigate(`./${params.row.id}/sbomQuality`)}>
                                <CalculateIcon />
                            </IconButton>
                        </Tooltip>
                    </Can>
                ),
                visualize: false,
                title: context.t.translate("SBOM_Metrics"),
                onClick: () => navigate(`./${params.row.id}/sbomQuality`)
            },
            {
                icon: (
                    <Can I="read" an="Vulnerability" key="components">
                        <Tooltip title={context.t.translate("components")}>
                            <IconButton size="small" onClick={() => handleOpenComponents(params.id as string)}>
                                <BiSolidComponent />
                            </IconButton>
                        </Tooltip>
                    </Can>
                ),
                visualize: false,
                title: context.t.translate("components"),
                onClick: () => handleOpenComponents(params.id as string)
            }
        )
    }
    /*
    if (params.row.template_id !== "00000000-0000-0000-0000-000000000000") {
        actions.push({
            icon: (
                <Can I="create" an="Template" key="templates">
                    <Tooltip title="Templates">
                        <IconButton size="small" onClick={() => handleOpenTemplates(params.row.template_id as string)}>
                            <CgTemplate />
                        </IconButton>
                    </Tooltip>
                </Can>
            ),
            visualize: true,
            title: "Templates",
            onClick: () => handleOpenTemplates(params.row.template_id as string)
        })
    } */

    return actions
}
