import HardwareComponents from "@models/HardwareComponents"
import { MediaTypes } from "@services/HttpService"
import Service from "@services/abstract/BaseService"

class HardwareComponentService extends Service<HardwareComponents> {
    public constructor () {
        super(process.env.REACT_APP_API, "api/v1/hardwareComponent", MediaTypes.JSON)
    }

    public getSecurityAdvisories = (id: string) => {
        return this.http.getMultiple(`/${this.path}/${id}/security`) as any
    }
}

export { HardwareComponentService }
export default HardwareComponentService
