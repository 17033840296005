import React from "react"
import { Image, Text, View } from "@react-pdf/renderer"
import Template from "@models/Template"
import styles from "../Styles"

const Header: React.FC<{template?: Template}> = ({ template }) => {
    let imagen
    let isCustomLogo = false
    if (template?.logo && template.logo !== "") {
        const byteArray = Uint8Array.from(atob(template.logo.toString()), c => c.charCodeAt(0))
        const blob = new Blob([byteArray], { type: "image/png" })
        imagen = URL.createObjectURL(blob)
        isCustomLogo = true
    } else {
        imagen = "./assets/img/report/encabezado.jpg"
    }
    return (
        <View fixed style={{ marginBottom: isCustomLogo ? 150 : 0 }}>
            <Image style={
                isCustomLogo
                    ? { position: "absolute", top: -30, left: 20, width: 220, height: 220, objectFit: "contain" }
                    : styles.image4
            } src={imagen} />
            {
                !isCustomLogo && <>
                    <Text style={{ ...styles.textoEncima }}>SW Composition Analysis</Text>
                    <Text>&nbsp;</Text>
                </>}
        </View>
    )
}

const Footer: React.FC<{color?: string}> = ({ color }) => (
    <View fixed style={{ width: "100%", position: "absolute", backgroundColor: color || "#fffa37", flexDirection: "row", justifyContent: "space-between", columnGap: 20, bottom: 0, padding: 15 }}>
        <View style={{ flex: 1 }}>
            <Text style={{ fontSize: 5.5 }}>
            Los derechos de propiedad intelectual e industrial sobre el presente documento son titularidad exclusiva de ORBIK Cybersecurity, S.L. Asimismo, el presente informe y, en su caso, cualquier documento anexo al
            mismo, contiene información de carácter confidencial exclusivamente dirigida a su destinatario.
                <Text style={{ fontFamily: "Bold", color: "#FFC000", backgroundColor: "black" }}>TLP-AMBER</Text>  Queda prohibida su divulgación, copia o distribución a terceros sin la previa autorización escrita de
            ORBIK Cybersecurity. En el caso de haber recibido este informe por error, proceda a su borrado y notifíquelo inmediatamente a ORBIK Cybersecurity, S.L.
            </Text>
        </View>

        <View style={{ flex: 1, textAlign: "right", alignItems: "flex-end", justifyContent: "center" }}>
            <Text style={{ fontSize: 13 }} render={({ pageNumber }) => `${pageNumber}`} />
        </View>
    </View>
)

export { Header, Footer }
