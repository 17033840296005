import React, { useContext, useEffect, useState } from "react"
import { Grid, Typography, useTheme } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid"
import { useNavigate } from "react-router-dom"
import { GenericTable } from "@components/common/tables/index"
import { convertModelDefToGridColDef } from "@components/common/tables/utils"
import { AbilityContext, Can } from "@components/permissions/index"
import { ServicesContext } from "@context/index"
import { Template, TemplateDefinition } from "@models/index"
import ActionButton from "@components/common/Button/ActionButton"
import { useTrack } from "@components/track/TrackContext"

const TemplateList: React.FC = () => {
    const theme = useTheme()
    const templateService = useContext(ServicesContext).templateService

    const navigate = useNavigate()
    const [cols, setCols] = useState<GridColDef[]>([])
    const { track, trackData } = useTrack()

    const ability = useContext(AbilityContext)
    useEffect(() => {
        track({ view: "TemplateList" })
        setCols(convertModelDefToGridColDef(TemplateDefinition, ability))
    }, [])

    return (
        <Grid item xs container flexDirection="column" spacing="20px">
            <Grid container sx={{ justifyContent: "space-between", alignItems: "center", spacing: "20px", paddingLeft: "20px", marginBottom: "0px", paddingBottom: "0px" }}>
                <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff" sx={{ marginTop: "0px", marginLeft: "20px" }}>Templates</Typography>
                <Grid>
                    <Can I="create" a="Template">
                        <ActionButton onClick={() => navigate("./add")} text="New Template"/>
                    </Can>
                </Grid>
            </Grid>
            <Grid item container flexDirection="column" rowGap="35px">
                <GenericTable<Template> entity="Template" columns={cols}
                    dataProvider={(filter) => templateService.getAll(filter)}
                    onEdit={(elem: Template) => navigate("./" + elem.id)}
                    onDelete={(elem: Template) => templateService.delete(elem.id)}
                />

            </Grid >
        </Grid>
    )
}

export { TemplateList }
export default TemplateList
