import React from "react"
import { Tooltip, Typography } from "@mui/material"
import WarningIcon from "@mui/icons-material/Warning"
import Component from "@models/Component"

type LicenseIndicatorProps = {
  row: Component;
};

const LicenseIndicator: React.FC<LicenseIndicatorProps> = ({ row }) => {
    const isExpired = () => {
        const expirationDate = new Date(row.license_expiration_date as string)
        const currentDate = new Date()

        if (isNaN(expirationDate.getTime()) || row.license_expiration_date === "0001-01-01T00:00:00Z") {
            return false
        }

        return expirationDate < currentDate
    }

    const formatDate = (dateString: string) => {
        const options: Intl.DateTimeFormatOptions = {
            year: "numeric",
            month: "long",
            day: "numeric"
        }
        const date = new Date(dateString)
        return date.toLocaleDateString(undefined, options)
    }

    return (
        <Typography display="flex" alignItems="center" gap={1}>
            {row.license}
            {isExpired() && (
                <Tooltip
                    title={`This license is expired. Expiration date: ${formatDate(row.license_expiration_date as string)}`}
                    arrow
                >
                    <WarningIcon color="warning" />
                </Tooltip>
            )}
        </Typography>
    )
}

export default LicenseIndicator
