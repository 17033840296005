import React, { useEffect, useState } from "react"

interface RankColoredProps {
  value: number; // El valor de rank
}

const RankColored: React.FC<RankColoredProps> = ({ value }) => {
    const [label, setLabel] = useState<string | undefined>("")

    useEffect(() => {
        if (value !== 0) {
            setLabel(`${value}`)
        } else {
            setLabel(undefined)
        }
    }, [value])

    if (!label) {
        return null
    }
    return <span style={{ fontWeight: "bolder", textAlign: "center" }}>{value}</span>
}

export { RankColored }
export default RankColored
