import React from "react"
import { Image, Page, Text, View } from "@react-pdf/renderer"
import Template from "@models/Template"
import styles from "../Styles"
import VexDocumentStyles from "../VexStyleSheet"
import { Footer, Header } from "./HeaderFooter"

export const ambitos = [
    { id: "Laboratorio: ", description: "Evaluaciones de cumplimiento de ciberseguridad" },
    { id: "MyOrbik: ", description: "Web para la gestión activa de vulnerabilidades de productos electrónicos" },
    { id: "Certificación: ", description: "Apoyo a la certificación de productos" },
    { id: "Consultoría técnica: ", description: "Definir la estrategia de ciberseguridad del producto y priorizar las acciones de implementación" }
]

export const plans = [
    { id: "One week Assessment: ", description: "En esta siguiente fase, profundizamos en los requisitos de su producto para lograr el cumplimiento de la CRA. Nuestros expertos llevan a cabo una evaluación exhaustiva para identificar los cambios necesarios, asegurando la alineación con las estrictas regulaciones de la CRA." },
    { id: "Foundations Assessment: ", description: "En esta siguiente fase, agregamos una capa de pruebas de seguridad a la evaluación. Un tutorial completo sobre el cumplimiento de la CRA." },
    { id: "Custom Assessment: ", description: "¡Personalizamos nuestros servicios para adaptarnos a sus necesidades únicas! Comuníquese con nosotros para crear un plan de evaluación personalizado adaptado a satisfacer todas sus necesidades." },
    { id: "Continuous Monitoring: ", description: "My Orbik proporciona monitoreo avanzado de vulnerabilidades para ayudar a las organizaciones a adelantarse a las amenazas cibernéticas. Como herramienta de gestión de vulnerabilidades, ofrece capacidad para identificar nuevas amenazas en tiempo real, My Orbik equipa a los usuarios para mitigar rápidamente los riesgos, garantizando una protección integral contra las ciberamenazas en evolución." }
]

const ServicesSection: React.FC<{ template?: Template}> = ({ template }) => (
    <Page size="A4" style={{ backgroundColor: !template || template.color === "#fffa37" ? "" : template.color }}>
        <Header template={template}></Header>
        <View style={{ ...VexDocumentStyles.section, flexDirection: "row" }}>
            <View style={styles.column}>
                <Text style={{ ...styles.titulo, fontFamily: "Bold" }}>3. Next Steps </Text>
                <Text style={{ ...styles.textua, fontFamily: "Bold", fontSize: 12, marginRight: 0 }}>3.1 Services</Text>
                <Text style={{ ...styles.textua, marginRight: 20, fontFamily: "Griff", fontSize: 10 }}>Are you worried about the safety status of your product or don`&apos;`t know how to comply with the new safety regulations?</Text>
                <Text style={{ ...styles.textua, marginRight: 20, fontFamily: "Griff", fontSize: 10 }}>Orbik Cybersecurity offers a list of services for this. From the most basic to the most complex, we offer support and solutions tailored to you.</Text>
                <Text style={{ ...styles.textua, top: 2, marginRight: 20, fontFamily: "Griff", fontSize: 10 }}>The services can be categorized into the following 4 areas:</Text>
                {ambitos.map((point, index) => (
                    <Text key={index} style={{ ...styles.puntuak, marginRight: 20, fontFamily: "Griff", fontSize: 10 }}>
                        <Text style={{ ...styles.puntuak, fontFamily: "Bold" }}>•  &nbsp;</Text>
                        <Text style={{ ...styles.puntuak, fontFamily: "Bold" }}>{point.id}</Text>
                        <Text style={styles.puntuak}>{point.description}</Text>
                    </Text>
                ))}
            </View>
            <View style={{ ...styles.column, marginLeft: 0 }}>
                <Text style={{ ...styles.textua, marginRight: 40, fontFamily: "Bold", fontSize: 8, marginTop: -1, color: "gray" }} >Check our work plans</Text>
                <Text style={{ ...styles.textua, marginRight: 40, fontFamily: "Griff", fontSize: 10, marginBottom: 10 }}>Based on the total value obtained, a series of thresholds are established that provide qualitative values:</Text>
                {plans.map((point, index) => (
                    <Text key={index} style={{ ...styles.puntuak, marginRight: 60, fontFamily: "Griff", fontSize: 10 }}>
                        <Text style={{ ...styles.puntuak, fontFamily: "Bold" }}>•  &nbsp;</Text>
                        <Text style={{ ...styles.puntuak, fontFamily: "Bold" }}>{point.id}</Text>
                        <Text style={styles.puntuak}>{point.description}</Text>
                    </Text>
                ))}
            </View>
        </View>
        <Footer color={template?.color}></Footer>
    </Page>
)
const ServicesImg: React.FC<{ template?: Template}> = ({ template }) => (
    <Page size="A4" style={{ backgroundColor: !template || template.color === "#fffa37" ? "" : template.color }}>
        <Header template={template}></Header>
        <View style={{ ...VexDocumentStyles.section, flexDirection: "row" }}>
            <View style={styles.column}>
                {/* <Image style={{ ...styles.image, width: 575, height: 195, paddingLeft: 40 }} src="./assets/img/report/servicios.jpg" /> */}
                <Image style={{ ...styles.image, marginTop: 50, width: 580, height: 277, paddingLeft: 20 }} src="/assets/img/report/serviciosTable.jpg" />
                <Text style={{ ...styles.textua, marginRight: 40, marginTop: 120, fontFamily: "Griff", fontSize: 10 }}>For more information, contact us by email:</Text>
                <Text style={{ ...styles.textua, marginRight: 40, fontFamily: "Bold", fontSize: 10 }}>info@orbik-cybersecurity.com</Text>
            </View>
        </View>
        <Footer color={template?.color}></Footer>
    </Page>
)

export { ServicesSection, ServicesImg }
