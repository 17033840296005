import { TestType } from "@models/Achilles"

export const AchillesStatusColors: Record<TestType, string> = {
    Ethernet: "#1F77B4", // Azul brillante
    ARP: "#FF7F0E", // Naranja cálido
    LLDP: "#2CA02C", // Verde esmeralda
    IP: "#D62728", // Rojo clásico
    ICMP: "#9467BD", // Morado suave
    IGMP: "#8C564B", // Marrón cálido
    TCP: "#E377C2", // Rosa claro
    HTTP: "#7F7F7F", // Gris neutro
    UDP: "#BCBD22", // Amarillo oliva
    FTP: "#17BECF", // Azul celeste
    NTP: "#AEC7E8", // Azul cielo claro
    RDP: "#FFBB78", // Melocotón
    RPC: "#98DF8A", // Verde pastel
    SNMP: "#FF9896", // Coral claro
    TELNET: "#C5B0D5", // Lila claro
    DNP3: "#C49C94", // Marrón arena
    "EtherNet/IP": "#F7B6D2", // Rosa vintage
    CIP: "#DBDB8D", // Amarillo pálido
    "FF-HSE": "#9EDAE5", // Azul hielo
    GOOSE: "#393B79", // Azul noche
    "IEC 104": "#637939", // Verde bosque
    MMS: "#8C6D31", // Oro oscuro
    "OPC UA": "#843C39", // Rojo ladrillo
    "MODBUS/TCP": "#E7BA52", // Dorado cálido
    PROFINET: "#D6616B" // Rojo coral
}
