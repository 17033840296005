import { ModelDefinition, ModelRelation } from "@models/utils"
import AssessmentStateColored from "@components/vulnerability/AssessmentStateColored"
import { NumberColoredCVSS } from "@components/vulnerability"
import React from "react"
import TemplateNameNavigate from "@components/template/TemplateNameNavigate"
import { CVSS3Severity } from "./Vulnerability"
import { AssessmentType } from "./Assessment"

type Template = {
    id: string;
    name: string;
    language: string;
    color: string;
    logo: string | ArrayBuffer | null;
    type: AssessmentType;
    metadata: TMetadata;
    config: TConfig;
    client: ModelRelation;
}

export const TemplateReportByAssessmentType: Record<AssessmentType, React.FC<any>> = {
    [AssessmentType.CRT]: require("@components/report/VulnReportDocumentAquilles").default,
    [AssessmentType.SCA]: require("@components/report/VulnReportDocument").default,
    [AssessmentType.NetworkScan]: require("@components/report/VulnReportDocumentGeneric").default,
    [AssessmentType.PortScan]: require("@components/report/VulnReportDocumentGeneric").default,
    [AssessmentType.WebScan]: require("@components/report/VulnReportDocumentGeneric").default,
    [AssessmentType.LogicalPentest]: require("@components/report/VulnReportPentest").default,
    [AssessmentType.FunctionalTest]: require("@components/report/VulnReportDocumentGeneric").default
}

type TConfig =
  | {
      type: AssessmentType.CRT;
      data: TConfigCRT;
    }
  | {
      type: AssessmentType.SCA;
      data: TConfigSCA;
    };

type TConfigCRT = {
    cvss_minimum: number;
    cvss_criticality: CVSS3Severity;
    limit_vulnerability_count: number;
}

type TConfigSCA = {
    cvss_minimum: number;
    cvss_criticality: CVSS3Severity;
    limit_vulnerability_count: number;
}

type TMetadata =
  | {
      type: AssessmentType.CRT;
      data: TMetadataSCA;
    }
  | {
      type: AssessmentType.SCA;
      data: TMetadataSCA;
    };

type TMetadataCRT = {
    header: string;
    content: string;
    description: string;
    // // TODO: En el futuro se añen todos los campos necesarios para el informe CRT
    // front_page?: {
    //     background_image?: string;
    //     title?: string;
    // };
    // index?: string;
    // introduction?: string;
    // scope?: string;
    // executive_summary?: string;
    // service_detection?: string;
    // results?: string;
    // test_execution?: string;
    // anomaly_identification?: string;
    // conclusion?: string;
    // headers?: string;
    // footer?: string;
}

type TMetadataSCA = {
    cover_page: TMetadataSCACover;
    table_of_contents: TMetadataSCAContent;
    custom_content: TMetadataSCADescription[];
}

type TMetadataSCACover = {
    header: string;
    subheader: string;
    metadata: string;
}

type TMetadataSCAContent = {
    table_of_content: boolean;
    introduction: boolean;
    scope: boolean;
    executive_summary: boolean;
}

type TMetadataSCADescription = {
    name: string;
    content: string;
}

const TemplateDefinition: ModelDefinition<Template> = {
    properties: [
        { name: "id", label: "Id", type: "string", flex: 0.1, isId: true },
        { name: "name", label: "Name", type: "string", flex: 0.2, render: TemplateNameNavigate },
        { name: "type", label: "Type", type: "singleSelect", flex: 0.15, render: AssessmentStateColored },
        { name: "cvss_minimum", label: "CVSS Minimum", type: "number", flex: 0.15, render: NumberColoredCVSS },
        { name: "color", label: "Color", type: "string", flex: 0.15 },
        { name: "client.name", label: "Client", type: "struct", flex: 0.15, valueGetter: product => product.client.name }
    ]
}

export { TemplateDefinition }
export type { Template, TConfig, TMetadata, TMetadataSCA, TMetadataSCACover, TMetadataSCAContent, TMetadataSCADescription, TConfigCRT, TConfigSCA, TMetadataCRT }
export default Template
