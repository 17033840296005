import React, { useContext, useEffect, useState } from "react"
import { AlertColor, Box, Chip, Grid, MenuItem, Select, SelectChangeEvent, Tab, Tabs, TextField, Tooltip, Typography } from "@mui/material"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import Tag from "@models/Tag"
import ActionButton from "@components/common/Button/ActionButton"
import GenericModal from "@components/common/modals/GenericModal"
import ServicesContext from "@context/ServicesContext"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import { I18nContext } from "I18nProvider"

interface CreateTagModalProps {
    open: boolean;
    onClose: () => void;
    formTagData: Tag;
    setFormTagData: React.Dispatch<React.SetStateAction<Tag>>;
    isFormValid: boolean;
    onTagDeleted: ()=> void
    onTagCreated: (message: string, severity: AlertColor) => void
}

const CreateTagModal: React.FC<CreateTagModalProps> = ({
    open,
    onClose,
    formTagData,
    setFormTagData,
    isFormValid,
    onTagDeleted,
    onTagCreated
}) => {
    const [error, setError] = useState<any>()
    const [tags, setTags] = useState<Tag[]>([]) // Lista de tags existentes
    const [activeTab, setActiveTab] = useState(0) // Controla la pestaña activa
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState<string>("")
    const [severity, setSeverity] = useState<AlertColor>()
    const tagService = useContext(ServicesContext).tagService
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }

    const colors = [
        { label: context.t.translate("yellow"), value: "#fdffb5" },
        { label: context.t.translate("red"), value: "#D9534F" },
        { label: context.t.translate("green"), value: "#b4de6c" },
        { label: context.t.translate("blue"), value: "#8dd3c7" }
    ]

    const fetchTags = async () => {
        try {
            const response = await tagService.getAll()
            setTags([...response.list])
        } catch (e) {
            console.error(context.t.translate("error_fetching_tags"), e)
        }
    }

    useEffect(() => {
        if (open) {
            fetchTags()
        }
    }, [open])

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>
    ) => {
        const { name, value } = e.target
        setFormTagData({ ...formTagData, [name]: value })
    }

    const handleCreateTag = async () => {
        try {
            if (!isFormValid) {
                return
            }
            await tagService.create(formTagData)
            onTagCreated(context.t.translate("tags_created_successfully"), context.t.translate("success"))

            // Limpia el formulario y cierra el modal
            setFormTagData({
                id: "",
                name: "",
                description: "",
                color: "",
                products: []
            })
        } catch (e: any) {
            console.error(e)
            setSnackbarMessage(e.message || context.t.translate("error_creating_tag"))
            setSeverity(context.t.translate("error"))
            setSnackbarOpen(true)
        }
    }

    const handleDeleteTag = async (tagId: string) => {
        try {
            await tagService.delete(tagId)
            const updatedTags = tags.filter(tag => tag.id !== tagId)
            setTags(updatedTags)
            // Mostrar mensaje de éxito
            setSnackbarMessage(context.t.translate("tag_deleted_successfully"))
            setSeverity(context.t.translate("success"))
            setSnackbarOpen(true)
            onTagDeleted && onTagDeleted()
        } catch (e: any) {
            console.error(e)
            setSnackbarMessage(e.message || context.t.translate("error_deleting_tag"))
            setSeverity(context.t.translate("error"))
            setSnackbarOpen(true)
        }
    }

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue)
    }
    const handleModalClose = () => {
        setSnackbarOpen(false)
        setSnackbarMessage("")
        setSeverity(context.t.translate("success"))

        setFormTagData({
            id: "",
            name: "",
            description: "",
            color: "",
            products: []
        })

        onClose()
    }
    return (
        <GenericModal title= {context.t.translate("manage_tags")} open={open} onClose={handleModalClose}>
            <Box width="100%" maxHeight="50vh">
                <CustomSnackbar
                    open={snackbarOpen}
                    onClose={() => setSnackbarOpen(false)}
                    message={snackbarMessage}
                    severity={severity}
                />
                <Tabs value={activeTab} onChange={handleTabChange}>
                    <Tab label= {context.t.translate("create_tag")} />
                </Tabs>
                {activeTab === 0 && (
                    <Box mt={2}>
                        <Grid container justifyContent="center" width="100%" direction="column">
                            <Grid item>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    variant="filled"
                                    label={context.t.translate("tag_name")}
                                    name="name"
                                    value={formTagData.name}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    variant="filled"
                                    label={context.t.translate("description")}
                                    name="description"
                                    value={formTagData.description}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item container direction="column">
                                <Grid item>
                                    <Typography fontSize="18px" fontWeight="bolder" >{context.t.translate("product_color")}</Typography>
                                </Grid>
                                <Grid item xs>
                                    <Select name="color" value={formTagData.color} onChange={handleInputChange} fullWidth>
                                        {colors.map((color, index) => (
                                            <MenuItem key={index} value={color.value}>
                                                <Typography sx={{ color: color.value }}>{color.label}</Typography>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                            </Grid>
                        </Grid>
                        <ActionButton
                            onClick={handleCreateTag}
                            disabled={!isFormValid}
                            text={context.t.translate("create_tag")}
                            style={{ width: "100%", marginTop: "15px" }}
                        />
                    </Box>
                )}
                {activeTab === 1 && (
                    <Box mt={2}>
                        <Typography fontSize="18px" fontWeight="bold" mb={2}>{context.t.translate("existing_tags")}</Typography>
                        <Box
                            sx={{
                                display: "grid",
                                gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))",
                                gap: "16px"
                            }}
                        >
                            {tags.map(tag => (
                                <Chip
                                    key={tag.id}
                                    label={tag.name}
                                    style={{
                                        backgroundColor: tag.color,
                                        color: "#000"
                                    }}
                                    onDelete={() => handleDeleteTag(tag.id)}
                                    deleteIcon={
                                        <Tooltip title="Delete">
                                            <DeleteForeverIcon style={{ color: "#000" }} />
                                        </Tooltip>
                                    }
                                />
                            ))}
                        </Box>
                    </Box>
                )}
            </Box>
        </GenericModal>
    )
}

export default CreateTagModal
