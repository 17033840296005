import ActionButton from "@components/common/Button/ActionButton"
import { convertModelDefToGridColDef, GenericTable } from "@components/common/tables"
import { HardwareComponentTableDefinition } from "@components/hardwareComponents/HardwareComponentsTableDefinition"
import { AbilityContext } from "@components/permissions"
import ServicesContext from "@context/ServicesContext"
import HardwareComponents from "@models/HardwareComponents"
import { FilterOperation, QueryParameters } from "@utils/queryParams"
import { Grid, Typography, useTheme } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid"
import { I18nContext } from "I18nProvider"
import { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

type HardwareComponentTableProps = {
    productID: string | undefined
}

const HardwareComponentTable = ({ productID }: HardwareComponentTableProps) => {
    const navigate = useNavigate()
    const theme = useTheme()
    const context = useContext(I18nContext)
    const hardwareComponentService = useContext(ServicesContext).hardwareComponentService
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    /* const addHardware = () => {
        const hardware: HardwareComponents = {
            id: "",
            identifier: "testidentifier",
            name: "test hardware",
            origin: HardwareComponentsOrigin.SIEMENS,
            client_id: "2450a8cd-e6f1-48fc-9c56-e1f69722eb31",
            product_id: "d725eab4-2b9b-4048-9312-1c1dc10a3923"
        }
        hardwareComponentService.create(hardware)
    }
    addHardware() */
    const [cols, setCols] = useState<GridColDef[]>([])
    const ability = useContext(AbilityContext)
    useEffect(() => {
        setCols(convertModelDefToGridColDef(HardwareComponentTableDefinition, ability))
    }, [])

    return (
        <Grid item xs container flexDirection="column" rowGap="25px" aria-description="Hardware components Section">
            <Grid
                item
                xs
                sx={{
                    top: "0px",
                    justifyContent: "space-between",
                    alignItems: "center",
                    spacing: "20px",
                    paddingRight: "20px"
                }}
                display="flex"
            >
                <Typography
                    color={theme.palette.text.secondary}
                    fontSize="45px"
                    fontWeight="bolder"
                    fontFamily="Griff"
                >
                    Hardware Components
                </Typography>
                <ActionButton
                    onClick={() => navigate("./addHardware")}
                    text="Add Hardware Component"
                />
            </Grid>

            <Grid item container>
                <Grid item xs container flexDirection="column" spacing="20px">
                    <Grid item container flexDirection="column" rowGap="35px">
                        <Grid item container>
                            <GenericTable<HardwareComponents>
                                entity="HardwareComponents"
                                columns={cols}
                                dataProvider={(filter) => {
                                    const updatedFilter: QueryParameters = { ...filter, filters: [{ field: "product_id", operation: FilterOperation.EnumEqual, value: productID as string }] }
                                    return hardwareComponentService
                                        .getAllExt(updatedFilter)
                                        .then((data) => ({
                                            list: data?.list || [], // Lista vacía si no hay datos
                                            count: data?.count || 0, // Conteo total es 0
                                            next: data?.next || "", // Convertir null a string vacío
                                            json: async () => [] // Retornar un arreglo vacío como fallback
                                        }))
                                        .catch(() => ({
                                            list: [],
                                            count: 0,
                                            next: "", // Asegurar que siempre sea un string
                                            json: async () => [] // Retornar un arreglo vacío como fallback
                                        }))
                                }}
                                onEdit={(elem: HardwareComponents) =>
                                    navigate("../../hardwarecomponent/" + elem.id)
                                }
                                onDelete={(elem: HardwareComponents) =>
                                    hardwareComponentService.delete(elem.id)
                                }
                                disabledActions={true}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default HardwareComponentTable
