import React from "react"
import { Circle, Path, Rect, Svg, Text, View } from "@react-pdf/renderer"

type Segment = {
    label: string;
    color: string;
    value: number;
};

type ProgressCircleProps = {
    segments?: Segment[]; // Array de segmentos con label, color y valor
    size?: number; // Tamaño del círculo
    innerCircleRatio?: number; // Proporción del radio del círculo interno respecto al externo
};

const ProgressCircle: React.FC<ProgressCircleProps> = ({
    segments = [], // Por defecto sin datos
    size = 100,
    innerCircleRatio = 0.6 // Por defecto el círculo interno es 60% del radio externo
}) => {
    const radius = size / 2
    const innerRadius = radius * innerCircleRatio
    const center = size / 2

    const generateSegmentPath = (startAngle: number, endAngle: number): string => {
        const largeArcFlag = endAngle - startAngle > Math.PI ? 1 : 0
        const startX = center + radius * Math.cos(startAngle)
        const startY = center + radius * Math.sin(startAngle)
        const endX = center + radius * Math.cos(endAngle)
        const endY = center + radius * Math.sin(endAngle)

        return [
            `M ${center},${center}`,
            `L ${startX},${startY}`,
            `A ${radius},${radius} 0 ${largeArcFlag} 1 ${endX},${endY}`,
            "Z"
        ].join(" ")
    }

    const totalValue = segments.reduce((sum, segment) => sum + segment.value, 0)
    let currentAngle = -Math.PI / 2

    return (
        <View style={{ alignItems: "center", marginBottom: 10 }}>
            {/* Círculo de progreso */}
            <Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
                {/* Renderizar un círculo gris si no hay segmentos */}
                {segments.length === 0 && (
                    <Path
                        d={generateSegmentPath(-Math.PI / 2, 3 * Math.PI / 2)}
                        fill="gray"
                        stroke="none"
                    />
                )}

                {/* Renderizar segmentos */}
                {segments.map((segment, index) => {
                    const segmentAngle = (segment.value / totalValue) * 2 * Math.PI
                    const path = generateSegmentPath(currentAngle, currentAngle + segmentAngle)
                    currentAngle += segmentAngle

                    return <Path key={index} d={path} fill={segment.color} stroke="none" />
                })}

                {/* Círculo interno */}
                <Circle cx={center} cy={center} r={innerRadius} fill="yellow" stroke="none" />
            </Svg>

            {/* Leyendas */}
            <View style={{ marginTop: 15, flexDirection: "row", gap: 4, flexWrap: "wrap", justifyContent: "center" }}>
                {segments.map((segment, index) => (
                    <View
                        key={index}
                        style={{
                            flexDirection: "row",
                            alignItems: "center",
                            marginBottom: 5
                        }}
                    >
                        {/* Rectángulo de color */}
                        <Svg width={10} height={10} style={{ marginRight: 5 }}>
                            <Rect width={10} height={10} fill={segment.color} />
                        </Svg>

                        {/* Texto de la leyenda */}
                        <Text
                            style={{
                                fontFamily: "Griff",
                                fontSize: 10,
                                textAlign: "left"
                            }}
                        >
                            {segment.label}
                        </Text>
                    </View>
                ))}
            </View>
        </View>
    )
}

export default ProgressCircle
