import { Achilles } from "@models/index"
import { MediaTypes } from "@services/HttpService"
import Service from "@services/abstract/BaseService"
import { FilterOperation } from "@utils/queryParams"

class AchillesService extends Service<Achilles> {
    public constructor () {
        super(process.env.REACT_APP_API, "api/v1/achilles", MediaTypes.JSON)
    }

    public getAllFormatted = (id: any, type: string) => {
        return this.http.getMultiple(`/${this.path}`, { filters: [{ field: "assessment_id", operation: FilterOperation.UUIDEqual, value: id }] }, [`type=${type}`])
    }

    /* public postFiles = (id: string, files: E) => {
        return this.http.postFile()
    } */
}

export { AchillesService }
export default AchillesService
