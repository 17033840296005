import React, { useContext } from "react"
import { Grid, Typography } from "@mui/material"
import { StyledBox } from "@components/common/Box/BoxContainer"
import { StatsVulnerabilities } from "@models/Stats"
import { I18nContext } from "I18nProvider"
import GenericDonutChart from "@components/assessment/GenericDonutChart"
import ProductTimeLine from "../ProductTimeLine/ProductTimeLine"
import { TimeLineValues } from "../ProductDetail"

const StatusComponent = ({ vulnStats, dataTime, id }: {vulnStats: StatsVulnerabilities | undefined, dataTime: TimeLineValues[], id: string | undefined}) => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    return (
        <Grid item xs container flexDirection="column" spacing="20px" sx={{ marginTop: "20px" }}>
            <Grid item container flexDirection="column" rowGap="35px">
                <Grid item container>
                    <Typography fontSize="45px" fontWeight="bolder" fontFamily="Griff" sx={{ marginTop: "-20px", marginBottom: "20px" }}>{context.t.translate("status")}</Typography>
                    <StyledBox>
                        <Grid item container flexDirection="row" alignItems='center' spacing="40px">
                            <Grid item xs={6} sx={{ borderRadius: "10px", display: "flex", justifyContent: "center", alignContent: "center" }}>
                                <ProductTimeLine newenventsdata={dataTime} selected_id={id} />
                            </Grid>
                            <Grid item xs={6} sx={{ borderRadius: "10px" }}>
                                {vulnStats != null && <Grid item container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <Typography fontSize="17px" fontWeight="100" sx={{ fontVariant: "all-small-caps", marginBottom: "20px" }}>
                                            {context.t.translate("dash_vulner_mitigated")}
                                        </Typography>
                                        <GenericDonutChart title="Criticity" dataType="object" data={vulnStats.MitigatedVulnerabilities} number={vulnStats.MitigatedVulnerability} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} >
                                        <Typography fontSize="17px" fontWeight="100" sx={{ fontVariant: "all-small-caps", marginBottom: "20px" }}>
                                            {context.t.translate("dash_vulner_nomitigated")}
                                        </Typography>
                                        <GenericDonutChart title="Criticity" dataType="object" data={vulnStats.UnmitigatedVulnerabilities} number={vulnStats.UnmitigatedVulnerability} />
                                    </Grid>
                                </Grid>}
                            </Grid>
                        </Grid>
                    </StyledBox>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default StatusComponent
