import React, { useContext, useEffect, useState } from "react"
import { ThemeProvider } from "@mui/material/styles"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { useAuth } from "react-oidc-context"
import { PureAbility } from "@casl/ability"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import { AbilityContext } from "@components/permissions/index"
import { ServicesContext } from "@context/index"
import AppLayout from "@layouts/AppLayout"
import { AssessmentService, BenchmarkService, ClientService, ComplianceService, ComponentService, FileService, GroupService, ProductService, StatsService, TagService, VulnerabilityService } from "@services/index"
import EmailService from "@services/EmailService"
import TemplatesService from "@services/TemplatesService"
import { Actions, buildAbilityFor, Subjects } from "@permissions/ability"
import KeycloakService from "@services/KeycloakService"
import { TrackProvider } from "@components/track/TrackContext"
import TrackService from "@services/TrackService"
import AchillesService from "@services/AchillesService"
import { createLightOrbikTheme, createOrbikTheme } from "./theme"
import { I18nContext } from "I18nProvider"
import "App.css"
import { ButtonStateProvider, useButtonState } from "@components/theme/ButtonStateContext"
import TicketingService from "@services/TicketingService"
import SectorStatsService from "@services/SectorStatsService"
import HierarchyService from "@services/HierarchyService"
import HistoricCveService from "@services/HistoricCveService"
import PolicyService from "@services/PolicyService"
import LicenseService from "@services/LicenseService"
import UserLogService from "@services/UserLogService"
import { ConfirmationDialogProvider } from "@components/dialogs/ConfirmationDialog"
import SystemNotificationService from "@services/SystemNotificationService"
import SLAService from "@services/SLAService"
import CPEService from "@services/CPEService"
import IssueService from "@services/IssueService"
import JiraConfigService from "@services/JiraConfigService"
import JiraService from "@services/JiraService"
import PentestService from "@services/PentestService"
import CustomTextService from "@services/CustomTextService"
import KEVService from "@services/KEVService"
import VaultService from "@services/VaultService"
import HardwareComponentService from "@services/HardwareComponentService"

const ThemeWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { darkMode } = useButtonState()
    const theme = darkMode ? createOrbikTheme() : createLightOrbikTheme()

    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    )
}

const theme = createOrbikTheme()
const router = createBrowserRouter([
    { path: "*", Component: AppLayout }
])

const services = {
    clientService: new ClientService(),
    groupService: new GroupService(),
    productService: new ProductService(),
    assessmentService: new AssessmentService(),
    vulnerabilityService: new VulnerabilityService(),
    fileService: new FileService(),
    statsService: new StatsService(),
    benchmarkService: new BenchmarkService(),
    tagService: new TagService(),
    emailService: new EmailService(),
    templateService: new TemplatesService(),
    keycloakService: new KeycloakService(),
    trackService: new TrackService(),
    componentService: new ComponentService(),
    complianceService: new ComplianceService(),
    achillesService: new AchillesService(),
    ticketingService: new TicketingService(),
    sectorStatsService: new SectorStatsService(),
    hierarchyService: new HierarchyService(),
    historicCveService: new HistoricCveService(),
    policyService: new PolicyService(),
    licenseService: new LicenseService(),
    userLogService: new UserLogService(),
    systemNotificationServices: new SystemNotificationService(),
    slaService: new SLAService(),
    cpeService: new CPEService(),
    issueService: new IssueService(),
    jiraConfigService: new JiraConfigService(),
    jiraService: new JiraService(),
    pentestService: new PentestService(),
    customTextService: new CustomTextService(),
    KEVService: new KEVService(),
    vaultService: new VaultService(),
    hardwareComponentService: new HardwareComponentService()
}

const App: React.FC = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const [darkMode, setDarkMode] = useState(true)
    const theme = darkMode ? createOrbikTheme() : createLightOrbikTheme()
    const auth = useAuth()
    useEffect(() => {
        return auth.events.addSilentRenewError(() => {
            auth.signinRedirect()
        })
    }, [auth.events])
    const [ability, setAbility] = useState(new PureAbility<[Actions, Subjects]>())
    useEffect(() => {
        if (!auth.isLoading && !auth.isAuthenticated) {
            auth.signinRedirect()
        }

        if (auth.isAuthenticated) {
            setAbility(buildAbilityFor(auth.user?.profile.group as string[]))
        }
    }, [auth])
    services.clientService.setAuth(auth)
    services.groupService.setAuth(auth)
    services.productService.setAuth(auth)
    services.assessmentService.setAuth(auth)
    services.vulnerabilityService.setAuth(auth)
    services.fileService.setAuth(auth)
    services.statsService.setAuth(auth)
    services.benchmarkService.setAuth(auth)
    services.tagService.setAuth(auth)
    services.emailService.setAuth(auth)
    services.templateService.setAuth(auth)
    services.keycloakService.setAuth(auth)
    services.trackService.setAuth(auth)
    services.componentService.setAuth(auth)
    services.complianceService.setAuth(auth)
    services.achillesService.setAuth(auth)
    services.ticketingService.setAuth(auth)
    services.sectorStatsService.setAuth(auth)
    services.hierarchyService.setAuth(auth)
    services.historicCveService.setAuth(auth)
    services.policyService.setAuth(auth)
    services.licenseService.setAuth(auth)
    services.systemNotificationServices.setAuth(auth)
    services.slaService.setAuth(auth)
    services.cpeService.setAuth(auth)
    services.issueService.setAuth(auth)
    services.jiraConfigService.setAuth(auth)
    services.jiraService.setAuth(auth)
    services.userLogService.setAuth(auth)
    services.pentestService.setAuth(auth)
    services.customTextService.setAuth(auth)
    services.KEVService.setAuth(auth)
    services.vaultService.setAuth(auth)
    services.hardwareComponentService.setAuth(auth)

    return (<>
        {auth.isAuthenticated &&

                <ServicesContext.Provider value={services}>
                    <AbilityContext.Provider value={ability}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <TrackProvider interval={600000}>
                                <ThemeProvider theme={theme}>
                                    <ButtonStateProvider>
                                        <ConfirmationDialogProvider>
                                            <ThemeWrapper>
                                                <RouterProvider router={router} />
                                            </ThemeWrapper>
                                        </ConfirmationDialogProvider>
                                    </ButtonStateProvider>
                                </ThemeProvider>
                            </TrackProvider>
                        </LocalizationProvider>
                    </AbilityContext.Provider>
                </ServicesContext.Provider>

        }</>)
}
export default App
