import React from "react"
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material"
import ActionButton from "@components/common/Button/ActionButton"

interface FieldConfig {
    label: string;
    key: string;
    maxLength?: number; // Longitud máxima opcional
    type?: "text" | "number" | "no-special" | "comments"; // Tipo opcional del campo
}

interface DynamicSectionProps {
    sectionTitle: string;
    sectionData: Record<string, string>[]; // Array de objetos con datos de cada fila
    fields: FieldConfig[]; // Configuración de los campos
    onUpdate: (updatedData: Record<string, string>[]) => void;
    onBlur?: (updatedItem: Record<string, string>) => void;
}

const DynamicSection: React.FC<DynamicSectionProps> = ({
    sectionTitle,
    sectionData,
    fields,
    onUpdate,
    onBlur
}) => {
    const addRow = () => {
        const newRow = fields.reduce((acc, field) => {
            acc[field.key] = field.key === "estado" ? "Open" : "" // Definir el valor inicial de "estado" como "Open"
            return acc
        }, {} as Record<string, string>)
        const updatedData = [...sectionData, newRow]
        onUpdate(updatedData)
    }

    const removeRow = (index: number) => {
        const updatedData = sectionData.filter((_, i) => i !== index)
        onUpdate(updatedData)
    }

    const updateField = (index: number, fieldKey: string, value: string) => {
        const updatedData = [...sectionData]
        updatedData[index][fieldKey] = value
        onUpdate(updatedData)
    }

    const handleBlur = (index: number) => {
        if (onBlur) {
            onBlur(sectionData[index])
        }
    }

    const handleChange = (index: number, field: FieldConfig, value: string) => {
        if (field.type === "number") {
            const numericValue = value.replace(/[^0-9]/g, "")
            updateField(index, field.key, numericValue)
        } else if (field.type === "no-special") {
            const sanitizedValue = value.replace(/[^a-zA-Z0-9\s]/g, "")
            updateField(index, field.key, sanitizedValue)
        } else if (field.type === "comments") {
            const sanitizedValue = value.replace(/[^a-zA-Z0-9\s,.;\-?¿()]/g, "")
            updateField(index, field.key, sanitizedValue)
        } else {
            updateField(index, field.key, value)
        }
    }

    const handleStatusChange = (index: number, event: SelectChangeEvent<string>) => {
        const updatedData = [...sectionData]
        updatedData[index].estado = event.target.value
        onUpdate(updatedData)
    }
    const hasEstadoField = fields.some((field) => field.key === "estado")

    return (
        <Box style={{ display: "flex", gap: 2, flexDirection: "column" }}>
            <h3>{sectionTitle}</h3>
            {Array.isArray(sectionData)
                ? sectionData.map((row, index) => (
                    <Grid container spacing={2} key={index} alignItems="center" sx={{ marginBottom: 2 }}>
                        {fields.map((field) => (
                            field.key !== "estado" && (
                                <Grid item xs={field.key === "comentarios" ? 6 : 4} key={field.key}>
                                    <TextField
                                        fullWidth
                                        label={field.label}
                                        value={row[field.key]}
                                        onChange={(e) => handleChange(index, field, e.target.value)}
                                        onBlur={() => handleBlur(index)}
                                        variant="filled"
                                        multiline={field.key === "comentarios"}
                                        inputProps={{
                                            maxLength: field.maxLength // Aplicar longitud máxima si está definida
                                        }}
                                    />
                                </Grid>
                            )
                        ))}

                        {/* Mostrar selector de estado solo si el campo "estado" está en fields */}
                        {hasEstadoField && (
                            <Grid item xs={4}>
                                <FormControl fullWidth variant="filled">
                                    <InputLabel id={`estado-selector-${index}`}>Estado</InputLabel>
                                    <Select
                                        labelId={`estado-selector-${index}`}
                                        value={row.estado || "Open"}
                                        onChange={(e) => { handleStatusChange(index, e); handleBlur(index) }}
                                    >
                                        <MenuItem value="Open">Open</MenuItem>
                                        <MenuItem value="Closed">Closed</MenuItem>
                                        <MenuItem value="Filtered">Filtered</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        )}

                        <Grid item xs={2}>
                            {index > 0 && (
                                <ActionButton
                                    variant="outlined"
                                    onClick={() => removeRow(index)}
                                    text="Remove"
                                />
                            )}
                        </Grid>
                    </Grid>
                ))
                : null}
            <ActionButton onClick={addRow} text="Add" />
        </Box>
    )
}

export default DynamicSection
