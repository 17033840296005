import AchillesTableDefinition from "@components/achilles/AchillesTableDefinition"
import { StyledBox } from "@components/common/Box/BoxContainer"
import { convertModelDefToGridColDef, GenericTable } from "@components/common/tables"
import { AbilityContext } from "@components/permissions"
import ServicesContext from "@context/ServicesContext"
import Achilles from "@models/Achilles"
import { Grid, Typography, useTheme } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid"
import { FilterOperation, QueryParameters } from "@utils/queryParams"
import { I18nContext } from "I18nProvider"
import { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

type AchillesTableProps = {
    id: string | undefined
}

const AchillesTable = ({ id }: AchillesTableProps) => {
    const navigate = useNavigate()
    const achillesService = useContext(ServicesContext).achillesService
    const theme = useTheme()
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const [cols, setCols] = useState<GridColDef[]>([])
    const ability = useContext(AbilityContext)
    useEffect(() => {
        setCols(convertModelDefToGridColDef(AchillesTableDefinition, ability))
    }, [])
    return (<Grid item xs container flexDirection="column" rowGap="25px" aria-description="Achilles Section">
        <Grid item xs>
            <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                Achilles
            </Typography>
        </Grid>
        <Grid item xs container flexDirection="column" spacing="20px">
            <Grid item container flexDirection="column" rowGap="35px">
                <Grid item container>
                    <StyledBox>
                        <Grid item xs container flexDirection="column" spacing="20px">
                            <Grid item container flexDirection="column" rowGap="35px">
                                <Grid item container>
                                    <GenericTable<Achilles> entity="Achilles" columns={cols}
                                        dataProvider={(filter) => {
                                            const updatedFilter: QueryParameters = { ...filter, filters: [{ field: "assessment_id", operation: FilterOperation.EnumEqual, value: id as string }] }
                                            return achillesService.getAll(updatedFilter)
                                        }}
                                        // TODO:configure routes
                                        onEdit={(elem: Achilles) => navigate("../../Achilles/" + elem.id)}
                                        onDelete={(elem: Achilles) => achillesService.delete(elem.id)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </StyledBox>
                </Grid>
            </Grid>
        </Grid>
    </Grid>)
}

export default AchillesTable
