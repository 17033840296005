import React, { useContext, useState } from "react"
import { Grid, TextField } from "@mui/material"
import { I18nContext } from "I18nProvider"

interface ReferenceGridProps {
    initialReference?: string; // Opcional para iniciar con un dato existente
    onChange?: (updatedReference: string) => void; // Prop para pasar el estado actualizado al padre
}

const ReferenceGrid: React.FC<ReferenceGridProps> = ({ initialReference = "", onChange }) => {
    const [reference, setReference] = useState<string>(initialReference)
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }

    const handleInputChange = (value: string) => {
        setReference(value) // Actualizar el estado
        onChange?.(value) // Notificar al padre
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label={context.t.translate("reference")}
                    value={reference}
                    onChange={(e) => handleInputChange(e.target.value)}
                />
            </Grid>

        </Grid>
    )
}

export default ReferenceGrid
