import { MediaTypes } from "@services/index"
import Service from "@services/abstract/BaseService"
import Pentest from "@models/Pentest"

class PentestConfigService extends Service<Pentest> {
    public constructor () {
        super(process.env.REACT_APP_API, "api/v1/pentest", MediaTypes.JSON)
    }
}

export { PentestConfigService }
export default PentestConfigService
