import React, { useContext } from "react"
import { Box, Tooltip, Typography, useTheme } from "@mui/material"
import WarningIcon from "@mui/icons-material/Warning"
import { I18nContext } from "I18nProvider"

interface ExpiredLicense {
  license: string;
  license_expiration_date: string;
}

interface ExpiredLicensesProps {
  licenses: ExpiredLicense[];
}
const ExpiredLicenses: React.FC<ExpiredLicensesProps> = ({ licenses }) => {
    const context = useContext(I18nContext)
    if (context === null) throw new Error("The I18n context is not initialized. Make sure you have the provider set up correctly.")
    // Función para verificar si una licencia está vencida
    const theme = useTheme()
    const isExpired = (licenseDate: string): boolean => {
        const expirationDate = new Date(licenseDate)
        const currentDate = new Date()

        // Si no es una fecha válida o es el valor predeterminado "0001-01-01T00:00:00Z"
        if (isNaN(expirationDate.getTime()) || licenseDate === "0001-01-01T00:00:00Z") {
            return false
        }

        // Retornar si está vencida
        return expirationDate < currentDate
    }

    // Filtrar las licencias vencidas
    const expiredLicenses = licenses.filter((license) =>
        isExpired(license.license_expiration_date)
    )

    // Contar las instancias de cada licencia expiradas
    const licenseCounts = expiredLicenses.reduce((acc, license) => {
        acc[license.license] = (acc[license.license] || 0) + 1
        return acc
    }, {} as Record<string, number>)

    // Filtrar las licencias duplicadas, obteniendo solo una instancia por licencia
    const uniqueLicenses = Array.from(new Set(expiredLicenses.map((license) => license.license)))
        .map((licenseName) => {
            return expiredLicenses.find((license) => license.license === licenseName)
        })

    // Si no hay licencias expiradas, no renderizar nada
    if (uniqueLicenses.length === 0) {
        return null
    }

    return (
        <Box>

            {/* Contenedor con scroll y límite de altura */}
            <Box sx={{
                maxHeight: 100,
                overflowY: "auto",
                "&::-webkit-scrollbar": {
                    height: "10px"
                },
                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: "10px",
                    border: "2px solid black"
                },
                "&::-webkit-scrollbar-track": {
                    backgroundColor: "black",
                    borderRadius: "10px"
                }
            }}>
                {uniqueLicenses.map((license, index) => {
                    const count = licenseCounts[license?.license || ""] // Obtener el número de instancias para esta licencia

                    return (
                        <Box key={index} display="flex" alignItems="center" mb={3}>
                            <Tooltip
                                title={`${count} component(s) with this license expired on ${new Date(
                  license?.license_expiration_date as string
                                ).toLocaleDateString()}`}
                                arrow
                            >
                                <WarningIcon color="warning" sx={{ marginRight: 1 }} />
                            </Tooltip>
                            <Typography>
                                {license?.license || context.t.translate("unnamed_license")} - {context.t.translate("expired_on")}{" "}
                                {new Date(license?.license_expiration_date as string).toLocaleDateString()}
                            </Typography>
                        </Box>
                    )
                })}
            </Box>
        </Box>
    )
}

export default ExpiredLicenses
