import React from "react"
import { Image, Page, Text, View } from "@react-pdf/renderer"
import { TMetadataSCAContent } from "@models/Template"
import styles from "../Styles"

const SimplifiedIndex: React.FC<{ metadata?: TMetadataSCAContent }> = ({ metadata }) => {
    // Si no hay metadata, mostramos todos los puntos
    const dynamicIndex = metadata
        ? [
            metadata?.introduction && "Introducción..........................................................3",
            metadata?.executive_summary && "Resumen ejecutivo................................................4",
            metadata?.table_of_content && "Siguientes pasos.......................................................5",
            metadata?.scope && "Identificación de vulnerabilidades............................7"
        ]
            .filter(Boolean) // Filtrar valores no válidos (falsos o nulos)
        : [
            "Introducción..........................................................3",
            "Resumen ejecutivo................................................4",
            "Siguientes pasos.......................................................5",
            "Identificación de vulnerabilidades............................7"
        ]

    // Lista de normativas que siempre se muestran
    const normativas = [
        { id: "Normativas" },
        { id: "- Open Web Application Security Project (OWASP)" },
        { id: "- IEC 62443-4-1" },
        { id: "- The Penetration Testing Execution Standard Documentation" },
        { id: "- PCI Data Security Standard (PCI DSS)" }
    ]

    // Seccion dinámica de índice con numeración ajustada para el caso de Siguientes pasos
    const renderIndex = () => {
        const pageNum = 3
        return dynamicIndex.map((point, index) => {
            if (typeof point === "string" && point.includes("Siguientes pasos")) {
                return (
                    <Text
                        key={index}
                        style={{
                            ...styles.puntuak2,
                            paddingLeft: 40,
                            marginRight: -30,
                            paddingBottom: 5
                        }}
                    >
                        <Text
                            style={{
                                ...styles.puntuak,
                                textAlign: "left",
                                fontFamily: "Bold",
                                fontSize: 10.5,
                                marginRight: -20
                            }}
                        >
                            {index + 1}. {point.replace("5", "5")}
                        </Text>
                    </Text>
                )
            } else if (typeof point === "string") {
                return (
                    <Text
                        key={index}
                        style={{
                            ...styles.puntuak2,
                            paddingLeft: 40,
                            marginRight: -30,
                            paddingBottom: 5
                        }}
                    >
                        <Text
                            style={{
                                ...styles.puntuak,
                                textAlign: "left",
                                fontFamily: "Bold",
                                fontSize: 10.5,
                                marginRight: -20
                            }}
                        >
                            {index + 1}. {point}
                        </Text>
                    </Text>
                )
            }
            return null // En caso de que el valor no sea un string (como `false` o nulo)
        })
    }

    return (
        <Page size="A4">
            <View style={styles.page}>
                <Image
                    style={{
                        ...styles.image3,
                        paddingRight: 0,
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: 595,
                        height: 842,
                        zIndex: -1
                    }}
                    src="/assets/img/report/indice_def.jpg"
                />
                <View style={{ ...styles.column, paddingLeft: 10, paddingTop: 300, paddingRight: 10 }}>
                    <Text style={{ ...styles.titulo, fontFamily: "Bold" }}>Indice</Text>
                    <View>{renderIndex()}</View>
                    <Text>&nbsp;</Text>
                    <View style={{ ...styles.tableScope, width: "100%" }}>
                        <Text
                            style={{
                                ...styles.tableColScope,
                                textAlign: "left",
                                width: "58.25%",
                                height: 120,
                                fontSize: 10.5
                            }}
                        >
                            {normativas.map((fila, index) =>
                                index === 0 ? fila.id : `\n\n${fila.id}`
                            )}
                        </Text>
                    </View>
                    <FooterIndex />
                </View>
            </View>
        </Page>
    )
}

const FooterIndex: React.FC<{}> = () => (
    <View>
        <View>
            <Text style={{ ...styles.textua, fontFamily: "Griff", paddingLeft: 32, paddingRight: -50, marginRight: 245, paddingBottom: 32, paddingTop: 230, fontSize: 5.5, position: "absolute", textAlign: "justify" }}>
                The intellectual and industrial property rights over this document are the exclusive property of ORBIK Cybersecurity, S.L.
                Likewise, this report and, where applicable, any document annexed to it, contains confidential information exclusively addressed to its recipient.
                <Text style={{ fontFamily: "Bold", color: "#FFC000", backgroundColor: "black" }}>TLP-AMBER</Text> Its disclosure, copy or distribution to third parties is prohibited without the prior written authorization of ORBIK Cybersecurity.
                If you have received this report in error, proceed to delete it and immediately notify ORBIK Cybersecurity, S.L.
            </Text>
        </View>
    </View>
)

export { SimplifiedIndex }
