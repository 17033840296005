import Compliance from "@models/Compliance"
import Service from "@services/abstract/BaseService"
import { MediaTypes } from "./HttpService"

class ComplianceService extends Service<Compliance> {
    public constructor () {
        super(process.env.REACT_APP_API, "api/v1/compliance", MediaTypes.JSON)
    }

    public sendHelpNotification = (id: any) => {
        return this.http.get(`/${this.path}/cra-help/${id}`)
    }

    public finishedQuiz = (clientId: any, data: Compliance) => {
        return this.http.post(`/${this.path}/finish/${clientId}`, data)
    }

    public notifyCRACompletion = (clientId: any, data: Compliance) => {
        return this.http.post(`/${this.path}/notification/${clientId}`, data)
    }
}

export { ComplianceService }
export default ComplianceService
