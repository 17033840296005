import { AssessmentExt, AssessmentStatus, AssessmentType } from "@models/index"
import { getEnumKeys, ModelDefinition } from "@models/utils"
import AssessmentStateColored from "@components/vulnerability/AssessmentStateColored"
import { VulnerabilityIndicator } from "@components/common/indicator/VulnerabilityIndicator"
import { FilterOperation } from "@utils/queryParams"
import AssessmentStatusColored from "./AssessmentStatusColored"
import AssessmentNameNavigate from "./AssessmentNameNavigate"

const AssessmentTypeKeys = getEnumKeys(AssessmentType)
const AssessmentTypeKeysValueOps = AssessmentTypeKeys.map((key) => ({ label: key, value: AssessmentType[key] }))

const AssessmentStatusKeys = getEnumKeys(AssessmentStatus)
const AssessmentStatusKeysValueOps = AssessmentStatusKeys.map((key) => ({ label: key, value: AssessmentStatus[key] }))
const AssessmentTableDefinition: ModelDefinition<AssessmentExt> = {
    properties: [
        { name: "id", label: "Id", type: "string", flex: 0.1, isId: true },
        { name: "product.name", label: "Product", type: "struct", flex: 0.3, valueGetter: assessment => assessment.product.name, render: AssessmentNameNavigate },
        { name: "product.version", label: "Version", type: "struct", flex: 0.1, valueGetter: assessment => assessment.product.version },
        { name: "type", label: "Type", type: "singleSelect", flex: 0.15, allowedValueOptions: AssessmentTypeKeysValueOps, render: AssessmentStateColored, minWidth: 120 },
        { name: "status", label: "Status", type: "singleSelect", flex: 0.15, allowedValueOptions: AssessmentStatusKeysValueOps, render: AssessmentStatusColored, minWidth: 120 },
        { name: "contract_date", label: "Contracted", type: "date", flex: 0.2, valueGetter: assessment => new Date(assessment.contractDate as string) },
        { name: "start_date", label: "Start", type: "date", flex: 0.2, valueGetter: assessment => assessment.startDate != null ? new Date(assessment.startDate) : null },
        { name: "end_date", label: "End", type: "date", flex: 0.2, valueGetter: assessment => assessment.endDate != null ? new Date(assessment.endDate) : null },
        // { name: "num_vulnerability", label: "Vulnerabilities", type: "number", flex: 0.2 },
        {
            name: "vulns_number",
            label: "Total Vulns",
            type: "struct",
            flex: 0.5,
            render: (params) =>
                <VulnerabilityIndicator
                    filters={[{ field: "assessment_id", operation: FilterOperation.UUIDEqual, value: params.row.id }]}
                    critical={params.value ? params.value.critical : 0}
                    high={params.value ? params.value.high : 0}
                    medium={params.value ? params.value.medium : 0}
                    low={params.value ? params.value.low : 0}
                    none={params.value ? params.value.none : 0}
                />
        },
        { name: "client.name", label: "Client", type: "struct", flex: 0.2, valueGetter: assessment => assessment.client.name, minWidth: 80 }
    ]
}

export { AssessmentTableDefinition, AssessmentTypeKeys, AssessmentStatusKeys, AssessmentTypeKeysValueOps, AssessmentStatusKeysValueOps }
export default AssessmentTableDefinition
