import { ModelRelation } from "./utils"

export enum HardwareComponentsOrigin {
    SIEMENS= "siemens",
    DEMO = "demo"
}

type HardwareComponents = {
    id: string,
    identifier: string,
    name: string,
    origin: HardwareComponentsOrigin,
    client: ModelRelation,
    product: ModelRelation
}

type HardwareComponentExt = HardwareComponents & {
    numRemediations: number;
}

export type { HardwareComponents, HardwareComponentExt }
export default HardwareComponents
