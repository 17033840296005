import { ComplianceResult, FunctionalRequirementCategory, Question, QuestionType } from "@models/Compliance"

export const FDAQuestions: Question[] = [
    {
        id: "q1",
        category: FunctionalRequirementCategory.FDA,
        type: QuestionType.SingleSelect,
        title: "System Diagram Availability",
        description: "Do you have a system diagram that illustrates components, connections, and interactions of your medical device system?",
        totalValue: 0,
        results: [
            {
                result: ComplianceResult.NoDocument,
                text: "We don't have a document number or haven't documented this activity.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.PartialDocument,
                text: "We partially meet the requirement or are unsure of the document number.",
                comment: "",
                value: 2
            },
            {
                result: ComplianceResult.PartiallyCompliant,
                text: "We fully meet the requirement but have some room for improvement.",
                comment: "",
                value: 5
            },
            {
                result: ComplianceResult.FullDocument,
                text: "We fully meet the requirement and have the document number.",
                comment: "",
                value: 9
            }
        ]
    },
    {
        id: "q2",
        category: FunctionalRequirementCategory.FDA,
        type: QuestionType.SingleSelect,
        title: "Threat Modeling Report Availability",
        description: "Do you have a threat modeling report that outlines security objectives, risks, and vulnerabilities across the system?",
        totalValue: 0,
        results: [
            {
                result: ComplianceResult.NoDocument,
                text: "We don't have a document number or haven't documented this activity.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.PartialDocument,
                text: "We partially meet the requirement or are unsure of the document number.",
                comment: "",
                value: 2
            },
            {
                result: ComplianceResult.PartiallyCompliant,
                text: "We fully meet the requirement but have some room for improvement.",
                comment: "",
                value: 5
            },
            {
                result: ComplianceResult.FullDocument,
                text: "We fully meet the requirement and have the document number.",
                comment: "",
                value: 9
            }
        ]
    },
    {
        id: "q3",
        category: FunctionalRequirementCategory.FDA,
        type: QuestionType.SingleSelect,
        title: "Security Risk Assessment (SRA)",
        description: "Do you have a comprehensive security risk assessment to identify, prioritize, and mitigate risks?",
        totalValue: 0,
        results: [
            {
                result: ComplianceResult.NoDocument,
                text: "We don't have a document number or haven't documented this activity.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.PartialDocument,
                text: "We partially meet the requirement or are unsure of the document number.",
                comment: "",
                value: 2
            },
            {
                result: ComplianceResult.PartiallyCompliant,
                text: "We fully meet the requirement but have some room for improvement.",
                comment: "",
                value: 5
            },
            {
                result: ComplianceResult.FullDocument,
                text: "We fully meet the requirement and have the document number.",
                comment: "",
                value: 9
            }
        ]
    },
    {
        id: "q4",
        category: FunctionalRequirementCategory.FDA,
        type: QuestionType.SingleSelect,
        title: "Vulnerability Management Plan",
        description: "Do you have a vulnerability management plan and/or report?",
        totalValue: 0,
        results: [
            {
                result: ComplianceResult.NoDocument,
                text: "We don't have a document number or haven't documented this activity.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.PartialDocument,
                text: "We partially meet the requirement or are unsure of the document number.",
                comment: "",
                value: 2
            },
            {
                result: ComplianceResult.PartiallyCompliant,
                text: "We fully meet the requirement but have some room for improvement.",
                comment: "",
                value: 5
            },
            {
                result: ComplianceResult.FullDocument,
                text: "We fully meet the requirement and have the document number.",
                comment: "",
                value: 9
            }
        ]
    },
    {
        id: "q5",
        category: FunctionalRequirementCategory.FDA,
        type: QuestionType.SingleSelect,
        title: "CVE Triage and Disposition Documentation",
        description: "Do you have a document that captures your vulnerability (e.g. Common Vulnerabilities and Exposures (CVE)) triage and disposition?",
        totalValue: 0,
        results: [
            {
                result: ComplianceResult.NoDocument,
                text: "We don't have a document number or haven't documented this activity.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.PartialDocument,
                text: "We partially meet the requirement or are unsure of the document number.",
                comment: "",
                value: 2
            },
            {
                result: ComplianceResult.PartiallyCompliant,
                text: "We fully meet the requirement but have some room for improvement.",
                comment: "",
                value: 5
            },
            {
                result: ComplianceResult.FullDocument,
                text: "We fully meet the requirement and have the document number.",
                comment: "",
                value: 9
            }
        ]
    },
    {
        id: "q6",
        category: FunctionalRequirementCategory.FDA,
        type: QuestionType.SingleSelect,
        title: "Software Bill of Materials (SBOM) Report",
        description: "Do you have a Software Bill of Materials (SBOM) report in machine-readable format (e.g. CycloneDX or SPDX)?",
        totalValue: 0,
        results: [
            {
                result: ComplianceResult.No,
                text: "No, we do not have an SBOM report.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.Yes,
                text: "Yes, we have a machine-readable SBOM report.",
                comment: "",
                value: 9
            }
        ]
    },
    {
        id: "q7",
        category: FunctionalRequirementCategory.FDA,
        type: QuestionType.SingleSelect,
        title: "Penetration Testing Documentation",
        description: "What is the name and/or identification number of the document that captures your penetration testing?",
        totalValue: 0,
        results: [
            {
                result: ComplianceResult.NoDocument,
                text: "We don't have a document number or haven't documented this activity.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.PartialDocument,
                text: "We partially meet the requirement or are unsure of the document number.",
                comment: "",
                value: 2
            },
            {
                result: ComplianceResult.PartiallyCompliant,
                text: "We fully meet the requirement but have some room for improvement.",
                comment: "",
                value: 5
            },
            {
                result: ComplianceResult.FullDocument,
                text: "We fully meet the requirement and have the document number.",
                comment: "",
                value: 9
            }
        ]
    },
    {
        id: "q8",
        category: FunctionalRequirementCategory.FDA,
        type: QuestionType.SingleSelect,
        title: "Fuzz Testing Documentation",
        description: "Do you have a document that captures your fuzz testing?",
        totalValue: 0,
        results: [
            {
                result: ComplianceResult.NoDocument,
                text: "We don't have a document number or haven't documented this activity.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.PartialDocument,
                text: "We partially meet the requirement or are unsure of the document number.",
                comment: "",
                value: 2
            },
            {
                result: ComplianceResult.PartiallyCompliant,
                text: "We fully meet the requirement but have some room for improvement.",
                comment: "",
                value: 5
            },
            {
                result: ComplianceResult.FullDocument,
                text: "We fully meet the requirement and have the document number.",
                comment: "",
                value: 9
            }
        ]
    },
    {
        id: "q9",
        category: FunctionalRequirementCategory.FDA,
        type: QuestionType.SingleSelect,
        title: "Vulnerability Scanning and Testing Documentation",
        description: "Do you have a document that captures your vulnerability scanning and/or testing?",
        totalValue: 0,
        results: [
            {
                result: ComplianceResult.NoDocument,
                text: "We don't have a document number or haven't documented this activity.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.PartialDocument,
                text: "We partially meet the requirement or are unsure of the document number.",
                comment: "",
                value: 2
            },
            {
                result: ComplianceResult.PartiallyCompliant,
                text: "We fully meet the requirement but have some room for improvement.",
                comment: "",
                value: 5
            },
            {
                result: ComplianceResult.FullDocument,
                text: "We fully meet the requirement and have the document number.",
                comment: "",
                value: 9
            }
        ]
    }
]
