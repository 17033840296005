import React from "react"
import { Image, Page, Text, View } from "@react-pdf/renderer"
import Template from "@models/Template"
import styles from "../Styles"
import VexDocumentStyles from "../VexStyleSheet"
import { Footer, Header } from "./HeaderFooter"

export const introduccion = [
    { id: "Executive Summary: ", description: "A general summary of the results obtained during the software composition analysis is presented." },
    { id: "Vulnerability Identification: ", description: "The system's vulnerabilities and weaknesses are identified, and a series of countermeasures are proposed to mitigate the identified and/or exploited vulnerabilities and weaknesses." },
    { id: "Next Steps: ", description: "The different services offered by Orbik to support compliance with cybersecurity regulations are explained." }
]

const SimplifiedIntro: React.FC<{template?: Template}> = ({ template }) => (
    <Page size="A4" style={{ backgroundColor: !template || template.color === "#fffa37" ? "" : template.color }} >
        <Header template={template}></Header>
        <View style={{ ...VexDocumentStyles.section, flexDirection: "row" }} >
            <View style={styles.column} >
                <View>
                    <Text style={styles.titulo}>1. Introduction</Text>
                    <Text style={{ ...styles.textua, marginRight: 50, fontFamily: "Griff", fontSize: 10 }}>This document describes the results of the vulnerability analysis that has been performed on the target device or software.</Text>
                    <Text style={{ ...styles.textua, marginRight: 50, fontFamily: "Griff", fontSize: 10 }} wrap={true}>Vulnerability analysis aims to identify and characterize system design flaws, weaknesses and vulnerabilities. This activity, which is routinely carried out in the product verification and validation phase, is a specific requirement of the IEC 62443 standard.</Text>
                    <Text style={{ ...styles.textua, marginRight: 50, fontFamily: "Griff", fontSize: 10 }}>The report is organized this way:</Text>
                    {introduccion.map((point, index) => (
                        <Text key={index} style={{ ...styles.puntuak, marginRight: 50, fontFamily: "Griff", fontSize: 10, paddingTop: 5 }}>
                            <Text style={styles.puntuak}>• &nbsp;</Text>
                            <Text style={{ ...styles.puntuak, fontFamily: "Bold" }}>{point.id}</Text>
                            <Text style={styles.puntuak}>{point.description}</Text>
                        </Text>
                    ))}
                </View>
            </View>
            <View style={styles.column}>
                <Image style={{ ...styles.rightImage, marginLeft: 30, paddingTop: 0, marginTop: -40, height: 305 }} src="./assets/img/report/computer.jpg" />
                <View style={{ ...styles.rightImage, marginTop: 265, height: 350, marginLeft: 30, marginRight: -20, paddingTop: 100, marginBottom: 50, backgroundColor: "#fffa37", flexDirection: "row" }}>
                    <Text style={{ marginLeft: 55, marginRight: 50, paddingTop: -80, marginBottom: 80, paddingRight: 30, textAlign: "right", fontFamily: "Bold", fontSize: 18 }}>The analysis aims to identify and characterize the design flaws, weaknesses and vulnerabilities of the system.</Text>
                </View>
            </View>
        </View>
        <Footer color={template?.color}></Footer>
    </Page>
)

export { SimplifiedIntro }
