
const en = {
    languages: {
        en: "English",
        es: "Spanish"
    },
    settings: {
        languageChange: "Change language",
        languageCurrent: "Chosen language"
    },
    addMessage: "Receive a message",
    messages: {
        one: "You have one message",
        other: "You have {count} messages"
    },
    // dashboard
    dash_assess_finished: "Finished tests",
    dash_vulner_mitigated: "Mitigated vulnerabilities",
    dash_vulner_nomitigated: "Unmitigated vulnerabilities",
    dash_assess: "Assessments",
    dash_vulner: "Vulnerabilities",
    dash_product: "Products",
    dash_assess_byproduct: "By product",
    dash_assess_bystatus: "By status",
    dash_assess_bytype: "By type",
    dash_vulner_criticity: "Criticality",
    dash_vulner_state: "State",
    dash_vulner_mitigaciones: "Mitigations",
    dash_product_bytype: "By state",
    dash_export: "Export",
    dash_vulner_title: "Mitigated vs. unmitigated vulnerabilities",
    // Products
    product_newproduct: "Add new product",
    product_newtag: "Manage tags",
    product_name: "Name",
    product_version: "Version",
    product_type: "Type",
    product_description: "Description",
    product_client: "Client name",
    product_descrip: "Product description",
    product_save: "Save changes",
    product_tag: "Search Tag",
    product_addtag: "Add tag",
    product_createtag: "Create Tag",
    product_color: "Color",
    product_newname: "Product Name",
    product_details: "Details",
    // Assessment
    assess_noption: "No options",
    asess_template_name: "Template name",
    assess_status: "Status",
    assess_date_contrac: "Contract date",
    assess_date_start: "Start date",
    assess_date_end: "End date",
    assess_new: "Add new assessment",
    assess_close: "Close",
    assess_comp: "Components",
    assess_scan: "Scanned at",
    assess_quali: "Quality Score",
    assess_metric: "SBOM Quality Metrics",
    // Benchmark
    bench_criti: "Criticity",
    // CRA compliance
    CRA_title: "Compliance Quiz",
    CRA_edit: "Edit Text",
    CRA_text: "Edit the Conclusion field of the compliance",
    CRA_save: "Save",
    CRA_cancel: "Exit",
    CRA_conclusion: "Conclusion",
    CRA_resume: "Resume compliance quiz",
    CRA_start: "Start your compliance quiz",
    CRA_view: "View response",
    CRA_quiz: "Quiz",
    CRA_details: "Details",
    CRA_total: "Total Score",
    CRA_closetext: "Cancel",
    CRA_text2: "You can't undo this operation",
    CRA_noquiz: "You have not completed any compliance quiz yet",
    CRA_noquiz2: "Take our quiz to know yout CRA compliance score",
    pending: "Pending answers",
    // Textos de views/assessment
    customize_this: "Customize this sections for your Assessment",
    // Textos de views/assessment/components
    vulnerabilities: "Vulnerabilities",
    criticity: "Criticity",
    there_are_no: "There are no mitigated vulnerabilities",
    // Textos de views/assessment/customactions
    realanalyze: "Reanalyze",
    SBOM_metrics: "SBOM Metrics",
    components: "Components",
    SBOM_quality: "SBOM Quality Metrics",
    quality_score: "Quality Score",
    scanned_at: "Scanned at",
    file_uploaded: "File uploaded successfully. Status changed correctly, please refresh the page.",
    succes: "Succes",
    customize_text: "Customize text",
    // Textos de views/assessment/AssesmentCustomText.tsx
    custom_text: "Custom text cleared successfully!",
    error_saving: "Error saving custom text.",
    error_clearing: "Error clearing custom text.",
    introduction: "Introduction",
    scope1: "Scope",
    test_initialization: "Test Initialization",
    conclusions: "Conclusions",
    // Textos de views/assessment/modals
    max_height: "max-height 0.3s ease-in-out",
    // Textos de views/assessment/AssessmentsDetail
    error_generating: "Error generating the CRT report",
    date_start: "Start date",
    date_end: "End date",
    date_contrac: "Contract date",
    upload_sbom: "Upload SBOM",
    report: "Report",
    original: "Original",
    assessment_section: "Assessment Section",
    unsupported_file: "Unsupported file type",
    wrong_file: "Wrong file type uploaded",
    an_error: "An error occurred.",
    add_new: "Add new assessment",
    error_downloading: "Error downloading the file:",
    error_downloading_report: "Error downloading the report file.",
    error_updating: "Error updating assessment status and startDate:",
    failed_to_update: "Failed to update assessment status and startDate.",
    // Textos de views/assessment/AssessmentsForm
    invalid_date: "Invalid date",
    error_testing: "Error testing",
    // Textos de views/assessment/AssessmentList.tsx
    add_new_assess: "Add new assessment",
    reanalyze: "Reanalyze",
    analysis_request: "Analysis request successful!",
    // Textos de views/assessment/CVSSCalculator.tsx
    cvvs_3_1_calculator: "CVSS 3.1 Calculator",
    base: "Base",
    temporal: "Temporal",
    environmental: "Environmental",
    overall: "Overall",
    cvss_vector: "CVSS v3.1 Vector",
    information_about: "Information about CVSS",
    base_score: "Base Score Metrics",
    temporal_score: "Temporal Score",
    environmental_score: "Environmental Score Metrics",
    exploitability: "Exploitability Metrics",
    attack_vector: "Attack Vector (AV)*",
    attack_complexity: "Attack Complexity (AC)*",
    pribileges_required: "Privileges Required (PR)*",
    user_interaction: "User Interaction (UI)*",
    scope: "Scope (S)*",
    impact_metrics: "Impact Metrics",
    confidentiality_imparct: "Confidentiality Impact (C)*",
    integrity_impact: "Integrity Impact (I)*",
    availability_impact: "Availability Impact (A)*",
    exploit_code: "Exploit Code Maturity (E)",
    remediation_level: "Remediation Level (RL)",
    report_confidence: "Report Confidence (RC)",
    exploitability_metrics: "Exploitability Metrics",
    attack_vector_m: "Attack Vector (MAV)",
    attack_complexity_m: "Attack Complexity (MAC)",
    privileges_required: "Privileges Required (MPR)",
    user_interaction_m: "User Interaction (MUI)",
    scope_ms: "Scope (MS)",
    confidentiality_imparct_m: "Confidentiality Impact (MC)",
    integrity_impact_m: "Integrity Impact (MI)",
    availability_impact_m: "Availability Impact (MA)",
    impact_subscore: "Impact Subscore Modifiers",
    confidentiality_requirement: "Confidentiality Requirement (CR)",
    integrity_requirement: "Integrity Requirement (IR)",
    availability_requirement: "Availability Requirement (AR)",
    // Textos de views/assessment/SBOMEvolution.tsx
    sbom_evolution: "SBoM Evolution",
    back: "back",
    outdated_only: "Outdated only",
    product_comparation: "Product comparation",
    // Textos de views/assessment/SBOMQuality.tsx
    download_sbom: "Download SBOM",
    score_summary: "Score Summary",
    // Textos de views/assessment/SunburstView.tsx
    level: "level",
    item: "item",
    // Textos de views/component
    component_edit_section: "Component Edit Section",
    reference: "Reference",
    supressed: "Supressed",
    add_new_component: "Add new component",
    outdated: "Outdated",
    supressed_only: "Supressed only",
    add_component: "Add component",
    error_adding_cpe: "Error adding CPE",
    hardware: "Hardware",
    software: "Software",
    add_vulnerability: "Add vulnerability",
    there_are_no_unmitigated: "There are no unmitigated vulnerabilities",
    // Textos de apartado views/client
    licenses: "Licenses",
    add_new_client: "Add new client",
    user_logs: "User logs",
    // Textos de apartado views/compliance
    no_data_found: "No data found",
    open: "Open",
    saved_successfully: "Saved successfully",
    is_compliant: "Is compliant?",
    true: "True",
    false: "False",
    next: "Next",
    send_the_results: "Send the results",
    congratulations: "Congratulations, you completed all the questions of the quiz",
    do_you_want: "Do you want to send the results?",
    we_will_send: "We will send you the report with our conclusions.",
    sla_management: "SLA management",
    failed_to_fetch: "Failed to fetch hierarchy",
    unsupported_operator: "Unsupported operator:",
    total_vulns: "Total Vulns",
    edit: "Edit",
    hierarchy: "Hierarchy",
    failed_to_save: "Failed to save hierarchy",
    failed_to_delete: "Failed to delete hierarchy",
    delete_node: "Delete node",
    are_you_sure: "Are you sure you want to delete selected item and its ",
    children: " children?",
    are_you_sure_delete: "Are you sure you want to delete selected node?",
    identity_status: "Identity status",
    breakdown_levels: "Breakdown levels",
    product_categories: "Product categories",
    manufacturers: "Manufacturers",
    update_status: "Update status",
    user_sessions: "User Sessions",
    login_in_last: "Login in last 2 days:",
    users_in_last: "Users in last 2 days:",
    dashboard: "Dashboard",
    issue_management: "Issue Management",
    jira_configuration: "Jira configuration saved successfully",
    jira_token_not: "Jira token not set",
    jira_webhook: "Jira webhook secret not set",
    error_fetching: "Error fetching client data:",
    jira_project: "Jira project created successfully",
    jira_project_restored: "Jira project restored successfully",
    // Textos que se encuentran en el apartado de dashboard (la mayoria):
    assessments: "Assessments",
    vuln_details: "Vulnerability Details",
    products: "Products",
    finished_tests: "Finished tests",
    mitigated_vulnerabilities: " Mitigated vulnerabilities",
    unmitigated_vulnerabilities: " Unmitigated vulnerabilities",
    by_product: "By product",
    by_status: "By status",
    by_type: "By type",
    by_state: "By state",
    mitigations: "Mitigations",
    mitigated_vs_unmitigated: "Mitigated vs Unmitigated vulnerabilities",
    number_of_vulnerabilities: "Number of vulnerabilities",
    critical: "Critical",
    high: "High",
    medium: "Medium",
    low: "Low",
    none: "None",
    mitigated: "mitigated",
    unmitigated: "unmitigated",
    evolution_of_vulnerabilities: "Evolution of vulnerabilities",
    daily: "Daily",
    yearly: "Yearly",
    monthly: "Monthly",
    // Textos de views/assessment/Components/SecurityAdvisoriesTable
    securityAdvisories: "Affected products and solution",
    affectedVersions: "Affected versions",
    remediation: "Remediation",
    details: "Details",
    sourceDocument: "Security advisory source document",
    // textos de views/pentest
    pentest_added: "Pentest added successfully",
    pentest_assessment: "Pentest Assessment",
    create_the_list: "Create the list of evidences you want to add in the Pentest",
    origin: "Origin",
    identifier: "Identifier",
    score: "Score",
    solution: "Solution",
    references: "References",
    metadata: "Metadata",
    component_details: "Component details",
    add_new_evidence: "Add New Evidence",
    authentication_api_vulnerability: "Authentication API Vulnerability",
    the_authentication_api: "The authentication API allows attackers to perform SQL injection attacks.",
    sesitive_data_can: "Sensitive data can be compromised due to insufficient user input validation.",
    implememt_proper_input: "Implement proper input validation and use prepared statements in SQL queries.",
    information_leakage: "Information Leakage Through Server Logs",
    server_logs: "Server logs contain sensitive information, such as database credentials and authentication tokens.",
    unauthorized_access: "Unauthorized access to logs could compromise system security.",
    review_server: "Review server configuration to ensure logs do not contain sensitive information, and apply proper permissions.",
    cloud_storage_service_vulnerability: "Cloud Storage Service Vulnerability",
    cloud_storage_access_permissions: "Cloud storage access permissions allow unauthorized users to access private files.",
    confidential_company_data: "Confidential company data can be exfiltrated due to misconfigured permissions.",
    reconfigure_file_access: "Reconfigure file access permissions and apply the principle of least privilege for cloud resource access.",
    pentest_added_successfully: "Pentest added successfully.",
    download_pdf_report: "Download PDF Report",
    add_pentest: "Add Pentest",
    list_of_current_pentest: "List of current pentest",
    identificator: "Identificator:",
    view_details: "View Details",
    confirm_delete: "Confirm Delete",
    are_you_sure_you_want: "Are you sure you want to delete this item?",
    delete: "Delete",
    affected: "Affected:",
    ui_preferences_and_language: "UI Preferences & Language",
    components_licenses: "Components Licenses",
    quantity: "Quantity",
    historic: "Historic",
    expired_licenses: "Expired Licenses",
    inheritance: "Inheritance",
    enter_description: "Enter description here...",
    components_with_this: "component(s) with this license expired on",
    unnamed_license: "Unnamed License",
    expired_on: "Expired on ",
    yellow: "Yellow",
    red: "Red",
    green: "Green",
    blue: "Blue",
    error_fetching_tags: "Error fetching tags:",
    tags_created_successfully: "Tags created successfully",
    error_creating_tags: "Error creating tags:",
    error: "Error",
    success: "Success",
    tag_deleted_successfully: "Tag deleted successfully",
    error_deleting_tag: "Error deleting tag:",
    create_tag: "Create Tag",
    delete_tag: "Delete Tag",
    manage_tags: "Manage Tags",
    tag_name: "Tag Name",
    existing_tags: "Existing tags",
    reakdown_levels: "Breakdown Levels",
    versions: "Versions",
    provisioning: "Provisioning",
    reprovisioning: "Reprovisioning",
    out: "Out",
    decommisioned: "Decommisioned",
    assessment_type: "Assessment Type",
    unnamed_hierarchy: "Unnamed Hierarchy",
    no_description_available: "No description available",
    unnamed_product: "Unnamed Product",
    electronics: "Electronics",
    all_electronic_devices: "All electronic devices and accessories",
    laptops: "Laptops",
    various_kinds_of_laptops: "Various kinds of laptops",
    smartphones: "Smartphones",
    mobile_phones_and_accessories: "Mobile phones and accessories",
    gaming_laptops: "Gaming Laptops",
    high_performance_laptops: "High-performance laptops for gaming",
    laptop_a: "Laptop A",
    a_high_performance_laptop: "A high-performance laptop",
    laptop_b: "Laptop B",
    a_budget_friendly_laptop: "A budget-friendly laptop",
    smartphone_a: "Smartphone A",
    a_smartphone_with_a_great_camera: "A smartphone with a great camera",
    gaming_laptop_a: "Gaming Laptop A",
    a_high_end_gaming_laptop: "A high-end gaming laptop",
    smartphone_b: "Smartphone B",
    an_affordable_smartphone: "An affordable smartphone",
    // Textos del apartado de Hierarchy
    manage_hierarchy: "Manage Hierarchy",
    name: "Name",
    description: "Description",
    actions: "Actions",
    // Textos que se encuentran en el apartado de Benchmark
    choose_two_products: "Choose two products to compare the vulnerabilities between them",
    select_product: "Select Product",
    sector: "Sector",
    industry: "industry",
    // Tabla del apartado de benchmark
    energy_and_electric: "Energy and electric networks",
    transport: "Transport",
    elevator: "Elevator",
    IOTDevice: "Iot device",
    automotive: "Automotive",
    health_Care: "Health care",
    // Textos que se encuentran en la pagina de CRA compliance
    summary: "Summary",
    security_requirement: "Security Requirement",
    vulnerability_management: "Vulnerability Management Requirement",
    conclusion: "Conclusion",
    export: "Export",
    quiz: "Quiz",
    total_score: "Total score",
    // textos dentro de la edicion de conclusiones en CRA Compliance
    edit_text: "Edit text",
    edit_the_conclusion: "Edit the Conclusion field of the compliance",
    // Textos dentro del apartado de quiz en CRA compliance
    save: "Save",
    cancel: "Cancel",
    are_you_sure_you: "Are you sure you want to exit without saving changes?",
    close_compliance: "Close Compliance",
    you_cant_undo: "You can't undo this operation",
    // Textos que se encuentran en la seccion de Notifications & alerts
    // policy_management: "Policy Management",
    // save_changes: "",
    notifications_title: "Notifications & Alerts",
    notifications: "Notifications",
    report_filters: "Report Filters",
    recieve_alerts: "Receive alerts when:",
    new_CVEs: "New CVEs are discovered",
    CVEs_are: "CVEs are updated",
    recieve_alerts_when_CVSS: "Receive alerts when CVSS is higher than:",
    alerts: "Alerts",
    enable: "Enable",
    recipient: "Recipient of the email",
    email_frequency: "Email frequency",
    // textos de Policy Management dentro del boton de la seccion de Policy Management
    add_new_policy: "Add new policy",
    // textos cuando editas una gestion de politica
    policy_name: "Policy Name",
    policy_add: "New Policy",
    policy_title: "Policy Detail",
    operator: "Operator",
    value: "Value",
    conditions: "Conditions",
    // Cuando añades una nueva politica
    violation_state: "Violation State",
    condition_subject: "Condition Subject",
    condition_operator: "Condition Operator",
    add_new_condition: "Add new condition",
    // Textos colocados en la seccin Account & Settings en orden
    user_information: "User Information",
    billing: "Billing",
    system_settings: "System settings",
    switch: "Switch between dark and light mode",
    tool_for_automatic_SBoM: "Tool for automatic SBoM generation, see details below",
    download: "Download",
    support: "Support",
    have_any_question: "Have any question in mind? Put in contact with our team!",
    check_subscription: "Check out our subscription plan and info in the following link",
    or_contact_us: "Or contact us through",
    subscription_plan: "Subscription plan",
    gold: "Gold",
    platinum: "Platinum",
    titanium: "Titanium",
    yearly_subscription: "Yearly subscription",
    CVEs_affecting: "CVEs affecting your software components",
    email_notifications: "Email notifications on CVE discovery or modification",
    track_multiple_software: "Track multiple Software Bill of Materials (BOMs)/manifests",
    limited: "Limited",
    unlimited: "Unlimited",
    on_demand_CVE: "On-demand CVE report generation via Web",
    customized: "Customized",
    custom_vulnerability_score: "Custom vulnerability score/prioritization and filtering",
    based_on_EPSS: "Based on EPSS and KEV",
    added_AI: "Added AI",
    added_AI_custom: "Added AI custom",
    CVE_summary: "CVE summary by severity, status, and software package",
    SBoM_quality_metric: "SBoM Quality Metric Score",
    CVSS_calculator: "CVSS Calculator for adding a custom revised score in CVEs",
    referece_links: "Referece links to available patches, miigation and exploits",
    filter_based: "Filter based on Component or Status",
    custom_report_generation: "Custom report generation",
    customizable_hierarchy: "Customizable hierarchy of components",
    benchmark_comparasion: "Benchmark comparison between Sectors: energy, transport, etc...",
    product_and_test: "Product and test creation",
    with_support: "With support",
    integration_with_scanning: "Integration with scanning tools (Nessus, Acunetix, BurpSuite and Achilles)",
    integration_with_jira: "Integration with Jira for streamlined vulnerability issue tracking and management",
    software_BOM: "Software BOM/Manifest editor and revision management",
    comparison_of_changes: "Comparison of changes between build/releases (SBOM historic)",
    CVE_remediation: "CVE remediation suggestions using IA",
    myorbiktoolbox: "myorbikToolbox: SBoM generation via command line",
    adding_private: "Adding Private Vulnerability Repository",
    sending_reports: "Sending reports to clients",
    policy_management: "Policy Management",
    upgrade_plan: "Upgrade plan",
    // textos de Facturacion dentro de Account & Settings en orden
    you_are_currently_free: "You are currently on a Free membership.",
    ticketing: "Ticketing",
    ask_for_additional_assessment: "Ask for additional assessment or product creation to our team",
    you_are_currently_pro: "You are currently on a Pro membership of type ",
    add_new_ticket: "Add new ticket",
    // textos del menu cuando añades un nuevo ticket
    client_name: "Client name",
    type: "Type",
    status: "Status",
    // textos que estan dentro de la edicion de tickets ya creados
    client: "Client",
    due_date: "Due Date",
    notes: "Notes",
    License_validity: "License Validity Date",
    License_new: "New License",
    save_changes: "Save changes",
    // textos de licenses dentro de views
    loading: "Loading...",
    vendor: "Vendor",
    version: "Version",
    part: "Part",
    product: "Product",
    last_modified: "Last modified",
    created_at: "Created at",
    license_title: "License",
    max_products: "Max Products",
    max_assessments: "Max Assessments",
    include_toolbox: "Include Toolbox",
    without_toolbox: "Without Toolbox",
    create_license: "Create License",
    license_management: "License Management",
    add_new_license: "Add New License",
    // Vulnerabilities
    vulner_produc: "Product",
    vulner_vers: "Version",
    vulner_comp: "Component",
    vulner_criti: "Criticity",
    vulner_revis: "Revised Score",
    vulner_score: "Score",
    vulner_vector: "Vector",
    vulner_prio: "Priority",
    vulner_epss: "EPSS",
    vulner_kev: "KEV",
    vulner_nokev: "Not included in KEV catalog",
    vulner_sikev: "Included in KEV catalog",
    vulner_desc: "Description",
    vulner_review: "Review",
    vulner_assess: "Assess",
    vulner_addre: "Address",
    vulner_stat: "Status",
    vulner_just: "Justification",
    vulner_resp: "Response",
    vulner_det: "Details",
    vulner_weak: "Weaknessess (CWES)",
    vulner_meta: "Metadata",
    vulner_cvs: "CVSS3 Calculator",
    vulner_generator: "Generator",
    vulner_generator_time: "Generator Time",
    // Hierarchy
    hierar_level: "Hierarchy level",
    hierar_option: "No options",
    hierar_manage: "Manage hierarchy",
    hierar_node: "Add New Node",
    hierar_back: "Back",
    hierar_reset: "Reset",
    sla: {
        detail: "SLA Detail",
        clientName: "Client Name",
        name: "Name",
        days: "Days",
        enforcements: "Enforcements",
        critical: "critical:",
        high: "high:",
        medium: "medium:",
        low: "low:",
        checkCritical: "Check Critical",
        checkHigh: "Check High",
        checkMedium: "Check Medium",
        checkLow: "Check Low",
        productSave: "Save Product",
        new: "New SLA",
        description: "Description",
        criticalDays: "Critical Days",
        highDays: "High Days",
        mediumDays: "Medium Days",
        lowDays: "Low Days",
        addNew: "Add New SLA"
    },
    issueDetails: {
        title: "Issue Details",
        key: "Key",
        status: "Status",
        project: "Project",
        client: "Client",
        productName: "Product Name",
        productVersion: "Product Version",
        cpeName: "CPE Name",
        cpeVersion: "CPE Version",
        cve: "CVE",
        revisedScore: "Revised Score",
        justification: "Justification",
        response: "Response",
        details: "Details",
        webhookEvent: "Webhook Event"
    },
    jira_integration: "Jira integration",
    jira_url: "Jira URL",
    jira_email: "Jira admin user email",
    jira_account: "Jira admin user account id",
    jira_token: "Jira API token",
    jira_secret: "Jira webhook secret",
    jira_create_project: "Create myOrbik Jira project",
    jira_restore_project: "Restore myOrbik Jira project",
    cpe_title: "CPE Detail",
    cpe_search: "CPE Search",
    add_cpe: "Add CPE",
    component_title: "Component Detail",
    no_date: "No date provided",
    read_all: "Read All",
    compliance: "Compliance",
    client_picker: "Choose a user",
    last_updated: "Last Updated",
    error_message: "An error occurred.",
    error_download: "An error occurred during the download.",
    epss_modal: "EPSS: The Exploit Prediction Scoring System predicts the likelihood that a vulnerability will be exploited within the next 30 days.",
    kev_modal: "KEV: The Known Exploited Vulnerabilities (KEV) catalog is a list maintained by CISA that identifies vulnerabilities with active real-world exploits, helping organizations prioritize the remediation of the most critical ones.",
    dialogs: {
        helpDialogTitleOffline: "README",
        helpDialogTextOffline: "This file contains the commands \"generate_sbom_offline.sh\" and \"generate_sbom_online.sh\" used for the creation of SBOMs on files, directories, or images and their subsequent management.",
        installationTitle: "Installation",
        portableBinaryLimitations: "Limitations & Requirements:",
        requirementsList: [
            "The scripts cannot be added to the PATH, so they must be executed from the same folder where they are located.",
            "A \"Linux\" device with Bash is required.",
            "Have git installed on the system.",
            "At least one tool for decompressing zip files is necessary."
        ],
        usageTitle: "Usage",
        usageRequirements: "Requirements:",
        usageRequirementsList: [
            "If you want to perform a remote execution, you must have access to the system.",
            "It is recommended to have an SSH key created on the system that will run the script. If not, the script will guide you through the creation process.",
            "You must have execution permissions for the script and read permissions (for files and images) or read and execute permissions if it's a directory.",
            "If the script is executed online, internet access is required for post-creation handling of the SBoM."
        ],
        executionExamplesList: [
            "For a normal execution",
            "For an automatic execution",
            "For execution on a system with IP 192.168.1.5"
        ],
        commands: "Commands",
        closeButtonText: "Close"
    },
    color_dialog: {
        title: "Color too dark",
        content: "This color is too dark and may affect the visibility of the text. We recommend choosing a lighter color to ensure better readability.",
        button: "Accept"
    },
    pricing: {
        title: "Get an attractive price here",
        subtitle: "Choose the perfect plan for your needs. Unlock powerful features and grow your business with our flexible pricing options.",
        priceSuffix: "/year"
    },
    system_notification: "System Notification",
    date: "Date",
    action: "Action",
    read_status: "Read Status",
    target_role: "Target Role",
    channels: "Channels",
    setting_check_subscription: "Check info for subscription",
    myorbiktoolbox_description: "My Orbik Toolbox is a custom terminal tool for uploading to Github. It is used for creating SBOMs (Software Bill of Materials) for files, directories, or images, and for their subsequent management. It can be executed both online and offline.",
    stepper: {
        title: "Steps to follow",
        download: "Download and unzip the file",
        step2: "Enter the following command in the terminal to grant execution permissions to the scripts",
        step3: "If you want to access the command from any directory",
        step1_description: "It contains two folders with the two tools (online and offline).",
        step3_description: "Open the bashrc file and write the export command in it."
    },
    myorbiktoolbox_commands: "The generated SBoM will be saved on the local device even if the analyzed file is on a remote system",
    myorbiktoolbox_commands2: "For different types of executions:",
    quizz_export: "Download responses",
    quizz_send: "Send quiz results",
    quizz_status_title: "Current Status",
    question: "Question",
    answered: "Answered",
    compliant: "Compliant",
    by_policy: "By policy",
    by_license: "By license",
    viewLess: "View less",
    viewMore: "View more",
    // Kev Details
    kev_details: " KEV Details",
    vendor_project: "Vendor project",
    ransomware_campaign: "Ransomware campaign",
    required_actiom: "Required action",
    no_action: "No action",
    no_notes: "No notes",
    // CMPDetailGrid
    you_can_only: "You can only add one component",
    component_name: "Component Name",
    component_version: "Component Version",
    add_new_components: "Add New Components",
    add_new_cwe: "Add New CWE",
    // MetadataGrid
    the_metadata_title: "The Metadata",
    is_already_in_use: "is already in use",
    you_can_only_add: "You can only add one metadata",
    add_new_metadata: "Add New Metadata",
    severity: "Severity"

}

export default en
