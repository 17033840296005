import React, { useEffect, useState } from "react"
import Chip from "@mui/material/Chip"
import { ClientAction } from "@models/UserLog"
import { ClientActionColors } from "@components/common/colors/ClientActionColors"
import { ClientLogActionValueOps } from "./ClientLogTableDefinition"

interface ColorAndBg{
    color: string;
    "background-color": string;
}

type UserLogColorMap = {
  [key in ClientAction]: ColorAndBg;
};

const getUserLogColor = (status: ClientAction): ColorAndBg => {
    const clientLogActionColorMap: UserLogColorMap = {
        [ClientAction.Create]: { color: "#000000", "background-color": ClientActionColors.CREATE },
        [ClientAction.Update]: { color: "#000000", "background-color": ClientActionColors.UPDATE },
        [ClientAction.Delete]: { color: "#000000", "background-color": ClientActionColors.DELETE }

    }
    return clientLogActionColorMap[status]
}

interface UserLogColoredProps {
    value: ClientAction;
}

const ClientLogActionColored: React.FC<UserLogColoredProps> = ({ value }) => {
    const [color, setColor] = useState<ColorAndBg|null>(null)
    const [label, setLabel] = useState<string|undefined>("")

    useEffect(() => {
        setLabel(ClientLogActionValueOps.find(v => v.value === value)?.label)
        setColor(getUserLogColor(value))
    }, [value])

    return (
        <Chip size="small" style={{
            fontWeight: "bolder",
            color: color?.color,
            backgroundColor: color?.["background-color"]
        }} label={label}
        />
    )
}

export { ClientLogActionColored }
export default ClientLogActionColored
