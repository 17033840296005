import { BiBug, BiSolidComponent } from "react-icons/bi"
import { FiLogOut } from "react-icons/fi"
import { CgFileDocument, CgTemplate } from "react-icons/cg"
import { VscGraph } from "react-icons/vsc"
import { TbDeviceHeartMonitor } from "react-icons/tb"
import { MdEditNote, MdNotificationsNone, MdOutlineDashboard, MdOutlineMail, MdOutlineManageAccounts, MdOutlinePolicy, MdOutlineQuestionAnswer, MdReceiptLong, MdTerminal } from "react-icons/md"
import { Actions, Subjects } from "@permissions/ability"
import { Assessment, Client, Home, Logout, Product, Vulnerability } from "@views/index"
import BenchmarkCharts from "@views/benchmark/BenchmarkCharts"
import { IoIosSettings } from "react-icons/io"
import { GrCompliance, GrTree } from "react-icons/gr"
import Compliance from "@views/compliance/Compliance"
import { Component, ComponentDetail, ComponentList } from "@views/component"
import SBOMQuality from "@views/assessment/SBOMQuality"
import { Ticketing, TicketingDetail, TicketingForm } from "@views/ticketing"
import { Hierarchy } from "@views/hierarchy"
import SBOMEvolution from "@views/assessment/SBOMEvolution"
import CpeList from "@views/cpe/CpeList"
import HistoricCveView from "@views/vulnerability/Historic/HistoricCve"
import { License } from "@views/license"
import SystemNotificationDetail from "@views/settings/SystemNotificationDetail"
import PrivateRoutes from "@components/route"
import SLA from "@views/sla/SLA"
import { LiaClipboardCheckSolid } from "react-icons/lia"
import CpeDetail from "@views/cpe/CpeDetail"
import IssueDetail from "@views/issue/IssueDetail"
import Issue from "@views/issue/Issue"
import SystemNotificationList from "@views/settings/SystemNotificationList"
import Policy from "@views/policy/Policy"
import PreferencesUI from "@views/preferences/PreferencesUI"
import { IoLanguageSharp } from "react-icons/io5"
import Email from "@views/email/Email"
import Toolbox from "@views/toolbox/Toolbox"
import { FaRegQuestionCircle } from "react-icons/fa"
import JiraIntegration from "@views/jira/JiraIntegration"
import { SiJira } from "react-icons/si"
import UserLogs from "@views/user_logs/HistoricUserLogView"
import { RiUserSearchLine } from "react-icons/ri"
import UserLogsDetails from "@views/user_logs/UserLogsDetails"
import { Template } from "@views/templates"
import Achilles from "@views/achilles/Achilles"
import { GiAchillesHeel } from "react-icons/gi"
import { ClientProvider } from "@context/useClient"
import { Contact } from "../support"
import FAQ from "../faq/FAQ"

export const sidebarPrimaryItems = [
    [
        {
            icon: MdOutlineDashboard,
            iconProps: {},
            route: PrivateRoutes.HOME,
            link: "/",
            component: <Home />,
            title: "Dashboard"
        }
    ],
    [
        {
            icon: MdEditNote,
            iconProps: {},
            route: "/manage",
            link: "/manage",
            entity: "Management" as Subjects,
            component: <></>,
            title: "Management",
            subItems: [
                {
                    icon: MdOutlineManageAccounts,
                    iconProps: {},
                    route: PrivateRoutes.CLIENT,
                    link: "manage/client",
                    component: <Client />,
                    entity: "Client" as Subjects,
                    title: "Client",
                    permission: { action: ["read", "update"] as Actions[], subject: "Client" as Subjects }
                },
                {
                    icon: TbDeviceHeartMonitor,
                    iconProps: { size: "30px" },
                    route: PrivateRoutes.PRODUCT,
                    link: "manage/product",
                    entity: "Product" as Subjects,
                    component: <Product />,
                    title: "Products",
                    permission: { action: ["read", "update"] as Actions[], subject: "Product" as Subjects }
                },
                {
                    icon: GrTree,
                    iconProps: {},
                    route: PrivateRoutes.HIERARCHY,
                    link: "manage/hierarchy",
                    entity: "Hierarchy" as Subjects,
                    component: <Hierarchy />,
                    title: "Hierarchy",
                    permission: { action: ["read", "update"] as Actions[], subject: "Hierarchy" as Subjects }
                },
                {
                    icon: MdOutlinePolicy,
                    iconProps: {},
                    route: PrivateRoutes.POLICY,
                    link: "manage/policy",
                    component: <Policy />,
                    entity: "Policy" as Subjects,
                    title: "Policy",
                    permission: { action: ["read", "update"] as Actions[], subject: "Policy" as Subjects }
                },
                {
                    icon: GiAchillesHeel,
                    iconProps: {},
                    route: PrivateRoutes.ACHILLES,
                    link: "/manage/achilles",
                    component: <Achilles />,
                    entity: "Achilles" as Subjects,
                    title: "Achilles",
                    permission: { action: ["read"] as Actions[], subject: "Achilles" as Subjects }
                },
                {
                    icon: CgTemplate,
                    iconProps: {},
                    route: PrivateRoutes.TEMPLATE,
                    link: "manage/template",
                    entity: "Template" as Subjects,
                    component: <Template />,
                    title: "Templates",
                    permission: { action: ["read"] as Actions[], subject: "Template" as Subjects }
                },
                {
                    icon: BiSolidComponent,
                    iconProps: {},
                    route: PrivateRoutes.COMPONENT,
                    link: "/manage/component",
                    entity: "Component" as Subjects,
                    component: <Component />,
                    title: "Components",
                    permission: { action: ["read"] as Actions[], subject: "Component" as Subjects }
                },
                {
                    icon: MdReceiptLong,
                    iconProps: {},
                    route: PrivateRoutes.TICKET,
                    link: "manage/ticket", // Aquí usamos el valor directamente de PrivateRoutes
                    entity: "Ticketing" as Subjects,
                    component: <Ticketing />,
                    title: "Billing & Ticketing",
                    permission: { action: ["read", "update"] as Actions[], subject: "Ticketing" as Subjects }
                }

            ],
            permission: { action: ["read"] as Actions[], subject: "Management" as Subjects }
        },
        {
            icon: CgFileDocument,
            iconProps: {},
            route: PrivateRoutes.ASSESSMENT,
            link: "/assessment",
            entity: "Assessment" as Subjects,
            component: <Assessment />,
            title: "Assessment",
            permission: { action: ["read", "update"] as Actions[], subject: "Assessment" as Subjects }
        },
        /* {
            icon: CgFileDocument,
            iconProps: {},
            route: PrivateRoutes.PENTEST,
            link: "/pentest",
            entity: "Pentest" as Subjects,
            component: <Pentest />,
            title: "Pentest"
        }, */
        {
            icon: BiBug,
            iconProps: {},
            route: PrivateRoutes.VULNERABILITY,
            link: "/vulnerability",
            entity: "Vulnerability" as Subjects,
            component: <Vulnerability />,
            title: "Vulnerabilities",
            permission: { action: ["read", "update"] as Actions[], subject: "Vulnerability" as Subjects }
        },
        {
            icon: VscGraph,
            iconProps: {},
            route: PrivateRoutes.BENCHMARK,
            link: "/benchmark",
            entity: "Benchmark" as Subjects,
            component: <BenchmarkCharts />,
            title: "Benchmark",
            permission: { action: ["read", "update"] as Actions[], subject: "Benchmark" as Subjects }
        },
        {
            icon: GrCompliance,
            iconProps: {},
            route: PrivateRoutes.COMPLIANCE,
            link: "/compliance",
            entity: "Compliance" as Subjects,
            component: <ClientProvider ><Compliance /></ClientProvider>,
            title: "CRA Compliance"
        }
        /* {
            icon: MdOutlineNotificationsNone,
            iconProps: {},
            route: PrivateRoutes.EMAIL,
            link: "/email",
            component: <Email />,
            entity: "Email" as Subjects,
            title: "Notifications & Alerts"
        } */
    ]
]

export const sidebarSecondaryItems = [
    [
        {
            icon: IoIosSettings,
            iconProps: {},
            route: PrivateRoutes.SETTINGS,
            link: "/settings",
            component: <></>,
            entity: "Settings" as Subjects,
            title: "Settings",
            subItems: [{
                icon: IoLanguageSharp,
                iconProps: {},
                route: PrivateRoutes.PREFERENCES,
                link: "settings/preferences",
                component: <PreferencesUI />,
                entity: "Preferences" as Subjects,
                title: "UI Preferences & Language",
                permission: { action: ["read"] as Actions[], subject: "Preferences" as Subjects }
            },
            {
                icon: MdNotificationsNone,
                iconProps: {},
                route: PrivateRoutes.EMAIL,
                link: "settings/email",
                component: <Email />,
                entity: "Notifications" as Subjects,
                title: "Notifications",
                permission: { action: ["read"] as Actions[], subject: "Notifications" as Subjects }
            },
            {
                icon: MdTerminal,
                iconProps: {},
                route: PrivateRoutes.TOOLBOX,
                link: "settings/toolbox",
                component: <Toolbox />,
                entity: "MyOrbikToolbox" as Subjects,
                title: "MyOrbikToolbox",
                permission: { action: ["read"] as Actions[], subject: "MyOrbikToolbox" as Subjects }
            },
            {
                icon: SiJira,
                iconProps: {},
                route: PrivateRoutes.JIRA,
                link: "settings/jira",
                component: <JiraIntegration />,
                entity: "Jira" as Subjects,
                title: "Jira Integration",
                permissions: { action: ["read", "create", "update", "delete"] as Actions[], subject: "JiraConfig" as Subjects }
            },
            {
                icon: LiaClipboardCheckSolid,
                iconProps: {},
                route: PrivateRoutes.SLA,
                link: "settings/sla",
                component: <SLA />,
                entity: "SLA" as Subjects,
                title: "SLA",
                permission: { action: ["read"] as Actions[], subject: "SLA" as Subjects }
            },
            {
                icon: RiUserSearchLine,
                iconProps: {},
                route: PrivateRoutes.USER_LOGS,
                link: "settings/user_logs",
                component: <UserLogs />,
                entity: "UserLog" as Subjects,
                title: "UserLogs",
                permission: { action: ["read"] as Actions[], subject: "UserLog" as Subjects }
            }],
            permission: { action: ["read", "create", "update", "delete"] as Actions[], subject: "Settings" as Subjects }
        }, {
            icon: FaRegQuestionCircle,
            iconProps: {},
            route: PrivateRoutes.SUPPORT,
            link: "/support",
            component: <></>,
            entity: "Support" as Subjects,
            title: "Support",
            subItems: [{
                icon: MdOutlineMail,
                iconProps: {},
                route: PrivateRoutes.CONTACT,
                link: "support/contact",
                component: <Contact />,
                entity: "Contact" as Subjects,
                title: "Contact"
            },
            {
                icon: MdOutlineQuestionAnswer,
                iconProps: {},
                route: PrivateRoutes.FAQ,
                link: "support/faq",
                component: <FAQ />,
                entity: "FAQ" as Subjects,
                title: "FAQ"
            }]
        }

    ],
    [
        {
            icon: FiLogOut,
            iconProps: {},
            route: PrivateRoutes.LOGOUT,
            link: "/logout",
            title: "Logout",
            component: <Logout />
        }
    ]
]

export const hiddenItems = [
    {
        icon: SiJira,
        iconProps: {},
        route: PrivateRoutes.JIRA,
        link: "settings/jira",
        component: <JiraIntegration />,
        entity: "Jira" as Subjects,
        title: "Jira Integration",
        permissions: { action: ["*"] as Actions[], subject: "JiraConfig" as Subjects }
    },
    {
        icon: MdOutlineQuestionAnswer,
        iconProps: {},
        route: PrivateRoutes.FAQ,
        link: "support/faq",
        component: <FAQ />,
        entity: "FAQ" as Subjects,
        title: "FAQ"
    },
    {
        icon: IoLanguageSharp,
        iconProps: {},
        route: PrivateRoutes.CONTACT,
        link: "support/contact",
        component: <Contact />,
        entity: "Contact" as Subjects,
        title: "Contact"
    },
    {
        icon: MdTerminal,
        iconProps: {},
        route: PrivateRoutes.TOOLBOX,
        link: "settings/toolbox",
        component: <Toolbox />,
        entity: "MyOrbikToolbox" as Subjects,
        title: "MyOrbikToolbox"
    },
    {
        icon: MdNotificationsNone,
        iconProps: {},
        route: PrivateRoutes.EMAIL,
        link: "settings/email",
        component: <Email />,
        entity: "Notifications" as Subjects,
        title: "Notifications"
    },
    {
        icon: RiUserSearchLine,
        iconProps: {},
        route: PrivateRoutes.USER_LOGS,
        link: "settings/user_logs",
        component: <UserLogs />,
        entity: "UserLogs" as Subjects,
        title: "UserLogs"
    },
    {
        icon: RiUserSearchLine,
        iconProps: {},
        route: PrivateRoutes.USER_LOGS_DETAILS,
        link: "settings/user_logs/:id",
        component: <UserLogsDetails />,
        entity: "UserLogs Details" as Subjects,
        title: "UserLogs Details"
    },
    {
        icon: LiaClipboardCheckSolid,
        iconProps: {},
        route: PrivateRoutes.ACHILLES,
        link: "/manage/achilles",
        component: <Achilles />,
        entity: "Achilles" as Subjects,
        title: "Achilles"
    },
    {
        icon: GrTree,
        iconProps: {},
        route: PrivateRoutes.HIERARCHY,
        link: "/hierarchy",
        entity: "Hierarchy" as Subjects,
        component: <Hierarchy />,
        title: "Hierarchy"
    },
    {
        icon: IoLanguageSharp,
        iconProps: {},
        route: PrivateRoutes.PREFERENCES,
        link: "settings/preferences",
        component: <PreferencesUI />,
        entity: "Preferences" as Subjects,
        title: "UI Preferences & Language"
    },
    {
        icon: TbDeviceHeartMonitor,
        iconProps: { size: "30px" },
        route: PrivateRoutes.PRODUCT,
        link: "/product",
        entity: "Product" as Subjects,
        component: <Product />,
        title: "Products"
    },
    {
        icon: MdOutlinePolicy,
        iconProps: {},
        route: PrivateRoutes.POLICY,
        link: "manage/policy",
        component: <Policy />,
        entity: "Policy" as Subjects,
        title: "Policy Management"
    },
    {
        icon: CgTemplate,
        iconProps: {},
        route: PrivateRoutes.TEMPLATE,
        link: "manage/template",
        entity: "Template" as Subjects,
        component: <Template />,
        title: "Templates"
    },
    {
        icon: BiBug,
        iconProps: {},
        route: PrivateRoutes.COMPONENT,
        link: "manage/component",
        entity: "Component" as Subjects,
        component: <Component />,
        title: "Components"
    },
    {
        icon: BiBug,
        iconProps: {},
        route: PrivateRoutes.COMPONENT_ASSESSMENT,
        link: "/assessment/:id/component/",
        entity: "Component" as Subjects,
        component: <ComponentList />,
        title: "Components"
    },
    {
        icon: BiBug,
        iconProps: {},
        route: PrivateRoutes.COMPONENT_DETAIL,
        link: "/component/:componentId",
        entity: "Component" as Subjects,
        component: <ComponentDetail />,
        title: "Components"
    },
    {
        icon: MdOutlinePolicy,
        iconProps: {},
        route: PrivateRoutes.ISSUE,
        link: "/issue",
        component: <Issue />,
        entity: "Issue" as Subjects,
        title: "Issues"
    },
    {
        icon: BiBug,
        iconProps: {},
        route: PrivateRoutes.ISSUE_DETAIL,
        link: "/issue/:issueId",
        entity: "Issue" as Subjects,
        component: <IssueDetail />,
        title: "Issues"
    },
    {
        icon: BiBug,
        iconProps: {},
        route: PrivateRoutes.ISSUE_DETAIL_PRODUCT,
        link: "/issue/:issueId",
        entity: "Issue" as Subjects,
        component: <IssueDetail />,
        title: "Issues"
    },
    {
        icon: BiBug,
        iconProps: {},
        route: PrivateRoutes.SBOM_QUALITY,
        link: "/assessment/:id/sbomQuality",
        entity: "SBOMQuality" as Subjects,
        component: <SBOMQuality />,
        title: "SBOMQuality"
    },
    {
        icon: BiBug,
        iconProps: {},
        route: PrivateRoutes.SBOM_EVOLUTION,
        link: `${PrivateRoutes.PRODUCT}/:id/evolution`,
        entity: "SBOMEvolution" as Subjects,
        component: <SBOMEvolution />,
        title: "SBOMEvolution"
    },
    {
        icon: BiBug,
        iconProps: {},
        route: PrivateRoutes.TICKET_DETAIL,
        link: "/manage/ticket/:id",
        entity: "Ticketing" as Subjects,
        component: <TicketingDetail />,
        title: "Ticketing"
    },
    {
        icon: MdReceiptLong,
        iconProps: {},
        route: PrivateRoutes.TICKET,
        link: "manage/ticket", // Aquí usamos el valor directamente de PrivateRoutes
        entity: "Ticketing" as Subjects,
        component: <Ticketing />,
        title: "Ticketing"
    },
    {
        icon: BiBug,
        iconProps: {},
        route: PrivateRoutes.TICKET_ADD,
        link: "/manage/ticket/add",
        entity: "Ticketing" as Subjects,
        component: <TicketingForm />,
        title: "Ticketing"
    },
    {
        icon: BiBug,
        iconProps: {},
        route: PrivateRoutes.CPE,
        link: "/assessment/:id/cpe",
        entity: "Cpe" as Subjects,
        component: <CpeList />,
        title: "Cpe"
    },
    {
        icon: BiBug,
        iconProps: {},
        route: PrivateRoutes.CPE_DETAIL,
        link: "/assessment/:id/cpe/:cpeId",
        entity: "Cpe" as Subjects,
        component: <CpeDetail />,
        title: "Cpe"
    },
    {
        icon: BiBug,
        iconProps: {},
        route: PrivateRoutes.HISTORIC_CVE,
        link: "/vulnerability/:id/historic",
        entity: "HistoricCve" as Subjects,
        component: <HistoricCveView />,
        title: "HistoricCve"
    },
    {
        icon: LiaClipboardCheckSolid,
        iconProps: {},
        route: PrivateRoutes.SLA,
        link: "settings/sla",
        component: <SLA />,
        entity: "SLA" as Subjects,
        title: "SLA Management"
    },
    {
        icon: MdOutlinePolicy,
        iconProps: {},
        route: PrivateRoutes.LICENSE,
        link: "/license",
        component: <License />,
        entity: "License" as Subjects,
        title: "License Management"
    },
    {
        icon: MdOutlinePolicy,
        iconProps: {},
        route: PrivateRoutes.SYSTEM_NOTIFICATION,
        link: "/system_notification",
        component: <SystemNotificationList />,
        entity: "SystemNotification" as Subjects,
        title: "System Notification"
    },
    {
        icon: BiBug,
        iconProps: {},
        route: PrivateRoutes.SYSTEM_NOTIFICATION_DETAIL,
        link: "/system_notification/:id",
        entity: "SystemNotification" as Subjects,
        component: <SystemNotificationDetail />,
        title: "System Notification"
    },
    {
        icon: MdOutlineManageAccounts,
        iconProps: {},
        route: PrivateRoutes.CLIENT,
        link: "manage/client",
        component: <Client />,
        entity: "Client" as Subjects,
        title: "Client"
    }
]

export const allRoutes = [
    ...sidebarPrimaryItems.flat(),
    ...sidebarSecondaryItems.flat(),
    ...hiddenItems.flat()
]
