import React, { useContext } from "react"
import { Route, Routes } from "react-router-dom"
import { AbilityContext } from "@components/permissions/Can"
import { HierarchyForm, HierarchyList, HierarchyTree } from "@views/hierarchy/index"
import HierarchyDetail from "./HierarchyDetail"

const entity = "Hierarchy"
const Hierarchy: React.FC = () => {
    const ability = useContext(AbilityContext)
    const routes = []

    if (ability.can("read", entity)) {
        routes.push(...[
            {
                route: "",
                component: <HierarchyList/>
            }
        ])
    }
    if (ability.can("read", entity)) {
        routes.push(...[
            {
                route: ":id",
                component: <HierarchyDetail/>
            }
        ])
    }

    if (ability.can("create", entity)) {
        routes.push(...[
            {
                route: "/management",
                component: <HierarchyTree/>
            },
            {
                route: "/add",
                component: <HierarchyForm/>
            },
            {
                route: "/edit/:id",
                component: <HierarchyForm/>
            }
        ])
    }

    return (
        <Routes>
            {
                routes.map((item, idx) => (
                    <Route key={idx} path={item.route} element={item.component} />
                ))
            }
        </Routes>
    )
}

export { Hierarchy }
export default Hierarchy
