
import { convertModelDefToGridColDef, GenericTable } from "@components/common/tables"
import { AbilityContext, Can } from "@components/permissions"
import { PolicyTableDefinition, PolicyTableDefinitionES } from "@components/policy/PolicyTableDefinition"
import Policy from "@models/Policy"
import { Grid, Typography, useTheme } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid"
import React, { useContext, useEffect, useState } from "react"
import { I18nContext } from "I18nProvider"
import { useNavigate } from "react-router-dom"
import ServicesContext from "@context/ServicesContext"
import ActionButton from "@components/common/Button/ActionButton"
import TrackList from "@views/track/trackList"
import { useTrack } from "@components/track/TrackContext"

const PolicyList: React.FC = () => {
    const theme = useTheme()
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const [cols, setCols] = useState<GridColDef[]>([])
    const ability = useContext(AbilityContext)
    const { track, trackData } = useTrack()
    useEffect(() => {
        track({ view: "PolicyList" })
    }, [])
    useEffect(() => {
        // Seleccionar la definición de la tabla según el idioma
        const policyTableDefinition = context.language === "es" ? PolicyTableDefinitionES : PolicyTableDefinition
        setCols(convertModelDefToGridColDef(policyTableDefinition, ability))
    }, [context.language, ability]) // Añadir 'context.language' a las dependencias

    const navigate = useNavigate()
    const policyService = useContext(ServicesContext).policyService

    return (
        <>
            <Grid item xs container flexDirection="column" spacing="20px" sx={{ position: "relative" }}>
                <Grid container sx={{ justifyContent: "space-between", alignItems: "center", spacing: "20px", paddingLeft: "20px", marginBottom: "0px", paddingBottom: "0px" }}>
                    <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff" sx={{ marginTop: "0px", marginLeft: "20px" }}>{context.t.translate("policy_management")}</Typography>
                    <Can I="create" a="Policy">
                        <ActionButton onClick={() => navigate("./add")} text= {context.t.translate("add_new_policy")} />
                    </Can>
                </Grid>
                <Grid item container flexDirection="column" rowGap="35px">
                    <GenericTable<Policy> entity="Policy" columns={cols}
                        dataProvider={(filter) => policyService.getAll(filter)}
                        onEdit={(elem: Policy) => navigate("./" + elem.id)}
                        onDelete={(elem: Policy) => policyService.delete(elem.id)}
                    />
                </Grid>
            </Grid>
            <TrackList invoke="policy"/>
        </>

    )
}
export { PolicyList }
export default PolicyList
