import React, { useContext, useEffect, useState } from "react"
import { ServicesContext } from "@context/index"
import { ComponentStats, StatsAssessments, StatsProducts, StatsVulnerabilities } from "@models/Stats"
import GenericDonutChart from "@components/assessment/GenericDonutChart"
import { Box, Grid, Tooltip, Typography, useTheme } from "@mui/material" // Importar Tooltip
import { Subject } from "@casl/ability"
import { I18nContext } from "I18nProvider"
import { AssessmentStatus, AssessmentType } from "@models/Assessment"
import { CVSS3Severity, VulnerabilityState } from "@models/Vulnerability"
import { ProductType } from "@models/Product"
import { ViolationState } from "@models/Policy"
import { AssessmentTypeColors } from "../colors/AssessmentTypeColors"
import { AssessmentStatusColors } from "../colors/AssessmentStatusColors"
import { VulnerabilityStateColors } from "../colors/VulnerabilityStateColors"
import { ProductTypeColors } from "../colors/ProductTypeColors"
import { PolicyViolationStateColors } from "../colors/PolicyViolationStateColors"
import CustomSnackbar from "../Snackbar/Snackbar"

interface ChartData {
    title: string;
    dataType: string;
    data: { name: string; color: string; y: number }[];
    number: number;
    footer: string;
}

const getRandomColor = () => {
    const letters = "0123456789ABCDEF"
    let color = "#"
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)]
    }
    return color
}

interface StatsCardProps {
    title: string;
    entity: Subject;
}

// interface Donuts
interface DonutValuesI {
    name: string;
    y: number;
    color: string;
   }

const StatsCard: React.FC<StatsCardProps> = ({ title, entity }) => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const statsService = useContext(ServicesContext).statsService

    const theme = useTheme()
    const [error, setError] = useState<string | null>(null)
    // Components
    const [circleChart1, setcircleChart1] = useState<ChartData | null>(null)
    const [circleChart2, setcircleChart2] = useState<ChartData | null>(null)
    const [circleChart3, setcircleChart3] = useState<ChartData | null>(null)

    const [topVulnerableComponents, setTopVulnerableComponents] = useState<any[]>([])

    // Assessments
    const [byProduct, setByProduct] = useState< DonutValuesI[] >([])
    const [byCategory, setByCategory] = useState< DonutValuesI[] >([])
    const [byStatus, setByStatus] = useState< DonutValuesI[] >([])
    const [ProductNumber, setProductNumber] = useState< number >(0)
    const [TotalTests, setTotalTests] = useState< number >(0)
    const [TestTypesNumber, setTestTypesNumber] = useState< number >(0)

    // Vulnerabilities
    const [byVulnState, setByVulnState] = useState< DonutValuesI[] >([])
    const [MitigatedVulnerability, setMitigatedVulnerability] = useState< number >(0)
    const [MitigatedVulnerabilities, setMitigatedVulnerabilities] = useState< { [key in CVSS3Severity]: number } >({ none: 0, low: 0, medium: 0, high: 0, critical: 0, unknown: 0 })

    const [UnmitigatedVulnerability, setUnmitigatedVulnerability] = useState< number >(0)
    const [UnmitigatedVulnerabilities, setUnmitigatedVulnerabilities] = useState< { [key in CVSS3Severity]: number } >({ none: 0, low: 0, medium: 0, high: 0, critical: 0, unknown: 0 })

    const [StateNumber, setStateNumber] = useState< number >(0)

    // Productos
    const [byType, setByType] = useState< DonutValuesI[] >([])
    const [byPolicies, setByPolicies] = useState< DonutValuesI[] >([])
    const [byLicenses, setByLicenses] = useState< DonutValuesI[] >([])
    const [TypeNumber, setTypeNumber] = useState< number >(0)
    const [PolicyNumber, setPolicyNumber] = useState< number >(0)
    const [LicenseNumber, setLicenseNumber] = useState< number >(0)
    const [openSnackbar, setOpenSnackbar] = useState<boolean>(false)
    // Estados para cada gráfico
    if (entity === "Component") {
        useEffect(() => {
            const fetchComponentStats = async () => {
                try {
                    const response: ComponentStats = await statsService.GetStatsComponents()
                    setcircleChart1(transformSuppressedData(response.suppressed))
                    setcircleChart2(transformOutdatedData(response.outdated))
                    setcircleChart3(transformInheritedData(response.inherited))
                    setTopVulnerableComponents(response.top_vulnerable_components)
                } catch (err: any) {
                    console.error("Error fetching component stats:", err)
                    setError(err.message)
                    setOpenSnackbar(true)
                }
            }

            fetchComponentStats()
        }, [statsService])

        const transformSuppressedData = (suppressed: ComponentStats["suppressed"]) => ({
            title: "Suppressed Components",
            dataType: "array",
            data: [
                { name: "False", color: "#7940A5", y: suppressed.false },
                { name: "True", color: "#D9534F", y: suppressed.true }
            ],
            number: suppressed.total,
            footer: ""
        })

        const transformOutdatedData = (outdated: ComponentStats["outdated"]) => ({
            title: "Outdated Components",
            dataType: "array",
            data: [
                { name: "False", color: "#EC971F", y: outdated.false },
                { name: "True", color: "#F2CC0C", y: outdated.true }
            ],
            number: outdated.total,
            footer: ""
        })

        const transformInheritedData = (inherited: ComponentStats["inherited"]) => ({
            title: "Inherited Components",
            dataType: "array",
            data: [
                { name: "False", color: "#8dd3c7", y: inherited.false },
                { name: "True", color: "#A9A9A9", y: inherited.true }
            ],
            number: inherited.total,
            footer: ""
        })

        if (!circleChart1 || !circleChart2 || !circleChart3) {
            return <div>Loading...</div>
        }
    }

    if (entity === "Assessment") {
        useEffect(() => {
            const fetchAssessmentStats = async () => {
                try {
                    const response: StatsAssessments = await statsService.getStatsAssessment()
                    const ByProduct: DonutValuesI[] = Object.keys(response.ProductName).map((key) => ({
                        name: key,
                        y: response.ProductName[key],
                        color: getRandomColor()
                    }))
                    setByProduct(ByProduct)

                    const ByCategory: DonutValuesI[] = (Object.keys(response.TestCategory) as AssessmentType[]).map((key) => ({
                        name: key,
                        y: response.TestCategory[key],
                        color: AssessmentTypeColors[key] || getRandomColor()
                    }))
                    setByCategory(ByCategory)

                    const ByStatus: DonutValuesI[] = (Object.keys(response.TestStatus) as AssessmentStatus[]).map((key) => ({
                        name: key,
                        y: response.TestStatus[key],
                        color: AssessmentStatusColors[key] || getRandomColor()
                    }))
                    setByStatus(ByStatus)

                    setProductNumber(response.ProductNumber)
                    setTestTypesNumber(response.TestTypesNumber)
                    setTotalTests(response.TotalTests)
                    setTopVulnerableComponents(response.top_vulnerable_assessments)
                } catch (err: any) {
                    console.error("Error fetching assessment stats:", err)
                    setError(err.message)
                    setOpenSnackbar(true)
                }
            }

            fetchAssessmentStats()
        }, [statsService])

        // if (!circleChart1 || !circleChart2 || !circleChart3) {
        //     return <div>Loading...</div>
        // }
    }
    if (entity === "Vulnerability") {
        useEffect(() => {
            const fetchVulnerabilityStats = async () => {
                try {
                    const response: StatsVulnerabilities = await statsService.getStatsVulnerabilities()
                    const ByVulnState: DonutValuesI[] = (Object.keys(response.VulnerabilityStates) as VulnerabilityState[]).map((key) => ({
                        name: key,
                        y: response.VulnerabilityStates[key],
                        color: VulnerabilityStateColors[key] || getRandomColor()
                    }))
                    setByVulnState(ByVulnState)
                    setUnmitigatedVulnerability(response.UnmitigatedVulnerability)
                    setMitigatedVulnerability(response.MitigatedVulnerability)
                    setStateNumber(response.StateNumber)
                    setMitigatedVulnerabilities(response.MitigatedVulnerabilities)
                    setUnmitigatedVulnerabilities(response.UnmitigatedVulnerabilities)
                    setTopVulnerableComponents(response.top_vulnerable_components)
                } catch (err: any) {
                    console.error("Error fetching vulnerability stats:", err)
                    setError(err.message)
                    setOpenSnackbar(true)
                }
            }

            fetchVulnerabilityStats()
        }, [statsService])
    }

    if (entity === "Product") {
        useEffect(() => {
            const fetchProductStats = async () => {
                try {
                    const response: StatsProducts = await statsService.getStatsProduct()
                    const ByType: DonutValuesI[] = (Object.keys(response.ProductTypes) as ProductType[]).map((key) => ({
                        name: key,
                        y: response.ProductTypes[key],
                        color: ProductTypeColors[key] || getRandomColor()
                    }))
                    setByType(ByType)

                    const ByPolicies: DonutValuesI[] = (Object.keys(response.PoliciesCountByState) as ViolationState[]).map((key) => ({
                        name: key,
                        y: response.PoliciesCountByState[key],
                        color: PolicyViolationStateColors[key] || getRandomColor()
                    }))
                    setByPolicies(ByPolicies)

                    const ByLicenses: DonutValuesI[] = (Object.keys(response.LicenseCountByType) as string[]).map((key) => ({
                        name: key,
                        y: response.LicenseCountByType[key],
                        color: getRandomColor()
                    }))
                    setByLicenses(ByLicenses)

                    setTypeNumber(response.TypeNumber)
                    setPolicyNumber(response.PolicyNumber)
                    setLicenseNumber(response.LicenseNumber)
                    setTopVulnerableComponents(response.top_vulnerable_products)
                } catch (err: any) {
                    console.error("Error fetching product stats:", err)
                    setError(err.message)
                    setOpenSnackbar(true)
                }
            }

            fetchProductStats()
        }, [statsService])
    }
    return (
        <Box sx={{ display: "flex", flexDirection: { xs: "column", lg: "row" }, p: 2 }}>
            <CustomSnackbar
                message={error || "An error ocurred"}
                onClose={() => {}}
                open={openSnackbar}
            />
            {entity === "Component"
                ? (<Box sx={{ width: { xs: "100%", lg: "60%" } }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <Box sx={{ minWidth: "220px" }}>
                                <GenericDonutChart
                                    title={circleChart1 !== null ? circleChart1.title : ""}
                                    dataType="array"
                                    data={circleChart1 !== null ? circleChart1.data : []}
                                    number={circleChart1 !== null ? circleChart1.number : 0}
                                    footer={circleChart1 !== null ? circleChart1.footer : ""}
                                    size="small"
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Box sx={{ minWidth: "220px" }}>
                                <GenericDonutChart
                                    title={circleChart2 !== null ? circleChart2.title : ""}
                                    dataType="array"
                                    data={circleChart2 !== null ? circleChart2.data : []}
                                    number={circleChart2 !== null ? circleChart2.number : 0}
                                    footer={circleChart2 !== null ? circleChart2.footer : ""}
                                    size="small"
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Box sx={{ minWidth: "220px" }}>
                                <GenericDonutChart
                                    title={circleChart3 !== null ? circleChart3.title : ""}
                                    dataType="array"
                                    data={circleChart3 !== null ? circleChart3.data : []}
                                    number={circleChart3 !== null ? circleChart3.number : 0}
                                    footer={circleChart3 !== null ? circleChart3.footer : ""}
                                    size="small"
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>)
                : (<></>)}
            {entity === "Assessment" && statsService != null && (
                <Grid container spacing={2}>
                    {ProductNumber !== 0 && (
                        <Grid item xs={12} sm={4}>
                            <Box sx={{ minWidth: "220px" }}>
                                <GenericDonutChart
                                    title={context.t.translate("by_product")}
                                    data={byProduct}
                                    dataType="array"
                                    number={ProductNumber}
                                    footer="PRODUCTS"
                                />
                            </Box>
                        </Grid>
                    )}
                    {TotalTests !== 0 && (
                        <Grid item xs={12} sm={4}>
                            <Box sx={{ minWidth: "220px" }}>
                                <GenericDonutChart
                                    title={context.t.translate("by_status")}
                                    data={byStatus}
                                    dataType="array"
                                    number={TotalTests}
                                    footer="TOTAL TESTS"
                                />
                            </Box>
                        </Grid>
                    )}
                    {TestTypesNumber !== 0 && (
                        <Grid item xs={12} sm={4}>
                            <Box sx={{ minWidth: "220px" }}>
                                <GenericDonutChart
                                    title={context.t.translate("by_type")}
                                    data={byCategory}
                                    dataType="array"
                                    number={TestTypesNumber}
                                    footer="TEST TYPES"
                                />
                            </Box>
                        </Grid>
                    )}
                </Grid>
            )}
            {entity === "Vulnerability" && statsService != null && (
                <Grid item container spacing={2}>
                    {UnmitigatedVulnerability !== 0 && (
                        <Grid item xs={12} sm={4}>
                            <Box sx={{ minWidth: "220px" }}>
                                <GenericDonutChart title={context.t.translate("criticity")} dataType="object" data={UnmitigatedVulnerabilities} number={UnmitigatedVulnerability}/>
                            </Box>
                        </Grid>
                    )}
                    {(MitigatedVulnerability !== 0 || UnmitigatedVulnerability !== 0) && (
                        <Grid item xs={12} sm={4}>
                            <Box sx={{ minWidth: "220px" }}>
                                <GenericDonutChart title={context.t.translate("mitigations")} dataType="mitigated-unmitigated" data={{ mitigated: MitigatedVulnerability, unmitigated: UnmitigatedVulnerability }} number={MitigatedVulnerability + UnmitigatedVulnerability} />
                            </Box>
                        </Grid>
                    )}
                    <Grid item xs={12} sm={4}>
                        <Box sx={{ minWidth: "220px" }}>
                            <GenericDonutChart title={context.t.translate("by_state")} data={byVulnState} dataType="array" number={StateNumber} footer="TYPES"/>
                        </Box>
                    </Grid>
                </Grid>
            )}
            {entity === "Product" && statsService != null && (
                <Grid item container spacing={2}>
                    {statsService != null && <Grid item container spacing={2} >
                        {TypeNumber !== 0 && (
                            <Grid item xs={12} sm={4}>
                                <Box sx={{ minWidth: "220px" }}>
                                    <GenericDonutChart title={context.t.translate("by_type")} data={byType} dataType="array" number={TypeNumber} footer="TYPES"/>
                                </Box>
                            </Grid>
                        )}
                        {PolicyNumber !== 0 && (
                            <Grid item xs={12} sm={4}>
                                <Box sx={{ minWidth: "220px" }}>
                                    <GenericDonutChart
                                        title={context.t.translate("by_policy")}
                                        data={byPolicies}
                                        dataType="array"
                                        number={PolicyNumber}
                                        footer="POLICIES"
                                    />
                                </Box>
                            </Grid>
                        )}
                        {LicenseNumber !== 0 && (
                            <Grid item xs={12} sm={4}>
                                <Box sx={{ minWidth: "220px" }}>
                                    <GenericDonutChart
                                        title={context.t.translate("by_license")}
                                        data={byLicenses}
                                        dataType="array"
                                        number={LicenseNumber}
                                        footer="LICENSES"
                                    />
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                    }
                </Grid>
            )}

            {/* Derecha: Top 5 Vulnerable Components */}
            {(entity === "Component" || entity === "Vulnerability") && (
                <Box sx={{ width: { xs: "100%", lg: "35%" }, mt: { xs: 3, lg: 0 }, p: 1, ml: 1 }}>
                    <Typography variant="h6">Top 5 Vulnerable Components</Typography>
                    <Grid container spacing={2} sx={{ marginTop: 2 }}>
                        {topVulnerableComponents.map((component, index) => (
                            <Grid item xs={6} sm={4} md={2} key={index} sx={{ textAlign: "center" }}>
                                <Tooltip title={component.name} placement="top">
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            fontWeight: "bold",
                                            color: theme.palette.text.secondary,
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            width: "100%",
                                            display: "block"
                                        }}
                                    >
                                        {component.name}
                                    </Typography>
                                </Tooltip>
                                <Typography variant="body2" fontWeight="bold" fontSize="16px">
                                    {component.total}
                                </Typography>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            )}
            {(entity === "Product") && (
                <Box sx={{ width: { xs: "100%", lg: "35%" }, mt: { xs: 3, lg: 0 }, p: 1, ml: 1 }}>
                    <Typography variant="h6">Top 5 Vulnerable Products</Typography>
                    <Grid container spacing={2} sx={{ marginTop: 2 }}>
                        {topVulnerableComponents.map((component, index) => (
                            <Grid item xs={6} sm={4} md={2} key={index} sx={{ textAlign: "center" }}>
                                <Tooltip title={component.name} placement="top">
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            fontWeight: "bold",
                                            color: theme.palette.text.secondary,
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            width: "100%",
                                            display: "block"
                                        }}
                                    >
                                        {component.name}
                                    </Typography>
                                </Tooltip>
                                <Typography variant="body2" fontWeight="bold" fontSize="16px">
                                    {component.total}
                                </Typography>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            )}
            {entity === "Assessment"
                ? (<Box sx={{ width: { xs: "100%", lg: "35%" }, mt: { xs: 3, lg: 0 }, p: 1, ml: 1 }}>
                    <Typography variant="h6">Top 5 Vulnerable Assessments</Typography>
                    <Grid container spacing={2} sx={{ marginTop: 2 }}>
                        {topVulnerableComponents.map((component, index) => (
                            <Grid item xs={6} sm={4} md={2} key={index} sx={{ textAlign: "center" }}>
                                <Tooltip title={component.product_with_version} placement="top">
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            fontWeight: "bold",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            width: "100%",
                                            display: "block",
                                            color: AssessmentTypeColors[component.assessment_type as AssessmentType] || "#000000"
                                        }}
                                    >
                                        {component.product_with_version}
                                    </Typography>
                                </Tooltip>
                                <Typography variant="body2" fontWeight="bold" fontSize="16px">
                                    {component.vulnerability_count}
                                </Typography>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
                )
                : (<></>)}
        </Box>
    )
}
export default StatsCard
