import React, { ReactNode, useContext, useEffect, useState } from "react"
import { Link, Page, Text, View } from "@react-pdf/renderer"
import Template from "@models/Template"
import { I18nContext } from "I18nProvider"
import Vulnerability, { getCVSS3Criticality } from "@models/Vulnerability"
import { getCVSS3Color } from "@components/vulnerability"
import { globalStyles } from "../Styles"
import { Footer, Header } from "./HeaderFooter"

interface introProps {
    title: string;
    description1: string;
    table: {
        headers: string[];
        dummie: { id: string; name: string; severity: string }[];
        legend: string;
    };
    description2: string;
    table2: {
        headers: string[];
        dummie: { id: string; name: string; severity: string }[];
        legend: string;
    };
    description3: string;
}

const i18n: {
    es: introProps;
    en: introProps;
} = {
    es: {
        title: "Identificación de vulnerabilidades",
        description1: "Los resultados obtenidos a lo largo del Test de Intrusión se presentan en la siguiente tabla identificados y ordenados por severidad.",
        table: {
            headers: ["Identificador", "Nombre", "Criticidad"],
            dummie: [{ id: "VULN-1234", name: "Vulnerabilidad 1", severity: "Alta" }],
            legend: "Tabla 6: Listado de vulnerabilidades identificadas"
        },
        description2: "A continuación, se incluye otra tabla con aquellas vulnerabilidades que tienen exploits públicos.",
        table2: {
            headers: ["Identificador", "Nombre", "Criticidad"],
            dummie: [{ id: "VULN-1234", name: "Vulnerabilidad 1", severity: "Alta" }],
            legend: "Tabla 9: Listado de vulnerabilidades con exploits públicos"
        },
        description3: "En los siguientes apartados se procederá a la descripción de todas las vulnerabilidades halladas en mayor detalle, clasificadas por criticidad, Seguido de la lista completa de vulnerabilidades detectadas en el sistema."
    },
    en: {
        title: "Vulnerability identification",
        description1: "The results obtained throughout the Intrusion Test are presented in the following table identified and ordered by severity.",
        table: {
            headers: ["Identifier", "Name", "Severity"],
            dummie: [{ id: "VULN-1234", name: "Vulnerability 1", severity: "High" }],
            legend: "Table 6: List of identified vulnerabilities"
        },
        description2: "Below is another table with those vulnerabilities that have public exploits.",
        table2: {
            headers: ["Identifier", "Name", "Severity"],
            dummie: [{ id: "VULN-1234", name: "Vulnerability 1", severity: "High" }],
            legend: "Table 9: List of vulnerabilities with public exploits"
        },
        description3: "In the following sections, the description of all vulnerabilities found in more detail will proceed, classified by severity, followed by the complete list of vulnerabilities detected in the system."
    }
}

interface SectionProps {
    lang?: string;
    template?: Template;
    client?: string
    vulnerabilities: Vulnerability[];
}

const ResultSection: React.FC<SectionProps> = ({ lang, template, vulnerabilities, client }) => {
    // Types
    type Structure = {
        pages: {
            c1: Array<Vulnerability | ReactNode>
            c2: Array<Vulnerability | ReactNode>
        }[]
    }
    // --------------------------------------------------
    // Constant
    const context = useContext(I18nContext)
    const MAX_ITEMS_FIRST = 6 // Máximo para la primera columna de la primera página
    const MAX_ITEMS_OTHER = 9 // Máximo para todas las columnas en las demás páginas
    const [casuality, setCasuality] = useState<boolean>(false)
    const [structure, setStructure] = useState<Structure>({ pages: [] })
    const createVulnerabilityData = (vulnList: Vulnerability[], reactNode: ReactNode): Structure => {
        const pages: Structure["pages"] = []

        // Crear la primera página con X elementos en c1 y vacía en c2
        const firstPage = {
            c1: vulnList.slice(0, MAX_ITEMS_FIRST),
            c2: vulnList.slice(MAX_ITEMS_FIRST, MAX_ITEMS_FIRST + MAX_ITEMS_OTHER)
        }
        pages.push(firstPage)

        // Crear las demás páginas con X elementos en c1 y c2
        for (let i = MAX_ITEMS_FIRST * 2; i < vulnList.length; i += MAX_ITEMS_OTHER * 2) {
            const page = {
                c1: vulnList.slice(i, i + MAX_ITEMS_OTHER),
                c2: vulnList.slice(i + MAX_ITEMS_OTHER, i + MAX_ITEMS_OTHER * 2)
            }
            pages.push(page)
        }

        // Añadir el ReactNode al final
        const lastPage = pages[pages.length - 1]

        if (lastPage) {
            if (lastPage.c1.length < MAX_ITEMS_OTHER) {
                lastPage.c1.push(reactNode)
            } else if (lastPage.c2.length < MAX_ITEMS_OTHER) {
                lastPage.c2.push(reactNode)
            } else {
                // Si ambas columnas están llenas, crea una nueva página
                const newPage = {
                    c1: [reactNode],
                    c2: []
                }
                pages.push(newPage)
            }
        } else {
            // Si no hay páginas, crea una nueva y añade el ReactNode
            const newPage = {
                c1: [reactNode],
                c2: []
            }
            pages.push(newPage)
        }

        return { pages }
    }

    // --------------------------------------------------

    // lenguaje
    type I18nType = typeof i18n
    let locale: keyof I18nType = "es"
    if (lang) {
        if (lang === "es" || lang === "en") {
            locale = lang as keyof I18nType
        } else {
            console.warn(`Idioma no soportado: ${lang}. Usando idioma por defecto.`)
        }
    } else if (context) {
        if (context.language === "es" || context.language === "en") {
            locale = context.language as keyof I18nType
        } else {
            console.warn(`Idioma no soportado en el contexto: ${context.language}. Usando idioma por defecto.`)
        }
    } else {
        console.error(
            "El contexto I18n no está inicializado. Asegúrese de que tiene el proveedor configurado correctamente."
        )
    }
    // --------------------------------------------------

    // useEffect
    useEffect(() => {
        const reactNode = <Text style={globalStyles.texto}>{i18n[locale].description3}</Text>
        setStructure(createVulnerabilityData(vulnerabilities, reactNode))
    }, [vulnerabilities])
    // --------------------------------------------------

    // Not vulnerability case
    if (vulnerabilities.length === 0) {
        return (
            // TODO: Finish this
            <></>
        )
    }

    function isVulnerability (item: React.ReactNode | Vulnerability): item is Vulnerability {
        return typeof item === "object" && item !== null && "id" in item // Ajusta 'id' según la estructura de Vulnerability
    }

    return (
        <Page size="A4" style={globalStyles.page} >
            <Header externalData={null} template={template}></Header>

            {structure.pages.map((page, pageIndex) =>
                pageIndex === 0
                    ? (
                        <View key={pageIndex} style={globalStyles.section}>
                            {/* Columna 1: 5 elementos */}
                            <View style={globalStyles.column}>
                                <View style={globalStyles.containerY}>
                                    <Text style={globalStyles.title}>{i18n[locale].title}</Text>
                                    <Text style={globalStyles.texto}>{i18n[locale].description1}</Text>

                                    <View style={{ ...globalStyles.containerY, gap: 2 }}>
                                        <View style={globalStyles.table} wrap={false}>
                                            {/* Encabezados */}
                                            <View style={{ ...globalStyles.row, backgroundColor: "yellow" }} wrap={false}>
                                                {i18n[locale].table.headers.map((header, headerIndex) => (
                                                    <View key={headerIndex} style={globalStyles.col}>
                                                        <Text style={globalStyles.textoBold}>{header}</Text>
                                                    </View>
                                                ))}
                                            </View>

                                            {/* Filas de la columna 1 */}
                                            {(page.c1.filter(isVulnerability) as Vulnerability[]).map((vuln, vulnIndex) => (
                                                <View key={vulnIndex} style={globalStyles.row} wrap={false}>
                                                    <View style={globalStyles.col}>
                                                        <Link
                                                            src={`#VULN-${vuln.id}`}
                                                            style={{ width: "auto", textDecoration: "none", color: "black" }}
                                                        >
                                                            <Text style={{ ...globalStyles.texto, textDecoration: "none", color: "black" }}>
                                                                {`VULN-${vuln.id}`}
                                                            </Text>
                                                        </Link>
                                                    </View>
                                                    <View style={globalStyles.col}>
                                                        <Text style={globalStyles.texto}>{vuln.metadata?.name || ""}</Text>
                                                    </View>
                                                    <View style={globalStyles.col}>
                                                        <Text
                                                            style={{
                                                                ...globalStyles.textoBold,
                                                                color: getCVSS3Color(getCVSS3Criticality(vuln.score))["background-color"]
                                                            }}
                                                        >
                                                            {getCVSS3Criticality(vuln.score).charAt(0).toUpperCase() +
                                getCVSS3Criticality(vuln.score).slice(1)}
                                                        </Text>
                                                    </View>
                                                </View>
                                            ))}
                                        </View>
                                        {/* Leyenda después de la columna 1 si no quedan más vulnerabilidades */}
                                        {page.c1.length > 1 && page.c2.length < 2 && (
                                            <View style={globalStyles.table_legend}>
                                                <Text style={globalStyles.texto}>{i18n[locale].table.legend}</Text>
                                            </View>
                                        )}
                                    </View>
                                    {structure.pages.length === pageIndex + 1 && page.c1.length <= MAX_ITEMS_FIRST && page.c2.length === 0 && (
                                        (page.c1[page.c1.length - 1] as ReactNode)
                                    )}
                                </View>
                            </View>

                            {/* Columna 2: 8 elementos */}
                            <View style={globalStyles.column}>
                                <View style={globalStyles.containerY}>
                                    <View style={{ ...globalStyles.containerY, gap: 2 }}>
                                        <View style={globalStyles.table} wrap={false}>
                                            {/* Filas de la columna 2 sin encabezados */}
                                            {(page.c2.filter(isVulnerability) as Vulnerability[]).map((vuln, vulnIndex) => (
                                                <View key={vulnIndex} style={globalStyles.row} wrap={false}>
                                                    <View style={globalStyles.col}>
                                                        <Link
                                                            src={`#VULN-${vuln.id}`}
                                                            style={{ width: "auto", textDecoration: "none", color: "black" }}
                                                        >
                                                            <Text style={{ ...globalStyles.texto, textDecoration: "none", color: "black" }}>
                                                                {`VULN-${vuln.id}`}
                                                            </Text>
                                                        </Link>
                                                    </View>
                                                    <View style={globalStyles.col}>
                                                        <Text style={globalStyles.texto}>{vuln.metadata?.name || ""}</Text>
                                                    </View>
                                                    <View style={globalStyles.col}>
                                                        <Text
                                                            style={{
                                                                ...globalStyles.textoBold,
                                                                color: getCVSS3Color(getCVSS3Criticality(vuln.score))["background-color"]
                                                            }}
                                                        >
                                                            {getCVSS3Criticality(vuln.score).charAt(0).toUpperCase() +
                                getCVSS3Criticality(vuln.score).slice(1)}
                                                        </Text>
                                                    </View>
                                                </View>
                                            ))}
                                        </View>
                                        {/* Leyenda después de la columna 2 si no quedan más vulnerabilidades */}
                                        {((structure.pages.length === pageIndex + 1 && page.c2.length > 1) || (structure.pages[pageIndex]?.c1.length === 1)) && (
                                            <View style={globalStyles.table_legend}>
                                                <Text style={globalStyles.texto}>{i18n[locale].table.legend}</Text>
                                            </View>
                                        )}
                                    </View>
                                    {structure.pages.length === pageIndex + 1 && page.c2.length <= MAX_ITEMS_OTHER && (
                                        (page.c2[page.c2.length - 1] as ReactNode)
                                    )}
                                </View>
                            </View>
                        </View>
                    )
                    : (
                        <View key={pageIndex} style={globalStyles.section}>
                            <View style={globalStyles.column}>
                                <View style={globalStyles.containerY}>
                                    <View style={{ ...globalStyles.containerY, gap: 2 }}>
                                        <View style={globalStyles.table} wrap={false}>
                                            {/* Filas de la columna 1 sin encabezados */}
                                            {(page.c1.filter(isVulnerability) as Vulnerability[]).map((vuln, vulnIndex) => (
                                                <View key={vulnIndex} style={globalStyles.row} wrap={false}>
                                                    <View style={globalStyles.col}>
                                                        <Link
                                                            src={`#VULN-${vuln.id}`}
                                                            style={{ width: "auto", textDecoration: "none", color: "black" }}
                                                        >
                                                            <Text style={{ ...globalStyles.texto, textDecoration: "none", color: "black" }}>
                                                                {`VULN-${vuln.id}`}
                                                            </Text>
                                                        </Link>
                                                    </View>
                                                    <View style={globalStyles.col}>
                                                        <Text style={globalStyles.texto}>{vuln.metadata?.name || ""}</Text>
                                                    </View>
                                                    <View style={globalStyles.col}>
                                                        <Text
                                                            style={{
                                                                ...globalStyles.textoBold,
                                                                color: getCVSS3Color(getCVSS3Criticality(vuln.score))["background-color"]
                                                            }}
                                                        >
                                                            {getCVSS3Criticality(vuln.score).charAt(0).toUpperCase() +
                                                                getCVSS3Criticality(vuln.score).slice(1)}
                                                        </Text>
                                                    </View>
                                                </View>
                                            ))}
                                        </View>
                                        {/* Leyenda después de la columna 2 si no quedan más vulnerabilidades */}
                                        {(page.c1.length > 1 && page.c2.length < 2) && (
                                            <View style={globalStyles.table_legend}>
                                                <Text style={globalStyles.texto}>{i18n[locale].table.legend}</Text>
                                            </View>
                                        )}
                                    </View>
                                    {structure.pages.length === pageIndex + 1 && page.c1.length <= MAX_ITEMS_OTHER && page.c2.length === 0 && (
                                        (page.c1[page.c1.length - 1] as ReactNode)
                                    )}
                                </View>
                            </View>
                            <View style={globalStyles.column}>
                                <View style={globalStyles.containerY} >
                                    <View style={{ ...globalStyles.containerY, gap: 2 }} wrap={false}>
                                        <View style={globalStyles.table} wrap={false}>
                                            {/* Filas de la columna 2 sin encabezados */}
                                            {(page.c2.filter(isVulnerability) as Vulnerability[]).map((vuln, vulnIndex) => (
                                                <View key={vulnIndex} style={globalStyles.row} wrap={false}>
                                                    <View style={globalStyles.col}>
                                                        <Link
                                                            src={`#VULN-${vuln.id}`}
                                                            style={{ width: "auto", textDecoration: "none", color: "black" }}
                                                        >
                                                            <Text style={{ ...globalStyles.texto, textDecoration: "none", color: "black" }}>
                                                                {`VULN-${vuln.id}`}
                                                            </Text>
                                                        </Link>
                                                    </View>
                                                    <View style={globalStyles.col}>
                                                        <Text style={globalStyles.texto}>{vuln.metadata?.name || ""}</Text>
                                                    </View>
                                                    <View style={globalStyles.col}>
                                                        <Text
                                                            style={{
                                                                ...globalStyles.textoBold,
                                                                color: getCVSS3Color(getCVSS3Criticality(vuln.score))["background-color"]
                                                            }}
                                                        >
                                                            {getCVSS3Criticality(vuln.score).charAt(0).toUpperCase() +
                                                            getCVSS3Criticality(vuln.score).slice(1)}
                                                        </Text>
                                                    </View>
                                                </View>
                                            ))}
                                        </View>
                                        {/* Leyenda después de la columna 2 si no quedan más vulnerabilidades */}
                                        {((structure.pages.length === pageIndex + 1 && page.c2.length > 1) || (structure.pages[pageIndex]?.c1.length === 1)) && (
                                            <View style={globalStyles.table_legend}>
                                                <Text style={globalStyles.texto}>{i18n[locale].table.legend}</Text>
                                            </View>
                                        )}
                                    </View>
                                    {structure.pages.length === pageIndex + 1 && page.c2.length <= MAX_ITEMS_OTHER && (
                                        (page.c2[page.c2.length - 1] as ReactNode)
                                    )}
                                </View>
                            </View>
                        </View>
                    )
            )}
            <Footer externalData={{ clientName: client }} template={template}></Footer>
        </Page>
    )
}

export { ResultSection }
