type UserLog = {
    id: string;
    client_id: string;
    timestamp: string;
    action: ClientAction;
    service_name: string;
    entity_id: string;
    changes: string;
}

enum ClientAction {
    Create = "CREATE",
    Update = "UPDATE",
    Delete = "DELETE",
}

export { ClientAction }
export type { UserLog }
export default UserLog
