import React from "react"
import { Image, Page, View } from "@react-pdf/renderer"
import Template from "@models/Template"
import styles from "@components/report/Styles"

interface BackPageProps {
  template?: Template;
}
const CustomImage = () => <Page size="A4">
    <View fixed>
        <Image style={styles.image} src="/assets/img/report/contra.jpg" />
    </View>
</Page>
const BackPage: React.FC<BackPageProps> = ({ template }) => {
    if (!template) {
    // Renderiza la estructura básica si no hay un template
        return (
            <CustomImage />
        )
    }

    let imagen = ""
    let backgroundColor = ""
    const isCustomLogo = false
    // Si el template tiene un logo, lo procesamos
    if (template?.logo && template.logo !== "") {
        const byteArray = Uint8Array.from(atob(template.logo.toString()), c => c.charCodeAt(0))
        const blob = new Blob([byteArray], { type: "image/png" })
        imagen = URL.createObjectURL(blob)
    } else {
    // Si no hay logo, usamos la imagen predeterminada
        return (
            <CustomImage />
        )
    }

    // Si el template tiene un color de fondo, lo aplicamos
    if (template?.color) {
        backgroundColor = !template || template.color === "#fffa37" ? "" : template.color
    }

    return (
        <Page size="A4" style={{ backgroundColor }}>
            <View fixed style={{ display: "flex", justifyContent: "center", alignItems: "center", flex: 1 }}>
                {imagen && (
                    <Image
                        style={{
                            ...styles.image,
                            width: "auto",
                            height: "auto",
                            maxWidth: "90%",
                            maxHeight: "90%"
                        }}
                        src={imagen}
                    />
                )}
            </View>
        </Page>
    )
}

export { BackPage }
