import React, { useContext } from "react"
import { Image, Page, Text, View } from "@react-pdf/renderer"
import Template from "@models/Template"
import { I18nContext } from "I18nProvider"
import Assessment from "@models/Assessment"
import styles from "../Styles"
import { Footer, Header } from "./HeaderFooter"

interface introProps {
    introductionTitle: string;
    introDescription1: (deviceName: string) => string;
    introDescription2: string;
    introDescription3: string;
    introduccion: Array<{ id: string; description: string }>;
    analysisNote: string;
}

const i18n: {
    es: introProps
    en: introProps
} = {
    es: {
        introductionTitle: "Introducción",
        introDescription1: (deviceName: string) => `En este documento se describen los resultados del ensayo de test que se ha realizado al dispositivo ${deviceName} objetivo.`,
        introDescription2: "El test de intrusión tiene como objetivo identificar y caracterizar los fallos de diseño, debilidades y vulnerabilidades del sistema. Esta actividad, que se realiza de forma habitual en la fase de verificación y validación del producto, es un requisito especifico del estándar IEC 62443.",
        introDescription3: "El informe está organizado de esta forma:",
        introduccion: [
            { id: "Metodología: ", description: "(solo en manual): Se describe la metodología aplicada y la planificación de las condiciones, objetivos y el entorno en la que se realizan las pruebas." },
            { id: "Alcance: ", description: "Se describe el alcance inicial desde el cual se parte a la hora de realizar este ejercicio." },
            { id: "Resumen ejecutivo: ", description: "Se muestra un resumen general de los resultados obtenidos durante el test de intrusión." },
            { id: "Descubrimiento e identificación del sistema: ", description: "Se describe el proceso de descubrimiento e identificación del dispositivo, sistema operativo y servicios de red del sistema." },
            { id: "Identificación de vulnerabilidades: ", description: "Se identifican las vulnerabilidades y debilidades del sistema y se proponen una serie de contramedidas para mitigar las vulnerabilidades y debilidades identificadas y/o explotadas." }
        ],
        analysisNote: "El test de intrusión tiene como objetivo identificar y caracterizar los fallos de diseño, debilidades y vulnerabilidades del sistema."
    },
    en: {
        introductionTitle: "Introduction",
        introDescription1: (deviceName: string) => `This document describes the results of the test conducted on the ${deviceName} device.`,
        introDescription2: "The penetration test aims to identify and characterize design flaws, weaknesses, and system vulnerabilities. This activity, typically carried out during the product verification and validation phase, is a specific requirement of the IEC 62443 standard.",
        introDescription3: "The report is organized as follows:",
        introduccion: [
            { id: "Methodology: ", description: "(manual only): Describes the methodology applied and the planning of the conditions, objectives, and environment in which the tests are conducted." },
            { id: "Scope: ", description: "Describes the initial scope from which the exercise is based." },
            { id: "Executive summary: ", description: "Provides a general summary of the results obtained during the penetration test." },
            { id: "System discovery and identification: ", description: "Describes the process of discovering and identifying the device, operating system, and network services of the system." },
            { id: "Vulnerability identification: ", description: "Identifies the vulnerabilities and weaknesses of the system and proposes a series of countermeasures to mitigate the identified and/or exploited vulnerabilities and weaknesses." }
        ],
        analysisNote: "The penetration test aims to identify and characterize design flaws, weaknesses, and system vulnerabilities."
    }
}

interface SectionProps {
    externalData: any
    lang?: string;
    assessment?: Assessment
    template?: Template
    client?: string
}

const Intro: React.FC<SectionProps> = ({ externalData, lang, assessment, template, client }) => {
    // Constant
    const context = useContext(I18nContext)

    // Language selection logic
    type I18nType = typeof i18n
    let locale: keyof I18nType = "es"
    if (lang) {
        if (lang === "es" || lang === "en") {
            locale = lang as keyof I18nType
        } else {
            console.warn(`Idioma no soportado: ${lang}. Usando idioma por defecto.`)
        }
    } else if (context) {
        if (context.language === "es" || context.language === "en") {
            locale = context.language as keyof I18nType
        } else {
            console.warn(`Idioma no soportado en el contexto: ${context.language}. Usando idioma por defecto.`)
        }
    } else {
        console.error(
            "El contexto I18n no está inicializado. Asegúrese de que tiene el proveedor configurado correctamente."
        )
    }

    // Type-safe external data mapping
    const externalDataMap: { [key: string]: string } = {
        "Metodología: ": externalData?.Intro?.methodology,
        "Alcance: ": externalData?.Intro?.scope,
        "Resumen ejecutivo: ": externalData?.Intro?.exec_summary,
        "Descubrimiento e identificación del sistema: ": externalData?.Intro?.system_identification,
        "Identificación de vulnerabilidades: ": externalData?.Intro?.vuln
    }

    // Prepare introduccion items with external data if available
    const introduccionItems = i18n[locale].introduccion.map((point) => ({
        ...point,
        description: externalDataMap[point.id] || point.description
    }))

    // Use external data for analysis note if available
    const analysisNote = externalData?.Intro?.side_title || i18n[locale].analysisNote

    return (
        <Page size="A4">
            <Header externalData={null} template={template}></Header>
            <View style={{ ...styles.section, flexDirection: "row" }}>
                <View style={styles.column}>
                    <View>
                        <Text style={styles.titulo}>1. {i18n[locale].introductionTitle}</Text>
                        <Text style={{ ...styles.textua, marginRight: 50, fontFamily: "Griff", fontSize: 10 }}>{i18n[locale].introDescription1(externalData?.Intro?.device ? externalData?.Intro?.device : "NOMBRE-DISPOSITIVO")}</Text>
                        <Text style={{ ...styles.textua, marginRight: 50, fontFamily: "Griff", fontSize: 10 }} wrap={true}>{i18n[locale].introDescription2}</Text>
                        <Text style={{ ...styles.textua, marginRight: 50, fontFamily: "Griff", fontSize: 10 }}>{i18n[locale].introDescription3}</Text>
                        {introduccionItems.map((point, index) => (
                            <Text key={index} style={{ ...styles.puntuak, marginRight: 50, fontFamily: "Griff", fontSize: 10, paddingTop: 5 }}>
                                <Text style={styles.puntuak}>• &nbsp;</Text>
                                <Text style={{ ...styles.puntuak, fontFamily: "Bold" }}>{point.id}</Text>
                                <Text style={styles.puntuak}>{point.description}</Text>
                            </Text>
                        ))}
                    </View>
                </View>
                <View style={styles.column}>
                    <Image style={{ ...styles.rightImage, marginLeft: 30, paddingTop: 0, marginTop: -20, height: 305 }} src="/assets/img/report/computer.jpg" />
                    <View style={{ ...styles.rightImage, marginTop: 265, height: 270, marginLeft: 30, marginRight: -20, paddingTop: 100, marginBottom: 50, backgroundColor: template?.color, flexDirection: "row" }}>
                        <Text style={{ marginLeft: 55, marginRight: 50, paddingTop: -80, marginBottom: 200, paddingRight: 10, textAlign: "right", fontFamily: "Bold", fontSize: 18 }}>{analysisNote}</Text>
                    </View>
                </View>
            </View>
            <Footer externalData={{ clientName: client }} template={template}></Footer>
        </Page>
    )
}

export { Intro }
