import { Box, Button, Checkbox, Grid, MenuItem, TextField, Typography, useTheme } from "@mui/material"
import React, { useContext, useState } from "react"
import { useNavigate } from "react-router-dom"
import { ClientSelector } from "@components/client/index"
import { ServicesContext } from "@context/index"
import { I18nContext } from "I18nProvider"
import { LicenseTypeKeys } from "@components/license/LicenseTableDefinition"
import { LicenseTypeColors } from "@components/common/colors/LicenseTypeColors"
import License, { LicenseType } from "@models/License"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"

const initValue: License = {
    id: "",
    name: "",
    type: LicenseType.Golden,
    max_assessment: "0",
    max_prod: "0",
    toolbox: false,
    client: { id: "" }
}
const LicenseForm: React.FC = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const theme = useTheme()
    const licenseService = useContext(ServicesContext).licenseService
    const [error, setError] = useState<Error|null>(null)
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    // API Call form submission
    const [formData, setFormData] = useState<License>(initValue)
    const handleInputChange = (e: any) => {
        const { name, value } = e.target
        // Check if the value is a string representation of a number
        const isValidNumberString = /^\d*$/.test(value) // Regular expression to match strings of digits only

        // Only update formData if the value is valid
        if (name === "max_prod" && isValidNumberString) {
            setFormData({ ...formData, [name]: value })
        }
        if (name === "max_assessment" && isValidNumberString) {
            setFormData({ ...formData, [name]: value })
        } else if (name !== "max_prod" && name !== "max_assessment") {
            setFormData({ ...formData, [name]: value })
        }
    }
    const formValid = (): boolean => {
        const isNotNullrules = [
            formData.max_assessment === "0",
            formData.max_prod === "0",
            formData.client.id === "",
            formData.client.id === undefined
        ]
        if (isNotNullrules.some(rule => rule)) {
            return false
        }
        return true
    }
    // Api call form validation
    const navigate = useNavigate()
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        try {
            await licenseService.create(formData)
            navigate(-1)
        } catch (e: any) {
            setError({ message: e.error } as Error)
            setSnackbarOpen(true)
        }
    }

    return (
        <Box sx={{ width: "100%" }}>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1, width: "50%", alignSelf: "center", margin: "50px auto 50px auto" }}>
                <Grid item xs container flexDirection="column">
                    <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                        {context.t.translate("License_new")}
                    </Typography>
                </Grid>
                <CustomSnackbar
                    open={snackbarOpen}
                    onClose={() => setSnackbarOpen(false)}
                    message={error?.message || context.t.translate("an_error")}
                />

                <Grid item container flexDirection="row" alignItems='center' spacing="40px">
                    <Grid item xs={6}>
                        <ClientSelector value={formData.client.id} onSelect={(id) => handleInputChange({ target: { name: "client", value: { id } } })}></ClientSelector>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <TextField margin="normal" required fullWidth variant="filled" label={context.t.translate("license_title")} name="name"
                            value={formData.name} onChange={handleInputChange}/>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField margin="normal" select required fullWidth variant="filled" label={context.t.translate("type")} name="type"
                            value={formData.type} onChange={handleInputChange}>
                            {LicenseTypeKeys.map((key, index) =>
                                (<MenuItem key={index} value={LicenseType[key]}><Typography fontFamily="Griff" fontWeight="bolder" sx={{ color: LicenseTypeColors[LicenseType[key]] }}>{key}</Typography></MenuItem>)
                            )}
                        </TextField>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <TextField margin="normal" required fullWidth variant="filled" label={context.t.translate("max_products")} name="max_prod"
                            value={formData.max_prod} onChange={handleInputChange}/>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField margin="normal" required fullWidth variant="filled" label={context.t.translate("max_assessments")} name="max_assessment"
                            value={formData.max_assessment} onChange={handleInputChange}/>
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container xs={12} alignItems="center" direction="row">
                            <Checkbox name="Toolbox" checked={formData.toolbox} onChange={(e) => setFormData({ ...formData, toolbox: e.target.checked })} style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}/>
                            {formData.toolbox
                                ? (
                                    <Typography color="#ffffff" fontSize="18px" fontFamily="Griff" fontWeight="bolder" style={{ marginLeft: 8 }}>
                                        {context.t.translate("include_toolbox")}
                                    </Typography>
                                )
                                : (
                                    <Typography color="#ffffff" fontSize="18px" fontFamily="Griff" style={{ marginLeft: 8 }}>
                                        {context.t.translate("without_toolbox")}
                                    </Typography>
                                )}
                        </Grid>
                    </Grid>
                </Grid>
                <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2, color: theme.palette.secondary.contrastText }} disabled={!formValid()}>
                    {context.t.translate("create_license")}
                </Button>
            </Box>
        </Box>
    )
}

export { LicenseForm }
export default LicenseForm
