import React, { useContext } from "react"
import { Route, Routes } from "react-router-dom"
import { AbilityContext } from "@components/permissions/index"
import { VulnerabilityDetail, VulnerabilityList } from "@views/vulnerability/index"
import CVSSCalcultor from "@views/assessment/CVSSCalculator"
import CVSSCalcultor4 from "@views/assessment/CVSSCalculator4"
import KEVDetail from "@views/kev/KEVDetail"
import HistoricCveView from "./Historic/HistoricCve"

const entity = "Vulnerability"
const Vulnerability: React.FC = () => {
    const ability = useContext(AbilityContext)
    const routes = []

    if (ability.can("read", entity)) {
        routes.push(...[
            {
                route: "",
                component: <VulnerabilityList/>
            }
        ])
    }

    if (ability.can("update", entity)) {
        routes.push(...[
            {
                route: ":id",
                component: <VulnerabilityDetail/>
            }
        ])
    }

    routes.push(...[
        {
            route: ":id/cvss",
            component: <CVSSCalcultor/>
        },
        {
            route: ":id/cvss4",
            component: <CVSSCalcultor4/>
        },
        {
            route: ":id/historic",
            component: <HistoricCveView/>
        },
        {
            route: ":id/:kevid",
            component: <KEVDetail />
        }
    ])

    return (
        <Routes>
            {
                routes.map((item, idx) => (
                    <Route key={idx} path={item.route} element={item.component} />
                ))
            }
        </Routes>
    )
}

export { Vulnerability }
export default Vulnerability
