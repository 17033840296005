/* eslint-disable multiline-ternary */
import { StyledBox } from "@components/common/Box/BoxContainer"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import { AbilityContext } from "@components/permissions"
import ServicesContext from "@context/ServicesContext"
import Hierarchy from "@models/Hierarchy"
import { Divider, Grid, Typography, useTheme } from "@mui/material"
import { FilterOperation, FilterOption } from "@utils/queryParams"
import { I18nContext } from "I18nProvider"
import React, { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useTrack } from "@components/track/TrackContext"
import { MAX_DEEP_LEVEL, PATH_SEPARATOR } from "./hierarchyUtils"

const initValue: Hierarchy = {
    id: "",
    name: "",
    description: "",
    path: "",
    client: { id: "00000000-0000-0000-0000-000000000000" }
}
const HierarchyDetail: React.FC = () => {
    // Constants
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    // Constants
    if (context === null) throw new Error("The I18n context is not initialized. Make sure you have the provider set up correctly.")
    const theme = useTheme()
    const { id } = useParams<{ id: string }>()
    const hierarchyService = useContext(ServicesContext).hierarchyService
    const [error, setError] = useState<Error|null>(null)
    const [selectedHierarchies, setSelectedHierarchies] = useState<(Hierarchy | null)[]>(Array.from({ length: MAX_DEEP_LEVEL - 1 }, () => null))
    const ability = useContext(AbilityContext)
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [formData, setFormData] = useState<Hierarchy>(initValue)
    // --------------------------------------------------
    const { track, trackData } = useTrack()
    useEffect(() => {
        track({ view: "HierarchyDetail" })
    }, [])
    useEffect(() => {
        const fetchHierarchy = async () => {
            if (id) {
                try {
                    const hierarchy = await hierarchyService.get(id)
                    setFormData(hierarchy)
                    const pathIds = hierarchy.path.split(PATH_SEPARATOR).filter(pathId => pathId !== id)
                    const fetchedHierarchies = await Promise.all(pathIds.map(id => hierarchyService.get(id)))
                    setSelectedHierarchies(fetchedHierarchies.map(h => h || null))
                } catch (error) {
                    setError({ message: context.t.translate("failed_to_fetch") } as Error)
                    setSnackbarOpen(true)
                }
            }
        }
        fetchHierarchy()
    }, [id, hierarchyService])

    const handleInputChange = (e: any) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }

    const handleSelectorChange = (index: number, id: string | undefined, hierarchy: Hierarchy | null) => {
        const newSelectedHierarchies = [...selectedHierarchies]
        newSelectedHierarchies[index] = hierarchy
        newSelectedHierarchies.fill(null, index + 1, MAX_DEEP_LEVEL - 1)
        setSelectedHierarchies(newSelectedHierarchies)

        const newPath = newSelectedHierarchies.filter(h => h !== null).map(h => h!.id).join(";")
        setFormData({ ...formData, path: newPath })
    }

    const formValid = (): boolean => {
        const isNotNullrules = [
            formData.name === "",
            formData.description === ""
        ]
        ability.can("*", "Client") ?? isNotNullrules.push(formData.client.id === "00000000-0000-0000-0000-000000000000", formData.client.id === "")
        if (isNotNullrules.some(rule => rule)) {
            return false
        }
        return true
    }
    // Api call form validation
    const navigate = useNavigate()
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        try {
            if (formData.id) {
                let updatedPath = formData.path
                if (!updatedPath || !updatedPath.endsWith(formData.id)) {
                    // Add the id to the end of the path
                    updatedPath = updatedPath ? `${updatedPath}${PATH_SEPARATOR}${formData.id}` : formData.id
                }
                const updatedFormData = { ...formData, path: updatedPath }
                // Update existing hierarchy
                await hierarchyService.update(formData.id, updatedFormData)
            } else {
                // Create new hierarchy
                await hierarchyService.create(formData)
            }
            setError(null)
            navigate(-1)
        } catch (e: any) {
            setError({ message: e.error } as Error)
            setSnackbarOpen(true)
        }
    }

    const getCustomFilters = (index: number): FilterOption[] | undefined => {
        if (index === 0) {
            if (ability.can("*", "Client")) {
                if (formData.client.id !== "00000000-0000-0000-0000-000000000000") {
                    return [
                        { field: "client_id", operation: FilterOperation.UUIDEqual, value: formData.client.id },
                        { field: "path", operation: FilterOperation.StringNotContains, value: `%${PATH_SEPARATOR}%` }
                    ]
                }
                return undefined
            }
            return [{ field: "path", operation: FilterOperation.StringNotContains, value: `%${PATH_SEPARATOR}%` }]
        }
        const parentHierarchy = selectedHierarchies[index - 1]
        if (!parentHierarchy) return []
        return [
            { field: "path", operation: FilterOperation.StringContains, value: `${parentHierarchy.path}${PATH_SEPARATOR}%` },
            { field: "path", operation: FilterOperation.StringNotContains, value: `${parentHierarchy.path}${PATH_SEPARATOR}%${PATH_SEPARATOR}%` }
        ]
    }

    return (
        <Grid item container flexDirection="column" rowGap="25px">

            <Grid item xs container flexDirection="column" rowGap="25px" aria-description="Hierarchy Edit Section //trans">
                <Grid item xs sx={{ position: "sticky", top: "0px", backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: 1000 }}>
                    <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                        {context.t.translate("hierarchy")}
                    </Typography>
                </Grid>
                <Grid item xs container flexDirection="column" spacing="20px">
                    <Grid item container flexDirection="column" rowGap="35px">
                        <Grid item container>
                            <StyledBox>
                                <CustomSnackbar
                                    open={snackbarOpen}
                                    onClose={() => setSnackbarOpen(false)}
                                    message={error?.message || context.t.translate("an_error")}
                                />
                                <Grid item container justifyContent="space-between" alignItems="center">
                                    <Grid item>
                                        <Typography fontSize="37px" fontFamily="Griff" color="primary" fontWeight="bolder">
                                            {formData.name}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item my={2}>
                                    <Divider sx={{ height: "1px", width: "100%", background: theme.palette.primary.main }}/>
                                </Grid>
                                <Typography fontSize="37px" fontFamily="Griff" color="primary" fontWeight="bolder">
                                    {formData.description}
                                </Typography>
                            </StyledBox>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid >
        </Grid >

    )
}
export { HierarchyDetail }
export default HierarchyDetail
