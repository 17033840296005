import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import React, { ChangeEvent, forwardRef, Ref, useContext, useEffect, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { AlertColor, Button, Divider, FormControl, MenuItem, Select, Tab, Tabs, TextField, useTheme } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers"
import { AssessmentStatusKeysValueOps } from "@components/assessment"
import { ServicesContext } from "@context/index"
import { Assessment, AssessmentStatus, AssessmentType, StatsVulnerabilities, TemplateReportByAssessmentType } from "@models/index"
import { FilterOperation, QueryParameters } from "@utils/index"
import { AbilityContext } from "@components/permissions"
import { AssessmentStatusColors } from "@components/common/colors/AssessmentStatusColors"
import { AssessmentTypeColors } from "@components/common/colors/AssessmentTypeColors"
import { I18nContext } from "I18nProvider"
import moment from "moment"
import VulnReportDocumentGeneric from "@components/report/VulnReportDocumentGeneric"
import { pdf, PDFViewer } from "@react-pdf/renderer"
import { FileTypeByAssessmentType } from "@models/File"
import saveAs from "file-saver"
import { MediaTypes } from "@services/HttpService"
import ActionButton from "@components/common/Button/ActionButton"
import { StyledBox } from "@components/common/Box/BoxContainer"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import VulnReportDocumentAchilles from "@components/report/VulnReportDocumentAquilles"
import VulnReportDocument from "@components/report/VulnReportDocument"
import vulnerabilityGenerator from "mockData/vulnerabilityChart"
import { CustomText } from "@models/CustomText"
import { useTrack } from "@components/track/TrackContext"
import achillesTestGenerator from "mockData/achillesTestChart"
import assessmentGenerator from "mockData/assessmentChart"
import PentestReport from "@components/report/VulnReportPentest"
import VulnerabilitiesTable from "./Components/VulnerabilitiesTable"
import AchillesTable from "./Components/AchillesTable"
import CustomizeText from "./AssesmentCustomText"
import HardwareComponentTable from "./Components/HardwareComponentTable"

const initValue: Assessment = {
    id: "",
    type: AssessmentType.CRT,
    status: AssessmentStatus.Contracted,
    contractDate: null,
    startDate: null,
    endDate: null,
    product: { id: "" },
    client: { id: "" },
    template_id: "00000000-0000-0000-0000-000000000000",
    analysis_status: 0
}

const AssessmentDetail: React.FC = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const theme = useTheme()
    const assessmentService = useContext(ServicesContext).assessmentService
    const vulnerabilityService = useContext(ServicesContext).vulnerabilityService
    const achillesService = useContext(ServicesContext).achillesService
    const statsService = useContext(ServicesContext).statsService
    const templateService = useContext(ServicesContext).templateService
    const clientService = useContext(ServicesContext).clientService
    const customTextService = useContext(ServicesContext).customTextService
    const [error, setError] = useState<Error | null>(null)
    const { id } = useParams<{ id: string }>()
    const [data, setData] = useState<Assessment>(initValue)
    const [formData, setFormData] = useState<Assessment>(initValue)
    const [vulnStats, setVulnStats] = useState<StatsVulnerabilities | undefined>(undefined)
    const [filetype, setFiletype] = useState(FileTypeByAssessmentType[data.type])
    const ability = useContext(AbilityContext)
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [dynamicReport, setDynamicReport] = useState<React.ReactNode | null>(null)
    const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>("info")
    const [snackbarMessage, setSnackbarMessage] = useState<string>("")

    const [customTexts, setCustomTexts] = useState<CustomText>({
        id: "",
        assessment_id: "",
        client_id: "",
        custom_text: {
        }
    })
    const [tabs, setTabs] = useState<{label:string, content: string}[]>([])
    const [tabIndex, setTabIndex] = useState(0)
    const handleTabChange = (event: any, newIndex: React.SetStateAction<number>) => {
        setTabIndex(newIndex)
    }
    // Tracking
    const { track, trackData } = useTrack()
    useEffect(() => {
        track({ view: "AssessmentDetail" })
    }, [])
    useEffect(() => {
        const newTabs = []
        if ((formData.type === AssessmentType.CRT && ability.can("*", "Achilles")) || (formData.type === AssessmentType.LogicalPentest && ability.can("*", "Pentest"))) {
            newTabs.push({ label: "Custom report", content: "custom" })
        }
        if (formData.type === AssessmentType.CRT) {
            if (ability.can("*", "Achilles")) {
                newTabs.push({
                    label: "Achilles",
                    content: "vulnerabilities"
                })
            }
        } else {
            newTabs.push({
                label: "Vulnerabilities",
                content: "vulnerabilities"
            })
        }

        newTabs.push({ label: "Hardware components", content: "hardware" })

        setTabs(newTabs)

        // Si el tabIndex actual no es válido, resetearlo
        if (tabIndex >= newTabs.length) {
            setTabIndex(0)
        }
    }, [formData])
    const formValid = (): boolean => {
        const isNotNullrules = [
            formData.id === undefined,
            formData.client.id === undefined,
            formData.contractDate === undefined,
            formData.startDate === undefined,
            formData.endDate === undefined,
            formData.contractDate === "Invalid date",
            formData.startDate === "Invalid date",
            formData.endDate === "Invalid date"
        ]
        if (isNotNullrules.some(rule => rule)) {
            return false
        }
        const isNotSameOldValues = [
            formData.type === data.type,
            formData.status === data.status,
            formData.contractDate === data.contractDate,
            formData.startDate === data.startDate,
            formData.endDate === data.endDate,
            formData.client.id === data.client.id,
            formData.template_id === data.template_id
        ]
        if (isNotSameOldValues.every(rule => rule)) {
            return false
        }
        return true
    }

    // Generic change handler for all form fields
    const handleInputChange = (e: any) => {
        let events: { target: { name: string, value: string } }[] = e
        if (!Array.isArray(e)) {
            events = [e]
        }

        setFormData(events.reduce((result, { target: { name, value } }) => {
            return { ...result, [name]: value }
        }, formData))
    }
    // Generic change handler for all form fields - END

    // Fetch assessment data from API
    useEffect(() => {
        const fecthData = async () => {
            try {
                const val = await assessmentService.get(id as string)
                setData(val)
                setFormData(val)
                setFiletype(FileTypeByAssessmentType[val.type])
                const queryParameters: QueryParameters = { filters: [{ field: "assessment_id", operation: FilterOperation.EnumEqual, value: id as string }] }
                const vulnsStats = await statsService.getStatsVulnerabilitiesWithParams(queryParameters)
                setVulnStats(vulnsStats)
            } catch (e: any) {
                setError({ message: e.error } as Error)
                setSnackbarSeverity("error")

                setSnackbarMessage(e.error)
                setSnackbarOpen(true)
            }
        }
        fecthData()
    }, [])
    // API Call save assessment
    const navigate = useNavigate()
    const saveHandler = async () => {
        try {
            await assessmentService.update(formData.id, formData)
            setData(formData)
            navigate(-1)
        } catch (e: any) {
            setError({ message: e.error } as Error)
            setSnackbarMessage(e.error)
            setSnackbarSeverity("error")
            setSnackbarOpen(true)
        }
    }
    // API Call save assessment - END

    // Handle component button
    const handleComponentButton = () => {
        navigate("./component")
    }
    // --------------------------------------------

    // Handle informe button
    const handleInformeButton = async () => {
        setSnackbarSeverity("info")
        setSnackbarMessage("Generating file...")
        setSnackbarOpen(true)
        if (formData.type === AssessmentType.LogicalPentest) {
            try {
                const params = { filters: [{ field: "assessment_id", operation: FilterOperation.UUIDEqual, value: id as string }] }
                const vulnerabilityList = await vulnerabilityService.getAll(params)
                const customTextData = await customTextService.getAll({
                    filters: [{ field: "assessment_id", operation: FilterOperation.UUIDEqual, value: id as string }]
                })
                const clientParams = { filters: [{ field: "id", operation: FilterOperation.UUIDEqual, value: formData.client.id as string }] }
                const client = await clientService.getAll(clientParams)
                const report = pdf(<PentestReport data={vulnerabilityList.list} externalData={customTextData.list[0]} clientName={client.list[0].name} /* template={templateData.list[0]} *//>)
                const blob = await report.toBlob()
                const url = window.URL.createObjectURL(blob)
                window.open(url)
                setSnackbarSeverity("success")
                setSnackbarMessage("File generated successfully")
                setSnackbarOpen(true)
            } catch (error) {
                console.error(error)
            }
        }
        try {
            const params = { filters: [{ field: "assessment_id", operation: FilterOperation.EnumEqual, value: id as string }] }
            const vulnerabilityList = await vulnerabilityService.getAllType(params, "csv")
            const vulns = await vulnerabilityService.getAllFormatted(formData.id, "cyclonedx")
            const templateData = await templateService.getAll({ filters: [{ field: "assessment_type", operation: FilterOperation.StringEqual, value: formData.type }] })
            const report = formData.type === AssessmentType.SCA ? pdf(<VulnReportDocument data={vulns} assessment={formData} template={templateData.list[0]} />) : pdf(<VulnReportDocumentGeneric data={vulnerabilityList.list} />)
            const blob = await report.toBlob()
            const url = window.URL.createObjectURL(blob)
            window.open(url)
            setSnackbarSeverity("success")
            setSnackbarMessage("File generated successfully")
            setSnackbarOpen(true)
        } catch (error) {
            console.error(error)
        }
    }
    const handleInformeAchilles = async () => {
        setSnackbarSeverity("info")
        setSnackbarMessage("Generating file...")
        setSnackbarOpen(true)
        try {
            if (filetype.type === "achilles") {
                const data = await achillesService.getAllFormatted(id as string, "achilles")
                const templateData = await templateService.getAll({ filters: [{ field: "assessment_type", operation: FilterOperation.StringEqual, value: formData.type }] })
                const report = pdf(
                    <VulnReportDocumentAchilles
                        assessment={formData}
                        achilles={data.list}
                        template={templateData.list[0]}
                    />
                )
                const blob = await report.toBlob()
                const url = window.URL.createObjectURL(blob)
                window.open(url)
                setSnackbarSeverity("success")
                setSnackbarMessage("File generated successfully")
                setSnackbarOpen(true)
                // Reiniciar customText después de generar el informe
            }
        } catch (error) {
            console.error(error)
            setError({ message: context.t.translate("error_generating") } as Error)
            setSnackbarMessage(context.t.translate("error_generating"))
            setSnackbarSeverity("error")
            setSnackbarOpen(true)
        }
    }
    // --------------------------------------------

    // Handle SBOM button
    const fileService = useContext(ServicesContext).fileService
    const handleSBOMButton = async () => {
        const filetype = FileTypeByAssessmentType[data.type]
        const file = await fileService.downloadFile(id, filetype.type)
        const blob = new Blob([file], { type: filetype.mimetype })
        saveAs(blob, `${data.product.name}_${data.product.version}_${data.type}`)
    }

    // --------------------------------------------
    // Handle SBOM quality button
    const handleSBOMQuality = async () => {
        navigate("./sbomQuality")
    }
    // Función para descargar el archivo genérico
    const handleDownloadGenericFileClick = async () => {
        try {
            // Descarga el archivo usando fileService
            const assessmentData = await fileService.downloadFile(data.id, "original")

            // Crea un blob para el archivo descargado con el tipo MIME correcto
            const blob = new Blob([assessmentData], { type: filetype.mimetype })

            // Define el nombre del archivo
            const fileName = `${data.product.name}_${data.product.version}_${data.type}`

            // Descarga el archivo usando saveAs
            saveAs(blob, fileName)
        } catch (error) {
            console.error(context.t.translate("error_downloading"), error)
            setError({ message: context.t.translate("error_downloading_report") } as Error)
            setSnackbarSeverity("error")
            setSnackbarMessage(context.t.translate("error_downloading_report"))
            setSnackbarOpen(true)
        }
    }
    const handleDownloadGenericFileSBOM = async () => {
        try {
            // Descarga el archivo usando fileService
            const assessmentData = await fileService.downloadFile(data.id, "report")

            // Crea un blob para el archivo descargado con el tipo MIME correcto
            const blob = new Blob([assessmentData], { type: filetype.mimetype })

            // Define el nombre del archivo
            const fileName = `${data.product.name}_${data.product.version}_${data.type}`

            // Descarga el archivo usando saveAs
            saveAs(blob, fileName)
        } catch (error) {
            console.error(context.t.translate("error_downloading"), error)
            setError({ message: context.t.translate("error_downloading_report") } as Error)
            setSnackbarSeverity("error")
            setSnackbarMessage(context.t.translate("error_downloading_report"))
            setSnackbarOpen(true)
        }
    }

    // Handle upload SBOM button
    const [fileId, _] = useState(`file-input-${Math.random().toString(36).substring(7)}`)
    const fileInputRef = useRef<HTMLInputElement>(null)
    const VisuallyHiddenInput = forwardRef<HTMLInputElement>((props, ref: Ref<HTMLInputElement>) => {
        return (
            <input
                type="file"
                accept={FileTypeByAssessmentType[data.type].extension}
                style={{ display: "none" }}
                onChange={handleFileInputChange}
                onClick={handleFileInputClick}
                id={fileId}
                ref={ref}
            />
        )
    })
    VisuallyHiddenInput.displayName = "VisuallyHiddenInput"
    const handleUploadSBOMButton = () => {
        fileInputRef.current?.click()
    }
    const handleFileInputClick = (e: React.MouseEvent<HTMLInputElement>) => {
        const input = e.target as HTMLInputElement
        input.value = ""
    }
    const onSectionChange = (updatedText: CustomText) => {
        setCustomTexts(updatedText)
    }
    const handleFileInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files && e.target.files[0]

        if (selectedFile) {
            try {
                const mediaTypesValues = Object.values(MediaTypes)
                const mimetypes = filetype.mimetype.split(",")
                const foundType = mediaTypesValues.find(v => mimetypes.includes(v))

                if (foundType) {
                    const response = await fileService.uploadFileV2(
                        data.id,
                        filetype.type,
                        foundType as MediaTypes,
                        selectedFile
                    )

                    if (response.status === 200) {
                        // Actualizar el estado basado en el tipo de evaluación
                        const updatedStatus = data.type === AssessmentType.SCA
                            ? AssessmentStatus.Started
                            : AssessmentStatus.Ended

                        const updatedData = { ...formData, status: updatedStatus }

                        await assessmentService.update(updatedData.id, updatedData)
                        setData(updatedData)
                        setFormData(updatedData)
                        navigate(-1)
                    }
                } else {
                    setError({ message: context.t.translate("unsupported_file") } as Error) // TODO: Translate
                }
            } catch (e: any) {
                setError({ message: context.t.translate("wrong_file") } as Error)
                setSnackbarMessage(context.t.translate("wrong_file"))
                setSnackbarSeverity("error")
                setSnackbarOpen(true)
                console.error(e)
            }
        }
    }
    // --------------------------------------------
    useEffect(() => {
        const updateAssessmentStatusAndStartDate = async () => {
            const totalVulnerabilities =
                (vulnStats
                    ? Object.values(vulnStats.MitigatedVulnerabilities).reduce((sum, val) => sum + val, 0) +
                      Object.values(vulnStats.UnmitigatedVulnerabilities).reduce((sum, val) => sum + val, 0)
                    : 0)

            if (
                data.type === AssessmentType.LogicalPentest &&
                data.status === AssessmentStatus.Planned &&
                totalVulnerabilities > 0 // Asegúrate de que hay al menos una vulnerabilidad
            ) {
                const updatedData = {
                    ...formData,
                    status: AssessmentStatus.Started,
                    startDate: moment().toISOString() // Asignar la fecha actual al campo startDate
                }
                try {
                    await assessmentService.update(updatedData.id, updatedData)
                    setData(updatedData) // Actualiza el estado local
                    setFormData(updatedData)
                } catch (error: any) {
                    console.error("Error updating assessment status and startDate:", error)
                    setError({ message: "Failed to update assessment status and startDate." } as Error)
                    setSnackbarSeverity("error")
                    setSnackbarMessage("Failed to update assessment status and startDate.")
                    setSnackbarOpen(true)
                }
            }
        }

        updateAssessmentStatusAndStartDate()
    }, [vulnStats, data.type, data.status]) // Observa los cambios en vulnStats, el tipo y estado del assessment

    // --------------------------------------------

    // Functions

    // Web iframe
    useEffect(() => {
        const generatePDFComponent = async () => {
            const TemplateComponent = TemplateReportByAssessmentType[formData.type]
            if (!TemplateComponent) return

            // Incluye los datos de textos editados como parte de los props
            if (formData.type === AssessmentType.CRT) {
                setDynamicReport(
                    <TemplateComponent
                        assessment={assessmentGenerator(1)[0]}
                        achilles={achillesTestGenerator(30)}
                        externalData={customTexts.custom_text} // Pasar datos editados
                    />
                )
            } else {
                if (formData.type === AssessmentType.LogicalPentest) {
                    setDynamicReport(
                        <TemplateComponent
                            data={vulnerabilityGenerator(30)}
                            externalData={customTexts.custom_text} // Pasar datos editados
                        />
                    )
                }
            }
        }

        if (formData.type === AssessmentType.CRT || formData.type === AssessmentType.LogicalPentest) {
            generatePDFComponent()
        }
    }, [formData.type, customTexts])
    // --------------------------------------------

    return (
        <Grid item flexDirection="column" rowGap="25px" sx={{ paddingTop: "0px !important" }}>

            <Grid item xs container flexDirection="column" rowGap="25px" aria-description="Assessment Section">
                <Grid item xs sx={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
                    <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                        {/* May be change this text to "Details" or "Assessments details" */}
                        {context.t.translate("assessments")}
                    </Typography>
                </Grid>
                <Grid item xs container flexDirection="column" spacing="20px">
                    <Grid item container flexDirection="column" rowGap="35px">
                        <Grid item container>
                            <StyledBox>
                                <CustomSnackbar
                                    open={snackbarOpen}
                                    onClose={() => setSnackbarOpen(false)}
                                    message={snackbarMessage || context.t.translate("an_error") }
                                    severity={snackbarSeverity}
                                />
                                <Grid container flexDirection="column" spacing="20px">
                                    <Grid item flexDirection="row">
                                        <Grid container flexDirection="row" justifyContent="space-between" alignItems="center" spacing={2}>
                                            <Grid item>
                                                <Typography fontSize="37px" fontFamily="Griff" color="primary" fontWeight="bolder">
                                                </Typography>
                                            </Grid>
                                            <Grid item container justifyContent="space-between">
                                                <Grid item container spacing={1} xs>
                                                    {[AssessmentStatus.Ended, AssessmentStatus.Reported].includes(data.status) &&
                                                        <>
                                                            {data.type === AssessmentType.SCA && <Grid item><ActionButton variant="outlined" onClick={handleComponentButton} text={context.t.translate("components")} /></Grid>}
                                                            {data.type === AssessmentType.CRT
                                                                ? (
                                                                    <Grid item>
                                                                        <ActionButton variant="outlined" onClick={handleInformeAchilles} text={context.t.translate("report")} />
                                                                    </Grid>
                                                                )
                                                                : (
                                                                    <Grid item>
                                                                        <ActionButton variant="outlined" onClick={handleInformeButton} text={context.t.translate("report")} />
                                                                    </Grid>
                                                                )}
                                                            {/* <Grid item>
                                                                <ActionButton variant="outlined" onClick={handleSBOMButton} text="SBOM" />
                                                            </Grid> */}
                                                            <Grid item>
                                                                <ActionButton
                                                                    variant="outlined"
                                                                    onClick={handleDownloadGenericFileClick}
                                                                    text={context.t.translate("original")}
                                                                />
                                                            </Grid>
                                                            {data.type === AssessmentType.SCA && <Grid item><ActionButton variant="outlined" onClick={handleDownloadGenericFileSBOM} text={context.t.translate("upload_sbom")} /></Grid>}
                                                        </>
                                                    }
                                                    {data.status === AssessmentStatus.Planned && data.type !== AssessmentType.LogicalPentest &&
                                                        <Grid item><VisuallyHiddenInput ref={fileInputRef} /><Button variant="outlined" tabIndex={-1} onClick={handleUploadSBOMButton} sx={{ mr: "5px" }}>{context.t.translate("upload_sbom")}</Button></Grid>
                                                    }
                                                    {[AssessmentStatus.Ended, AssessmentStatus.Reported].includes(data.status) && data.type === AssessmentType.SCA &&
                                                        <Grid item><ActionButton variant="outlined" onClick={handleSBOMQuality} text={context.t.translate("SBOM_quality")} /></Grid>
                                                    }
                                                    {/* TODO: add translation */}
                                                    {data.type === AssessmentType.LogicalPentest && ability.can("*", "Pentest") &&
    (data.status === AssessmentStatus.Planned || data.status === AssessmentStatus.Started) && (
                                                        <Grid item>
                                                            <ActionButton
                                                                variant="outlined"
                                                                onClick={() => navigate("./addvuln")}
                                                                text="Add vulnerability"
                                                            />
                                                        </Grid>
                                                    )
                                                    }

                                                </Grid>
                                                <Grid item>
                                                    <ActionButton variant="contained" onClick={saveHandler} disabled={!formValid()} text={context.t.translate("save")} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item>
                                        <Divider sx={{ height: "1px", width: "100%", background: theme.palette.primary.main }} />
                                    </Grid>

                                    <Grid item container flexDirection="row" alignItems='center' spacing="40px">
                                        <Grid item xs={6}>
                                            <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("issueDetails.productName")}</Typography>
                                            <FormControl sx={{ width: "100%" }} disabled>
                                                <Select name="type" size="small" value={formData.product.id} fullWidth>
                                                    <MenuItem value={formData.product.id}><Typography fontFamily="Griff" fontWeight="bolder" sx={{ color: AssessmentTypeColors[formData.type] }} >{formData.product.name}</Typography></MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            {/*
    <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">Template</Typography>
    <TemplateSelector
        value={formData.template_id}
        customFilters={[{ field: "client_id", operation: FilterOperation.UUIDEqual, value: formData.client.id }]}
        onSelect={(id, p) => {
            handleInputChange([{ target: { name: "template_id", value: id } }])
        }}
    ></TemplateSelector>
    */}
                                        </Grid>

                                    </Grid>
                                    <Grid item container flexDirection="row" alignItems='center' spacing="40px">
                                        <Grid item xs={12} md={6}>
                                            <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("type")}</Typography>
                                            <FormControl sx={{ width: "100%" }} disabled>
                                                <Select name="type" size="small" value={formData.type} fullWidth>
                                                    <MenuItem value={formData.type}><Typography fontFamily="Griff" fontWeight="bolder" sx={{ color: AssessmentTypeColors[formData.type] }} >{formData.type}</Typography></MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("status")}</Typography>
                                            <Select disabled={!ability.can("*", "Assessment")} name="status" size="small" value={formData.status} onChange={handleInputChange} fullWidth>
                                                {AssessmentStatusKeysValueOps.map((opt, idx) =>
                                                    (<MenuItem key={idx} value={opt.value}><Typography fontFamily="Griff" fontWeight="bolder" sx={{ color: AssessmentStatusColors[AssessmentStatus[opt.label]] }}>{opt.label}</Typography></MenuItem>)
                                                )}
                                            </Select>
                                        </Grid>
                                    </Grid>
                                    <Grid item container flexDirection="row" alignItems='center' spacing="40px">

                                        <Grid item xs={12} md={4}>
                                            <DatePicker
                                                label={context.t.translate("date_contrac")}
                                                value={formData.contractDate != null ? moment(formData.contractDate, "YYYY-MM-DDTHH:mm:ssZ") : null}
                                                onChange={m => handleInputChange({ target: { name: "contractDate", value: m?.format("YYYY-MM-DDTHH:mm:ssZ") } })}
                                                slots={{
                                                    textField: (params) => <TextField required margin="normal"
                                                        variant='filled' {...params} />
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <DatePicker
                                                label={context.t.translate("date_start")}
                                                value={formData.startDate != null ? moment(formData.startDate, "YYYY-MM-DDTHH:mm:ssZ") : null}
                                                onChange={m => handleInputChange({ target: { name: "startDate", value: m?.isValid ? m?.format("YYYY-MM-DDTHH:mm:ssZ") : null } })}
                                                slots={{
                                                    textField: (params) => <TextField margin="normal"
                                                        variant='filled' {...params} />
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <DatePicker
                                                label={context.t.translate("date_end")}
                                                value={formData.endDate != null ? moment(formData.endDate, "YYYY-MM-DDTHH:mm:ssZ") : null}
                                                onChange={m => handleInputChange({ target: { name: "endDate", value: m?.isValid ? m?.format("YYYY-MM-DDTHH:mm:ssZ") : null } })}
                                                slots={{
                                                    textField: (params) => <TextField margin="normal"
                                                        variant='filled' {...params} />
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </StyledBox>
                        </Grid>
                    </Grid >
                </Grid >
            </Grid>
            <Grid item xs={12} marginTop={3}>
                <Tabs value={tabIndex} onChange={handleTabChange} centered>
                    {tabs.map((tab, index) => (
                        <Tab key={index} label={tab.label} />
                    ))}

                </Tabs>
            </Grid>
            <Grid item xs={12} padding="30px">
                {tabs[tabIndex]?.content === "custom" && ((formData.type === AssessmentType.CRT && ability.can("*", "Achilles")) || (formData.type === AssessmentType.LogicalPentest && ability.can("*", "Pentest"))) &&
                    <Grid item xs container flexDirection="column" spacing="20px" marginTop="30px">

                        <Grid item container flexDirection="row" rowGap="35px">
                            <Grid xs={6} item container>
                                <StyledBox sx={{ width: "100%" }}>
                                    <CustomizeText assessment={data} onSectionChange={onSectionChange}/>
                                </StyledBox>

                            </Grid>
                            <Grid xs={6} item>
                                {dynamicReport && (
                                    <Grid item style={{ height: "100%", width: "100%" }}>
                                        <PDFViewer style={{ width: "100%", height: "100%" }}>
                                            {dynamicReport as any}
                                        </PDFViewer>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>}
                {tabs[tabIndex]?.content === "vulnerabilities" && (formData.type === AssessmentType.CRT && ability.can("*", "Achilles")
                    ? (
                        <AchillesTable id={id} />
                    )
                    : (
                        <VulnerabilitiesTable vulnStats={vulnStats} id={id} />
                    ))}
                {tabs[tabIndex]?.content === "hardware" && <HardwareComponentTable productID={formData.product.id} />}

            </Grid>
        </Grid>
    )
}

export { AssessmentDetail }
export default AssessmentDetail
