import React, { useEffect, useState } from "react"
import { Document, Font } from "@react-pdf/renderer"
import Assessment, { AssessmentType } from "@models/Assessment"
import Template, { TMetadataSCAContent } from "@models/Template"
import VulnerabilityReportCycloneDX, { VulnerabilityCycloneDX } from "@models/VulnerabilityReport"
import { BackPage } from "./sections/99_BackPage"
import { ExecutiveSection } from "./sections/3_ExecutiveSection"
import { VulnsSection, VulnsSection2 } from "./sections/4_1_VulnsSection"
import { VulnsList } from "./sections"
import { ServicesImg, ServicesSection } from "./sections/5_ServicesSection"
import { SimplifiedIndex } from "./sections/0_Simplified_index"
import { SimplifiedIntro } from "./sections/1_Simplified_intro"
import { SimplifiedFrontPage } from "./sections/0_Simplified_FrontPage"
import { CustomFrontPage } from "./GenericVulnerability/customSections/0_CustomFrontPage"
import CustomContentPage from "./sections/CustomContentPage"

Font.register({ family: "Griff", src: "/assets/fonts/Griff-Regular.otf" })
Font.register({ family: "Bold", src: "/assets/fonts/Griff-Bold.otf" })

const hyphenationCallback = (word: string) => {
    // Devuelve las sílabas de la palabra en un array
    // Este es solo un ejemplo simple y puede necesitar mejoras
    return word.split("-")
}

// Registra la función de separación de sílabas
Font.registerHyphenationCallback(hyphenationCallback)

interface VEXDocumentProps {
    assessment: Assessment,
    template?: Template,
    data: VulnerabilityReportCycloneDX
    simplified?: boolean
}
export const renderDynamicSections = (template: Template, data: VulnerabilityReportCycloneDX) => {
    if (template?.metadata.type === AssessmentType.SCA && template.metadata.data?.table_of_contents) {
        const metadata = template.metadata.data.table_of_contents as TMetadataSCAContent

        return (
            <>
                {metadata.introduction && <SimplifiedIntro template={template} />}
                {metadata.executive_summary && (
                    <ExecutiveSection index="2" vulnerabilities={data.vulnerabilities} template={template} />
                )}
                {metadata.table_of_content && <><ServicesSection template={template} /><ServicesImg template={template}/></>}
                {metadata.scope && <><VulnsSection template={template}/> <VulnsSection2 template={template}/></>}
            </>
        )
    }
    return null
}
const VulnReportDocument: React.FC<VEXDocumentProps> = ({ assessment, template, data, simplified }) => {
    const [orderedVulns, setOrderedVulns] = useState<VulnerabilityCycloneDX[]>([])
    const [orderedLimitedVulns, setOrderedLimitedVulns] = useState<VulnerabilityCycloneDX[]>([])

    useEffect(() => {
        if (data.vulnerabilities) {
            const ordered = [...data.vulnerabilities].sort((a, b) => {
                let rank = 0
                const rankB = b.ratings.find(r => r.source?.name === "ORBIK")?.score
                const rankA = a.ratings.find(r => r.source?.name === "ORBIK")?.score
                if (rankA != null && rankB != null) {
                    rank = rankA - rankB
                }
                if (rank === 0) {
                    rank =
                        (b.ratings.find(r => r.method === "CVSSv3")?.score || 0) -
                        (a.ratings.find(r => r.method === "CVSSv3")?.score || 0)
                }
                return rank
            })
            setOrderedVulns(ordered)
            setOrderedLimitedVulns(ordered.slice(0, 10))
        }
    }, [data])

    return (
        <>
            {!template ||
            template === undefined ||
            template.id === "" ||
            template.id === "00000000-0000-0000-0000-000000000000"
                ? (
                    <Document pageLayout="twoColumnRight">
                        <SimplifiedFrontPage assessment={assessment} />
                        <SimplifiedIndex />
                        <SimplifiedIntro />
                        <ExecutiveSection index="2" vulnerabilities={data.vulnerabilities} />
                        <ServicesSection />
                        <ServicesImg />
                        <VulnsSection />
                        <VulnsSection2 />
                        <VulnsList vulnerabilities={orderedVulns} components={data.components} />
                        <BackPage />
                    </Document>
                )
                : (
                    <Document pageLayout="twoColumnRight">
                        <CustomFrontPage
                            template={template}
                            assessment={{ endDate: new Date().toUTCString() } as Assessment}
                        />
                        <SimplifiedIndex
                            metadata={template.metadata.data.table_of_contents}
                        />
                        {renderDynamicSections(template, data)}
                        <VulnsList vulnerabilities={orderedVulns} components={data.components} template={template} />
                        <CustomContentPage template={template}/>
                        <BackPage template={template}/>
                    </Document>
                )}
        </>
    )
}

export { VulnReportDocument }
export default VulnReportDocument
