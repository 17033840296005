import React from "react"
import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material"
import DynamicSection from "./DynamicSection"

export const serviceCategories = [
    "Protocolos de la capa de aplicación",
    "Protocolos de la capa de presentación",
    "Protocolos de la capa de sesión",
    "Protocolos de la capa de transporte",
    "Protocolos de la capa red",
    "Protocolos de la capa enlace de datos",
    "Protocolos de la capa física"
]

interface SystemDiscoverySectionProps {
  selectedCategory: string;
  servicesData: Record<string, any[]>; // Datos de los servicios
  extraInfo: string;
  applicationsData: { activo: string; aplicacion: string }[]; // Datos de aplicaciones
  technologyData: { tecnologia: string; implementacion: string }[]; // Datos de tecnología
  onCategoryChange: (event: SelectChangeEvent<string>) => void;
  onUpdateCategoryData: (category: string, updatedData: any[]) => void;
  onUpdateApplications: (updatedData: any[]) => void;
  onUpdateTechnology: (updatedData: any[]) => void;
  updateExtraInfo: (value: string) => void;
  onBlur?: () => void;
}

const SystemDiscoverySection: React.FC<SystemDiscoverySectionProps> = ({
    selectedCategory,
    servicesData,
    extraInfo,
    applicationsData,
    technologyData,
    onCategoryChange,
    onUpdateCategoryData,
    onUpdateApplications,
    onUpdateTechnology,
    updateExtraInfo,
    onBlur
}) => {
    return (
        <Grid item container flexDirection="column" spacing="20px" rowGap="25px">
            <h3>Services</h3>
            <FormControl variant="filled" fullWidth>
                <InputLabel id="service-category-selector">Seleccionar Categoría</InputLabel>
                <Select
                    labelId="service-category-selector"
                    value={selectedCategory}
                    onChange={onCategoryChange}
                >
                    {serviceCategories.map((category) => (
                        <MenuItem key={category} value={category}>
                            {category}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            {selectedCategory && servicesData[selectedCategory] && (
                <DynamicSection
                    key={selectedCategory}
                    sectionTitle={selectedCategory}
                    sectionData={servicesData[selectedCategory]}
                    fields={[
                        { label: "Puerto", key: "puerto", type: "number", maxLength: 50 },
                        { label: "Protocolo", key: "protocolo", maxLength: 50, type: "no-special" },
                        { label: "Estado", key: "estado", maxLength: 50 },
                        { label: "CPE", key: "cpe", maxLength: 50 },
                        { label: "Comentarios", key: "comentarios", maxLength: 50, type: "comments" }
                    ]}
                    onUpdate={(updatedData) => onUpdateCategoryData(selectedCategory, updatedData)}
                    onBlur={onBlur}
                />
            )}
            <TextField
                label="Add more information (optional)"
                value={extraInfo}
                onChange={(e) => { updateExtraInfo(e.target.value) }}
                onBlur={(e) => onBlur}
                variant="filled"
                fullWidth
                multiline={true}
                rows={3}
            />
            <DynamicSection
                sectionTitle="Aplicaciones"
                sectionData={applicationsData}
                fields={[
                    { label: "Activo", key: "activo" },
                    { label: "Aplicación", key: "aplicacion" }
                ]}
                onUpdate={onUpdateApplications}
                onBlur={onBlur}
            />

            <DynamicSection
                sectionTitle="Tecnología"
                sectionData={technologyData}
                fields={[
                    { label: "Tecnología", key: "tecnologia" },
                    { label: "Implementación", key: "implementacion" }
                ]}
                onUpdate={onUpdateTechnology}
                onBlur={onBlur}
            />
        </Grid>
    )
}

export default SystemDiscoverySection
