import React from "react"
import { useTheme } from "@mui/material"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import CancelIcon from "@mui/icons-material/Cancel"

interface BooleanProps {
    value: boolean;
}

const KevCheckbox: React.FC<BooleanProps> = ({ value }) => {
    const theme = useTheme()

    return (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            {value
                ? (
                    <CheckCircleIcon
                        style={{
                            color: theme.palette.success.main,
                            fontSize: "1.5rem"
                        }}
                    />
                )
                : (
                    <CancelIcon
                        style={{
                            color: theme.palette.error.main,
                            fontSize: "1.5rem"
                        }}
                    />
                )}
        </div>
    )
}

export { KevCheckbox }
export default KevCheckbox
