import React, { useContext } from "react"
import { Font, Page, Text, View } from "@react-pdf/renderer"
import Template from "@models/Template"
import { I18nContext } from "I18nProvider"
import Vulnerability from "@models/Vulnerability"
import RenderContent from "@views/vulnerability/RenderContent"
import { globalStyles } from "../Styles"
import { Footer, Header } from "./HeaderFooter"
Font.register({ family: "Bold", src: "/assets/fonts/Griff-Bold.otf" })
interface introProps {
    sufix: string;
    columns: {
        identifier: string;
        name: string;
        criticity: string;
        cvss: string;
        affected_asset: string;
    };
    rows: {
        description: string;
        solution: string;
    };
    references: string;
}

const i18n: {
    es: introProps;
    en: introProps;
} = {
    es: {
        sufix: "VULN - ",
        columns: {
            identifier: "Identificador",
            name: "Nombre",
            criticity: "Criticidad",
            cvss: "CVSS",
            affected_asset: "Activo afectado"
        },
        rows: {
            description: "Descripción",
            solution: "Solución"

        },
        references: "Referencias"
    },
    en: {
        sufix: "VULN - ",
        columns: {
            identifier: "Identifier",
            name: "Name",
            criticity: "Criticity",
            cvss: "CVSS",
            affected_asset: "Affected asset"
        },
        rows: {
            description: "Description",
            solution: "Solution"
        },
        references: "References"
    }
}

interface SectionProps {
    lang?: string;
    template?: Template;
    client?: string
    vulnerabilities: Vulnerability[];
}

const VulnSection: React.FC<SectionProps> = ({ lang, template, vulnerabilities, client }) => {
    // Constant
    const context = useContext(I18nContext)
    // --------------------------------------------------

    // lenguaje
    type I18nType = typeof i18n
    let locale: keyof I18nType = "es"
    if (lang) {
        if (lang === "es" || lang === "en") {
            locale = lang as keyof I18nType
        } else {
            console.warn(`Idioma no soportado: ${lang}. Usando idioma por defecto.`)
        }
    } else if (context) {
        if (context.language === "es" || context.language === "en") {
            locale = context.language as keyof I18nType
        } else {
            console.warn(`Idioma no soportado en el contexto: ${context.language}. Usando idioma por defecto.`)
        }
    } else {
        console.error(
            "El contexto I18n no está inicializado. Asegúrese de que tiene el proveedor configurado correctamente."
        )
    }
    // --------------------------------------------------
    return (
        <Page size="A4" style={globalStyles.page}>
            <Header externalData={null} template={template}></Header>
            <View style={{ ...globalStyles.section }}>
                <View style={globalStyles.container}>
                    {vulnerabilities.map((vuln, index) => (
                        <View key={index} style={globalStyles.container} wrap={false}>
                            <Text id={"VULN-" + index} style={globalStyles.title}>
                                {`${i18n[locale].sufix + index}. ${vuln.metadata?.name || ""}`}
                            </Text>
                            <View style={{ ...globalStyles.table, marginTop: "10px" }} wrap={false}>
                                <View style={{ ...globalStyles.row }} wrap={false}>
                                    <View style={{ ...globalStyles.col, backgroundColor: "yellow", flex: 0.5 }}>
                                        <Text style={globalStyles.textoBold}>{i18n[locale].columns.identifier}</Text>
                                    </View>
                                    <View style={{ ...globalStyles.col }}>
                                        <Text style={globalStyles.texto}>{` ${vuln.identifier} `}</Text>
                                    </View>
                                </View>
                                <View style={{ ...globalStyles.row }} wrap={false}>
                                    <View style={{ ...globalStyles.col, backgroundColor: "yellow", flex: 0.5 }}>
                                        <Text style={globalStyles.textoBold}>{i18n[locale].columns.name}</Text>
                                    </View>
                                    <View style={{ ...globalStyles.col }}>
                                        <Text style={globalStyles.texto}>{vuln.metadata?.name}</Text>
                                    </View>
                                </View>
                                <View style={{ ...globalStyles.row }} wrap={false}>
                                    <View style={{ ...globalStyles.col, backgroundColor: "yellow", flex: 0.5 }}>
                                        <Text style={globalStyles.textoBold}>{i18n[locale].columns.criticity}</Text>
                                    </View>
                                    <View style={{ ...globalStyles.col }}>
                                        <Text style={globalStyles.texto}>{vuln.revised_score}</Text>
                                    </View>
                                </View>
                                <View style={{ ...globalStyles.row }} wrap={false}>
                                    <View style={{ ...globalStyles.col, backgroundColor: "yellow", flex: 0.5 }}>
                                        <Text style={globalStyles.textoBold}>{i18n[locale].columns.cvss}</Text>
                                    </View>
                                    <View style={{ ...globalStyles.col }}>
                                        <Text style={globalStyles.texto}>{vuln.vector}</Text>
                                    </View>
                                </View>
                                <View style={{ ...globalStyles.row }} wrap={false}>
                                    <View style={{ ...globalStyles.col, backgroundColor: "yellow", flex: 0.5 }}>
                                        <Text style={globalStyles.textoBold}>{i18n[locale].columns.affected_asset}</Text>
                                    </View>
                                    <View style={{ ...globalStyles.col }}>
                                        <Text style={globalStyles.texto}>{vuln.affected_component}</Text>
                                    </View>
                                </View>
                                <View style={{ ...globalStyles.row, backgroundColor: "yellow" }} wrap={false}>
                                    <View style={{ ...globalStyles.col }}>
                                        <Text style={globalStyles.textoBold}>{i18n[locale].rows.description}</Text>
                                    </View>
                                </View>
                                <View style={{ ...globalStyles.row }} wrap={false}>
                                    <View style={{ ...globalStyles.col }}>
                                        <RenderContent description={vuln.description} />
                                    </View>
                                </View>
                                <View style={{ ...globalStyles.row, backgroundColor: "yellow" }} wrap={false}>
                                    <View style={{ ...globalStyles.col }}>
                                        <Text style={globalStyles.textoBold}>{i18n[locale].rows.solution}</Text>
                                    </View>
                                </View>
                                <View style={{ ...globalStyles.row }} wrap={false}>
                                    <View style={{ ...globalStyles.col }}>
                                        <Text style={globalStyles.texto}>{vuln.solution}</Text>
                                    </View>
                                </View>

                                <View style={{ ...globalStyles.row }} wrap={false}>
                                    <View style={{ ...globalStyles.col, backgroundColor: "yellow", flex: 0.5 }}>
                                        <Text style={globalStyles.textoBold}>{i18n[locale].references}</Text>
                                    </View>
                                    <View style={{ ...globalStyles.col }}>
                                        <Text style={globalStyles.texto}>{vuln.references}</Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                    ))}
                </View>
            </View>
            <Footer externalData={{ clientName: client }} template={template}></Footer>
        </Page>
    )
}

export { VulnSection }
