import { Box, Grid, IconButton, MenuItem, Switch, TextField, Tooltip, Typography, useTheme } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { ClientSelector } from "@components/client/index"
import { ServicesContext } from "@context/index"
import { Product, ProductType } from "@models/index"
import { ProductTypeColors } from "@components/common/colors/ProductTypeColors"
import { FilterOperation, FilterOption } from "@utils/queryParams"
import { MAX_DEEP_LEVEL, PATH_SEPARATOR } from "@views/hierarchy/hierarchyUtils"
import HierarchySelector from "@components/hierarchy/HierarchySelector"
import { I18nContext } from "I18nProvider"
import Hierarchy from "@models/Hierarchy"
import { ProductTypeKeys } from "@components/product/ProductTableDefinition"
import ActionButton from "@components/common/Button/ActionButton"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import { AbilityContext, Can } from "@components/permissions"
import { useTrack } from "@components/track/TrackContext"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
export const getDescriptionForType = (type: ProductType): string => {
    const descriptions = {
        [ProductType.Application]: "A product of the “Application” type refers to a software application that runs on an operating system or a specific environment. These products are designed to perform specific tasks and can include web, desktop, or mobile applications.",
        [ProductType.Library]: "A product of the “Library” type is a code library that is used to add specific functionalities to other applications or products. Libraries are not executable by themselves but provide functions, classes, or methods that can be used by applications integrating them.",
        [ProductType.Firmware]: "A product of the “Firmware” type refers to specialized software integrated into hardware devices. Firmware is designed to control and manage specific hardware and is commonly found in electronic devices such as routers, printers, cameras, and more.",
        [ProductType.OS]: "A product of the “Operating System” type refers to a complete operating system, such as Linux, Windows, macOS, etc. The operating system manages the hardware and system resources, allowing applications and users to interact with the device.",
        [ProductType.Container]: "A product of the “Container” type refers to a software unit that packages an application and its dependencies into a single container. This allows applications to run consistently in any environment, whether local or in the cloud. Containers are popular in microservices-based infrastructures.",
        [ProductType.Device]: "A product of the “Device” type refers to a physical device, such as a server, computer, IoT (Internet of Things) device, etc. These devices may be subject to firmware updates or interact with specific applications and operating systems."

    }
    return descriptions[type] || ""
}
const initValue: Product = {
    id: "",
    name: "",
    type: ProductType.Application,
    description: "",
    version: "",
    hierarchy: "",
    supressed: false,
    inheritance: false,
    client: { id: "00000000-0000-0000-0000-000000000000", name: "" },
    created_at: ""
}

const ProductForm: React.FC = () => {
    // Constants
    const context = useContext(I18nContext)
    if (context === null) throw new Error("The I18n context is not initialized. Make sure you have the provider set up correctly.")
    const theme = useTheme()
    const productService = useContext(ServicesContext).productService
    const [error, setError] = useState<Error|null>(null)
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const ability = useContext(AbilityContext)
    const [formData, setFormData] = useState<Product>(initValue)
    const navigate = useNavigate()
    const [selectedHierarchies, setSelectedHierarchies] = useState<(Hierarchy | null)[]>(Array.from({ length: MAX_DEEP_LEVEL }, () => null))
    const { track, trackData } = useTrack()

    // --------------------------------------------------
    // useEffect
    useEffect(() => {
        track({ view: "ProductForm" })
    }, [])
    // --------------------------------------------------
    // Functions
    // Generic functions
    const handleInputChange = (e: any) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }
    const formValid = (): boolean => {
        const isNotNullrules = [
            formData.name === "",
            formData.version === "",
            formData.description === "",
            formData.client.id === null
        ]
        ability.can("*", "Client") ?? isNotNullrules.push(formData.client.id === "00000000-0000-0000-0000-000000000000", formData.client.id === "")
        if (isNotNullrules.some(rule => rule)) {
            return false
        }
        return true
    }
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        try {
            await productService.create(formData)
            setError(null)
            navigate(-1)
        } catch (e: any) {
            setError({ message: e.error } as Error)
            setSnackbarOpen(true)
        }
    }
    // Hierarchy functions
    const handleSelectorChange = (index: number, id: string | undefined, hierarchy: Hierarchy | null) => {
        const newSelectedHierarchies = [...selectedHierarchies]
        newSelectedHierarchies[index] = hierarchy
        newSelectedHierarchies.fill(null, index + 1, MAX_DEEP_LEVEL)
        setSelectedHierarchies(newSelectedHierarchies)

        const newPath = newSelectedHierarchies.filter(h => h !== null).map(h => h!.id).join(";")
        setFormData({ ...formData, hierarchy: newPath })
    }
    const getCustomFilters = (index: number): FilterOption[] | undefined => {
        if (index === 0) {
            if (ability.can("*", "Client")) {
                if (formData.client.id !== "00000000-0000-0000-0000-000000000000") {
                    return [
                        { field: "client_id", operation: FilterOperation.UUIDEqual, value: formData.client.id },
                        { field: "path", operation: FilterOperation.StringNotContains, value: `%${PATH_SEPARATOR}%` }
                    ]
                }
                return undefined
            }
            return [{ field: "path", operation: FilterOperation.StringNotContains, value: `%${PATH_SEPARATOR}%` }]
        }
        const parentHierarchy = selectedHierarchies[index - 1]
        if (!parentHierarchy) return []
        return [
            { field: "path", operation: FilterOperation.StringContains, value: `${parentHierarchy.path}${PATH_SEPARATOR}%` },
            { field: "path", operation: FilterOperation.StringNotContains, value: `${parentHierarchy.path}${PATH_SEPARATOR}%${PATH_SEPARATOR}%` }
        ]
    }
    const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
        setFormData({ ...formData, [name]: e.target.checked })
    }
    // --------------------------------------------------

    return (
        <Box sx={{ width: "100%" }}>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1, width: "50%", alignSelf: "center", margin: "50px auto 50px auto" }}>
                <CustomSnackbar
                    open={snackbarOpen}
                    onClose={() => setSnackbarOpen(false)}
                    message={error?.message || "An error occurred."}
                />
                <Can key="showClient" I="*" a="Client">
                    <Grid item container flexDirection="row" alignItems='center' spacing="40px">
                        <Grid item xs={12}>
                            <ClientSelector value={formData.client.id} onSelect={(id) => handleInputChange({ target: { name: "client", value: { id } } })}></ClientSelector>
                        </Grid>
                    </Grid>
                </Can>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <TextField margin="normal" required fullWidth variant="filled" label={context.t.translate("product_newname")} name="name"
                            value={formData.name} onChange={handleInputChange}/>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField margin="normal" required fullWidth variant="filled" label={context.t.translate("product_version")} name="version"
                            value={formData.version} onChange={handleInputChange}/>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            margin="normal"
                            select
                            required
                            fullWidth
                            variant="filled"
                            label={context.t.translate("type")}
                            name="type"
                            value={formData.type}
                            onChange={handleInputChange}
                            SelectProps={{
                                renderValue: (selected: unknown) => {
                                    // Aseguramos que el valor seleccionado tenga un tipo explícito
                                    const selectedValue = selected as ProductType

                                    // Encontrar la clave (mayúsculas) correspondiente al valor seleccionado
                                    const selectedKey = ProductTypeKeys.find(key => ProductType[key] === selectedValue)

                                    return (
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                width: "100%"
                                            }}
                                        >
                                            <Typography
                                                fontFamily="Griff"
                                                fontWeight="bolder"
                                                sx={{ color: ProductTypeColors[selectedValue] }}
                                            >
                                                {selectedKey}
                                            </Typography>
                                            <Tooltip title={getDescriptionForType(selectedValue)}>
                                                <IconButton size="small">
                                                    <HelpOutlineIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    )
                                }
                            }}
                        >
                            {ProductTypeKeys.map((key, index) => (
                                <MenuItem
                                    key={index}
                                    value={ProductType[key]}
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between"
                                    }}
                                >
                                    <Typography
                                        fontFamily="Griff"
                                        fontWeight="bolder"
                                        sx={{ color: ProductTypeColors[ProductType[key]] }}
                                    >
                                        {key} {/* Mostrar la clave del enum */}
                                    </Typography>
                                    <Tooltip title={getDescriptionForType(ProductType[key])}>
                                        <IconButton size="small">
                                            <HelpOutlineIcon />
                                        </IconButton>
                                    </Tooltip>
                                </MenuItem>
                            ))}
                        </TextField>

                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={8}>
                        <TextField margin="normal" required fullWidth variant="filled" label={context.t.translate("product_description")} name="description"
                            value={formData.description} onChange={handleInputChange}/>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography>{context.t.translate("supressed")}</Typography>
                        <Switch
                            checked={formData.supressed}
                            onChange={(e) => handleSwitchChange(e, "supressed")}
                            name="supressed"
                        />
                    </Grid>
                </Grid>
                <Grid>
                    <HierarchySelector
                        value={selectedHierarchies[0]?.id || ""}
                        customFilters={getCustomFilters(0)}
                        onSelect={(id, hierarchy) => handleSelectorChange(0, id, hierarchy)}
                        index={0}
                    />
                    {selectedHierarchies[0] && (
                        <HierarchySelector
                            value={selectedHierarchies[1]?.id || ""}
                            customFilters={getCustomFilters(1)}
                            onSelect={(id, hierarchy) => handleSelectorChange(1, id, hierarchy)}
                            index={1}
                        />
                    )}
                    {selectedHierarchies[1] && (
                        <HierarchySelector
                            value={selectedHierarchies[2]?.id || ""}
                            customFilters={getCustomFilters(2)}
                            onSelect={(id, hierarchy) => handleSelectorChange(2, id, hierarchy)}
                            index={2}
                        />
                    )}
                    {selectedHierarchies[2] && (
                        <HierarchySelector
                            value={selectedHierarchies[3]?.id || ""}
                            customFilters={getCustomFilters(3)}
                            onSelect={(id, hierarchy) => handleSelectorChange(3, id, hierarchy)}
                            index={3}
                        />
                    )}
                </Grid>
                <ActionButton type="submit" disabled={!formValid()} onClick={() => {}} text={context.t.translate("product_newproduct")} style={{ width: "100%" }}/>

            </Box>
        </Box>
    )
}

export { ProductForm }
export default ProductForm
