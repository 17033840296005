import React, { useContext } from "react"
import { Route, Routes } from "react-router-dom"
import { AbilityContext } from "@components/permissions/Can"
import ComponentList from "./ComponentList"
import ComponentDetail from "./ComponentDetail"
import ComponentForm from "./ComponentForm"

const entity = "Component"
const Component: React.FC = () => {
    const ability = useContext(AbilityContext)
    const routes = []

    if (ability.can("read", entity)) {
        routes.push(...[
            {
                route: "",
                component: <ComponentList/>
            }
        ])
    }

    if (ability.can("update", entity)) {
        routes.push(...[
            {
                route: ":componentId",
                component: <ComponentDetail/>
            }
        ])
    }

    if (ability.can("create", entity)) {
        routes.push(...[
            {
                route: "/add",
                component: <ComponentForm/>
            }
        ])
    }

    return (
        <Routes>
            {
                routes.map((item, idx) => (
                    <Route key={idx} path={item.route} element={item.component} />
                ))
            }
        </Routes>
    )
}

export { Component }
export default Component
