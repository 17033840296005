const es = {
    languages: {
        en: "Inglés",
        es: "Español"
    },
    settings: {
        languageChange: "Cambiar idioma",
        languageCurrent: "Idioma seleccionado"
    },
    addMessage: "Recibir un mensaje",
    messages: {
        one: "Tienes un mensaje",
        other: "Tienes {count} mensajes"
    },
    // dashboard
    dash_assess_finished: "Pruebas finalizadas",
    dash_vulner_mitigated: "Vulnerabilidades mitigadas",
    dash_vulner_nomitigated: "Vulnerabilidades no mitigadas",
    dash_assess: "Evaluaciones",
    dash_vulner: "Vulnerabilidades",
    dash_product: "Productos",
    dash_assess_byproduct: "Por producto",
    dash_assess_bystatus: "Por estado",
    dash_assess_bytype: "Por tipo",
    dash_vulner_criticity: "Criticidad",
    dash_vulner_state: "Estado",
    dash_vulner_mitigaciones: "Mitigaciones",
    dash_product_bytype: "Por estado",
    dash_export: "Exportar",
    dash_vulner_title: "Vulnerabilidades mitigadas frente a vulnerabilidades no mitigadas",
    // Products
    product_newproduct: "Nuevo producto",
    product_newtag: "Administrar etiquetas",
    product_name: "Nombre",
    product_version: "Version",
    product_type: "Tipo",
    product_description: "Descripción",
    product_client: "Nombre del cliente",
    product_descrip: "Descripcion del producto",
    product_save: "Guardar cambios",
    product_tag: "Buscar por etiqueta",
    product_addtag: "Añadir etiqueta",
    product_createtag: "Crear etiqueta",
    product_color: "Color",
    product_newname: "Nombre del producto",
    product_details: "Detalles",
    // Assessment
    assess_noption: "Sin opciones",
    asess_template_name: "Nombre de la plantilla",
    assess_status: "Estado",
    assess_date_contrac: "Fecha del contrato",
    assess_date_start: "Fecha de inicio",
    assess_date_end: "Fecha de finalización",
    assess_new: "Añadir nueva evaluación",
    assess_close: "Cerrar",
    assess_comp: "Componentes",
    assess_scan: "Escaneado en",
    assess_quali: "Puntuación de calidad",
    assess_metric: "Métricas de calidad del SBOM",
    // Benchmark
    bench_sector: "Sector",
    bench_criti: "Criticidad",
    // CRA compliance
    CRA_title: "Test de Normativa",
    CRA_edit: "Editar texto",
    CRA_save: "Guardar",
    CRA_cancel: "Salir",
    CRA_conclusion: "Conclusión",
    CRA_resume: "Reanudar cuestionario",
    CRA_start: "Iniciar cuestionario ",
    CRA_view: "Ver respuestas",
    CRA_quiz: "Cuestionario",
    CRA_details: "Detalles",
    CRA_total: "Puntuación total",
    CRA_closetext: "Cancelar",
    CRA_text2: "No puedes deshacer esta operación",
    CRA_noquiz: "No has realizado ningún cuestionario hasta ahora.",
    CRA_noquiz2: "Realiza nuestro cuestionario para conocer tu puntuación de cumplimiento CRA",
    pending: "reguntas pendientes",
    // Textos de views/assessment
    customize_this: "Personaliza estas secciones para tu evaluación",
    // Textos de views/assessment/components
    there_are_no: "No hay vulnerabilidades mitigadas",
    // Textos de views/assessment/cumstomactions
    realanalyze: "Reanalizar",
    SBOM_metrics: "Métricas de SBOM",
    components: "Componentes",
    SBOM_quality: "Métricas de calidad del SBOM",
    quality_score: "Puntuación de calidad",
    scanned_at: "Escaneado el",
    file_uploaded: "Archivo subido con éxito. Estado cambiado correctamente, por favor refresque la página.",
    succes: "éxito",
    customize_text: "Personalizar texto",
    // Textos de views/assessment/AssesmentCustomText.tsx
    custom_text: "¡Texto personalizado borrado con éxito!",
    error_saving: "Error al guardar el texto personalizado.",
    error_clearing: "Error al borrar el texto personalizado.",
    introduction: "Introducción",
    scope1: "Alcance",
    test_initialization: "Inicialización de Pruebas",
    conclusions: "Conclusiones",
    // Textos de views/assessment/modals
    max_height: "altura máxima 0.3s facilidad de entrada y salida",
    // Textos de views/assessment/AssessmentsDetail
    error_generating: "Error al generar el informe CRT",
    date_start: "Fecha de inicio",
    date_end: "Fecha de finalización",
    date_contrac: "Fecha del contrato",
    upload_sbom: "Subir SBOM",
    report: "Informe",
    original: "Original",
    assessment_section: "Sección de Evaluación",
    unsupported_file: "Tipo de archivo no soportado",
    wrong_file: "Se ha subido un tipo de archivo incorrecto",
    an_error: "Ocurrió un error.",
    add_new: "Añadir nueva evaluación",
    error_downloading: "Error al descargar el archivo:",
    error_downloading_report: "Error al descargar el archivo del informe.",
    error_updating: "Error al actualizar el estado de la evaluación y la fecha de inicio:",
    failed_to_update: "No se pudo actualizar el estado de la evaluación y la fecha de inicio.",
    invalid_date: "Fecha inválida",
    error_testing: "Error en la prueba",
    add_new_assess: "Añadir nueva evaluación",
    reanalyze: "Reanalizar",
    analysis_request: "¡Solicitud de análisis exitosa!",
    // Textos de views/assessment/CVSSCalculator.tsx
    cvvs_3_1_calculator: "Calculadora CVSS 3.1",
    base: "Base",
    temporal: "Temporal",
    environmental: "Ambiental",
    overall: "General",
    cvss_vector: "Vector CVSS v3.1",
    information_about: "Información sobre CVSS",
    base_score: "Métricas de Puntuación Base",
    temporal_score: "Puntuación Temporal",
    environmental_score: "Métricas de Puntuación Ambiental",
    exploitability: "Métricas de Explotabilidad",
    attack_vector: "Vector de Ataque (AV)*",
    attack_complexity: "Complejidad del Ataque (AC)*",
    pribileges_required: "Privilegios Requeridos (PR)*",
    user_interaction: "Interacción del Usuario (UI)*",
    scope: "Alcance (S)*",
    impact_metrics: "Métricas de Impacto",
    confidentiality_imparct: "Impacto en la Confidencialidad (C)*",
    integrity_impact: "Impacto en la Integridad (I)*",
    availability_impact: "Impacto en la Disponibilidad (A)*",
    exploit_code: "Madurez del Código de Explotación (E)",
    remediation_level: "Nivel de Remediación (RL)",
    report_confidence: "Confianza en el Reporte (RC)",
    exploitability_metrics: "Métricas de Explotabilidad",
    attack_vector_m: "Vector de Ataque (MAV)",
    attack_complexity_m: "Complejidad del Ataque (MAC)",
    privileges_required: "Privilegios Requeridos (MPR)",
    user_interaction_m: "Interacción del Usuario (MUI)",
    scope_ms: "Alcance (MS)",
    confidentiality_imparct_m: "Impacto en la Confidencialidad (MC)",
    integrity_impact_m: "Impacto en la Integridad (MI)",
    availability_impact_m: "Impacto en la Disponibilidad (MA)",
    impact_subscore: "Modificadores del Subpuntaje de Impacto",
    confidentiality_requirement: "Requisito de Confidencialidad (CR)",
    integrity_requirement: "Requisito de Integridad (IR)",
    availability_requirement: "Requisito de Disponibilidad (AR)",
    // Textos de views/assessment/SBOMEvolution.tsx
    sbom_evolution: "Evolución de SBoM",
    back: "atrás",
    outdated_only: "Solo desactualizados",
    product_comparation: "Comparación de productos",
    // Textos de views/assessment/SBOMQuality.tsx
    download_sbom: "Descargar SBoM",
    score_summary: "Resumen de Puntuación",
    // Textos de views/assessment/SunburstView.tsx
    level: "nivel",
    item: "ítem",
    // Textos de views/component
    component_edit_section: "Sección de edición de componente",
    reference: "Referencia",
    supressed: "Suprimido",
    add_new_component: "Agregar nuevo componente",
    outdated: "Desactualizado",
    supressed_only: "Solo suprimido",
    add_component: "Agregar componente",
    error_adding_cpe: "Error al agregar CPE",
    hardware: "Hardware",
    software: "Software",
    add_vulnerability: "Agregar vulnerabilidad",
    there_are_no_unmitigated: "No hay vulnerabilidades sin mitigar",
    // Textos de apartado views/client
    licenses: "Licencias",
    add_new_client: "Agregar nuevo cliente",
    user_logs: "Registros de usuario",
    // Textos de apartado views/compliance
    no_data_found: "No se encontraron datos",
    open: "Abrir",
    saved_successfully: "Guardado con éxito",
    is_compliant: "¿Es conforme?",
    true: "Verdadero",
    false: "Falso",
    next: "Siguiente",
    send_the_results: "Enviar los resultados",
    congratulations: "Felicidades, completaste todas las preguntas del cuestionario",
    do_you_want: "¿Deseas enviar los resultados?",
    we_will_send: "Te enviaremos el informe con nuestras conclusiones.",
    sla_management: "Gestión de SLA",
    failed_to_fetch: "Error al obtener la jerarquía",
    unsupported_operator: "Operador no compatible:",
    total_vulns: "Vulnerabilidades totales",
    edit: "Editar",
    hierarchy: "Jerarquía",
    failed_to_save: "Error al guardar la jerarquía",
    failed_to_delete: "Error al eliminar la jerarquía",
    delete_node: "Eliminar nodo",
    are_you_sure: "¿Estás seguro de que deseas eliminar el elemento seleccionado y sus ",
    children: " hijos?",
    are_you_sure_delete: "¿Estás seguro de que deseas eliminar el nodo seleccionado?",
    identity_status: "Estado de identidad",
    breakdown_levels: "Niveles de desglose",
    product_categories: "Categorías de producto",
    manufacturers: "Fabricantes",
    update_status: "Actualizar estado",
    user_sessions: "Sesiones de usuario",
    login_in_last: "Inicios de sesión en los últimos 2 días:",
    users_in_last: "Usuarios en los últimos 2 días:",
    dashboard: "Tablero",
    issue_management: "Gestión de problemas",
    jira_configuration: "Configuración de Jira guardada con éxito",
    jira_token_not: "Token de Jira no establecido",
    jira_webhook: "Secreto del webhook de Jira no establecido",
    error_fetching: "Error al obtener datos del cliente:",
    jira_project: "Proyecto de Jira creado con éxito",
    jira_project_restored: "Proyecto de Jira restaurado con éxito",
    // Textos que se encuentran en el apartado de dashboard:
    assessments: "Evaluaciones",
    vulnerabilities: "Vulnerabilidades",
    vuln_details: "Detalles de vulnarabilidad",
    products: "Productos",
    finished_tests: "Tests finalizados",
    mitigated_vulnerabilities: "Vulnerabilidades mitigadas",
    unmitigated_vulnerabilities: "Vulnerabilidades no mitigadas",
    by_product: "Por producto",
    by_status: "Por estado",
    criticity: "Criticidad",
    by_type: "Por tipo",
    by_state: "Por estado",
    mitigations: "Mitigaciones",
    mitigated_vs_unmitigated: "Vulnerabilidades mitigadas vs no mitigadas",
    number_of_vulnerabilities: "Número de vulnerabilidades",
    critical: "Crítico",
    high: "Alto",
    medium: "Medio",
    low: "Bajo",
    none: "Ninguno",
    mitigated: "Mitigados",
    unmitigated: "No mitigados",
    evolution_of_vulnerabilities: "Evolución de vulnerabilidades",
    daily: "Diario",
    yearly: "Anual",
    monthly: "Mensual",
    // Textos de views/assessment/Components/SecurityAdvisoriesTable
    securityAdvisories: "Productos afectados y solución",
    affectedVersions: "Affected versions",
    remediation: "Corrección",
    details: "Detalles",
    sourceDocument: "Documento fuente de Security advisory",
    // textos de views/pentest
    pentest_added: "Pentest agregado con éxito",
    pentest_assessment: "Evaluación de Pentest",
    create_the_list: "Crea la lista de evidencias que deseas agregar al Pentest",
    origin: "Origen",
    identifier: "Identificador",
    score: "Puntuación",
    solution: "Solución",
    references: "Referencias",
    metadata: "Metadatos",
    component_details: "Detalles del componente",
    add_new_evidence: "Agregar nueva evidencia",
    authentication_api_vulnerability: "Vulnerabilidad en la API de autenticación",
    the_authentication_api: "La API de autenticación permite a los atacantes realizar ataques de inyección SQL.",
    sesitive_data_can: "Los datos sensibles pueden ser comprometidos debido a una validación insuficiente de la entrada del usuario.",
    implememt_proper_input: "Implementa una validación adecuada de las entradas y usa declaraciones preparadas en las consultas SQL.",
    information_leakage: "Fuga de información a través de los registros del servidor",
    server_logs: "Los registros del servidor contienen información sensible, como credenciales de base de datos y tokens de autenticación.",
    unauthorized_access: "El acceso no autorizado a los registros podría comprometer la seguridad del sistema.",
    review_server: "Revisa la configuración del servidor para asegurarte de que los registros no contengan información sensible y aplica los permisos adecuados.",
    cloud_storage_service_vulnerability: "Vulnerabilidad en el servicio de almacenamiento en la nube",
    cloud_storage_access_permissions: "Los permisos de acceso al almacenamiento en la nube permiten a usuarios no autorizados acceder a archivos privados.",
    confidential_company_data: "Los datos confidenciales de la empresa pueden ser exfiltrados debido a permisos mal configurados.",
    reconfigure_file_access: "Reconfigura los permisos de acceso a los archivos y aplica el principio de privilegio mínimo para el acceso a recursos en la nube.",
    pentest_added_successfully: "Pentest agregado con éxito.",
    download_pdf_report: "Descargar informe en PDF",
    add_pentest: "Agregar Pentest",
    list_of_current_pentest: "Lista de pentests actuales",
    identificator: "Identificador:",
    view_details: "Ver detalles",
    confirm_delete: "Confirmar eliminación",
    are_you_sure_you_want: "¿Estás seguro de que deseas eliminar este elemento?",
    delete: "Eliminar",
    affected: "Afectado:",
    ui_preferences_and_language: "Preferencias de interfaz y idioma",
    components_licenses: "Licencias de componentes",
    quantity: "Cantidad",
    historic: "Histórico",
    expired_licenses: "Licencias caducadas",
    iheritance: "Herencia",
    enter_description: "Introduce la descripción aquí...",
    components_with_this: "componente(s) con esta licencia caducaron el",
    unnamed_license: "Licencia sin nombre",
    expired_on: "Caducada el",
    yellow: "Amarillo",
    red: "Rojo",
    green: "Verde",
    blue: "Azul",
    error_fetching_tags: "Error al obtener etiquetas:",
    tags_created_successfully: "Etiquetas creadas con éxito",
    error_creating_tags: "Error al crear etiquetas:",
    error: "Error",
    success: "Éxito",
    tag_deleted_successfully: "Etiqueta eliminada con éxito",
    error_deleting_tag: "Error al eliminar la etiqueta:",
    create_tag: "Crear etiqueta",
    delete_tag: "Eliminar etiqueta",
    manage_tags: "Gestionar etiquetas",
    tag_name: "Nombre de la etiqueta",
    existing_tags: "Etiquetas existentes",
    reakdown_levels: "Niveles de desglose",
    versions: "Versiones",
    provisioning: "Provisionamiento",
    reprovisioning: "Reprovisionamiento",
    out: "Fuera",
    decommisioned: "Retirado",
    assessment_type: "Tipo de evaluación",
    unnamed_hierarchy: "Jerarquía sin nombre",
    no_description_available: "No hay descripción disponible",
    unnamed_product: "Producto sin nombre",
    electronics: "Electrónica",
    all_electronic_devices: "Todos los dispositivos electrónicos y accesorios",
    laptops: "Portátiles",
    various_kinds_of_laptops: "Varios tipos de portátiles",
    smartphones: "Teléfonos inteligentes",
    mobile_phones_and_accessories: "Teléfonos móviles y accesorios",
    gaming_laptops: "Portátiles para juegos",
    high_performance_laptops: "Portátiles de alto rendimiento para juegos",
    laptop_a: "Portátil A",
    a_high_performance_laptop: "Un portátil de alto rendimiento",
    laptop_b: "Portátil B",
    a_budget_friendly_laptop: "Un portátil económico",
    smartphone_a: "Teléfono inteligente A",
    a_smartphone_with_a_great_camera: "Un teléfono inteligente con una gran cámara",
    gaming_laptop_a: "Portátil para juegos A",
    a_high_end_gaming_laptop: "Un portátil para juegos de alta gama",
    smartphone_b: "Teléfono inteligente B",
    an_affordable_smartphone: "Un teléfono inteligente asequible",
    // Textos del apartado de Hierarchy
    manage_hierarchy: "Gestionar Jerarquía",
    description: "Descripción",
    actions: "Acciones",
    // Textos que se encuentran en el apartado de Benchmark
    choose_two_products: "Elige dos productos para comparar las vulnerabilidades entre ellos",
    select_product: "Seleccionar producto",
    sector: "Sector",
    // Tabla del apartado de benchmark
    energy_and_electric: "Energy and electric networks",
    transport: "Transport",
    elevator: "Elevator",
    IOTDevice: "Iot device",
    automotive: "Automotive",
    health_Care: "Health care",
    // Textos que se encuentran en la página de CRA compliance
    summary: "Resumen",
    security_requirement: "Requisito de Seguridad",
    vulnerability_management: "Requisito de Gestión de Vulnerabilidades",
    conclusion: "Conclusión",
    export: "Exportar",
    quiz: "Cuestionario",
    total_score: "Puntuación total",
    // textos dentro de la edicion de conclusiones en CRA Compliance
    edit_text: "Editar texto",
    edit_the_conclusion: "Editar el campo Conclusión de la conformidad",
    // Textos dentro del apartado de quiz en CRA compliance
    save: "Guardar",
    cancel: "Salir",
    are_you_sure_you: "¿Estás seguro de que quieres salir sin guardar los cambios?",
    close_compliance: "Cerrar Cumplimiento",
    you_cant_undo: "No puedes deshacer esta accion",
    // textos de Policy Management dentro del botón de la sección de Policy Management
    add_new_policy: "Añadir nueva política",
    // textos cuando editas una gestión de política
    policy_name: "Nombre de la política",
    policy_add: "Nueva Política",
    policy_title: "Detalle de Política",
    operator: "Operador",
    value: "Valor",
    conditions: "Condiciones",
    // Cuando añades una nueva política
    name: "Nombre",
    violation_state: "Estado de violación",
    condition_subject: "Sujeto de la condición",
    condition_operator: "Operador de la condición",
    add_new_condition: "Añadir nueva condición",
    // Textos que se encuentran en la sección de Notifications & alerts
    notifications_title: "Notificaciones y Alertas",
    notifications: "Notificaciones",
    report_filters: "Filtros de informes",
    recieve_alerts: "Recibir alertas cuando:",
    new_CVEs: "Se descubren nuevas CVEs",
    CVEs_are: "Las CVEs se actualizan",
    recieve_alerts_when_CVSS: "Recibir alertas cuando el CVSS sea mayor que:",
    alerts: "Alertas",
    enable: "Habilitar",
    recipient: "Destinatario del correo electrónico",
    email_frequency: "Frecuencia del correo electrónico",
    // Textos colocados en la seccin Account & Settings en orden
    user_information: "Información del usuario",
    // textos de Informacion de usuario dentro de Account & Settings en orden
    billing: "Facturación",
    system_settings: "Ajustes del sistema",
    switch: "Cambiar entre modo oscuro y claro",
    tool_for_automatic_SBoM: "Herramienta para la generación automática de SBoM, véanse los detalles más abajo",
    download: "Descargar",
    support: "Soporte",
    have_any_question: "¿Tienes alguna pregunta? Póngase en contacto con nuestro equipo.",
    check_subscription: "Consulta nuestro plan de suscripción y la información en el siguiente enlace",
    or_contact_us: "O contáctanos enviando un correo electrónico a",
    subscription_plan: "Plan de suscripción",
    gold: "Oro",
    platinum: "Platino",
    titanium: "Titanio",
    yearly_subscription: "Suscripción anual",
    CVEs_affecting: "CVEs que afectan a tus componentes de software",
    email_notifications: "Notificaciones por correo electrónico sobre el descubrimiento o modificación de CVE",
    track_multiple_software: "Seguimiento de múltiples listas de materiales de software (BOMs)/manifiestos",
    limited: "Limitado",
    unlimited: "Ilimitado",
    on_demand_CVE: "Generación de informes CVE bajo demanda a través de la Web",
    customized: "Personalizado",
    custom_vulnerability_score: "Puntuación de vulnerabilidad personalizada/priorización y filtrado",
    based_on_EPSS: "Basado en EPSS y KEV",
    added_AI: "IA añadida",
    added_AI_custom: "IA personalizada añadida",
    CVE_summary: "Resumen de CVE por gravedad, estado y paquete de software",
    SBoM_quality_metric: "Puntuación de Métrica de Calidad de SBoM",
    CVSS_calculator: "Calculadora CVSS para añadir una puntuación revisada personalizada en CVEs",
    referece_links: "Enlaces de referencia a parches disponibles, mitigación y exploits",
    filter_based: "Filtrar basado en Componente o Estado",
    custom_report_generation: "Generación de informes personalizados",
    customizable_hierarchy: "Jerarquía de componentes personalizable",
    benchmark_comparasion: "Comparación de referencia entre sectores: energía, transporte, etc.",
    product_and_test: "Creación de productos y pruebas",
    with_support: "Con soporte",
    integration_with_scanning: "Integración con herramientas de escaneo (Nessus, Acunetix, BurpSuite y Achilles)",
    integration_with_jira: "Integración con Jira para una gestión y seguimiento fluido de problemas de vulnerabilidad",
    software_BOM: "Editor de BOM/Manifiesto de software y gestión de revisiones",
    comparison_of_changes: "Comparación de cambios entre compilaciones/lanzamientos (histórico de SBoM)",
    CVE_remediation: "Sugerencias de remediación de CVE utilizando IA",
    myorbiktoolbox: "myorbikToolbox: Generación de SBoM a través de línea de comandos",
    adding_private: "Añadir Repositorio Privado de Vulnerabilidades",
    sending_reports: "Envío de informes a clientes",
    policy_management: "Gestión de Políticas",
    upgrade_plan: "Actualizar plan",
    // textos de Facturacion dentro de Account & Settings en orden
    you_are_currently_free: "Actualmente tienes una membresía gratuita.",
    ticketing: "Sistema de tickets",
    ask_for_additional_assessment: "Solicitar una evaluación adicional o creación de producto a nuestro equipo",
    you_are_currently_pro: "Actualmente tienes una membresía Pro de tipo ",
    add_new_ticket: "Añadir nuevo ticket",
    // textos del menú cuando añades un nuevo ticket
    client_name: "Nombre del cliente",
    type: "Tipo",
    status: "Estado",
    // textos que están dentro de la edición de tickets ya creados
    client: "Cliente",
    due_date: "Fecha de vencimiento",
    notes: "Notas",
    License_validity: "Fecha de validez de la licencia",
    License_new: "Nueva Licencia",
    save_changes: "Guardar cambios",
    // textos de licenses dentro de views
    loading: "Cargando...",
    vendor: "Proveedor",
    version: "Versión",
    part: "Parte",
    product: "Producto",
    last_modified: "Última modificación",
    created_at: "Creado en",
    license_title: "Licencia",
    max_products: "Máx. Productos",
    max_assessments: "Máx. Evaluaciones",
    include_toolbox: "Incluir Caja de Herramientas",
    without_toolbox: "Sin Caja de Herramientas",
    create_license: "Crear Licencia",
    license_management: "Gestión de Licencias",
    add_new_license: "Agregar Nueva Licencia",
    // Vulnerabilities
    vulner_produc: "Producto",
    vulner_vers: "Versión",
    vulner_comp: "Componente",
    vulner_criti: "Criticidad",
    vulner_revis: "Puntuación Revisada",
    vulner_score: "Puntuación",
    vulner_vector: "Vector",
    vulner_prio: "Prioridad",
    vulner_epss: "EPSS",
    vulner_kev: "KEV",
    vulner_nokev: "No incluido en el catálogo KEV",
    vulner_sikev: "Incluido en el catálogo KEV",
    vulner_desc: "Descripción",
    vulner_review: "Revisión",
    vulner_assess: "Evaluar",
    vulner_addre: "Dirección",
    vulner_stat: "Estado",
    vulner_just: "Justificación",
    vulner_resp: "Respuesta",
    vulner_det: "Detalles",
    vulner_weak: "Debilidades (CWES)",
    vulner_meta: "Metadatos",
    vulner_cvs: "Calculadora de CVSS3",
    vulner_generator: "Generador",
    vulner_generator_time: "Fecha de Generación",
    // Hierarchy
    hierar_level: "Nivel jerárquico",
    hierar_option: "Sin opciones",
    hierar_manage: "Gestionar la jerarquía",
    hierar_node: "Añadir nuevo nodo",
    hierar_back: "Atrás",
    hierar_reset: "Reiniciar",
    sla: {
        detail: "Detalles del SLA",
        clientName: "Nombre del Cliente",
        name: "Nombre",
        days: "Días",
        enforcements: "Cumplimientos",
        critical: "crítico:",
        high: "alto:",
        medium: "medio:",
        low: "bajo:",
        checkCritical: "Marcar Crítico",
        checkHigh: "Marcar Alto",
        checkMedium: "Marcar Medio",
        checkLow: "Marcar Bajo",
        productSave: "Guardar Producto",
        new: "Nuevo SLA",
        description: "Descripción",
        criticalDays: "Días Críticos",
        highDays: "Días Altos",
        mediumDays: "Días Medios",
        lowDays: "Días Bajos",
        addNew: "Añadir nuevo SLA"
    },
    issueDetails: {
        title: "Detalles del Problema",
        key: "Clave",
        status: "Estado",
        project: "Proyecto",
        client: "Cliente",
        productName: "Nombre del Producto",
        productVersion: "Versión del Producto",
        cpeName: "Nombre CPE",
        cpeVersion: "Versión CPE",
        cve: "CVE",
        revisedScore: "Puntuación Revisada",
        justification: "Justificación",
        response: "Respuesta",
        details: "Detalles",
        webhookEvent: "Evento Webhook"
    },
    jira_integration: "Integración con Jira",
    jira_url: "URL de Jira",
    jira_email: "Email usuario administrador jira",
    jira_account: "Identificador de cuenta de usuario administrador jira",
    jira_token: "Jira API token",
    jira_secret: "Jira webhook secret",
    jira_create_project: "Crear proyecto myOrbik en Jira",
    jira_restore_project: "Restablecer proyecto myOrbik en Jira",
    cpe_title: "Detalles de CPE",
    cpe_search: "Busqueda de CPE",
    add_cpe: "Añadir CPE",
    component_title: "Detalle de Componente",
    no_date: "No hay fecha disponible",
    read_all: "Leer todos",
    client_picker: "Seleccione un usuario",
    last_updated: "Última modificación",
    error_message: "Ocurrió un error.",
    error_download: "Ocurrió un error durante la descarga.",
    epss_modal: "EPSS: La puntuación de explotación predice la probabilidad de que una vulnerabilidad sea explotada en los próximos 30 días.",
    kev_modal: "KEV: El catálogo de Vulnerabilidades Conocidas Explotadas (KEV) es una lista mantenida por CISA que identifica vulnerabilidades con exploits activos en el mundo real, ayudando a las organizaciones a priorizar la corrección de las más críticas.",
    dialogs: {
        helpDialogTitleOffline: "README",
        helpDialogTextOffline: "Este archivo contiene los comandos \"generate_sbom_offline.sh\" y \"generate_sbom_online.sh\" utilizados para la creación de SBOMs sobre archivos, directorios o imágenes y su posterior gestión.",
        installationTitle: "Instalación",
        portableBinaryLimitations: "Limitaciones y Requisitos previos:",
        requirementsList: [
            "Los scripts no pueden ser añadidos al PATH por lo que deben ser ejecutados desde la misma carpeta donde se hayen.",
            "Se requiere un dispositivo \"Linux\" que contenga Bash.",
            "Tener git instalado en el sistema.",
            "Es necesario tener al menos una herramienta para descomprimir archivos zip."
        ],
        usageTitle: "Uso",
        usageRequirements: "Requisitos:",
        usageRequirementsList: [
            "Si deseas realizar una ejecución remota, debes tener acceso al sistema.",
            "Se recomienda tener una clave SSH creada en el sistema que ejecutará el script. Si no, el script te guiará en el proceso de creación.",
            "Debes tener permisos de ejecución para el script y permisos de lectura (para archivos e imágenes) o permisos de lectura y ejecución si es un directorio.",
            "Si el script se ejecuta en línea, se requiere acceso a Internet para el manejo posterior a la creación del SBoM."
        ],
        executionExamplesList: [
            "Para una ejecución normal",
            "Para una ejecución automática",
            "Para la ejecución en un sistema con IP 192.168.1.5"
        ],
        commands: "Comandos",
        closeButtonText: "Cerrar"
    },
    color_dialog: {
        title: "Color demasiado oscuro",
        content: "Este color es demasiado oscuro y podría afectar la visibilidad del texto. Recomendamos elegir un color más claro para asegurar una mejor legibilidad.",
        button: "Aceptar"
    },
    pricing: {
        title: "Obtén un precio atractivo aquí",
        subtitle: "Elige el plan perfecto para tus necesidades. Desbloquea potentes funciones y haz crecer tu negocio con nuestras opciones de precios flexibles.",
        priceSuffix: "/año"
    },
    system_notification: "Notificación de sistema",
    date: "Fecha",
    action: "Acción",
    read_status: "Estado de lectura",
    target_role: "Tipo de destinatario",
    channels: "Canales",
    setting_check_subscription: "Más información acerca de las subscripciones",
    myorbiktoolbox_description: "My Orbik Toolbox es una herramienta de terminal personalizada para subir a Github.Sirve para la creación de SBOMS sobre archivos, directorios o imágenes y su posterior administración.Su ejecución puede tanto ser online como offline.",
    stepper: {
        title: "Pasos a seguir",
        download: "Descarga y descomprime el archivo",
        step2: "Introduce en el terminal el siguiente comando para dar permisos de ejecución a los scripts",
        step3: "Si quieres acceder al comando desde cualquier directorio",
        step1_description: "Contiene dos carpetas con las dos herramientas (online y offline).",
        step3_description: "Abre el archivo bashrc y escribe en él el comando de exportación"
    },
    myorbiktoolbox_commands: "El SBoM generado se guardará en el dispositivo local aunque el archivo analizado sea en un sistema remoto.",
    myorbiktoolbox_commands2: "Para otro tipo de ejecuciones:",
    quizz_export: "Descargar respuestas",
    quizz_send: "Enviar resultados",
    quizz_status_title: "Estado Actual",
    question: "Pregunta",
    answered: "Respondido",
    compliant: "Cumple",
    by_policy: "Por política",
    by_license: "Por licencia",
    viewLess: "Ver menos",
    viewMore: "Ver más",
    // Detalles de KEV
    kev_details: "Detalles de KEV",
    vendor_project: "Proyecto del proveedor",
    ransomware_campaign: "Campaña de ransomware",
    required_actiom: "Acción requerida",
    no_action: "Sin acción",
    no_notes: "Sin notas",
    // CMPDetailGrid
    you_can_only: "Solo puedes agregar un componente",
    component_name: "Nombre del componente",
    component_version: "Versión del componente",
    add_new_components: "Agregar nuevos componentes",
    add_new_cwe: "Agregar nuevo CWE",
    // MetadataGrid
    the_metadata_title: "Los metadatos",
    is_already_in_use: "ya están en uso",
    you_can_only_add: "Solo puedes agregar un metadato",
    add_new_metadata: "Agregar nuevo metadato",
    severity: "Severidad"
}

export default es
