import ServicesContext from "@context/ServicesContext"
import { Button, FormControlLabel, FormGroup, Grid, Switch, Typography, useTheme } from "@mui/material"
import { GridColDef, GridRowClassNameParams, GridRowParams } from "@mui/x-data-grid"
import React, { useContext, useEffect, useState } from "react"
import { NavigateFunction, useNavigate, useParams } from "react-router-dom"
import { Component } from "@models/Component"
import { AbilityContext } from "@components/permissions"
import { convertModelDefToGridColDef, CustomAction, GenericTable } from "@components/common/tables"
import ComponentTableDefinition from "@components/component/ComponentTableDefinition"
import { useTrack } from "@components/track/TrackContext"
import { FilterOperation, FilterOption, QueryParameters } from "@utils/queryParams"
import { I18nContext } from "I18nProvider"
import ActionButton from "@components/common/Button/ActionButton"
import StatsCard from "@components/common/stats/StatsCard"

type ApiResponse = {
    count: number;
    list: Component[];
}

const ComponentList: React.FC = () => {
    // Constant
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const theme = useTheme()
    const componentService = useContext(ServicesContext).componentService
    const navigate: NavigateFunction = useNavigate()
    const [cols, setCols] = useState<GridColDef[]>([])
    const ability = useContext(AbilityContext)
    const { id } = useParams<{ id: string; componentId: string }>()
    const { track, trackData } = useTrack()
    const [queryParameters, setQueryParameters] = useState<QueryParameters>({ filters: [{ field: "assessment_id", operation: FilterOperation.UUIDEqual, value: id as string }] })
    // --------------------------------------------------

    // useEffect
    useEffect(() => {
        setCols(convertModelDefToGridColDef(ComponentTableDefinition, ability))
    }, [])
    useEffect(() => {
        track({ view: "ComponentList" })
    }, [])
    // --------------------------------------------------
    const customActions = (params: GridRowParams<Component>) => {
        const actions: CustomAction[] = []
        return actions
    }

    const handleOutdatedSwitch = (event: any) => {
        const { checked } = event.target
        const newFilter: FilterOption = {
            field: "outdated",
            operation: FilterOperation.StringEqual,
            value: checked
        }
        if (checked) {
            // append filter
            setQueryParameters({ ...queryParameters, filters: [...queryParameters.filters || [], newFilter] })
        } else {
            const tmpFilter = queryParameters.filters?.filter(f => f.field !== "outdated")
            setQueryParameters({ ...queryParameters, filters: tmpFilter })
        }
    }
    const handleSupressedSwitch = (event: any) => {
        const { checked } = event.target
        const newFilter: FilterOption = {
            field: "supressed",
            operation: FilterOperation.StringEqual,
            value: checked
        }
        if (checked) {
            setQueryParameters({ ...queryParameters, filters: [...queryParameters.filters || [], newFilter] })
        } else {
            const tmpFilter = queryParameters.filters?.filter(f => f.field !== "supressed")
            setQueryParameters({ ...queryParameters, filters: tmpFilter })
        }
    }

    return (
        <>

            <Grid container sx={{ justifyContent: "space-between", alignItems: "center", spacing: "20px", paddingLeft: "20px", marginBottom: "0px", paddingBottom: "0px" }}>
                <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                    {context.t.translate("components")}
                </Typography>
                <FormGroup>
                    <Grid item display="flex" gap={2}>
                        <Button variant="contained" sx={{ color: theme.palette.secondary.contrastText }} onClick={() => navigate(`../assessment/${id}/cpe`)}>{context.t.translate("cpe_search")}</Button>
                        <ActionButton onClick={() => navigate("./add")} text={context.t.translate("add_component")} />
                        <FormControlLabel label={context.t.translate("supressed_only")} control={<Switch />} onChange={handleSupressedSwitch} />
                        <FormControlLabel label={context.t.translate("outdated_only")} control={<Switch />} onChange={handleOutdatedSwitch} />
                    </Grid>
                </FormGroup>
            </Grid>
            <StatsCard title= {context.t.translate("components")} entity="Component"/>

            <Grid container item className="scroll-container">
                <Typography fontSize="45px" fontWeight="bolder" color={theme.palette.text.secondary} fontFamily="Griff" sx={{ marginTop: "-20px", marginBottom: "20px" }}>{context.t.translate("product_comp")}</Typography>
                <Grid item container flexDirection="column" rowGap="35px">
                    <GenericTable<Component> entity="Component" columns={cols}
                        dataProvider={(filter) => componentService.getAll(filter)}
                        onEdit={(elem: Component) => navigate("../component/" + elem.id)}
                        onDelete={(elem: Component) => componentService.delete(elem.id)}
                        customActions={customActions}
                        externalParameters={queryParameters}
                        dataGridProps={{
                            getRowClassName: (params: GridRowClassNameParams) =>
                                params.row.supressed ? "suppressedRow" : "",
                            sx: {
                                "& .suppressedRow .MuiDataGrid-cell": {
                                    opacity: 0.5
                                }
                            }
                        }}
                    />
                </Grid>

            </Grid>
        </>
    )
}
export { ComponentList }
export default ComponentList
