import React, { useContext, useEffect, useState } from "react"
import { AlertColor, Box, Grid, Typography, useTheme } from "@mui/material"
import { Compliance, ComplianceResult, FunctionalRequirementCategory, Question, QuizzStatus, QuizzType, RegistryData } from "@models/Compliance"
import { useNavigate, useParams } from "react-router-dom"
import { pdf } from "@react-pdf/renderer"
import { AbilityContext } from "@components/permissions/Can"
import VulnReportDocumentCompliance from "@components/report/VulnReportDocumentCompliance"
import ServicesContext from "@context/ServicesContext"
import TextareaDialog from "@components/dialogs/TextareaDialog"
import { I18nContext } from "I18nProvider"
import { StyledBox } from "@components/common/Box/BoxContainer"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import { FilterOperation, FilterOption, QueryParameters } from "@utils/queryParams"
import QuestionTable from "@components/compliance/QuestionTable"
import { QuizIndicator } from "@components/common/indicator/QuizzIndicator"
import Legend from "@components/common/Legend/QuizLegend/QuizzLegend"
import ActionButton from "@components/common/Button/ActionButton"
import { useTrack } from "@components/track/TrackContext"
import { useClient } from "@context/useClient"
import VulnReportDocumentComplianceLab from "@components/report/VulnReportDocumentComplianceLab"
import { FDAQuestions } from "../../components/compliance/FDAQuestionData"
import { CRAQuestions } from "../../components/compliance/CRAQuestionData"
import QuizCard from "./components/QuizCard"
import ScoreCompliance from "./components/score_compliance"
export const countNonEmptyQValues = (registryArray: RegistryData[], type: QuizzType) => {
    if (type === QuizzType.FDA) {
        // Para FDA, sigue contando todo
        return registryArray.reduce((totalCount, registry) => {
            const countForCurrentRegistry = Object.entries(registry)
                .filter(([key]) => key.startsWith("q")) // Filtrar claves que empiezan con 'q'
                .reduce((count, [, value]) => (value !== "" ? count + 1 : count), 0) // Contar valores no vacíos
            return totalCount + countForCurrentRegistry
        }, 0)
    }

    if (type === QuizzType.CRA) {
        // Para CRA, cuenta solo preguntas principales (sin '.')
        return registryArray.reduce((totalCount, registry) => {
            const countForCurrentRegistry = Object.entries(registry)
                .filter(([key]) => key.startsWith("q") && !key.includes(".")) // Filtrar claves que no contienen '.'
                .reduce((count, [, value]) => (value !== "" ? count + 1 : count), 0) // Contar valores no vacíos
            return totalCount + countForCurrentRegistry
        }, 0)
    }

    return 0 // Por defecto, retorna 0
}

const ComplianceSummary: React.FC = () => {
    const context = useContext(I18nContext)
    const [isStarted, setIsStarted] = useState<boolean>(false)
    const { type } = useParams<{ type: string }>()
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }

    const theme = useTheme()
    const navigate = useNavigate()
    const initRegistry: RegistryData = Object.fromEntries(
        Array.from({ length: 39 }, (_, i) => i === 0 ? "time" : `q${i}`).map(key => [key, ""])
    ) as RegistryData

    const initValue = {
        id: "",
        registry: [initRegistry],
        conclusion: "",
        client_id: "",
        type: type?.toUpperCase() as QuizzType,
        created_at: "",
        updated_at: "",
        justification: { },
        score: 0,
        conclusions: {}
    }

    type ColorMapCategoryType = {
        [key in FunctionalRequirementCategory]: string;
    };
    const colorCategoryMap: ColorMapCategoryType = {
        [FunctionalRequirementCategory.Security]: theme.palette.primary.main,
        [FunctionalRequirementCategory.Vulnerability]: theme.palette.primary.main,
        [FunctionalRequirementCategory.FDA]: theme.palette.primary.main,
        [FunctionalRequirementCategory.Context]: theme.palette.primary.main
    }
    const [openConclusionDialog, setOpenConclusionDialog] = React.useState(false)
    const [data, setData] = useState<Compliance>(initValue)
    const ability = useContext(AbilityContext)
    const [status, setStatus] = useState<QuizzStatus>(QuizzStatus.Pending)
    const [conclusion, setConclusion] = useState<string>("")
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const complianceService = useContext(ServicesContext).complianceService
    const clientService = useContext(ServicesContext).clientService
    const [snackbarMessage, setSnackbarMessage] = useState<string>("")
    const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>("success")
    const questions = type === QuizzType.CRA.toString() ? CRAQuestions : FDAQuestions
    const { track, trackData } = useTrack()
    const [clienteId, setClientId] = useState<string>("")
    const [createQuiz, setCreateQuiz] = useState<boolean>(true)
    const [total, setTotal] = useState<number>(0)
    const { clientid } = useClient()
    const [resultCounts, setResultCounts] = useState<Record<ComplianceResult, number>>({
        [ComplianceResult.NonCompliant]: 0,
        [ComplianceResult.RequiredImprovements]: 0,
        [ComplianceResult.PartiallyCompliant]: 0,
        [ComplianceResult.FullyCompliant]: 0,
        [ComplianceResult.NoDocument]: 0,
        [ComplianceResult.PartialDocument]: 0,
        [ComplianceResult.FullDocument]: 0,
        [ComplianceResult.NotApplicable]: 0,
        [ComplianceResult.Yes]: 0,
        [ComplianceResult.No]: 0,
        [ComplianceResult.NoKnown]: 0
    })
    const [openDialog, setOpenDialog] = useState(false) // Controla el estado del modal
    const [averageScore, setAverageScore] = useState<number>(0) // Guarda la media
    const [scores, setScores] = useState<{ [key: string]: number }>({
        "CRA Situation Question": 0,
        "Security Requirements": 0,
        "Vulnerability Management Requirements": 0
    })
    const handleFinishCRA = async () => {
        if (!ability.can("*", "Client")) {
            setSnackbarMessage("You do not have permission to perform this action.")
            setSnackbarSeverity("error")
            setSnackbarOpen(true)
            return
        }

        try {
            await complianceService.notifyCRACompletion(data.client_id, data)

            setSnackbarMessage("CRA finalized send to client succesfully.")
            setSnackbarSeverity("success")
            setSnackbarOpen(true)
        } catch (error: any) {
            setSnackbarMessage(error.message || "An error occurred while finalizing the CRA.")
            setSnackbarSeverity("error")
            setSnackbarOpen(true)
        }
    }

    const handleDialogSave = async (average: number, newScores: { [key: string]: number }) => {
        try {
            // Actualiza el estado local
            setScores(newScores)
            setAverageScore(average)

            // Actualiza la base de datos
            await saveScoresToDatabase(average)
        } catch (error) {
            console.error("Error saving scores:", error)
        }
        setOpenDialog(false) // Cierra el modal
    }

    const saveScoresToDatabase = async (average: number) => {
        try {
            const response = await complianceService.update(data.id, {
                ...data,
                score: average // Guardar solo la media en la base de datos
            })

            if (!response.ok) {
                throw new Error("Failed to save the average score")
            }
        } catch (error) {
            console.error("Error saving the average score:", error)
            throw error
        }
    }

    // const calculateTotalScore = (compliance: Compliance): number => {
    //     let total = 0

    //     questions.forEach((question: { id: string; results: any[] }) => {
    //         compliance.registry.forEach((entry) => {
    //             if (Object.prototype.hasOwnProperty.call(entry, question.id)) {
    //                 const resultValue = compliance.registry[0][question.id as keyof RegistryData] as ComplianceResult || ComplianceResult.NonCompliant
    //                 const resultEnumValue = question.results.find((r) => r.result === resultValue)

    //                 if (resultEnumValue) {
    //                     total += resultEnumValue.value
    //                 }
    //             }
    //         })
    //     })

    //     return total
    // }

    const calculatePartialScore = (registry: RegistryData[], categoryQuestions: Question[]): number => {
        let total = 0
        let count = 0

        // Iterar sobre cada pregunta
        questions.forEach((question) => {
            // Iterar sobre cada entrada del registro
            registry.forEach((entry) => {
                // Verificar si la entrada tiene la propiedad de la pregunta
                if (Object.prototype.hasOwnProperty.call(entry, question.id)) {
                    // Obtener el valor del resultado
                    const resultValue = entry[question.id as keyof RegistryData] as ComplianceResult || ComplianceResult.NonCompliant
                    // Buscar el valor correspondiente en los resultados de la pregunta
                    const resultEnumValue = question.results.find((r) => r.result === resultValue)

                    if (resultEnumValue) {
                        total += resultEnumValue.value
                        count++ // Incrementar el contador por cada resultado encontrado
                    }
                }
            })
        })

        // Calcular la media si count es mayor que 0
        return count > 0 ? total / categoryQuestions.length : 0 // Retornar 0 si no hay resultados
    }

    useEffect(() => {
        fetchData()
        track({ view: "ComplianceSummary" })
        const getClientId = async () => {
            try {
                const client = await clientService.getAll()
                if (client.list[0].id) {
                    setClientId(client.list[0].id)
                }
            } catch (e: any) {
                setSnackbarMessage(e.message)
                setSnackbarOpen(true)
            }
        }
        getClientId()
    }, [])

    const handleCloseDialog = () => {
        setOpenConclusionDialog(false)
    }

    const handleConfirmDialog = (newText: string) => {
        setConclusion(newText)
    }

    const handleExport = async () => {
        try {
            let report

            if (type === QuizzType.CRA.toString()) {
                report = pdf(<VulnReportDocumentCompliance compliance={data} type="CRA" />)
            } else if (type === QuizzType.FDA.toString()) {
                report = pdf(<VulnReportDocumentCompliance compliance={data} type="FDA" />)
            }

            if (report) {
                const blob = await report.toBlob()
                const url = window.URL.createObjectURL(blob)
                window.open(url)
            }
        } catch (error: any) {
            setSnackbarMessage(error.message)
            setSnackbarSeverity("error")
            console.error(error)
            setSnackbarOpen(true)
        }
    }
    const onConclusionExport = async () => {
        try {
            let report
            const newData = await complianceService.get(data.id)
            if (type === QuizzType.CRA.toString()) {
                report = pdf(<VulnReportDocumentComplianceLab compliance={newData} type="CRA" />)
            } else if (type === QuizzType.FDA.toString()) {
                report = pdf(<VulnReportDocumentComplianceLab compliance={newData} type="FDA" />)
            }

            if (report) {
                const blob = await report.toBlob()
                const url = window.URL.createObjectURL(blob)
                window.open(url)
            }
        } catch (error: any) {
            setSnackbarMessage(error.message)
            setSnackbarSeverity("error")
            console.error(error)
            setSnackbarOpen(true)
        }
    }
    const fetchData = async () => {
        try {
            const filters: FilterOption[] = [
                { field: "type", operation: FilterOperation.StringEqual, value: type as string }
            ]
            const validClientId = clientid || clienteId
            if (validClientId) {
                filters.push({ field: "client_id", operation: FilterOperation.StringEqual, value: validClientId })
            }

            const queryParameters: QueryParameters = { filters }
            const complianceList = await complianceService.getAll(queryParameters)

            if (complianceList.count > 0) {
                setData(complianceList.list[0])
                const complianceTotals = countComplianceResults(complianceList.list[0].registry)
                setSnackbarMessage("")
                setCreateQuiz(false)

                // Ajustar el conteo según el tipo
                const totalQuestions = type === QuizzType.CRA ? 38 : 9 // CRA tiene 38 principales, FDA tiene 9
                setTotal(totalQuestions)

                const nonEmpty = countNonEmptyQValues(complianceList.list[0].registry, type as QuizzType)

                setIsStarted(nonEmpty > 0)

                if (type === QuizzType.CRA) {
                    setStatus(nonEmpty === 38 ? QuizzStatus.Finished : QuizzStatus.Pending) // Finalizado si se responden las 38 principales
                } else if (type === QuizzType.FDA) {
                    setStatus(nonEmpty === 9 ? QuizzStatus.Finished : QuizzStatus.Pending) // Finalizado si se responden las 9 preguntas
                }
            }
        } catch (e: any) {
            setSnackbarMessage(e.message)
            setSnackbarSeverity("error")
            setSnackbarOpen(true)
        }
    }

    const countComplianceResults = (registry: RegistryData[]): Record<ComplianceResult, number> => {
        const resultCounts: Record<ComplianceResult, number> = {
            [ComplianceResult.NonCompliant]: 0,
            [ComplianceResult.RequiredImprovements]: 0,
            [ComplianceResult.PartiallyCompliant]: 0,
            [ComplianceResult.FullyCompliant]: 0,
            [ComplianceResult.NoDocument]: 0,
            [ComplianceResult.PartialDocument]: 0,
            [ComplianceResult.FullDocument]: 0,
            [ComplianceResult.NotApplicable]: 0,
            [ComplianceResult.Yes]: 0,
            [ComplianceResult.No]: 0,
            [ComplianceResult.NoKnown]: 0
        }

        registry.forEach(entry => {
            for (let i = 1; i <= 39; i++) {
                const key = `q${i}` as keyof RegistryData // Ensure correct key type
                const result = entry[key]

                // Ensure result is of type ComplianceResult
                if (Object.prototype.hasOwnProperty.call(resultCounts, result)) {
                    resultCounts[result as ComplianceResult]++ // Cast result to ComplianceResult
                }
            }
        })
        setResultCounts(resultCounts)

        return resultCounts
    }

    const sendHelpEmail = async (id: string) => {
        try {
            await complianceService.sendHelpNotification(id).then((response:any) => {
                setSnackbarMessage(context.t.translate("CRA_help"))
                setSnackbarSeverity("success")
                setSnackbarOpen(true)
            })
        } catch (e: any) {
            setSnackbarMessage(e.details)
            setSnackbarSeverity("error")
            setSnackbarOpen(true)
        }
    }
    return (
        <Grid item>
            <Grid item xs container flexDirection="column" spacing="20px" paddingLeft={2} sx={{ position: "relative" }}>
                <Grid container justifyContent="space-between" alignItems="center" paddingBottom={2}>
                    <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                        {context.t.translate("CRA_title")}
                    </Typography>
                    <Grid display="flex" gap={2}>
                        <ActionButton text="Help" variant="outlined" onClick={() => sendHelpEmail(clienteId)} />
                        {/*                         <ActionButton text="Check" variant="outlined" onClick={() => {}} /> */}
                        {type === QuizzType.CRA && ability.can("*", "Client") && status === QuizzStatus.Finished && (
                            <Grid item xs={12}>
                                <ActionButton
                                    variant="contained"
                                    onClick={() => setOpenDialog(true)}
                                    text="Open"
                                />
                            </Grid>
                        )}
                        {type === QuizzType.CRA && ability.can("*", "Client") && status === QuizzStatus.Finished && (
                            <Grid item xs={12}>
                                <ActionButton
                                    variant="contained"
                                    onClick={() => setOpenDialog(true)}
                                    text="Set Scores"
                                />
                            </Grid>
                        )}

                        {type === QuizzType.CRA && ability.can("*", "Client") && status === QuizzStatus.Finished && (
                            <ActionButton
                                text="Finish"
                                variant="contained"
                                onClick={handleFinishCRA}
                            />
                        )}
                    </Grid>

                    <Grid container direction="column" spacing={2} rowGap="35px" style={{ height: "100%" }}>
                        <Grid item>
                            <StyledBox>
                                <QuizCard
                                    score={data.score}
                                    status={status}
                                    resultCounts={resultCounts}
                                    totalQuestions={total}
                                    onEditConclusion={() => navigate("./" + data.id)}
                                    onStartQuiz={() => navigate("./" + data.id)}
                                    onExport={handleExport}
                                    title={`${data.type} ${context.t.translate("CRA_title")}`}
                                    contextType="summary"
                                    isStarted={isStarted}
                                    onConclusionExport={onConclusionExport}
                                />
                            </StyledBox>
                        </Grid>
                    </Grid>

                    <Grid container direction="column" spacing={2} rowGap="35px" style={{ height: "100%" }}>
                        <Grid item xs={12}>
                            <CustomSnackbar
                                open={snackbarOpen}
                                onClose={() => setSnackbarOpen(false)}
                                message={snackbarMessage}
                                severity={snackbarSeverity}
                            />

                            <Box mt="35px">
                                <Grid container spacing={2} rowGap={2}>

                                    {Object.values(FunctionalRequirementCategory).map((category) => {
                                        const categoryQuestions = questions
                                            .filter((question) => question.category === category)
                                            .map((question) => ({
                                                ...question,
                                                text: question.text || "" // Asigna un valor por defecto si 'text' es undefined
                                            }))

                                        // Filtrar categorías sin preguntas
                                        if (categoryQuestions.length === 0) return null

                                        const filteredRegistry: RegistryData[] = data.registry.map(registryItem => {
                                        // Inicializar un objeto RegistryData
                                            const initRegistry: RegistryData = Object.fromEntries(
                                                Array.from({ length: 39 }, (_, i) => i === 0 ? "time" : `q${i}`).map(key => [key, ""])
                                            ) as RegistryData

                                            const filteredItem: RegistryData = {
                                                ...initRegistry
                                            }

                                            // Asignamos valores solo para las preguntas en categoryQuestions
                                            categoryQuestions.forEach(question => {
                                                const value = registryItem[question.id as keyof RegistryData]
                                                if (typeof value === "string") {
                                                    filteredItem[question.id as keyof RegistryData] = value as unknown as ComplianceResult
                                                } else {
                                                    filteredItem[question.id as keyof RegistryData] = ComplianceResult.NonCompliant // Valor por defecto
                                                }
                                            })

                                            return filteredItem
                                        })
                                        // const partialScore = calculatePartialScore(filteredRegistry, categoryQuestions)
                                        return (
                                            <Grid item xs={12} sm={6} key={category}>
                                                <Grid container spacing={2} mt="15px">
                                                    <Grid item alignItems="center" display="flex" gap={2}>
                                                        {/* <ScoreBox value={partialScore}/> */}
                                                        <Grid alignItems="top" sx={{ gap: 2 }} width="auto">
                                                            <Typography
                                                                color={theme.palette.text.secondary}
                                                                fontSize="24px"
                                                                fontWeight="bolder"
                                                                fontFamily="Griff"
                                                            >
                                                                {category}
                                                            </Typography>
                                                            {status === QuizzStatus.Pending && (
                                                                <Box
                                                                    sx={{
                                                                        border: "1px solid #4A4733",
                                                                        borderRadius: "8px",
                                                                        padding: "5px 10px",
                                                                        width: "fit-content",
                                                                        marginBottom: 1
                                                                    }}
                                                                >
                                                                    <Typography>
                                                                        {context.t.translate("pending")} {countNonEmptyQValues(data.registry, type as QuizzType)}/{type === QuizzType.CRA ? 38 : 9}
                                                                    </Typography>
                                                                </Box>
                                                            )}

                                                            <Box sx={{ gap: 1 }}>
                                                                <Grid><QuizIndicator
                                                                    NoDocumentAvailable={resultCounts[ComplianceResult.NoDocument]}
                                                                    RequiredImprovements={resultCounts[ComplianceResult.PartialDocument]}
                                                                    PartiallyCompliant={resultCounts[ComplianceResult.PartiallyCompliant]}
                                                                    FullyCompliant={resultCounts[ComplianceResult.FullDocument]}
                                                                    Yes={resultCounts[ComplianceResult.Yes]}
                                                                    No={resultCounts[ComplianceResult.No]}
                                                                    NoKnown={resultCounts[ComplianceResult.NoKnown]}
                                                                    total={total}
                                                                /></Grid>
                                                                <Grid marginTop={1}>
                                                                    <Legend type={type === QuizzType.CRA ? "CRA" : "FDA"} />
                                                                </Grid>

                                                            </Box>
                                                        </Grid>
                                                    </Grid>

                                                </Grid>
                                                <Grid mt="15px">
                                                    {data.id !== "" && <QuestionTable categoryQuestions={categoryQuestions} data={data} />}
                                                </Grid>
                                            </Grid>
                                        )
                                    })}

                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>

                </Grid>
                <div>

                    {/* Modal para ingresar los puntajes */}
                    <ScoreCompliance
                        open={openDialog}
                        onClose={() => setOpenDialog(false)}
                        onSave={handleDialogSave}
                        isAdmin={true} />
                </div>
                <Grid item xs={12}>
                    <TextareaDialog
                        open={openConclusionDialog}
                        initialText={context.t.translate("CRA_text")}
                        title={context.t.translate("CRA_edit")}
                        handleCloseDialog={handleCloseDialog}
                        handleConfirmDialog={handleConfirmDialog}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ComplianceSummary
