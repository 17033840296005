import React, { useContext } from "react"
import { Page, Text, View } from "@react-pdf/renderer"
import Template from "@models/Template"
import { I18nContext } from "I18nProvider"
import Assessment from "@models/Assessment"
import { globalStyles } from "../Styles"
import { Footer, Header } from "./HeaderFooter"

interface introProps {
    title: string;
    description1: string;
    subtitle_1: string;
    description2: string;
    table_1: {
        header: string[];
        layers: {
            title: string;
            body: Array<{ port: string; protocol: string, status: string, cpe: string, comments: string }>;
        }[]
        legend: string;
    }
    subtitle_2: string;
    description_3: string;
    table_2: {
        header: string[];
        body: Array<{ active: string; application: string }>;
        legend: string;
    },
    description_4: string;
    table_3: {
        header: string[];
        body: Array<{ id: string, content: string }>;
        legend: string;
    }
}

const i18n: {
    es: introProps;
    en: introProps;
} = {
    es: {
        title: "Descubrimiento e identificación del sistema",
        description1: "El objetivo de esta fase es descubrir e identificar en el sistema objetivo, sus características y los servicios que ofrece.",
        subtitle_1: "Servicios publicados",
        description2: "En esta fase se descubren y se identifican tanto el puerto, el protocolo como el nombre y la versión del software de los servicios de red que ofrece el dispositivo sobre IP, TCP y UDP en todas las interfaces detectadas. Este proceso se ha realizado utilizando técnicas de detección activa y detección pasiva.",
        table_1: {
            header: ["Puerto", "Protocolo", "Estado", "CPE", "Comentarios"],
            layers: [
                {
                    title: "Protocolos de la capa de aplicación",
                    body: [
                        { port: "4444", protocol: "TCP", status: "ABIERTO", cpe: "Example", comments: "Example" }
                    ]
                },
                {
                    title: "Protocolos de la capa de presentación",
                    body: [
                        { port: "4444", protocol: "TCP", status: "ABIERTO", cpe: "Example", comments: "Example" }
                    ]
                },
                {
                    title: "Protocolos de la capa de sesión",
                    body: [
                        { port: "4444", protocol: "UDP", status: "ABIERTO", cpe: "Example", comments: "Example" }
                    ]
                },
                {
                    title: "Protocolos de la capa de transporte",
                    body: [
                        { port: "4444", protocol: "TCP", status: "ABIERTO", cpe: "Example", comments: "Example" }
                    ]
                },
                {
                    title: "Protocolos de la capa red",
                    body: [
                        { port: "4444", protocol: "TCP", status: "ABIERTO", cpe: "Example", comments: "Example" }
                    ]
                },
                {
                    title: "Protocolos de la capa enlace de datos",
                    body: [
                        { port: "4444", protocol: "TCP", status: "ABIERTO", cpe: "Example", comments: "Example" }
                    ]
                },
                {
                    title: "Protocolos de la capa fisica",
                    body: [
                        { port: "4444", protocol: "TCP", status: "ABIERTO", cpe: "Example", comments: "Example" }
                    ]
                }
            ],
            legend: "Table 3: Servicios."
        },
        subtitle_2: "Aplicaciones detectadas",
        description_3: "Para finalizar se quieren detectar frontales de aplicaciones que puedan ser susceptible a ser atacados dentro de los dominios identificados.",
        table_2: {
            header: ["Activo", "Aplicación"],
            body: [
                { active: "127.0.0.1", application: "Example" }
            ],
            legend: "Table 4: Aplicaciones."
        },
        description_4: "Una vez finalizada esta fase se procedió a identificar las tecnologías que están siendo utilizadas en las URLs identificadas. El resultado de esta identificación se muestra en la tabla siguiente:",
        table_3: {
            header: ["Tecnología", "Implementación"],
            body: [
                { id: "Example", content: "Example" }
            ],
            legend: "Table 4: Tecnología."
        }
    },
    en: {
        title: "System discovery and identification",
        description1: "The goal of this phase is to discover and identify the target system, its characteristics, and the services it offers.",
        subtitle_1: "Published services",
        description2: "In this phase, both the port, the protocol, and the name and version of the software of the network services offered by the device over IP, TCP, and UDP on all detected interfaces are discovered and identified. This process was carried out using active detection and passive detection techniques.",
        table_1: {
            header: ["Port", "Protocol", "State", "CPE", "Comments"],
            layers: [
                {
                    title: "Application layer protocols",
                    body: [
                        { port: "4444", protocol: "TCP", status: "OPEN", cpe: "Example", comments: "Example" }
                    ]
                },
                {
                    title: "Presentation layer protocols",
                    body: [
                        { port: "4444", protocol: "TCP", status: "OPEN", cpe: "Example", comments: "Example" }
                    ]
                },
                {
                    title: "Session layer protocols",
                    body: [
                        { port: "4444", protocol: "UDP", status: "OPEN", cpe: "Example", comments: "Example" }
                    ]
                },
                {
                    title: "Transport layer protocols",
                    body: [
                        { port: "4444", protocol: "TCP", status: "OPEN", cpe: "Example", comments: "Example" }
                    ]
                },
                {
                    title: "Network layer protocols",
                    body: [
                        { port: "4444", protocol: "TCP", status: "OPEN", cpe: "Example", comments: "Example" }
                    ]
                },
                {
                    title: "Data link layer protocols",
                    body: [
                        { port: "4444", protocol: "TCP", status: "OPEN", cpe: "Example", comments: "Example" }
                    ]
                },
                {
                    title: "Physical layer protocols",
                    body: [
                        { port: "4444", protocol: "TCP", status: "OPEN", cpe: "Example", comments: "Example" }
                    ]
                }
            ],
            legend: "Table 2: Services."
        },
        subtitle_2: "Detected applications",
        description_3: "Finally, we aim to detect application frontends that may be susceptible to attacks within the identified domains.",
        table_2: {
            header: ["Asset", "Application"],
            body: [
                { active: "127.0.0.1", application: "Example" }
            ],
            legend: "Table 3: Applications."
        },
        description_4: "Once this phase was completed, the technologies used in the identified URLs were identified. The result of this identification is shown in the following table:",
        table_3: {
            header: ["Technology", "Implementation"],
            body: [
                { id: "Example", content: "Example" }
            ],
            legend: "Table 4: Technologies."
        }
    }
}

interface SectionProps {
    externalData: any
    lang?: string;
    assessment?: Assessment
    template?: Template
    client?: string
}

const SystemDiscovery: React.FC<SectionProps> = ({ externalData, lang, assessment, template, client }) => {
    // Constant
    const context = useContext(I18nContext)
    const serviceData = externalData?.servicesData || []
    const applicationData = externalData?.applicationsData || []
    const technologyData = externalData?.technologyData || []
    const additionalText = externalData?.additionalInfo || []
    // --------------------------------------------------

    // lenguaje
    type I18nType = typeof i18n
    let locale: keyof I18nType = "es"
    if (lang) {
        if (lang === "es" || lang === "en") {
            locale = lang as keyof I18nType
        } else {
            console.warn(`Idioma no soportado: ${lang}. Usando idioma por defecto.`)
        }
    } else if (context) {
        if (context.language === "es" || context.language === "en") {
            locale = context.language as keyof I18nType
        } else {
            console.warn(`Idioma no soportado en el contexto: ${context.language}. Usando idioma por defecto.`)
        }
    } else {
        console.error(
            "El contexto I18n no está inicializado. Asegúrese de que tiene el proveedor configurado correctamente."
        )
    }
    // --------------------------------------------------
    const servicesTable = externalData?.servicesData || []

    return (
        <Page size="A4" style={globalStyles.page}>
            <Header externalData={null} template={template}></Header>
            <View style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                padding: "0px 20px",
                gap: "20px"
            }}>
                <View style={globalStyles.column}>
                    <View style={globalStyles.container}>
                        <Text style={globalStyles.title}>{i18n[locale].title}</Text>
                        <Text style={globalStyles.texto}>{i18n[locale].description1}</Text>
                    </View>
                    <View style={globalStyles.container}>
                        <Text style={globalStyles.textoBold}>{i18n[locale].subtitle_1}</Text>
                        <Text style={globalStyles.texto}>{i18n[locale].description2}</Text>
                    </View>
                    <View style={{ ...globalStyles.container, marginBottom: "20px" }} break={false}>
                        <View style={{ ...globalStyles.table, marginBottom: "10px" }}>
                            <View style={{ ...globalStyles.row, backgroundColor: "yellow" }}>
                                {i18n[locale].table_1.header.map((header, index) => (
                                    <View key={index} style={globalStyles.col}>
                                        <Text style={globalStyles.textoBold}>{header}</Text>
                                    </View>
                                ))}
                            </View>

                            {i18n[locale].table_1.layers.map((layer, layerIndex) => {
                                const layerData = serviceData[layer.title] || []
                                if (layerData.length > 0) {
                                    return (
                                        <View key={layerIndex} style={globalStyles.container} break={false}>
                                            <View style={{ ...globalStyles.row, backgroundColor: "black" }}>
                                                <View style={globalStyles.col}>
                                                    <Text style={{ ...globalStyles.textoBold, color: "white" }}>
                                                        {layer.title}
                                                    </Text>
                                                </View>
                                            </View>
                                            {layerData.map((service: any, serviceIndex: number) => (
                                                <View key={serviceIndex} style={globalStyles.row} break={false}>
                                                    <View style={globalStyles.col}>
                                                        <Text style={globalStyles.texto}>{service.puerto}</Text>
                                                    </View>
                                                    <View style={globalStyles.col}>
                                                        <Text style={globalStyles.texto}>{service.protocolo}</Text>
                                                    </View>
                                                    <View style={globalStyles.col}>
                                                        <Text style={globalStyles.texto}>{service.estado}</Text>
                                                    </View>
                                                    <View style={globalStyles.col}>
                                                        <Text style={globalStyles.texto}>{service.cpe}</Text>
                                                    </View>
                                                    <View style={globalStyles.col}>
                                                        <Text style={globalStyles.texto}>{service.comentarios}</Text>
                                                    </View>
                                                </View>
                                            ))}
                                        </View>
                                    )
                                }
                                // Si no hay datos en serviceData, no renderizamos nada
                                return null
                            })}
                        </View>

                        <View style={globalStyles.table_legend}>
                            <Text style={globalStyles.texto}>{i18n[locale].table_1.legend}</Text>
                        </View>
                        <View style={globalStyles.container}>
                            <Text style={globalStyles.texto}>{servicesTable.additionalInfo}</Text>
                        </View>
                    </View>
                    <View style={globalStyles.container}>
                        <Text style={globalStyles.textoBold}>{i18n[locale].subtitle_2}</Text>
                        <Text style={globalStyles.texto}>{i18n[locale].description_3}</Text>
                        <View style={globalStyles.table}>
                            <View style={{ ...globalStyles.row, backgroundColor: "yellow" }}>
                                {i18n[locale].table_2.header.map((header, index) => (
                                    <View key={index} style={globalStyles.col}>
                                        <Text style={globalStyles.textoBold}>{header}</Text>
                                    </View>
                                ))}
                            </View>
                            {applicationData.map((application: any, index: number) => (
                                <View key={index} style={globalStyles.row}>
                                    <View style={globalStyles.col}>
                                        <Text style={globalStyles.texto}>{application.activo}</Text>
                                    </View>
                                    <View style={globalStyles.col}>
                                        <Text style={globalStyles.texto}>{application.aplicacion}</Text>
                                    </View>
                                </View>
                            ))}
                        </View>
                        <View style={globalStyles.table_legend}>
                            <Text style={globalStyles.texto}>{i18n[locale].table_2.legend}</Text>
                        </View>
                    </View>
                    <View style={globalStyles.container}>
                        <Text style={globalStyles.texto}>{i18n[locale].description_4}</Text>
                        <View style={globalStyles.table}>
                            <View style={{ ...globalStyles.row, backgroundColor: "yellow" }} wrap={false}>
                                {i18n[locale].table_3.header.map((header, index) => (
                                    <View key={index} style={globalStyles.col}>
                                        <Text style={globalStyles.textoBold}>{header}</Text>
                                    </View>
                                ))}
                            </View>
                            {technologyData.map((technology: any, index: number) => (
                                <View key={index} style={globalStyles.row} wrap={false}>
                                    <View style={globalStyles.col}>
                                        <Text style={globalStyles.texto}>{technology.tecnologia}</Text>
                                    </View>
                                    <View style={globalStyles.col}>
                                        <Text style={globalStyles.texto}>{technology.implementacion}</Text>
                                    </View>
                                </View>
                            ))}
                        </View>
                        <View style={globalStyles.table_legend}>
                            <Text style={globalStyles.texto}>{i18n[locale].table_3.legend}</Text>
                        </View>
                    </View>
                </View>
                {/* <View style={globalStyles.column}>
                    <View style={globalStyles.container}>

                    </View>
                </View> */}
            </View>
            <Footer externalData={{ clientName: client }} template={template}></Footer>
        </Page>
    )
}

export { SystemDiscovery }
