import React from "react"
import { TestType } from "@models/Achilles"
import { Typography } from "@mui/material"

interface TestTypeColoredProps {
    value: TestType;
}
// Define la función para interpolar colores entre verde y rojo
const interpolateColor = (percentage: number): string => {
    const clampedPercentage = Math.max(0, Math.min(100, percentage)) // Asegurarse de que esté entre 0 y 100
    const green = Math.round(255 * (1 - clampedPercentage / 100)) // Decrece de 255 a 0
    const red = Math.round(255 * (clampedPercentage / 100)) // Aumenta de 0 a 255
    return `rgb(${red}, ${green}, 0)` // Genera el color RGB
}

const AchillesFailureRender: React.FC<{ value: string }> = ({ value }) => {
    const percentage = parseFloat(value) // Convierte el texto a número
    const color = interpolateColor(percentage) // Calcula el color basado en el porcentaje

    return (
        <Typography
            style={{
                color, // Asigna el color calculado
                fontWeight: "bolder",
                textAlign: "center"
            }}
        >
            {value}
        </Typography>
    )
}

export { AchillesFailureRender }
export default AchillesFailureRender
