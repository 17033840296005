import { Checkbox, Divider, Grid, MenuItem, TextField, Typography, useTheme } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { ClientSelector } from "@components/client/index"
import { ServicesContext } from "@context/index"
import { AbilityContext } from "@components/permissions/index"
import { LicenseTypeColors } from "@components/common/colors/LicenseTypeColors"
import { I18nContext } from "I18nProvider"
import { LicenseTypeKeys } from "@components/license/LicenseTableDefinition"
import License, { LicenseType } from "@models/License"
import ActionButton from "@components/common/Button/ActionButton"
import { StyledBox } from "@components/common/Box/BoxContainer"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"

const initValue = {
    id: "",
    name: "",
    type: LicenseType.Golden,
    max_assessment: "0",
    max_prod: "0",
    toolbox: false,
    client: { id: "" }
}

const LicenseDetail: React.FC = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const theme = useTheme()
    const licenseService = useContext(ServicesContext).licenseService

    const [error, setError] = useState<Error|null>(null)
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const { id } = useParams<{ id: string }>()
    const [data, setData] = useState<License>(initValue)
    const [formData, setFormData] = useState<License>(initValue)

    const ability = useContext(AbilityContext)

    const formValid = (): boolean => {
        const isNotNullrules = [
            formData.max_assessment === "0",
            formData.max_prod === "0",
            formData.client.id === "",
            formData.client.id === undefined
        ]
        if (isNotNullrules.some(rule => rule)) {
            return false
        }
        const isNotSameOldValues = [
            formData.name === data.name,
            formData.max_assessment === data.max_assessment,
            formData.max_prod === data.max_prod,
            formData.type === data.type,
            formData.client.id === data.client.id,
            formData.toolbox === data.toolbox
        ]
        if (isNotSameOldValues.every(rule => rule)) {
            return false
        }
        return true
    }

    // Generic change handler for all form fields
    const handleChange = (e:any) => {
        const { name, value } = e.target

        if (name === "max_prod" || name === "max_assessment") {
            if (!/^\d*$/.test(value)) {
                return
            }
        }

        setFormData({ ...formData, [name]: value })
    }

    // Fetch license data from API
    const fecthData = async () => {
        try {
            const val = await licenseService.get(id as string)
            setData(val)
            setFormData(val)
        } catch (e: any) {
            setError({ message: e.error } as Error)
            setSnackbarOpen(true)
        }
    }
    useEffect(() => {
        fecthData()
    }, [id])

    const navigate = useNavigate()
    // API Call save license
    const saveHandler = async () => {
        try {
            await licenseService.update(formData.id, formData)
            setData(formData)
            navigate(-1)
        } catch (e: any) {
            setError({ message: e.error } as Error)
        }
    }
    // API Call save license - END

    if (data.id === "") {
        return (<CustomSnackbar
            open={snackbarOpen}
            onClose={() => setSnackbarOpen(false)}
            message={error?.message || context.t.translate("an_error")}
        />)
    }

    return (<Grid item>
        <Grid item xs container flexDirection="column">
            <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                {context.t.translate("license_title")}
            </Typography>
        </Grid>
        <Grid item xs container flexDirection="column" spacing="20px">
            <Grid item container flexDirection="column" rowGap="35px">
                <Grid item container>
                    <StyledBox>
                        <CustomSnackbar
                            open={snackbarOpen}
                            onClose={() => setSnackbarOpen(false)}
                            message={error?.message || context.t.translate("error_message")}
                        />
                        <Grid container flexDirection="column" spacing="20px">
                            <Grid item flexDirection="row">
                                <Grid container flexDirection="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ marginTop: "10px" }}>
                                    <Grid item>
                                        <Typography fontSize="37px" fontFamily="Griff" color="primary" fontWeight="bolder">
                                        </Typography>
                                    </Grid>
                                    <Grid item container justifyContent="space-between">
                                        <ActionButton onClick={saveHandler} disabled={!formValid()} text={context.t.translate("save")} />
                                        <ActionButton onClick={() => navigate(-1)} text="Back" />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Divider sx={{ height: "1px", width: "100%", background: theme.palette.primary.main }} />
                            </Grid>
                            <Grid item container flexDirection="row" alignItems='center' spacing="40px">
                                <Grid item xs={6}>
                                    <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("client_name")}</Typography>
                                    <ClientSelector value={formData.client.id} disabled={!ability.can("update", "License")} onSelect={(id) => handleChange({ target: { name: "client", value: { id } } })}></ClientSelector>
                                </Grid>
                            </Grid>
                            <Grid item container flexDirection="row" alignItems='center' spacing="40px">
                                <Grid item xs={3}>
                                    <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("name")}</Typography>
                                    <TextField disabled={!ability.can("update", "License")} name="name" value={formData.name} onChange={handleChange} variant="standard" style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}/>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("type")}</Typography>
                                    <TextField disabled={!ability.can("update", "License")} margin="normal" select required variant="standard" name="type" style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}
                                        value={formData.type} onChange={handleChange}>
                                        {LicenseTypeKeys.map((key, index) =>
                                            (<MenuItem key={index} value={LicenseType[key]}><Typography fontFamily="Griff" fontWeight="bolder" sx={{ color: LicenseTypeColors[LicenseType[key]] }}>{key}</Typography></MenuItem>)
                                        )}
                                    </TextField>
                                </Grid>
                            </Grid>
                            <Grid item container flexDirection="row" alignItems='center' spacing="40px">
                                <Grid item xs={3}>
                                    <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("max_products")}</Typography>
                                    <TextField disabled={!ability.can("update", "License")} name="max_prod" value={formData.max_prod} onChange={handleChange} variant="standard" style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}/>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("max_assessments")}</Typography>
                                    <TextField disabled={!ability.can("update", "License")} name="max_assessment" value={formData.max_assessment} onChange={handleChange} variant="standard" style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}/>
                                </Grid>
                                <Grid item xs={3}>
                                    <Grid container xs={12} alignItems="center" direction="row">
                                        <Checkbox name="toolbox" disabled checked={formData.toolbox} style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}/>
                                        {formData.toolbox
                                            ? (
                                                <Typography color="#ffffff" fontSize="18px" fontFamily="Griff" fontWeight="bolder" style={{ marginLeft: 8 }}>
                                                    {context.t.translate("include_toolbox")}
                                                </Typography>
                                            )
                                            : (
                                                <Typography color="#ffffff" fontSize="18px" fontFamily="Griff" style={{ marginLeft: 8 }}>
                                                    {context.t.translate("without_toolbox")}
                                                </Typography>
                                            )}
                                    </Grid>
                                </Grid>

                            </Grid>

                        </Grid>
                    </StyledBox>
                </Grid>
            </Grid >
        </Grid >

    </Grid>
    )
}

export { LicenseDetail }
export default LicenseDetail
