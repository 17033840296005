import { MediaTypes } from "@services/index"
import Service from "@services/abstract/BaseService"
import { CustomText } from "@models/CustomText"

class CustomTextService extends Service<CustomText> {
    public constructor () {
        super(process.env.REACT_APP_API, "api/v1/custom", MediaTypes.JSON)
    }
}

export { CustomTextService }
export default CustomTextService
