import { Checkbox, Divider, Grid, TextField, Typography, useTheme } from "@mui/material"
import { StyledBox } from "@components/common/Box/BoxContainer"
import { KEV, RansomwareCampaignUse } from "@models/KEV"
import { useContext, useEffect, useState } from "react"
import ServicesContext from "@context/ServicesContext"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import { useParams } from "react-router-dom"
import { I18nContext } from "I18nProvider"

const KEVDetails = () => {
    const theme = useTheme()
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const kevService = useContext(ServicesContext).KEVService
    const { kevid } = useParams()
    const [kevData, setKevData] = useState<KEV | null>(null)
    const [error, setError] = useState<string | null>(null)
    const [snackbarOpen, setSnackbarOpen] = useState(false)

    useEffect(() => {
        const fetchKEV = async () => {
            try {
                const data = await kevService.get(kevid as string)
                setKevData(data)
            } catch (e: any) {
                setError(e.message || context.t.translate("an_error_occurred"))
                setSnackbarOpen(true)
            }
        }
        if (kevid) fetchKEV()
    }, [kevid])

    const formatDate = (dateString: string) => {
        if (!dateString) return "N/A"
        const date = new Date(dateString)
        return new Intl.DateTimeFormat("en-GB").format(date) // Format as DD/MM/YYYY
    }

    if (!kevData) {
        return <Typography color="primary">{context.t.translate("loading")}</Typography>
    }

    return (
        <Grid item xs container flexDirection="column" spacing="20px">
            <Grid item container flexDirection="column" rowGap="35px">
                <Grid item container>
                    <StyledBox>
                        <CustomSnackbar
                            open={snackbarOpen}
                            onClose={() => setSnackbarOpen(false)}
                            message={error || context.t.translate("an_error")}
                        />
                        <Grid item container justifyContent="space-between" alignItems="center">
                            <Grid item>
                                <Typography fontSize="37px" fontFamily="Griff" color="primary" fontWeight="bolder">
                                    {kevData.vulnerability_name || context.t.translate("kev_details")}
                                </Typography>
                            </Grid>

                        </Grid>
                        <Grid item my={2}>
                            <Divider sx={{ height: "1px", width: "100%", background: theme.palette.primary.main }} />
                        </Grid>
                        <Grid item container flexDirection="row" alignItems="center" spacing="40px">
                            <Grid item xs={12} md={6}>
                                <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("cve_id")}</Typography>
                                <TextField
                                    disabled
                                    name="cve_id"
                                    value={kevData.cve_id}
                                    variant="standard"
                                    style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("vendor_project")}</Typography>
                                <TextField
                                    disabled
                                    name="vendor_project"
                                    value={kevData.vendor_project}
                                    variant="standard"
                                    style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("vendor_project")}</Typography>
                                <TextField
                                    disabled
                                    name="product"
                                    value={kevData.product}
                                    variant="standard"
                                    style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("vendor_project")}</Typography>
                                <TextField
                                    disabled
                                    name="date_added"
                                    value={formatDate(kevData.date_added)}
                                    variant="standard"
                                    style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("due_date")}</Typography>
                                <TextField
                                    disabled
                                    name="due_date"
                                    value={formatDate(kevData.due_date)}
                                    variant="standard"
                                    style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("ransomware_campaign")}</Typography>
                                <Checkbox
                                    disabled
                                    checked={kevData.known_ransomware_campaign_use === RansomwareCampaignUse.Known}
                                    name="ransomware_campaign"
                                />
                            </Grid>
                        </Grid>
                        {/* Required Action & Notes moved below */}
                        <Grid item container flexDirection="column" rowGap="25px" mt={4}>
                            <Grid item xs={12}>
                                <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("required_actiom")}</Typography>
                                <TextField
                                    disabled
                                    name="required_action"
                                    value={kevData.required_action || context.t.translate("no_action")}
                                    variant="standard"
                                    multiline
                                    minRows={3}
                                    fullWidth
                                    style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("notes")}</Typography>
                                <TextField
                                    disabled
                                    name="notes"
                                    value={kevData.notes || context.t.translate("no_notes")}
                                    variant="standard"
                                    multiline
                                    minRows={3}
                                    fullWidth
                                    style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}
                                />
                            </Grid>
                        </Grid>
                    </StyledBox>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default KEVDetails
