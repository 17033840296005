const Secrets = {
    JiraSecret: "jira_webhook_secret"
}

type VaultSecret = {
    key: string;
    value?: string;
}

export { Secrets }

export type { VaultSecret }
