import React, { useContext } from "react"
import { ComplianceResult } from "@models/Compliance"
import { I18nContext } from "I18nProvider"
import LegendCircle from "./LegendCircle"

// Definir los tipos permitidos para el prop 'type'
type LegendProps = {
    type: "CRA" | "FDA"; // Solo acepta "CRA" o "FDA"
};

const Legend: React.FC<LegendProps> = ({ type }) => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }

    // Leyendas para CRA
    const craLegendData = [
        { color: "green", value: ComplianceResult.Yes, labelEs: "Si", labelEn: "Yes" },
        { color: "red", value: ComplianceResult.No, labelEs: "No", labelEn: "No" },
        { color: "grey", value: ComplianceResult.NoKnown, labelEs: "No lo se", labelEn: "I Dont Know" }
    ]

    // Leyendas para FDA
    const fdaLegendData = [
        { color: "#93000A", value: ComplianceResult.NonCompliant, labelEs: "No Cumple", labelEn: "Non Compliant" },
        { color: "#93000A", value: ComplianceResult.NoDocument, labelEs: "No hay Documento Disponible", labelEn: "No Document Available" },
        { color: "#CC8900", value: ComplianceResult.PartialDocument, labelEs: "Documento Parcial Disponible", labelEn: "Partial Document Available" },
        { color: "#E9D71F", value: ComplianceResult.PartiallyCompliant, labelEs: "Cumple Parcialmente", labelEn: "Partially Compliant" },
        { color: "green", value: ComplianceResult.Yes, labelEs: "Si", labelEn: "Yes" },
        { color: "red", value: ComplianceResult.No, labelEs: "No", labelEn: "No" },
        { color: "#00AC39", value: ComplianceResult.FullDocument, labelEs: "Documento Completo Disponible", labelEn: "Full Document Available" }
    ]

    // Elegir los datos correctos según el tipo
    const legendData = type === "CRA" ? craLegendData : fdaLegendData

    return (
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            {legendData.map((item, index) => {
                const label = context.language === "es" ? item.labelEs : item.labelEn // Seleccionar el label según el contexto
                return (
                    <LegendCircle key={index} color={item.color} label={label} />
                )
            })}
        </div>
    )
}

export default Legend
