import { VulnerabilityIndicator } from "@components/common/indicator/VulnerabilityIndicator"
import KevCheckbox from "@components/vulnerability/KevCheckbox"
import Component from "@models/Component"
import { ModelDefinition } from "@models/utils"
import { FilterOperation } from "@utils/queryParams"
import LicenseIndicator from "./LicenseIndicator"
import ComponentNameNavigate from "./ComponentNameNavigate"

const ComponentTableDefinition: ModelDefinition<Component> = {
    properties: [
        { name: "id", label: "Id", type: "string", flex: 0.3, isId: true },
        // { name: "bom_ref", label: "Bom Ref", type: "string", flex: 0.25 },
        { name: "name", label: "Name", type: "string", flex: 0.25, render: ComponentNameNavigate },
        { name: "version", label: "Version", type: "string", flex: 0.25 },
        {
            name: "vulns_numbers",
            label: "Total Vulns",
            type: "struct",
            flex: 0.5,
            render: (params) => {
                if (!params.row || !params.value) return null
                return (
                    <VulnerabilityIndicator
                        filters={[{ field: "affected_component", operation: FilterOperation.UUIDEqual, value: params.row.id }]}
                        critical={params.value.critical || 0}
                        high={params.value.high || 0}
                        medium={params.value.medium || 0}
                        low={params.value.low || 0}
                        none={params.value.none || 0}
                    />
                )
            }
        },
        { name: "supressed", label: "Supressed", type: "string", flex: 0.1, render: KevCheckbox },
        { name: "outdated", label: "Outdated", type: "string", flex: 0.1, render: KevCheckbox },
        {
            name: "license",
            label: "License",
            type: "struct",
            flex: 0.3,
            render: (params) => {
                return (<LicenseIndicator row={params.row} />)
            }
        }
    ]
}

export default ComponentTableDefinition
