import React, { ChangeEvent, useCallback } from "react"
import { Grid, IconButton, TextField, Tooltip, Typography, useTheme } from "@mui/material"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { MdOutlineDragIndicator } from "react-icons/md"
import { SectionFormProps } from "./SectionFormProps"

interface CoverData {
    header: string;
    subheader: string;
    metadata: string;
}

const initCoverValue: CoverData = {
    header: "",
    subheader: "",
    metadata: ""
}

const CoverPage: React.FC<SectionFormProps<CoverData>> = ({ id, data, onSectionChange, openRequest }) => {
    const theme = useTheme()

    const handleInputChange = useCallback(
        (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            // Ensure that events is always an array for consistent processing
            const events = (Array.isArray(e) ? e : [e]) as ChangeEvent<HTMLInputElement | HTMLTextAreaElement>[]

            // Create the updated form data by applying the changes from events
            const updatedData = events.reduce(
                (result, { target: { name, value } }) => ({
                    ...result,
                    [name]: value
                }),
                { ...data }
            )

            // Update state and notify the parent of the change
            onSectionChange(id, updatedData as CoverData, true)
        },
        [id, data, onSectionChange]
    )

    const handleOpen = openRequest

    return (<Grid item container my={2}>
        <Grid item container xs={12} justifyContent="space-between" alignItems="center" bgcolor={theme.palette.primary.main} height="40px" sx={{ borderRadius: "5px", cursor: "grab", padding: "0 10px" }}>
            <Typography color="Black" fontSize="22px" fontWeight="bolder">Cover Page</Typography>
            <MdOutlineDragIndicator size={24} color="black" />
        </Grid>
        <TextField margin="normal" required variant="standard" label="Header" value={data.header} onChange={handleInputChange} name="header"
            InputProps={{
                endAdornment: (
                    <Tooltip title="Help">
                        <IconButton onClick={() => { handleOpen("/assets/img/dragAndDropView/customHeader.png") }}>
                            <HelpOutlineIcon />
                        </IconButton>
                    </Tooltip>
                )
            }}/>
        <TextField margin="normal" required variant="standard" label="Subheader" value={data.subheader} onChange={handleInputChange} name="subheader"
            InputProps={{
                endAdornment: (
                    <Tooltip title="Help">
                        <IconButton onClick={() => { handleOpen("/assets/img/dragAndDropView/customSubheader.png") }}>
                            <HelpOutlineIcon />
                        </IconButton>
                    </Tooltip>
                )
            }}/>
        <TextField margin="normal" required variant="standard" label="Metadata info" value={data.metadata} onChange={handleInputChange} name="metadata"
            InputProps={{
                endAdornment: (
                    <Tooltip title="Help">
                        <IconButton onClick={() => { handleOpen("/assets/img/dragAndDropView/customMetadata.png") }}>
                            <HelpOutlineIcon />
                        </IconButton>
                    </Tooltip>
                )
            }}/>
    </Grid>
    )
}

export { CoverPage, initCoverValue }
export type { CoverData }
export default CoverPage
