import React, { useContext, useState } from "react"
import { Grid, Typography, useTheme } from "@mui/material"
import EditIcon from "@mui/icons-material/Edit"
import SendIcon from "@mui/icons-material/Send"
import DownloadIcon from "@mui/icons-material/Download"
import { useParams } from "react-router-dom"
import { ComplianceResult, QuizzStatus, QuizzType } from "@models/Compliance"
import GradiantGauge from "@components/charts/gauge/GradientGauge"
import QuizStateColored from "@components/compliance/QuizStateColored"
import { QuizIndicator } from "@components/common/indicator/QuizzIndicator"
import Legend from "@components/common/Legend/QuizLegend/QuizzLegend"
import ActionButton from "@components/common/Button/ActionButton"
import { I18nContext } from "I18nProvider"
import VisibilityIcon from "@mui/icons-material/Visibility"
import CheckIcon from "@mui/icons-material/Check"
import { AbilityContext } from "@components/permissions"
interface QuizCardProps {
  score: number
  status: QuizzStatus
  resultCounts: Record<ComplianceResult, number>
  totalQuestions: number
  onEditConclusion: () => void
  onStartQuiz: () => void
  onExport: () => void
  title: string
  contextType: string
  isStarted?: boolean
  onConclusionExport?: ()=>void
}

const QuizCard: React.FC<QuizCardProps> = ({
    score,
    status,
    resultCounts,
    totalQuestions,
    onEditConclusion,
    onStartQuiz,
    onConclusionExport,
    onExport,
    title,
    contextType,
    isStarted
}) => {
    const theme = useTheme()
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const ability = useContext(AbilityContext)
    const [createQuiz, setCreateQuiz] = useState<boolean>(true) // State for checking if a quiz can be created
    const { id, type } = useParams<{ id: string, type: string }>()

    const renderButtons = () => {
        if (contextType === "list") {
            if (status === QuizzStatus.Pending || status) {
                return (
                    <>
                        {!ability.can("*", "Compliance")
                            ? <ActionButton
                                onClick={onStartQuiz}
                                icon={<EditIcon />}
                                text={context.t.translate("CRA_resume")}
                            />
                            : <ActionButton
                                onClick={onEditConclusion}
                                icon={<VisibilityIcon />}
                                text={context.t.translate("CRA_view")}
                            />}

                        <ActionButton
                            onClick={onExport}
                            icon={<DownloadIcon />}
                            text={context.t.translate("quizz_export")}
                            variant="outlined"
                        />
                    </>
                )
            }
            if (status === QuizzStatus.Finished) {
                return (
                    <>
                        <ActionButton
                            onClick={onEditConclusion}
                            icon={<VisibilityIcon />}
                            text={context.t.translate("CRA_view")}
                        />
                        <ActionButton
                            onClick={onExport}
                            icon={<DownloadIcon />}
                            text={context.t.translate("quizz_export")}
                            variant="outlined"
                        />

                    </>
                )
            }
            return (
                <ActionButton
                    onClick={onStartQuiz}
                    icon={<CheckIcon />}
                    text={context.t.translate("CRA_start")}
                />
            )
        } else if (contextType === "summary") {
            return (
                <>
                    {status !== QuizzStatus.Finished && (
                        <ActionButton
                            onClick={isStarted ? onEditConclusion : onStartQuiz}
                            icon={<SendIcon />}
                            text={context.t.translate("CRA_resume")}
                        />
                    )}
                    <ActionButton
                        onClick={onExport}
                        icon={<DownloadIcon />}
                        text={context.t.translate("quizz_export")}
                        variant="outlined"
                    />
                    { ability.can("*", "Compliance") &&
                    <ActionButton
                        onClick={onConclusionExport}
                        icon={<DownloadIcon />}
                        text="Download full report"
                        variant="outlined"
                    />}
                </>
            )
        }
        return null
    }
    return (
        <Grid item>
            <Grid container direction="column" spacing={2}>
                <Grid item>
                    <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                        {title}
                    </Typography>
                </Grid>

                <Grid container direction="column" spacing={2}>
                    <Grid item alignItems="center" display="flex">
                        <Grid item sm={4} display="flex" alignItems="flex-start" justifyContent="flex-start">
                            <GradiantGauge value={parseInt(score.toFixed(1))} />
                        </Grid>

                        <Grid gap={2} display="flex" flexDirection="column" sm={8}>
                            <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                                {context.t.translate("quizz_status_title")}
                            </Typography>

                            <Grid item>
                                <QuizStateColored value={status} />
                            </Grid>

                            <Grid item>
                                <QuizIndicator
                                    NoDocumentAvailable={resultCounts[ComplianceResult.NoDocument]}
                                    RequiredImprovements={resultCounts[ComplianceResult.PartialDocument]}
                                    PartiallyCompliant={resultCounts[ComplianceResult.PartiallyCompliant]}
                                    FullyCompliant={resultCounts[ComplianceResult.FullDocument]}
                                    Yes={resultCounts[ComplianceResult.Yes]}
                                    No={resultCounts[ComplianceResult.No]}
                                    NoKnown={resultCounts[ComplianceResult.NoKnown]}
                                    total={totalQuestions}
                                />
                            </Grid>

                            <Grid marginTop={2}>
                                <Legend type={type === QuizzType.CRA ? "CRA" : "FDA"} />
                            </Grid>

                        </Grid>
                    </Grid>

                    <Grid item>
                        <Grid gap={2} display="flex">
                            {renderButtons()}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default QuizCard
