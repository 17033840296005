import React, { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import {
    Divider,
    Grid,
    InputAdornment,
    TextField,
    Typography,
    useTheme
} from "@mui/material"
import { ServicesContext } from "@context/index"
import { Achilles, MonitorName, MonitorValue, TestType } from "@models/index"
import { I18nContext } from "I18nProvider"
import { StyledBox } from "@components/common/Box/BoxContainer"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import AchillesFailureRender from "@components/achilles/AchillesFailureRender"
import { useTrack } from "@components/track/TrackContext"

const initValue: Achilles = {
    id: "",
    test_type: TestType.TCP,
    test_name: "",
    monitor_name: MonitorName.Test,
    monitor_value: MonitorValue.TestGreen,
    failure_percentage: "0",
    assessment_id: { id: "" },
    product_id: { id: "", name: "", version: "" },
    client_id: { id: "" },
    anomaly: 0
}

const AchillesDetail: React.FC = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const theme = useTheme()
    const achillesService = useContext(ServicesContext).achillesService
    const [error, setError] = useState<Error | null>(null)
    const { id } = useParams<{ id: string }>()
    const [formData, setFormData] = useState<Achilles>(initValue)
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const { track, trackData } = useTrack()
    useEffect(() => {
        track({ view: "AchillesDetail" })
    }, [])
    // Fetch achilles data from API
    useEffect(() => {
        const fetchData = async () => {
            try {
                const val = await achillesService.get(id as string)
                setFormData(val)
            } catch (e: any) {
                setError({ message: e.error } as Error)
                setSnackbarOpen(true)
            }
        }
        fetchData()
    }, [id, achillesService])

    return (
        <Grid item flexDirection="column" rowGap="25px" sx={{ paddingTop: "0px !important" }}>

            {/* Snackbar for Errors */}
            <CustomSnackbar
                open={snackbarOpen}
                onClose={() => setSnackbarOpen(false)}
                message={error?.message || context.t.translate("an_error")}
            />

            <Grid item xs container flexDirection="column" rowGap="25px" aria-description="Achilles Section">
                {/* Header */}
                <Grid item xs sx={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
                    <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                        Achilles Details
                    </Typography>
                </Grid>

                {/* Details Section */}
                <StyledBox>
                    <Grid container flexDirection="column" spacing="20px">

                        {/* Test Name */}
                        <Grid item>
                            <Typography fontSize="37px" fontFamily="Griff" color="primary" fontWeight="bolder">
                                {formData.test_name}
                            </Typography>
                        </Grid>

                        <Divider sx={{ height: "1px", width: "98%", background: theme.palette.primary.main, margin: "0px auto" }} />

                        <Grid item container flexDirection="row" spacing={4}>
                            <Grid item xs={12} md={6}>
                                <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">
                                   Test Type
                                </Typography>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    value={formData.test_type}
                                    InputProps={{ readOnly: true }}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">
                                    Monitor Name
                                </Typography>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    value={formData.monitor_name}
                                    InputProps={{ readOnly: true }}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">
                                    Monitor Value
                                </Typography>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    value={formData.monitor_value}
                                    InputProps={{ readOnly: true }}
                                />
                            </Grid>

                            {formData.product_id.name && formData.product_id.version && <Grid item xs={12} md={6}>
                                <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">
                                    Product
                                </Typography>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    value={`${formData.product_id.name} (v${formData.product_id.version})`}
                                    InputProps={{ readOnly: true }}
                                />
                            </Grid>}
                            <Grid item xs={12} md={3}>
                                <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">
                                    Failure Percentage
                                </Typography>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    InputProps={{
                                        readOnly: true,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <AchillesFailureRender value={formData.failure_percentage} />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">
                                    Anomaly
                                </Typography>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    value={formData.anomaly}
                                    InputProps={{ readOnly: true }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </StyledBox>
            </Grid>
        </Grid>
    )
}

export { AchillesDetail }
export default AchillesDetail
