import React from "react"
import { useTheme } from "@mui/material/styles"
import { Breadcrumbs, Divider, Grid, Link, Paper, Typography } from "@mui/material"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import { Navigate, Route, Link as RouterLink, Routes, useLocation } from "react-router-dom"
import { Sidebar } from "@components/index"
import HeaderNotifications from "@views/settings/HeaderNotifications"
import { hiddenItems, sidebarPrimaryItems, sidebarSecondaryItems } from "@components/sidebar/SideBarConfig"

// Función para capitalizar la primera letra de cada palabra
// Función para capitalizar y dividir en palabras, manejando prefijo "add"
const capitalize = (str: string) => {
    if (str === "system_notification") {
        return "Notifications"
    }

    // Detectar prefijo "add" al inicio de la cadena
    if (str.startsWith("add")) {
        const remaining = str.slice(3) // Remover "add" del inicio
        return `Add ${remaining.charAt(0).toUpperCase()}${remaining.slice(1).toLowerCase()}`
    }

    // General: Dividir en palabras basándose en cambios de mayúscula o guiones bajos
    return str
        .split(/(?=[A-Z])|_+/) // Divide en palabras basándote en mayúsculas o guiones bajos
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitaliza cada palabra
        .join(" ") // Une las palabras con un espacio
}

const AppLayout: React.FC = () => {
    const theme = useTheme()
    const location = useLocation()

    const isUUID = (str: string): boolean => {
        const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/
        return uuidRegex.test(str)
    }

    const isIgnoredSegment = (str: string): boolean => {
        const ignoredSegments = ["settings", "manage", "hardwarecomponent"]
        return ignoredSegments.includes(str.toLowerCase())
    }

    const generateBreadcrumbs = () => {
        const pathnames = location.pathname.split("/").filter((x) => x)
        return pathnames.map((value, index) => {
            const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`

            let displayName
            if (isUUID(value)) {
                const previousSegment = pathnames[index - 1] || "Detail"
                displayName = `${capitalize(previousSegment)} Detail`
            } else {
                displayName = capitalize(value)
            }

            const isLast = index === pathnames.length - 1

            if (isIgnoredSegment(value)) {
                return (
                    <Typography key={index} color={isLast ? "primary" : "inherit"}>
                        {displayName}
                    </Typography>
                )
            }

            return isLast
                ? (
                    <Typography key={index} color="primary">
                        {displayName}
                    </Typography>
                )
                : (
                    <Link key={index} component={RouterLink} to={routeTo} color="inherit">
                        {displayName}
                    </Link>
                )
        })
    }

    return (
        <Paper sx={{ height: "100%", borderRadius: 0 }}>
            <Grid container sx={{ height: "100%" }}>
                <Grid item container flexDirection="column" paddingY="40px" sx={{ background: theme.palette.background.default, height: "100%", width: "90px" }}>
                    <Sidebar primaryItems={sidebarPrimaryItems} secondaryItems={sidebarSecondaryItems}/>
                </Grid>
                <Grid item xs container sx={{ background: theme.palette.secondary.contrastText, height: "100%", overflowY: "scroll" }}>
                    <Grid item xs padding="40px" container flexDirection="column" spacing="20px">
                        <Grid item container alignItems="center">
                            <Grid item xs display="flex" alignItems="center">
                                <Grid item>
                                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} >
                                        <Link component={RouterLink} to="/" color="primary" fontSize="32px" fontWeight="bolder" fontFamily="Griff" sx={{ textDecoration: "none" }}>
                                    Orbik
                                        </Link>
                                        {generateBreadcrumbs()}
                                    </Breadcrumbs>
                                </Grid>
                            </Grid>

                            <Grid item sx={{ marginTop: "8px" }}>
                                <HeaderNotifications />
                            </Grid>
                        </Grid>
                        <Divider sx={{ height: "0.5px", width: "100%", background: theme.palette.primary.main, margin: "9px 0" }} />
                        <Routes>
                            {
                                [...sidebarPrimaryItems, ...sidebarSecondaryItems, ...hiddenItems].flat(1).map((item, idx) => (
                                    <Route key={idx} path={item.route} element={item.component} />
                                ))
                            }
                            <Route key="" path="*" element={<Navigate to="/" replace={true} />} />
                        </Routes>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    )
}

export { AppLayout }
export default AppLayout
