import React, { createContext, useContext, useState } from "react"

interface ClientContextType {
    clientid: string | null;
    setClientid: (id: string | null) => void;
}

const ClientContext = createContext<ClientContextType | undefined>(undefined)

export const useClient = () => {
    const context = useContext(ClientContext)
    if (!context) {
        throw new Error("useClient must be used within a ClientProvider")
    }
    return context
}

export const ClientProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [clientid, setClientid] = useState<string | null>(null)

    return (
        <ClientContext.Provider value={{ clientid, setClientid }}>
            {children}
        </ClientContext.Provider>
    )
}
