
import { convertModelDefToGridColDef, GenericTable } from "@components/common/tables"
import { AbilityContext, Can } from "@components/permissions"
import SLA from "@models/SLA"
import { Grid, Typography, useTheme } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid"
import React, { useContext, useEffect, useState } from "react"
import { I18nContext } from "I18nProvider"
import { useNavigate } from "react-router-dom"
import ServicesContext from "@context/ServicesContext"
import ActionButton from "@components/common/Button/ActionButton"
import SLATableDefinition, { SLATableDefinitionES } from "@components/sla/SLATableDefinition"
import { useTrack } from "@components/track/TrackContext"

const SLAList: React.FC = () => {
    // Constants
    const theme = useTheme()
    const context = useContext(I18nContext)
    if (context === null) throw new Error("The I18n context is not initialized. Make sure you have the provider set up correctly.")
    const [cols, setCols] = useState<GridColDef[]>([])
    const ability = useContext(AbilityContext)
    const navigate = useNavigate()
    const slaService = useContext(ServicesContext).slaService
    const { track, trackData } = useTrack()

    // --------------------------------------------------
    // useEffect
    useEffect(() => {
        track({ view: "SLAList" })
    }, [])
    useEffect(() => {
        const policyTableDefinition = context.language === "es" ? SLATableDefinitionES : SLATableDefinition
        setCols(convertModelDefToGridColDef(policyTableDefinition, ability))
    }, [context.language, ability])
    // --------------------------------------------------
    return (
        <>
            <Grid item xs container flexDirection="column" spacing="20px" sx={{ position: "relative" }}>
                <Grid container sx={{ justifyContent: "space-between", alignItems: "center", spacing: "20px", paddingLeft: "20px", marginBottom: "0px", paddingBottom: "0px" }}>
                    <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff" sx={{ marginTop: "0px", marginLeft: "20px" }}>SLA Management</Typography>
                    <Can I="create" a="SLA">
                        <ActionButton onClick={() => navigate("./add")} text="Add New SLA" />
                    </Can>
                </Grid>
                <Grid item container flexDirection="column" rowGap="35px">
                    <GenericTable<SLA> entity="SLA" columns={cols}
                        dataProvider={(filter) => slaService.getAll(filter)}
                        onEdit={(elem: SLA) => navigate("./" + elem.id)}
                        onDelete={(elem: SLA) => slaService.delete(elem.id)}
                    />
                </Grid>
            </Grid>
        </>

    )
}
export { SLAList }
export default SLAList
