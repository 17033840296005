import { Checkbox, FormControlLabel, Grid, Typography, useTheme } from "@mui/material"
import React, { useCallback } from "react"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { MdOutlineDragIndicator } from "react-icons/md"
import { TMetadataSCAContent } from "@models/Template"
import { SectionFormProps } from "./SectionFormProps"

const initTOCValue: TMetadataSCAContent = {
    table_of_content: false,
    introduction: false,
    scope: false,
    executive_summary: false
}

const TableOfContents: React.FC<SectionFormProps<TMetadataSCAContent>> = ({ id, data, onSectionChange }) => {
    const theme = useTheme()
    // Drag and Drop functions
    const tocValid = (data: TMetadataSCAContent): boolean => {
        const isNotNullrules = [

            data.table_of_content !== undefined,
            data.introduction !== undefined,
            data.scope !== undefined,
            data.executive_summary !== undefined
        ]
        if (isNotNullrules.every(rule => rule)) {
            return false
        }
        return true
    }

    const handleChange = useCallback((e: any) => {
        const { name, checked } = e.target
        const updatedData = { ...data, [name]: checked }
        const valid = tocValid(updatedData)
        onSectionChange(id, updatedData, valid)
    }, [id, data, onSectionChange])

    return (<Grid item container mt={2} mb={3}>
        <Grid item container xs={12} justifyContent="space-between" alignItems="center" bgcolor={theme.palette.primary.main} height="40px" sx={{ borderRadius: "5px", cursor: "grab", padding: "0 10px" }}>
            <Typography color="Black" fontSize="22px" fontWeight="bolder">Table of Contents</Typography>
            <MdOutlineDragIndicator size={24} color="black" />
        </Grid>
        <Grid item><FormControlLabel name="table_of_content" checked={data.table_of_content} onChange={handleChange} control={<Checkbox />} label={<Typography>Add a table of contents</Typography>} labelPlacement="end" /></Grid>
        <FormControlLabel name="introduction" checked={data.introduction} onChange={handleChange} control={<Checkbox />} label={<Typography >Add the introduction section</Typography>} labelPlacement="end" />
        <FormControlLabel name="scope" checked={data.scope} onChange={handleChange} control={<Checkbox />} label={<Typography >Add the scope section</Typography>} labelPlacement="end" />
        <FormControlLabel name="executive_summary" checked={data.executive_summary} onChange={handleChange} control={<Checkbox />} label={<Typography >Add the executive summary section</Typography>} labelPlacement="end" />
    </Grid>
    )
}

export { TableOfContents, initTOCValue }

export default TableOfContents
