import React, { useContext, useState } from "react"
import {
    Button,
    IconButton, Paper, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, useTheme
} from "@mui/material"
import Compliance, { ComplianceResult, QuizzType, RegistryData } from "@models/Compliance"
import { ComplianceIndicator } from "@components/common/indicator/ComplianceIndicator"
import { I18nContext } from "I18nProvider"
import { MdExpandLess, MdExpandMore, MdOutlineCheck, MdOutlineClose } from "react-icons/md"
import { Can } from "@components/permissions"
import ActionButton from "@components/common/Button/ActionButton"
import ConclusionModal from "@views/compliance/components/ConclusionModal"
import ServicesContext from "@context/ServicesContext"

interface Props {
  categoryQuestions: Array<{ id: string; title: string; dependsOn?: string }>; // Las preguntas con id y título
  data: Compliance; // Datos con el registro de respuestas
}

const QuestionTable: React.FC<Props> = ({ categoryQuestions, data }) => {
    const theme = useTheme()
    const context = useContext(I18nContext)
    const [expandedQuestions, setExpandedQuestions] = useState<{ [key: string]: boolean }>({})
    const [modalData, setModalData] = useState({
        open: false,
        id: "",
        question: "",
        answer: "",
        conclusion: ""
    })
    const [showAll, setShowAll] = useState(false)

    const [conclusions, setConclusions] = useState<{ [key: string]: string }>(data.conclusions || {})
    const complianceService = useContext(ServicesContext).complianceService

    const handleSaveConclusion = async (conclusion: string) => {
        const currentConclusions = data.conclusions || {}
        const updatedConclusions = {
            ...currentConclusions,
            [modalData.id]: conclusion
        }
        const updatedData = {
            ...data,
            conclusions: updatedConclusions
        }

        try {
            await complianceService.update(data.id, updatedData)
            setConclusions(updatedConclusions)
            setModalData({ ...modalData, open: false })
        } catch (error) {
            console.error("Error updating conclusions:", error)
        }
    }

    const isAnswered = (id: string) => {
        return data.registry.some((entry) => entry[id as keyof RegistryData] !== "")
    }

    if (context === null) {
        throw new Error("The I18n context is not initialized. Make sure you have the provider set up correctly.")
    }

    const organizeQuestions = (questions: Array<{ id: string; dependsOn?: string }>) => {
        const groupedQuestions: Array<any> = []

        questions.forEach(question => {
            if (!question.dependsOn) {
                groupedQuestions.push({ ...question, subquestions: [] })
            } else {
                const parentQuestion = groupedQuestions.find(q => q.id === question.dependsOn)
                if (parentQuestion) {
                    parentQuestion.subquestions.push(question)
                }
            }
        })

        return groupedQuestions
    }

    const groupedQuestions = organizeQuestions(categoryQuestions)

    const toggleSubquestionsVisibility = (questionId: string) => {
        setExpandedQuestions(prevState => ({
            ...prevState,
            [questionId]: !prevState[questionId]
        }))
    }

    const visibleQuestions = showAll ? groupedQuestions : groupedQuestions.slice(0, 5)

    return (
        <TableContainer component={Paper} sx={{ backgroundColor: (theme) => theme.palette.secondary.contrastText }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>#</TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>{context.t.translate("question")}</TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>{context.t.translate("answered")}</TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>{context.t.translate("compliant")}</TableCell>
                        <Can I="*" a="Conclusions"><TableCell sx={{ fontWeight: "bold" }}>{context.t.translate("conclusions")}</TableCell></Can>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {visibleQuestions.map((question, index) => {
                        const answered = isAnswered(question.id)
                        const answer = data.registry?.[data.registry.length - 1]?.[question.id as keyof RegistryData] as ComplianceResult || ComplianceResult.NonCompliant

                        return (
                            <React.Fragment key={question.id}>
                                <TableRow
                                    sx={{
                                        ...(answered ? {} : { "& td": { opacity: 0.5 } })
                                    }}
                                >
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell sx={{ color: (theme) => theme.palette.primary.main }}>
                                        {data.type === QuizzType.CRA ? question.text : question.title}
                                    </TableCell>
                                    <TableCell align="center">
                                        {answered ? <MdOutlineCheck size="25px" /> : <MdOutlineClose size="25px" />}
                                    </TableCell>
                                    <TableCell>
                                        <ComplianceIndicator result={answer} />
                                    </TableCell>
                                    <Can I="*" a="Conclusions">
                                        <TableCell>
                                            <ActionButton
                                                text="Conclusion"
                                                onClick={() => {
                                                    if (question.id && data.registry.length > 0) {
                                                        const answer = data.registry[data.registry.length - 1][question.id as keyof RegistryData] as ComplianceResult
                                                        setModalData({
                                                            open: true,
                                                            id: question.id,
                                                            question: question.title,
                                                            answer: answer.toString() || "No answer",
                                                            conclusion: conclusions[question.id] || ""
                                                        })
                                                    }
                                                }}
                                            />
                                        </TableCell>
                                    </Can>
                                    <TableCell>
                                        {question.subquestions.length > 0 && (
                                            <IconButton onClick={() => toggleSubquestionsVisibility(question.id)}>
                                                {expandedQuestions[question.id] ? <MdExpandLess /> : <MdExpandMore />}
                                            </IconButton>
                                        )}
                                    </TableCell>
                                </TableRow>

                                {expandedQuestions[question.id] && question.subquestions.map((subquestion: any, subIndex: any) => {
                                    const subAnswered = isAnswered(subquestion.id)
                                    const subAnswer = data.registry?.[data.registry.length - 1]?.[subquestion.id as keyof RegistryData] as ComplianceResult || ComplianceResult.NonCompliant

                                    return (
                                        <TableRow
                                            key={subquestion.id}
                                            sx={{
                                                "& td": {
                                                    paddingLeft: 4
                                                },
                                                ...(subAnswered ? {} : { "& td": { opacity: 0.5 } })
                                            }}
                                        >
                                            <TableCell>{`${index + 1}.${subIndex + 1}`}</TableCell>
                                            <TableCell>
                                                {data.type === QuizzType.CRA ? subquestion.text : subquestion.title}
                                            </TableCell>
                                            <TableCell align="center">
                                                {subAnswered ? <MdOutlineCheck size="25px" /> : <MdOutlineClose size="25px" />}
                                            </TableCell>
                                            <TableCell>
                                                <ComplianceIndicator
                                                    result={subAnswer}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </React.Fragment>
                        )
                    })}
                </TableBody>
            </Table>

            {groupedQuestions.length > 5 && (
                <Button onClick={() => setShowAll(!showAll)} sx={{ margin: 2 }}>
                    {showAll ? context.t.translate("viewLess") : context.t.translate("viewMore")}
                </Button>
            )}

            <ConclusionModal
                open={modalData.open}
                onClose={() => setModalData({ open: false, id: "", question: "", answer: "", conclusion: "" })}
                title="Add conclusions"
                question={modalData.question || "Unknown question"}
                answer={modalData.answer || "No answer"}
                initialConclusion={modalData.conclusion || ""}
                onSave={handleSaveConclusion} // Pasa el manejador de guardado
            />
        </TableContainer>
    )
}

export default QuestionTable
