import React, { useState } from "react"
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography
} from "@mui/material"
import ActionButton from "@components/common/Button/ActionButton"

interface ComplianceModalProps {
    open: boolean;
    onClose: () => void;
    onSave: (
        averageScore: number,
        scores: { [key: string]: number }
    ) => void; // Devuelve la media y los puntajes por categoría
    isAdmin: boolean;
}

const ScoreCompliance: React.FC<ComplianceModalProps> = ({
    open,
    onClose,
    onSave,
    isAdmin
}) => {
    const [scores, setScores] = useState<{ [key: string]: number }>({
        "CRA Situation Question": 0,
        "Security Requirements": 0,
        "Vulnerability Management Requirements": 0
    })

    const calculateAverage = () =>
        Object.values(scores).reduce((a, b) => a + b, 0) / Object.keys(scores).length

    const handleScoreChange = (category: string, value: string) => {
        // Eliminar ceros iniciales y validar como número
        const normalizedValue = value.replace(/^0+(?!$)/, "") || "0"
        let numericValue = parseFloat(normalizedValue)

        // Limitar entre 0 y 10
        if (numericValue > 10) numericValue = 10
        if (numericValue < 0) numericValue = 0

        // Actualizar el estado
        setScores((prevScores) => ({
            ...prevScores,
            [category]: numericValue
        }))
    }

    const handleSave = () => {
        const averageScore = calculateAverage()
        onSave(averageScore, scores) // Devuelve los puntajes individuales y la media
        onClose()
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Set Compliance Scores</DialogTitle>
            <DialogContent>
                {isAdmin && (
                    <Box mt={2}>
                        <Typography>Enter the compliance scores:</Typography>
                        {Object.keys(scores).map((category) => (
                            <TextField
                                key={category}
                                type="number"
                                label={category}
                                value={scores[category]}
                                onChange={(e) => handleScoreChange(category, e.target.value)}
                                fullWidth
                                variant="outlined"
                                size="small"
                                inputProps={{ min: 0, max: 10, step: 0.1 }}
                                margin="normal"
                            />
                        ))}
                        <Typography mt={2}>
                            Average Score: <strong>{calculateAverage().toFixed(2)}</strong>
                        </Typography>
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <ActionButton
                    variant="contained"
                    onClick={handleSave}
                    text="Save"
                />
            </DialogActions>
        </Dialog>
    )
}

export default ScoreCompliance
