
import { ComplianceResult, FunctionalRequirementCategory, Question, QuestionType } from "../../models/Compliance"

export const CRAQuestions: Question[] = [
    {
        id: "q1",
        category: FunctionalRequirementCategory.Context,
        type: QuestionType.Text,
        title: "Knowledge about the CRA",
        description: "Evaluation of the manufacturer's knowledge about the CRA.",
        totalValue: 0,
        text: "What knowledge do you have about the CRA?",
        results: []
    },
    {
        id: "q2",
        category: FunctionalRequirementCategory.Context,
        type: QuestionType.Text,
        title: "Applicable regulations",
        description: "Evaluation of the manufacturer's knowledge about the regulations applicable to their products.",
        totalValue: 0,
        text: "Do you know the regulations applicable to your products?",
        results: []
    },
    {
        id: "q3",
        category: FunctionalRequirementCategory.Context,
        type: QuestionType.Text,
        title: "Cybersecurity in the company",
        description: "Evaluation of the company's track record in product cybersecurity.",
        totalValue: 0,
        text: "What is the company's track record in product cybersecurity?",
        results: []
    },
    {
        id: "q4",
        category: FunctionalRequirementCategory.Context,
        type: QuestionType.Text,
        title: "Interest in certification",
        description: "Identification of the products that the manufacturer wishes to certify.",
        totalValue: 0,
        text: "Which products are you interested in certifying?",
        results: []
    },
    {
        id: "q5",
        category: FunctionalRequirementCategory.Security,
        type: QuestionType.SingleSelect,
        title: "Design, development, and production of products with digital elements",
        description: "Products with digital elements shall be designed, developed, and produced to ensure an adequate level of cybersecurity based on existing risks.",
        totalValue: 0,
        text: "Have you performed a risk analysis?",
        results: [
            {
                result: ComplianceResult.Yes,
                text: "The system does not meet the compliance requirements. Immediate action is needed to address the non-compliance issues and bring the system up to the required standards.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.No,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.NoKnown,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            }
        ]
    },
    {
        id: "q5.2",
        category: FunctionalRequirementCategory.Security,
        type: QuestionType.SingleSelect,
        dependsOn: "q5",
        condition: ComplianceResult.Yes,
        title: "Design, development, and production of products with digital elements",
        description: "Products with digital elements shall be designed, developed, and produced to ensure an adequate level of cybersecurity based on existing risks.",
        totalValue: 0,
        text: "Is the performed risk analysis systematized and documented?",
        results: [
            {
                result: ComplianceResult.Yes,
                text: "The system does not meet the compliance requirements. Immediate action is needed to address the non-compliance issues and bring the system up to the required standards.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.No,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.NoKnown,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0

            }
        ]
    },
    {
        id: "q5.3",
        category: FunctionalRequirementCategory.Security,
        type: QuestionType.Text,
        dependsOn: "q5",
        condition: ComplianceResult.Yes,
        title: "Design, development, and production of products with digital elements",
        description: "Products with digital elements shall be designed, developed, and produced to ensure an adequate level of cybersecurity based on existing risks.",
        totalValue: 0,
        text: "If a risk analysis has been performed, was it conducted according to a recognized standard or best practice guide?",
        results: []
    },
    {
        id: "q6",
        category: FunctionalRequirementCategory.Security,
        type: QuestionType.SingleSelect,
        title: "Design, development, and production of products with digital elements",
        description: "Products with digital elements shall be designed, developed, and produced to ensure an adequate level of cybersecurity based on existing risks.",
        totalValue: 0,
        requiresJustification: true,
        text: "Is the product under analysis designed, developed, and produced to guarantee its cybersecurity?",
        results: [
            {
                result: ComplianceResult.Yes,
                text: "The system does not meet the compliance requirements. Immediate action is needed to address the non-compliance issues and bring the system up to the required standards.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.No,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.NoKnown,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            }
        ]
    },
    {
        id: "q7",
        category: FunctionalRequirementCategory.Security,
        type: QuestionType.Text,
        title: "Generally, what mechanisms or practices are implemented to ensure cybersecurity in these three phases?",
        description: "Products with digital elements shall be designed, developed, and produced to ensure an adequate level of cybersecurity based on existing risks.",
        totalValue: 0,
        text: "Generally, what mechanisms or practices are implemented to ensure cybersecurity in these three phases?",
        results: []
    },
    {
        id: "q8",
        category: FunctionalRequirementCategory.Security,
        type: QuestionType.SingleSelect,
        title: "Design, development and production of products with digital elements",
        description: "On the basis of the risk assessment referred to in Article 13(0), and where appropriate, products with digital elements shall be made available on the market without known exploitable vulnerabilities",
        totalValue: 0,
        requiresJustification: true,
        text: "Is the analyzed product delivered without any known exploitable vulnerabilities?",
        results: [
            {
                result: ComplianceResult.Yes,
                text: "The system does not meet the compliance requirements. Immediate action is needed to address the non-compliance issues and bring the system up to the required standards.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.No,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.NoKnown,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            }
        ]
    },
    {
        id: "q9",
        category: FunctionalRequirementCategory.Security,
        type: QuestionType.SingleSelect,
        title: "Design, development and production of products with digital elements",
        description: "On the basis of the cybersecurity risk assessment referred to in Article 13(0) and where applicable, products with digital elements shall be made available on the market with a secure by default configuration, unless otherwise agreed between manufacturer and business user in relation to a tailor-made product with digital elements, including the possibility to reset the product to its original state.",
        totalValue: 0,
        requiresJustification: true,
        text: "Is the product delivered with a secure default configuration?",
        results: [
            {
                result: ComplianceResult.Yes,
                text: "The system does not meet the compliance requirements. Immediate action is needed to address the non-compliance issues and bring the system up to the required standards.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.No,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.NoKnown,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            }
        ]
    }, {
        id: "q10",
        category: FunctionalRequirementCategory.Security,
        type: QuestionType.Text,
        title: "Design, development and production of products with digital elements",
        description: "On the basis of the cybersecurity risk assessment referred to in Article 13(0) and where applicable, products with digital elements shall be made available on the market with a secure by default configuration, unless otherwise agreed between manufacturer and business user in relation to a tailor-made product with digital elements, including the possibility to reset the product to its original state.",
        totalValue: 0,
        text: "What mechanisms or practices have been used to guarantee it?",
        results: []
    }, {
        id: "q11",
        category: FunctionalRequirementCategory.Security,
        type: QuestionType.SingleSelect,
        title: "Design, development and production of products with digital elements",
        description: "On the basis of the cybersecurity risk assessment referred to in Article 13(0) and where applicable, products with digital elements shall ensure that vulnerabilities can be addressed through security updates, including, where applicable, through automatic security updates that are installed within an appropriate timeframe enabled as a default setting, with a clear and easy-to-use opt-out mechanism, through the notification of available updates to users, and the option to temporarily postpone them.",
        totalValue: 0,
        text: "Are security updates provided?",
        results: [
            {
                result: ComplianceResult.Yes,
                text: "The system does not meet the compliance requirements. Immediate action is needed to address the non-compliance issues and bring the system up to the required standards.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.No,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.NoKnown,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            }
        ]
    }, {
        id: "q11.2",
        category: FunctionalRequirementCategory.Security,
        type: QuestionType.Text,
        dependsOn: "q11",
        condition: ComplianceResult.Yes,
        title: "Design, development and production of products with digital elements",
        description: "On the basis of the cybersecurity risk assessment referred to in Article 13(0) and where applicable, products with digital elements shall ensure that vulnerabilities can be addressed through security updates, including, where applicable, through automatic security updates that are installed within an appropriate timeframe enabled as a default setting, with a clear and easy-to-use opt-out mechanism, through the notification of available updates to users, and the option to temporarily postpone them.",
        totalValue: 0,
        text: "If yes, are these provided automatically?",
        results: [
            {
                result: ComplianceResult.Yes,
                text: "The system does not meet the compliance requirements. Immediate action is needed to address the non-compliance issues and bring the system up to the required standards.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.No,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.NoKnown,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            }
        ]
    }, {
        id: "q11.3",
        category: FunctionalRequirementCategory.Security,
        type: QuestionType.Text,
        dependsOn: "q11",
        condition: ComplianceResult.Yes,
        title: "Design, development and production of products with digital elements",
        description: "On the basis of the cybersecurity risk assessment referred to in Article 13(0) and where applicable, products with digital elements shall ensure that vulnerabilities can be addressed through security updates, including, where applicable, through automatic security updates that are installed within an appropriate timeframe enabled as a default setting, with a clear and easy-to-use opt-out mechanism, through the notification of available updates to users, and the option to temporarily postpone them.",
        totalValue: 0,
        text: "Are they performed within a timeframe that complies with current legal regulations?",
        results: [
            {
                result: ComplianceResult.Yes,
                text: "The system does not meet the compliance requirements. Immediate action is needed to address the non-compliance issues and bring the system up to the required standards.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.No,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.NoKnown,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            }
        ]
    }, {
        id: "q11.4",
        category: FunctionalRequirementCategory.Security,
        type: QuestionType.SingleSelect,
        dependsOn: "q11",
        condition: ComplianceResult.Yes,
        title: "Design, development and production of products with digital elements",
        description: "On the basis of the cybersecurity risk assessment referred to in Article 13(0) and where applicable, products with digital elements shall ensure that vulnerabilities can be addressed through security updates, including, where applicable, through automatic security updates that are installed within an appropriate timeframe enabled as a default setting, with a clear and easy-to-use opt-out mechanism, through the notification of available updates to users, and the option to temporarily postpone them.",
        totalValue: 0,
        text: "Has an opt-out mechanism been implemented to notify users of a new version and allow them to postpone the update?",
        results: [
            {
                result: ComplianceResult.Yes,
                text: "The system does not meet the compliance requirements. Immediate action is needed to address the non-compliance issues and bring the system up to the required standards.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.No,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.NoKnown,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            }
        ]
    }, {
        id: "q12",
        category: FunctionalRequirementCategory.Security,
        type: QuestionType.SingleSelect,
        title: "Design, development and production of products with digital elements",
        description: "On the basis of the cybersecurity risk assessment referred to in Article 13(0) and where applicable, products with digital elements shall ensure protection from unauthorised access by appropriate control mechanisms, including but not limited to authentication, identity or access management systems, and report on possible unauthorised access.",
        totalValue: 0,
        text: "Are access control mechanisms used?",
        results: [
            {
                result: ComplianceResult.Yes,
                text: "The system does not meet the compliance requirements. Immediate action is needed to address the non-compliance issues and bring the system up to the required standards.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.No,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.NoKnown,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            }
        ]
    }, {
        id: "q12.2",
        category: FunctionalRequirementCategory.Security,
        type: QuestionType.Text,
        dependsOn: "q12",
        condition: ComplianceResult.Yes,
        title: "Design, development and production of products with digital elements",
        description: "On the basis of the cybersecurity risk assessment referred to in Article 13(0) and where applicable, products with digital elements shall ensure protection from unauthorised access by appropriate control mechanisms, including but not limited to authentication, identity or access management systems, and report on possible unauthorised access.",
        totalValue: 0,
        text: "If yes, what access control mechanisms are used?",
        results: []
    }, {
        id: "q13",
        category: FunctionalRequirementCategory.Security,
        type: QuestionType.SingleSelect,
        title: "Design, development and production of products with digital elements",
        description: "On the basis of the risk assessment referred to in Article 13(0), and where appropriate, products with digital elements shall protect the confidentiality of stored, transmitted or otherwise processed data, personal or other, such as by encrypting relevant data at rest or in transit by state of the art mechanisms, and by using other technical means.",
        totalValue: 0,
        text: "Are mechanisms used to protect integrity? E.g., encryption, signatures, etc.",
        results: [
            {
                result: ComplianceResult.Yes,
                text: "The system does not meet the compliance requirements. Immediate action is needed to address the non-compliance issues and bring the system up to the required standards.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.No,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.NoKnown,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            }
        ]
    }, {
        id: "q13.2",
        category: FunctionalRequirementCategory.Security,
        type: QuestionType.Text,
        dependsOn: "q13",
        condition: ComplianceResult.Yes,
        title: "Design, development and production of products with digital elements",
        description: "On the basis of the risk assessment referred to in Article 13(0), and where appropriate, products with digital elements shall protect the confidentiality of stored, transmitted or otherwise processed data, personal or other, such as by encrypting relevant data at rest or in transit by state of the art mechanisms, and by using other technical means.",
        totalValue: 0,
        text: "If yes, what mechanisms are implemented for this purpose?",
        results: []
    }, {
        id: "q14",
        category: FunctionalRequirementCategory.Security,
        type: QuestionType.SingleSelect,
        title: "Design, development and production of products with digital elements",
        description: "On the basis of the risk assessment referred to in Article 13(0), and where appropriate, products with digital elements shall process only personal or other data that are adequate, relevant and limited to what is necessary for the intended use of the product with digital elements (data minimisation).",
        totalValue: 0,
        text: "Is there a flow or storage of personal data?",
        results: [
            {
                result: ComplianceResult.Yes,
                text: "The system does not meet the compliance requirements. Immediate action is needed to address the non-compliance issues and bring the system up to the required standards.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.No,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.NoKnown,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            }
        ]
    }, {
        id: "q14.2",
        category: FunctionalRequirementCategory.Security,
        type: QuestionType.SingleSelect,
        dependsOn: "q14",
        condition: ComplianceResult.Yes,
        title: "Design, development and production of products with digital elements",
        description: "On the basis of the risk assessment referred to in Article 13(0), and where appropriate, products with digital elements shall process only personal or other data that are adequate, relevant and limited to what is necessary for the intended use of the product with digital elements (data minimisation).",
        totalValue: 0,
        text: "Are data minimization criteria applied, whether personal or not?",
        results: [
            {
                result: ComplianceResult.Yes,
                text: "The system does not meet the compliance requirements. Immediate action is needed to address the non-compliance issues and bring the system up to the required standards.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.No,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.NoKnown,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            }
        ]
    },
    {
        id: "q14.3",
        category: FunctionalRequirementCategory.Security,
        type: QuestionType.Text,
        dependsOn: "q14",
        condition: ComplianceResult.Yes,
        title: "Design, development and production of products with digital elements",
        description: "On the basis of the risk assessment referred to in Article 13(0), and where appropriate, products with digital elements shall protect the availability of essential functions, including through resilience and mitigation measures against denial-of-service attacks.",
        totalValue: 0,
        text: "If yes, what are they?",
        results: []
    }, {
        id: "q15",
        category: FunctionalRequirementCategory.Security,
        type: QuestionType.SingleSelect,
        title: "Design, development and production of products with digital elements",
        description: "On the basis of the risk assessment referred to in Article 13(0), and where appropriate, products with digital elements shall protect the availability of essential functions, including through resilience and mitigation measures against denial-of-service attacks.",
        totalValue: 0,
        text: "Are essential functions defined?",
        results: [
            {
                result: ComplianceResult.Yes,
                text: "The system does not meet the compliance requirements. Immediate action is needed to address the non-compliance issues and bring the system up to the required standards.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.No,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.NoKnown,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            }
        ]
    }, {
        id: "q15.2",
        category: FunctionalRequirementCategory.Security,
        type: QuestionType.Text,
        dependsOn: "q15",
        condition: ComplianceResult.Yes,
        title: "Design, development and production of products with digital elements",
        description: "On the basis of the risk assessment referred to in Article 13(0), and where appropriate, products with digital elements shall protect the availability of essential functions, including through resilience and mitigation measures against denial-of-service attacks.",
        totalValue: 0,
        text: "If yes, are they maintained in case of a denial-of-service attack?",
        results: []
    }, {
        id: "q16",
        category: FunctionalRequirementCategory.Security,
        type: QuestionType.SingleSelect,
        title: "Design, development and production of products with digital elements",
        description: "On the basis of the risk assessment referred to in Article 13(0), and where appropriate, products with digital elements shall protect the availability of essential functions, including through resilience and mitigation measures against denial-of-service attacks.",
        totalValue: 0,
        requiresJustification: true,
        text: "Are DoS attack mitigation measures implemented?",
        results: [
            {
                result: ComplianceResult.Yes,
                text: "The system does not meet the compliance requirements. Immediate action is needed to address the non-compliance issues and bring the system up to the required standards.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.No,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.NoKnown,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            }
        ]
    }, {
        id: "q17",
        category: FunctionalRequirementCategory.Security,
        type: QuestionType.SingleSelect,
        title: "Design, development and production of products with digital elements",
        description: "On the basis of the risk assessment referred to in Article 13(0), and where appropriate, products with digital elements shall protect the availability of essential functions, including through resilience and mitigation measures against denial-of-service attacks.",
        totalValue: 0,
        requiresJustification: true,
        text: "Any other related measures?",
        results: [
            {
                result: ComplianceResult.Yes,
                text: "The system does not meet the compliance requirements. Immediate action is needed to address the non-compliance issues and bring the system up to the required standards.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.No,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.NoKnown,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            }
        ]
    }, {
        id: "q18",
        category: FunctionalRequirementCategory.Security,
        type: QuestionType.SingleSelect,
        title: "Design, development and production of products with digital elements",
        description: "On the basis of the risk assessment referred to in Article 13(0), and where appropriate, products with digital elements shall minimize their own negative impact on the availability of services provided by other devices or networks.",
        totalValue: 0,
        text: "Is it connected to other devices or networks?",
        results: [
            {
                result: ComplianceResult.Yes,
                text: "The system does not meet the compliance requirements. Immediate action is needed to address the non-compliance issues and bring the system up to the required standards.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.No,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.NoKnown,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            }
        ]
    }, {
        id: "q19",
        category: FunctionalRequirementCategory.Security,
        type: QuestionType.SingleSelect,
        title: "Design, development and production of products with digital elements",
        description: "On the basis of the risk assessment referred to in Article 13(0), and where appropriate, products with digital elements shall minimize their own negative impact on the availability of services provided by other devices or networks.",
        totalValue: 0,
        text: "Does it impact their availability?",
        results: [
            {
                result: ComplianceResult.Yes,
                text: "The system does not meet the compliance requirements. Immediate action is needed to address the non-compliance issues and bring the system up to the required standards.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.No,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.NoKnown,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            }
        ]
    }, {
        id: "q19.2",
        category: FunctionalRequirementCategory.Security,
        type: QuestionType.Text,
        dependsOn: "q19",
        condition: ComplianceResult.No,
        title: "Design, development and production of products with digital elements",
        description: "On the basis of the risk assessment referred to in Article 13(0), and where appropriate, products with digital elements shall minimize their own negative impact on the availability of services provided by other devices or networks.",
        totalValue: 0,
        text: "If not, how is it guaranteed?",
        results: [
            {
                result: ComplianceResult.Yes,
                text: "The system does not meet the compliance requirements. Immediate action is needed to address the non-compliance issues and bring the system up to the required standards.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.No,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.NoKnown,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            }
        ]
    }, {
        id: "q20",
        category: FunctionalRequirementCategory.Security,
        type: QuestionType.SingleSelect,
        title: "Design, development and production of products with digital elements",
        description: "On the basis of the risk assessment referred to in Article 13(0), and where appropriate, products with digital elements shall designed, developed and produced to limit attack surfaces, including external interfaces.",
        totalValue: 0,
        text: "Has the product been designed considering the attack surface minimization criterion?",
        results: [
            {
                result: ComplianceResult.Yes,
                text: "The system does not meet the compliance requirements. Immediate action is needed to address the non-compliance issues and bring the system up to the required standards.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.No,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.NoKnown,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            }
        ]
    }, {
        id: "q20.2",
        category: FunctionalRequirementCategory.Security,
        type: QuestionType.Text,
        dependsOn: "q20",
        condition: ComplianceResult.Yes,
        title: "Design, development and production of products with digital elements",
        description: "On the basis of the risk assessment referred to in Article 13(0), and where appropriate, products with digital elements shall designed, developed and produced to limit attack surfaces, including external interfaces.",
        totalValue: 0,
        text: "Describe how this criterion has been applied:",
        results: []
    }, {
        id: "q21",
        category: FunctionalRequirementCategory.Security,
        type: QuestionType.SingleSelect,
        title: "Design, development and production of products with digital elements",
        description: "On the basis of the risk assessment referred to in Article 13(0), and where appropriate, products with digital elements shall be designed, developed and produced to reduce the impact of an incident using appropriate exploitation mitigation mechanisms and techniques.",
        totalValue: 0,
        text: "Does it implement any mitigation mechanism?",
        results: [
            {
                result: ComplianceResult.Yes,
                text: "The system does not meet the compliance requirements. Immediate action is needed to address the non-compliance issues and bring the system up to the required standards.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.No,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.NoKnown,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            }
        ]
    }, {
        id: "q21.2",
        category: FunctionalRequirementCategory.Security,
        type: QuestionType.Text,
        dependsOn: "q21",
        condition: ComplianceResult.Yes,
        title: "Design, development and production of products with digital elements",
        description: "On the basis of the risk assessment referred to in Article 13(0), and where appropriate, products with digital elements shall be designed, developed and produced to reduce the impact of an incident using appropriate exploitation mitigation mechanisms and techniques.",
        totalValue: 0,
        text: "If yes, what mechanisms are implemented for this?",
        results: []
    }, {
        id: "q22",
        category: FunctionalRequirementCategory.Security,
        type: QuestionType.SingleSelect,
        title: "Design, development and production of products with digital elements",
        description: "On the basis of the risk assessment referred to in Article 13(0), and where appropriate, products with digital elements shall provide security related information by recording and monitoring relevant internal activity, including the access to or modification of data, services or functions, with an opt-out mechanism for the user.",
        totalValue: 0,
        requiresJustification: true,
        text: "Does it implement any mechanism or tool for event logging or monitoring?",
        results: [
            {
                result: ComplianceResult.Yes,
                text: "The system does not meet the compliance requirements. Immediate action is needed to address the non-compliance issues and bring the system up to the required standards.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.No,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.NoKnown,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            }
        ]
    }, {

        id: "q23",
        category: FunctionalRequirementCategory.Security,
        type: QuestionType.SingleSelect,
        title: "Design, development and production of products with digital elements",
        description: "On the basis of the risk assessment referred to in Article 13(0), and where appropriate, products with digital elements shall provide security related information by recording and monitoring relevant internal activity, including the access to or modification of data, services or functions, with an opt-out mechanism for the user.",
        totalValue: 0,
        text: "Do you think it is necessary?",
        results: [
            {
                result: ComplianceResult.Yes,
                text: "The system does not meet the compliance requirements. Immediate action is needed to address the non-compliance issues and bring the system up to the required standards.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.No,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.NoKnown,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            }
        ]
    }, {
        id: "q23.2",
        category: FunctionalRequirementCategory.Security,
        type: QuestionType.Text,
        dependsOn: "q23",
        condition: ComplianceResult.Yes,
        title: "Design, development and production of products with digital elements",
        description: "On the basis of the risk assessment referred to in Article 13(0), and where appropriate, products with digital elements shall provide security related information by recording and monitoring relevant internal activity, including the access to or modification of data, services or functions, with an opt-out mechanism for the user.",
        totalValue: 0,
        text: "If yes, what mechanisms are implemented for this?",
        results: []
    }, {
        id: "q24",
        category: FunctionalRequirementCategory.Security,
        type: QuestionType.SingleSelect,
        title: "Design, development and production of products with digital elements",
        description: "On the basis of the risk assessment referred to in Article 13(0), and where appropriate, products with digital elements shall provide the possibility for users to securely and easily remove on a permanent basis all data and settings and, where such data can be transferred to other products or systems, ensure that this is done in a secure manner",
        totalValue: 0,
        text: "Does it implement mechanisms for users to delete necessary data?",
        results: [
            {
                result: ComplianceResult.Yes,
                text: "The system does not meet the compliance requirements. Immediate action is needed to address the non-compliance issues and bring the system up to the required standards.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.No,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.NoKnown,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            }
        ]
    }, {
        id: "q25",
        category: FunctionalRequirementCategory.Security,
        type: QuestionType.SingleSelect,
        title: "Design, development and production of products with digital elements",
        description: "On the basis of the risk assessment referred to in Article 13(0), and where appropriate, products with digital elements shall provide the possibility for users to securely and easily remove on a permanent basis all data and settings and, where such data can be transferred to other products or systems, ensure that this is done in a secure manner",
        totalValue: 0,
        requiresJustification: true,
        text: "Is this necessary or relevant?",
        results: [
            {
                result: ComplianceResult.Yes,
                text: "The system does not meet the compliance requirements. Immediate action is needed to address the non-compliance issues and bring the system up to the required standards.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.No,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.NoKnown,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            }
        ]
    }, {
        id: "q26",
        category: FunctionalRequirementCategory.Security,
        type: QuestionType.SingleSelect,
        title: "Design, development and production of products with digital elements",
        description: "On the basis of the risk assessment referred to in Article 13(0), and where appropriate, products with digital elements shall provide the possibility for users to securely and easily remove on a permanent basis all data and settings and, where such data can be transferred to other products or systems, ensure that this is done in a secure manner",
        totalValue: 0,
        requiresJustification: true,
        text: "Is the secure transfer of data guaranteed?",
        results: [
            {
                result: ComplianceResult.Yes,
                text: "The system does not meet the compliance requirements. Immediate action is needed to address the non-compliance issues and bring the system up to the required standards.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.No,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.NoKnown,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            }
        ]
    }, {
        id: "q27",
        category: FunctionalRequirementCategory.Security,
        type: QuestionType.Text,
        title: "Design, development and production of products with digital elements",
        description: "On the basis of the risk assessment referred to in Article 13(0), and where appropriate, products with digital elements shall provide the possibility for users to securely and easily remove on a permanent basis all data and settings and, where such data can be transferred to other products or systems, ensure that this is done in a secure manner",
        totalValue: 0,
        requiresJustification: true,
        text: "Are security updates provided?",
        results: []
    }, {
        id: "q28",
        category: FunctionalRequirementCategory.Vulnerability,
        type: QuestionType.SingleSelect,
        title: "Design, development and production of products with digital elements",
        description: "Manufacturers of products with digital elements shall provide for mechanisms to securely distribute updates for products with digital elements to ensure that vulnerabilities are fixed or mitigated in a timely manner and, where applicable for security updates, in an automatic manner.",
        totalValue: 0,
        requiresJustification: true,
        text: "Are updates distributed securely?",
        results: [
            {
                result: ComplianceResult.Yes,
                text: "The system does not meet the compliance requirements. Immediate action is needed to address the non-compliance issues and bring the system up to the required standards.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.No,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.NoKnown,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            }
        ]
    }, {
        id: "q29",
        category: FunctionalRequirementCategory.Vulnerability,
        type: QuestionType.SingleSelect,
        title: "Design, development and production of products with digital elements",
        description: "Manufacturers of products with digital elements shall provide for mechanisms to securely distribute updates for products with digital elements to ensure that vulnerabilities are fixed or mitigated in a timely manner and, where applicable for security updates, in an automatic manner.",
        totalValue: 0,
        text: "Are these provided for free?",
        results: [
            {
                result: ComplianceResult.Yes,
                text: "The system does not meet the compliance requirements. Immediate action is needed to address the non-compliance issues and bring the system up to the required standards.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.No,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.NoKnown,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            }
        ]
    }, {
        id: "q30",
        category: FunctionalRequirementCategory.Vulnerability,
        type: QuestionType.SingleSelect,
        title: "Design, development and production of products with digital elements",
        description: "On the basis of the risk assessment referred to in Article 13(0), and where appropriate, products with digital elements shall provide the possibility for users to securely and easily remove on a permanent basis all data and settings and, where such data can be transferred to other products or systems, ensure that this is done in a secure manner",
        totalValue: 0,
        text: "Are updates distributed securely?",
        results: [
            {
                result: ComplianceResult.Yes,
                text: "The system does not meet the compliance requirements. Immediate action is needed to address the non-compliance issues and bring the system up to the required standards.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.No,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.NoKnown,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            }
        ]

    }, {
        id: "q30.2",
        category: FunctionalRequirementCategory.Vulnerability,
        type: QuestionType.Text,
        dependsOn: "q30",
        condition: ComplianceResult.Yes,
        title: "Design, development and production of products with digital elements",
        description: "Manufacturers of products with digital elements with regard to risks to products with digital elements shall in relation to the risks posed to products with digital elements, address and remediate vulnerabilities without delay, including by providing security updates; where technically feasible, new security updates shall be provided separately from functionality updates.",
        totalValue: 0,
        text: "If yes, what channels or mechanisms are used to guarantee it?",
        results: []
    }, {
        id: "q31",
        category: FunctionalRequirementCategory.Vulnerability,
        type: QuestionType.SingleSelect,
        title: "Design, development and production of products with digital elements",
        description: "Manufacturers of products with digital elements shall identify and document vulnerabilities and components contained in products with digital elements, including by drawing up a software bill of materials in a commonly used and machine-readable format covering at the very least the top-level dependencies of the products.",
        totalValue: 0,
        text: "Is an SBOM (Software Bill of Materials) for the component available?",
        results: [
            {
                result: ComplianceResult.Yes,
                text: "The system does not meet the compliance requirements. Immediate action is needed to address the non-compliance issues and bring the system up to the required standards.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.No,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.NoKnown,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            }
        ]
    }, {
        id: "q32",
        category: FunctionalRequirementCategory.Vulnerability,
        type: QuestionType.SingleSelect,
        title: "Design, development and production of products with digital elements",
        description: "Manufacturers of products with digital elements shall identify and document vulnerabilities and components contained in products with digital elements, including by drawing up a software bill of materials in a commonly used and machine-readable format covering at the very least the top-level dependencies of the products.",
        totalValue: 0,
        text: "Have the dependencies of the software components been identified?",
        results: [
            {
                result: ComplianceResult.Yes,
                text: "The system does not meet the compliance requirements. Immediate action is needed to address the non-compliance issues and bring the system up to the required standards.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.No,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.NoKnown,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            }
        ]
    }, {
        id: "q33",
        category: FunctionalRequirementCategory.Vulnerability,
        type: QuestionType.SingleSelect,
        title: "Design, development and production of products with digital elements",
        description: "Manufacturers of products with digital elements shall apply effective and regular tests and reviews of the security of the product with digital elements.",
        totalValue: 0,
        text: "Are periodic tests performed?",
        results: [
            {
                result: ComplianceResult.Yes,
                text: "The system does not meet the compliance requirements. Immediate action is needed to address the non-compliance issues and bring the system up to the required standards.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.No,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.NoKnown,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            }
        ]
    }, {
        id: "q33.2",
        category: FunctionalRequirementCategory.Vulnerability,
        type: QuestionType.Text,
        dependsOn: "q33",
        condition: ComplianceResult.Yes,
        title: "Design, development and production of products with digital elements",
        description: "Manufacturers of products with digital elements shall apply effective and regular tests and reviews of the security of the product with digital elements.",
        totalValue: 0,
        text: "If yes, what type of tests are performed?",
        results: []
    }, {
        id: "q33.3",
        category: FunctionalRequirementCategory.Vulnerability,
        type: QuestionType.Text,
        dependsOn: "q33",
        condition: ComplianceResult.Yes,
        title: "Design, development and production of products with digital elements",
        description: "Manufacturers of products with digital elements shall apply effective and regular tests and reviews of the security of the product with digital elements.",
        totalValue: 0,
        text: "If yes, are they performed based on a recognized methodology or standard?",
        results: []
    }, {
        id: "q34",
        category: FunctionalRequirementCategory.Vulnerability,
        type: QuestionType.SingleSelect,
        title: "Design, development and production of products with digital elements",
        description: "Manufacturers of products with digital elements shall, once a security update has been made available, share and publicly disclose information about fixed vulnerabilities, including a description of the vulnerabilities, information allowing users to identify the product with digital elements affected, the impacts of the vulnerabilities, their severity and clear and accessible information helping users to remediate the vulnerabilities; in duly justified cases, where manufacturers consider the security risks of publication to outweigh the security benefits, they may delay making public information regarding a fixed vulnerability until after users have been given the possibility to apply the relevant patch.",
        totalValue: 0,
        text: "Are vulnerabilities published within 70 hours or less?",
        results: [
            {
                result: ComplianceResult.Yes,
                text: "The system does not meet the compliance requirements. Immediate action is needed to address the non-compliance issues and bring the system up to the required standards.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.No,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.NoKnown,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            }
        ]
    }, {
        id: "q35",
        category: FunctionalRequirementCategory.Vulnerability,
        type: QuestionType.SingleSelect,
        title: "Design, development and production of products with digital elements",
        description: "Manufacturers of products with digital elements shall, once a security update has been made available, share and publicly disclose information about fixed vulnerabilities, including a description of the vulnerabilities, information allowing users to identify the product with digital elements affected, the impacts of the vulnerabilities, their severity and clear and accessible information helping users to remediate the vulnerabilities; in duly justified cases, where manufacturers consider the security risks of publication to outweigh the security benefits, they may delay making public information regarding a fixed vulnerability until after users have been given the possibility to apply the relevant patch.",
        totalValue: 0,
        requiresJustification: true,
        text: "Do you consider it justified not to publish within the established deadlines for security reasons?",
        results: [
            {
                result: ComplianceResult.Yes,
                text: "The system does not meet the compliance requirements. Immediate action is needed to address the non-compliance issues and bring the system up to the required standards.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.No,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.NoKnown,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            }
        ]
    }, {
        id: "q36",
        category: FunctionalRequirementCategory.Vulnerability,
        type: QuestionType.SingleSelect,
        title: "Design, development and production of products with digital elements",
        description: "Manufacturers of products with digital elements shall put in place and enforce a policy on coordinated vulnerability disclosure.",
        totalValue: 0,
        text: "Do you maintain any kind of vulnerability management and disclosure policy?",
        results: [
            {
                result: ComplianceResult.Yes,
                text: "The system does not meet the compliance requirements. Immediate action is needed to address the non-compliance issues and bring the system up to the required standards.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.No,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.NoKnown,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            }
        ]
    }, {
        id: "q36.2",
        category: FunctionalRequirementCategory.Vulnerability,
        type: QuestionType.Text,
        dependsOn: "q36",
        condition: ComplianceResult.Yes,
        title: "Design, development and production of products with digital elements",
        description: "Manufacturers of products with digital elements shall put in place and enforce a policy on coordinated vulnerability disclosure.",
        totalValue: 0,
        text: "If yes, is it based on a recognized standard?",
        results: []
    }, {
        id: "q36.3",
        category: FunctionalRequirementCategory.Vulnerability,
        type: QuestionType.Text,
        dependsOn: "q36",
        condition: ComplianceResult.Yes,
        title: "Design, development and production of products with digital elements",
        description: "Manufacturers of products with digital elements shall put in place and enforce a policy on coordinated vulnerability disclosure.",
        totalValue: 0,
        text: "Briefly describe the most important activities:",
        results: []
    }, {
        id: "q37",
        category: FunctionalRequirementCategory.Vulnerability,
        type: QuestionType.SingleSelect,
        title: "Design, development and production of products with digital elements",
        description: "Manufacturers of products with digital elements shall take measures to facilitate the sharing of information about potential vulnerabilities in their product with digital elements as well as in third-party components contained in that product, including by providing a contact address for the reporting of the vulnerabilities discovered in the product with digital elements.",
        totalValue: 0,
        text: "Are channels available for receiving information about new vulnerabilities?",
        results: [
            {
                result: ComplianceResult.Yes,
                text: "The system does not meet the compliance requirements. Immediate action is needed to address the non-compliance issues and bring the system up to the required standards.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.No,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.NoKnown,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            }
        ]
    }, {
        id: "q38",
        category: FunctionalRequirementCategory.Vulnerability,
        type: QuestionType.SingleSelect,
        title: "Design, development and production of products with digital elements",
        description: "Manufacturers of products with digital elements shall take measures to facilitate the sharing of information about potential vulnerabilities in their product with digital elements as well as in third-party components contained in that product, including by providing a contact address for the reporting of the vulnerabilities discovered in the product with digital elements.",
        totalValue: 0,
        text: "Is there a designated person responsible for receiving reports on detected vulnerabilities?",
        results: [
            {
                result: ComplianceResult.Yes,
                text: "The system does not meet the compliance requirements. Immediate action is needed to address the non-compliance issues and bring the system up to the required standards.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.No,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.NoKnown,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 0
            }
        ]
    }
]
