import React, { useContext, useEffect, useState } from "react"
import { Box, Button, Grid, MenuItem, TextField, Typography, useTheme } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { DatePicker } from "@mui/x-date-pickers"
import moment from "moment"
import { ProductSelector } from "@components/product/index"
import { ServicesContext } from "@context/index"
import { Assessment, AssessmentStatus, AssessmentType } from "@models/index"
import ClientSelector from "@components/client/ClientSelector"
import { AssessmentStatusKeysValueOps, AssessmentTypeKeysValueOps } from "@components/assessment"
import { FilterOperation } from "@utils/queryParams"
import { AssessmentTypeColors } from "@components/common/colors/AssessmentTypeColors"
import { I18nContext } from "I18nProvider"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import { AbilityContext, Can } from "@components/permissions"
import TemplateSelector from "@components/template/TemplateSelector"
import { AssessmentStatusColors } from "@components/common/colors/AssessmentStatusColors"
import { useTrack } from "@components/track/TrackContext"

const initValue: Assessment = {
    id: "",
    type: AssessmentType.CRT,
    status: AssessmentStatus.Contracted,
    contractDate: null,
    startDate: null,
    endDate: null,
    product: { id: "00000000-0000-0000-0000-000000000000" },
    client: { id: "00000000-0000-0000-0000-000000000000" },
    analysis_status: 0,
    template_id: "00000000-0000-0000-0000-000000000000"
}

const AssessmentForm: React.FC = () => {
    // Tracking
    const { track, trackData } = useTrack()
    useEffect(() => {
        track({ view: "AssessmentForm" })
    }, [])
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const theme = useTheme()
    const ability = useContext(AbilityContext)
    // TODO: if permissions show pentest
    const [openPentest, setOpenPentest] = React.useState(false)
    const handleClosePentest = () => setOpenPentest(false)
    const assessmentService = useContext(ServicesContext).assessmentService
    const [error, setError] = useState<Error|null>(null)
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [formData, setFormData] = useState<Assessment>(initValue)
    const formValid = (): boolean => {
        const isNotNullrules = [
            formData.contractDate === context.t.translate("invalid_date"),
            formData.contractDate === "",
            formData.contractDate === undefined,
            formData.contractDate === null
        ]
        if (isNotNullrules.some(rule => rule)) {
            return false
        }
        return true
    }

    const styleScroll = {
        overflowY: "auto",
        height: "60%",
        "&::-webkit-scrollbar": {
            width: "12px"
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.primary.main,
            borderRadius: "6px"
        },
        "&::-webkit-scrollbar-track": {
            backgroundColor: "transparent"
        }
    }
    // Generic change handler for all form fields
    const handleInputChange = (e: any) => {
        let events: {target :{name: string, value: string}}[] = e
        if (!Array.isArray(e)) {
            events = [e]
        }

        setFormData(events.reduce((result, { target: { name, value } }) => {
            return { ...result, [name]: value }
        }, formData))
    }
    // Generic change handler for all form fields - END

    // API Call create new assessment
    const navigate = useNavigate()
    const handleSubmit = async (e: React.FormEvent) => {
        try {
            await assessmentService.create(formData)
            navigate(-1)
            throw new Error(context.t.translate("error_testing"))
        } catch (e: any) {
            setError({ message: e.error } as Error)
            setSnackbarOpen(true)
        }
    }
    // API Call create new assessment - END
    function formatLabel (label: string) {
        const acronymLabels = ["CRT", "SCA"]
        if (acronymLabels.includes(label)) {
            return label
        }
        return label.replace(/([a-z])([A-Z])/g, "$1 $2")
    }
    const canSeeCRT = ability.can("read", "Achilles")
    const canSeeLogicalPentest = ability.can("read", "Pentest")

    const filteredAssessmentTypes = AssessmentTypeKeysValueOps.filter(opt => {
        if (opt.value === AssessmentType.CRT && !canSeeCRT) {
            return false
        }
        if (opt.value === AssessmentType.LogicalPentest && !canSeeLogicalPentest) {
            return false
        }
        return true
    })
    return (
        <Box sx={{ width: "100%" }}>
            <Box sx={{ mt: 1, width: "50%", alignSelf: "center", margin: "50px auto 50px auto" }}>
                <Grid container spacing={2}>
                    <CustomSnackbar
                        open={snackbarOpen}
                        onClose={() => setSnackbarOpen(false)}
                        message={error?.message || "An error occurred.//edi"}
                    />
                    <Grid item container flexDirection="row" alignItems='flex-start' spacing="25px">
                        <Can key="viewClient" I="*" an="Client">
                            <Grid item xs={12}>
                                <ClientSelector value={formData.client.id} onSelect={(id) => handleInputChange({ target: { name: "client", value: { id } } })}></ClientSelector>
                            </Grid>
                        </Can>
                        <Grid item xs={12} md={6}>
                            <ProductSelector value={formData.product.id} customFilters={ability.can("*", "Client") ? [{ field: "client_id", operation: FilterOperation.UUIDEqual, value: formData.client.id }] : undefined} onSelect={(id, p) => {
                                handleInputChange([{ target: { name: "product", value: { id } } }])
                            }}></ProductSelector>
                        </Grid>
                        {ability.can("*", "Client")
                            ? (
                                <Grid item xs={12} md={6}>
                                    <TextField select required fullWidth variant="filled" label={context.t.translate("status")} name="status"
                                        value={formData.status} onChange={handleInputChange}>
                                        {AssessmentStatusKeysValueOps.map((opt, idx) =>
                                            (<MenuItem key={idx} value={opt.value}>
                                                <Typography fontFamily="Griff" fontWeight="bolder" sx={{ color: AssessmentStatusColors[AssessmentStatus[opt.label]] }}>
                                                    {opt.label}
                                                </Typography>
                                            </MenuItem>)
                                        )}
                                    </TextField>
                                </Grid>
                            )
                            : (
                                <Grid item xs={12} md={6}>
                                    <TextField disabled fullWidth variant="filled" label={context.t.translate("status")} value={AssessmentStatus.Contracted} />
                                </Grid>
                            )}
                        <Grid item xs={12} md={6}>
                            <TextField select required fullWidth variant="filled" label={context.t.translate("type")} name="type"
                                value={formData.type} onChange={handleInputChange}>
                                {filteredAssessmentTypes.map((opt, idx) => {
                                    const formattedLabel = formatLabel(opt.label)
                                    return (
                                        <MenuItem key={idx} value={opt.value}>
                                            <Typography fontFamily="Griff" fontWeight="bolder" sx={{ color: AssessmentTypeColors[AssessmentType[opt.label]] }}>
                                                {formattedLabel}
                                            </Typography>
                                        </MenuItem>
                                    )
                                })}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TemplateSelector value={formData.template_id} customFilters={ability.can("*", "Client") ? [{ field: "client_id", operation: FilterOperation.UUIDEqual, value: formData.client.id }, { field: "assessment_type", operation: FilterOperation.EnumEqual, value: formData.type }] : [{ field: "assessment_type", operation: FilterOperation.EnumEqual, value: formData.type }]} onSelect={(id, p) => {
                                handleInputChange([{ target: { name: "template_id", value: id } }])
                            }}></TemplateSelector>
                        </Grid>

                        <Grid item container flexDirection="row" alignItems='flex-start' spacing="25px">
                            <Grid item xs={12} md={4}>
                                <DatePicker
                                    label={context.t.translate("assess_date_contrac")}
                                    value={formData.contractDate != null ? moment(formData.contractDate, "YYYY-MM-DDTHH:mm:ssZ") : null}
                                    onChange={m => handleInputChange({ target: { name: "contractDate", value: m?.format("YYYY-MM-DDTHH:mm:ssZ") } })}
                                    slots={{
                                        textField: (params) => <TextField required
                                            variant='filled' {...params} />
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <DatePicker
                                    label={context.t.translate("assess_date_start")}
                                    value={formData.startDate != null ? moment(formData.startDate, "YYYY-MM-DDTHH:mm:ssZ") : null}
                                    onChange={m => handleInputChange({ target: { name: "startDate", value: m?.isValid ? m?.format("YYYY-MM-DDTHH:mm:ssZ") : null } })}
                                    slots={{
                                        textField: (params) => <TextField
                                            variant='filled' {...params} />
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <DatePicker
                                    label={context.t.translate("assess_date_end")}
                                    value={formData.endDate != null ? moment(formData.endDate, "YYYY-MM-DDTHH:mm:ssZ") : null}
                                    onChange={m => handleInputChange({ target: { name: "endDate", value: m?.isValid ? m?.format("YYYY-MM-DDTHH:mm:ssZ") : null } })}
                                    slots={{
                                        textField: (params) => <TextField
                                            variant='filled' {...params} />
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Button type="button" fullWidth variant="contained" sx={{ mt: 3, mb: 2, color: theme.palette.secondary.contrastText }} onClick={handleSubmit} disabled={!formValid()}>
                    {context.t.translate("add_new")}
                </Button>
            </Box>
        </Box>
    )
}

export { AssessmentForm }
export default AssessmentForm
