import { ModelDefinition } from "./utils"

type KeycloakSessions = {
    Clients: Map<string, string>;
    ID: string;
    IpAddress: string;
    LastAccess: string;
    Start: string;
    UserID: string;
    Username: string;
}

const SessionDefinition: ModelDefinition<KeycloakSessions> = {
    properties: [
        { name: "ID", label: "Id", type: "string", flex: 0.1, isId: true },
        { name: "Username", label: "Username", type: "string", flex: 0.4 },
        { name: "Start", label: "Start Access", type: "string", flex: 0.4 },
        { name: "LastAccess", label: "Last Access", flex: 0.4, type: "string" }
    ]
}

const CustomAttributes = {
    ApiKey: "api_key",
    JiraToken: "jira_api_token"
}

type KeycloakAttribute = {
    attribute: string;
    value?: string;
}

export { SessionDefinition, CustomAttributes }

export type { KeycloakSessions, KeycloakAttribute }
