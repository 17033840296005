import { VaultSecret } from "@models/Vault"
import { MediaTypes } from "@services/HttpService"
import Service from "@services/abstract/BaseService"

class VaultService extends Service<any> {
    public constructor () {
        super(process.env.REACT_APP_API, "api/v1/vault", MediaTypes.JSON)
    }

    public readSecret = (id: string, key: string) => {
        const url = `/api/v1/vault?path=${id}&key=${key}`
        return this.http.get(url) as any
    }

    public writeSecret = (id: string, secret: VaultSecret) => {
        const url = `/api/v1/vault?path=${id}`
        return this.http.post(url, secret) as any
    }
}

export { VaultService }

export default VaultService
