import { Page, Text, View } from "@react-pdf/renderer"
import React from "react"
import Template from "@models/Template"
import { Footer, Header } from "./HeaderFooter"
import VexDocumentStyles from "../VexStyleSheet"
import styles from "../Styles"

const CustomContentPage: React.FC<{ template?: Template }> = ({ template }) => {
    const customContent = template?.metadata.data.custom_content || []

    // Filtrar el customContent para solo tener aquellos que tienen contenido no vacío
    const validCustomContent = customContent.filter(item => item.content.trim() !== "")

    // Si no hay contenido válido, no renderizar nada
    if (validCustomContent.length === 0) {
        return null
    }

    return (
        <Page size="A4" style={{ paddingBottom: 80, backgroundColor: !template || template.color === "#fffa37" ? "" : template.color }}>
            <Header template={template} />
            <View style={VexDocumentStyles.section} wrap={true}>
                {validCustomContent.map((item, index) => (
                    <View key={index} style={{ marginBottom: 10 }}>
                        <Text style={styles.titulo}>{item.name}</Text>
                        <Text style={{ ...styles.textua, marginRight: 50, fontFamily: "Griff", fontSize: 10 }}>
                            {item.content}
                        </Text>
                    </View>
                ))}
            </View>
            <Footer color={template?.color} />
        </Page>
    )
}

export default CustomContentPage
