import React, { useContext, useEffect, useState } from "react"
import {
    AlertColor,
    Box,
    Divider,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField,
    Typography,
    useTheme
} from "@mui/material"
import Compliance, { ComplianceResult, FunctionalRequirementCategory, Question, QuestionType, QuizzType, RegistryData, Result } from "@models/Compliance"
import CloseIcon from "@mui/icons-material/Close"
import { useNavigate, useParams } from "react-router-dom"
import ServicesContext from "@context/ServicesContext"
import { I18nContext } from "I18nProvider"
import { useConfirmationDialog } from "@components/dialogs/ConfirmationDialog"
import { StyledBox } from "@components/common/Box/BoxContainer"
import { QuizIndicator } from "@components/common/indicator/QuizzIndicator"
import Legend from "@components/common/Legend/QuizLegend/QuizzLegend"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import ActionButton from "@components/common/Button/ActionButton"
import { useTrack } from "@components/track/TrackContext"
import CustomEditor from "@views/pentest/component/CustomEditor"
import { CRAQuestions } from "../../components/compliance/CRAQuestionData"
import { FDAQuestions } from "../../components/compliance/FDAQuestionData"
import QuizModal from "./QuizModal"
const initRegistry: RegistryData = Object.fromEntries(
    Array.from({ length: 57 }, (_, i) => i === 0 ? "time" : `q${i}`).map(key => [key, ""])
) as RegistryData
const initValue = {
    id: "",
    registry: [initRegistry],
    type: QuizzType.CRA,
    conclusion: "",
    client_id: "",
    created_at: "",
    updated_at: "",
    justification: { },
    score: 0,
    conclusions: {}
}

function Quiz () {
    // Constant
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const theme = useTheme()
    const navigate = useNavigate()
    const { id, type } = useParams<{ id: string, type: string }>()
    const [data, setData] = useState<Compliance>(initValue)
    const [formData, setFormData] = useState<Compliance>(initValue)
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(1)
    const [selectedAnswers, setSelectedAnswers] = useState<{ [key: string]: any }>({})
    const [score, setScore] = useState(0)
    const [results, setResults] = useState<Result[]>([])
    const { showDialog } = useConfirmationDialog()
    const [visibleQuestions, setVisibleQuestions] = useState<Question[]>([])
    const questions = type === QuizzType.CRA ? CRAQuestions : FDAQuestions
    const [error, setError] = useState<Error | null>(null)
    const currentQuestion = visibleQuestions[currentQuestionIndex - 1]
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState<string>("")
    const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>("info")
    const [dependQuestions, setDependQuestions] = useState<Question[]>([])
    const [justification, setJustifications] = useState<{ [key: string]: string; }>({})
    const [resultCounts, setResultCounts] = useState({
        NoDocumentAvailable: 0,
        RequiredImprovements: 0,
        PartiallyCompliant: 0,
        FullyCompliant: 0,
        Yes: 0,
        No: 0,
        NoKnown: 0,
        total: questions.length
    })
    const [answeredQuestionsCount, setAnsweredQuestionsCount] = useState(0)
    useEffect(() => {
        setVisibleQuestions(questions.filter((q) => !q.dependsOn))
        setDependQuestions(questions.filter((q) => q.dependsOn))
    }, [questions])
    const { track, trackData } = useTrack()
    useEffect(() => {
        track({ view: "Quiz" })
    }, [])
    useEffect(() => {
        const answeredCount = Object.values(selectedAnswers).filter(answer => answer).length
        setAnsweredQuestionsCount(answeredCount)
    }, [selectedAnswers])
    const formValid = (): boolean => {
        const isNotNullrules = [
            selectedAnswers[currentQuestionIndex] === "",
            (/^[A-Za-z]+$/.test(selectedAnswers[currentQuestionIndex]))
        ]
        if (isNotNullrules.some(rule => rule)) {
            return false
        }
        const isNotSameOldValues = [
            data.registry[0][("q" + currentQuestionIndex) as keyof RegistryData] === selectedAnswers[currentQuestionIndex]
        ]
        if (isNotSameOldValues.every(rule => rule)) {
            return false
        }
        return true
    }
    // --------------------------------------------------
    // useEffect
    useEffect(() => {
        fetch()
    }, [id])
    useEffect(() => {
        const counts = {
            NoDocumentAvailable: 0,
            RequiredImprovements: 0,
            PartiallyCompliant: 0,
            FullyCompliant: 0,
            Yes: 0,
            No: 0,
            NoKnown: 0,
            total: questions.length
        }
        for (const questionIndex in selectedAnswers) {
            const selectedAnswer = selectedAnswers[questionIndex]
            if (selectedAnswer) {
                switch (selectedAnswer) {
                case ComplianceResult.NoDocument:
                    counts.NoDocumentAvailable += 1
                    break
                case ComplianceResult.PartialDocument:
                    counts.RequiredImprovements += 1
                    break
                case ComplianceResult.PartiallyCompliant:
                    counts.PartiallyCompliant += 1
                    break
                case ComplianceResult.FullDocument:
                    counts.FullyCompliant += 1
                    break
                case ComplianceResult.Yes:
                    counts.Yes += 1
                    break
                case ComplianceResult.No:
                    counts.No += 1
                    break
                case ComplianceResult.NoKnown:
                    counts.NoKnown += 1
                    break
                default:
                    break
                }
            }
        }
        setResultCounts(counts)
    }, [selectedAnswers])
    useEffect(() => {
        const newSelectedAnswers: { [key: string]: any } = {}
        Object.entries(data.registry[0]).forEach(([key, value]) => {
            if (key !== "time") {
                newSelectedAnswers[key.replace("q", "")] = value
            }
        })
        setSelectedAnswers(newSelectedAnswers)
    }, [data])
    // --------------------------------------------------
    // Functions
    const fetch = async () => {
        try {
            const compliance = await complianceService.get(id as string)
            setData(compliance)
            setFormData(compliance)

            setJustifications(compliance.justification)
            setScore(calculateTotalScore())
        } catch (e: any) {
            setError({ message: e.message } as Error)
            setSnackbarOpen(true)
        }
    }

    const handleNext = () => {
        if (currentQuestionIndex < questions.length) {
            setCurrentQuestionIndex((prev) => prev + 1)
        }
    }

    const handleBack = () => {
        if (currentQuestionIndex > 1) {
            setCurrentQuestionIndex((prev) => prev - 1)
        }
    }

    const handleFinishQuiz = async () => {
        if (data.type === QuizzType.CRA) {
            try {
                const registry: any = [{
                    Time: new Date().toISOString(),
                    // Incluir las respuestas de las preguntas principales
                    ...Array.from({ length: 38 }, (_, i) => `Q${i + 1}`)
                        .reduce((acc, key, index) => ({
                            ...acc,
                            [key]: selectedAnswers[index + 1] ?? ""
                        }), {}),
                    // Incluir las respuestas de las subpreguntas opcionales
                    ...Object.keys(selectedAnswers)
                        .filter((key) => key.includes("."))
                        .reduce((acc, key) => ({
                            ...acc,
                            [key]: selectedAnswers[key] ?? ""
                        }), {})
                }]

                const compliance = {
                    ...data,
                    results,
                    score,
                    registry,
                    justification
                }
                await complianceService.finishedQuiz(data.client_id, compliance).then((response: any) => {
                    navigate("/compliance")
                })
            } catch (e: any) {
                setError({ message: e.message } as Error)
                setSnackbarMessage(e.message)
                setSnackbarSeverity("error")
                setSnackbarOpen(true)
            }
        } else {
            navigate("/compliance")
        }
    }
    const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value

        // Actualizar la respuesta seleccionada
        const updatedSelectedAnswers: { [key: string]: string } = {
            ...selectedAnswers,
            [currentQuestionIndex]: newValue
        }

        // Limpiar subpreguntas si el nuevo valor cambia las condiciones
        dependQuestions.forEach((dependQuestion) => {
            if (dependQuestion.dependsOn === currentQuestion.id) {
                // Si la respuesta seleccionada no cumple con la condición para mostrar la subpregunta
                if (dependQuestion.condition !== newValue) {
                    delete updatedSelectedAnswers[dependQuestion.id.toUpperCase()] // Eliminar respuesta
                }
            }
        })

        // Actualizar estado
        setSelectedAnswers(updatedSelectedAnswers)
        setScore(calculateTotalScore())
        if (isSaved) {
            setIsSaved(false)
        }
    }

    const complianceService = useContext(ServicesContext).complianceService
    const [isSaved, setIsSaved] = useState(false)
    const saveHandler = async () => {
        try {
            const registry: any = [{
                Time: new Date().toISOString(),
                // Incluir las respuestas de las preguntas principales
                ...Array.from({ length: 38 }, (_, i) => `Q${i + 1}`)
                    .reduce((acc, key, index) => ({
                        ...acc,
                        [key]: selectedAnswers[index + 1] ?? ""
                    }), {}),
                // Incluir las respuestas de las subpreguntas opcionales
                ...Object.keys(selectedAnswers)
                    .filter((key) => key.includes("."))
                    .reduce((acc, key) => ({
                        ...acc,
                        [`Q${key}`]: selectedAnswers[key] ?? ""
                    }), {})
            }]

            const compliance = {
                ...data,
                results,
                score,
                registry,
                justification
            }
            await complianceService.update(id, compliance).then((response: any) => {
                setSnackbarSeverity("success")
                setSnackbarMessage("Saved successfully")
                setSnackbarOpen(true)
                setIsSaved(true)
            })
        } catch (e: any) {
            setError({ message: e.message } as Error)
            setSnackbarMessage(e.message)
            setSnackbarSeverity("error")
            setSnackbarOpen(true)
        }
    }

    const handleExitClick = () => {
        if (!isSaved) {
            showDialog(
                "Exit",
                context.t.translate("you_cant_undo"),
                () => navigate("/compliance"),
                "Exit"
            )
        } else {
            navigate("/compliance")
        }
    }
    const setSelectedAnswer = (answerOption: Result) => {
        setSelectedAnswers({
            ...selectedAnswers,
            [currentQuestionIndex]: answerOption.value
        })
        questions[currentQuestionIndex - 1].totalValue = answerOption.value
        setResults([...results, answerOption])
    }

    type ColorMapType = {
        [key in ComplianceResult]: string;
    };
    const colorMap: ColorMapType = {
        [ComplianceResult.NonCompliant]: "#D9534F",
        [ComplianceResult.RequiredImprovements]: "orange",
        [ComplianceResult.PartiallyCompliant]: "yellow",
        [ComplianceResult.FullyCompliant]: "green",
        [ComplianceResult.NoDocument]: "#D9534F",
        [ComplianceResult.PartialDocument]: "orange",
        [ComplianceResult.FullDocument]: "yellow",
        [ComplianceResult.NotApplicable]: "#D9534F",
        [ComplianceResult.Yes]: "green",
        [ComplianceResult.No]: "red",
        [ComplianceResult.NoKnown]: "grey"
    }
    type ColorMapCategoryType = {
        [key in FunctionalRequirementCategory]: string;
    };
    const colorCategoryMap: ColorMapCategoryType = {
        [FunctionalRequirementCategory.Security]: theme.palette.primary.main,
        [FunctionalRequirementCategory.Vulnerability]: theme.palette.primary.main,
        [FunctionalRequirementCategory.FDA]: theme.palette.primary.main,
        [FunctionalRequirementCategory.Context]: theme.palette.primary.main
    }
    const [modalOpen, setModalOpen] = useState(false)

    const handleCloseModal = () => {
        setModalOpen(false)
    }

    useEffect(() => {
        const answeredCount = Object.keys(selectedAnswers).filter((key) => {
            // Excluir subpreguntas (las que tienen un "." en su ID)
            return !key.includes(".") && selectedAnswers[key]
        }).length

        setAnsweredQuestionsCount(answeredCount)

        if (answeredCount === questions.filter((q) => !q.dependsOn && !q.id.includes(".")).length && isSaved) {
            setModalOpen(true)
        }
    }, [selectedAnswers, questions.length, isSaved])

    const calculateTotalScore = () => {
        const answeredQuestions = questions.filter(q => q.totalValue > 0)
        const totalScore = answeredQuestions.reduce((accumulator, currentQuestion) => {
            return accumulator + currentQuestion.totalValue
        }, 0)
        if (answeredQuestions.length === 0) {
            return 0
        }
        const maxPossibleScore = answeredQuestions.length * 9
        return (totalScore / maxPossibleScore) * 10
    }
    return (
        currentQuestion && <Grid item>
            <Grid item xs container flexDirection="column" spacing="20px">
                <CustomSnackbar
                    open={snackbarOpen}
                    onClose={() => setSnackbarOpen(false)}
                    message={snackbarMessage}
                    severity={snackbarSeverity}
                />
                <Grid item container flexDirection="column" rowGap="35px" color="#E8E2D0">
                    <Grid item container>
                        <StyledBox>

                            <Grid>
                                <Typography variant="h5">Quiz</Typography>
                                <Grid alignItems="center" justifyContent="space-between" display="flex">
                                    <Grid xs={8}>
                                        <Grid>
                                            <QuizIndicator
                                                NoDocumentAvailable={resultCounts.NoDocumentAvailable}
                                                RequiredImprovements={resultCounts.RequiredImprovements}
                                                PartiallyCompliant={resultCounts.PartiallyCompliant}
                                                FullyCompliant={resultCounts.FullyCompliant}
                                                Yes={resultCounts.Yes}
                                                No={resultCounts.No}
                                                NoKnown={resultCounts.NoKnown}
                                                total={resultCounts.total}
                                            /></Grid>
                                        <Grid marginTop={2}>
                                            <Legend type={type === QuizzType.CRA ? "CRA" : "FDA"} />
                                        </Grid>

                                    </Grid>

                                    <Box
                                        sx={{
                                            border: "1px solid #4A4733",
                                            borderRadius: "8px",
                                            padding: "5px 10px",
                                            width: "fit-content"
                                        }}
                                    >
                                        <Typography>
                                            {context.t.translate("pending")} {answeredQuestionsCount}/{type === QuizzType.CRA ? 38 : 9}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider sx={{ height: "1px", width: "100%", mt: "10px", background: "#4A4733" }} />
                            </Grid>
                            <Grid item xs={12} flexDirection="row" >
                                <Grid item flexDirection="row">
                                    <Grid container flexDirection="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ marginTop: "10px" }}>
                                        <Grid item xs={12}>
                                            <Box sx={{ border: "1px solid #4A4733", borderRadius: "8px", display: "flex", gap: 2, alignItems: "center" }}>
                                                <Grid sx={{ backgroundColor: "#4A4733", padding: "15px", borderRadius: "6px", fontSize: "30px" }}>
                                                #{currentQuestionIndex}
                                                </Grid>
                                                <Box alignItems="center">
                                                    <Typography fontSize="11px" fontWeight="bold">{currentQuestion.category}</Typography>
                                                    <Typography fontSize="22px" color="#CDC7AC">{currentQuestion.title}</Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography fontSize="16px" fontFamily="Griff" fontWeight="light" color="#E8E2D0">
                                                {currentQuestion.description}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container xs={12} flexDirection="column">
                                <Grid item xs={12}>
                                    <Divider sx={{ height: "1px", width: "100%", mt: "10px", background: "#4A4733" }} />
                                </Grid>
                                <Grid container flexDirection="column" alignItems="flex-start" >
                                    <Grid item xs={12} justifyContent="flex-start" paddingTop="15px">
                                        <Typography>{currentQuestion.text}</Typography>

                                        {currentQuestion.type === QuestionType.SingleSelect && (
                                            <RadioGroup
                                                value={selectedAnswers[currentQuestionIndex] || ""}
                                                sx={{ display: "flex", flexDirection: "row" }}
                                                onChange={handleOptionChange}
                                            >
                                                {currentQuestion.results.map((answerOption, index) => (
                                                    <FormControlLabel
                                                        key={index}
                                                        value={answerOption.result}
                                                        control={<Radio
                                                            onClick={() => setSelectedAnswer(answerOption)}
                                                            sx={{
                                                                "&, &.Mui-checked": {
                                                                    color: colorMap[answerOption.result]
                                                                }
                                                            }}
                                                        />}
                                                        label={answerOption.result}
                                                    />
                                                ))}
                                            </RadioGroup>
                                        )}

                                        {currentQuestion.type === QuestionType.Text && (
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                value={selectedAnswers[currentQuestionIndex] || ""}
                                                onChange={handleOptionChange}
                                                multiline
                                                rows={4}
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                                { currentQuestion.requiresJustification &&
    selectedAnswers[currentQuestionIndex] === ComplianceResult.Yes && (
                                    <Grid item xs={12}>
                                        <Typography fontSize="16px">Justification</Typography>
                                        <CustomEditor
                                            initialContent={justification ? justification[currentQuestionIndex] : ""}
                                            onSave={(content: string) => {
                                                setJustifications((prev) => ({
                                                    ...prev,
                                                    [currentQuestionIndex]: content
                                                }))
                                            }}
                                        />
                                    </Grid>
                                )}

                                <Grid container flexDirection="column" alignItems="flex-start" sx={{ mt: 3 }}>
                                    {dependQuestions.map((dependQuestion) => {
                                        if (dependQuestion.dependsOn === currentQuestion.id) {
                                            // Transformar el ID de dependQuestion para buscar en selectedAnswers
                                            const normalizedId = dependQuestion.id.replace(/^q/, "") // Quitar la 'q' al inicio
                                            const parentAnswer = selectedAnswers[currentQuestionIndex]

                                            if (parentAnswer === dependQuestion.condition) {
                                                return (
                                                    <Grid item xs={12} key={dependQuestion.id}>
                                                        <Typography fontSize="16px">{dependQuestion.text}</Typography>
                                                        {dependQuestion.type === QuestionType.SingleSelect && (
                                                            <RadioGroup
                                                                value={selectedAnswers[normalizedId] || ""}
                                                                sx={{ display: "flex", flexDirection: "row" }}
                                                                onChange={(e) =>
                                                                    setSelectedAnswers({
                                                                        ...selectedAnswers,
                                                                        [normalizedId]: e.target.value
                                                                    })
                                                                }
                                                            >
                                                                {dependQuestion.results.map((answerOption, index) => (
                                                                    <FormControlLabel
                                                                        key={index}
                                                                        value={answerOption.result}
                                                                        control={
                                                                            <Radio
                                                                                sx={{
                                                                                    "&, &.Mui-checked": {
                                                                                        color: colorMap[answerOption.result]
                                                                                    }
                                                                                }}
                                                                            />
                                                                        }
                                                                        label={answerOption.result}
                                                                    />
                                                                ))}
                                                            </RadioGroup>
                                                        )}
                                                        {dependQuestion.type === QuestionType.Text && (
                                                            <TextField
                                                                fullWidth
                                                                variant="outlined"
                                                                value={selectedAnswers[normalizedId] || ""}
                                                                onChange={(e) =>
                                                                    setSelectedAnswers({
                                                                        ...selectedAnswers,
                                                                        [normalizedId]: e.target.value
                                                                    })
                                                                }
                                                                multiline
                                                                rows={4}
                                                            />
                                                        )}
                                                    </Grid>
                                                )
                                            }
                                        }
                                        return null
                                    })}
                                </Grid>

                            </Grid>
                            <Grid container xs={12} flexDirection="row" alignItems="flex-end" justifyContent="flex-end" spacing={2}>
                                <Grid item>
                                    <ActionButton
                                        variant="outlined"
                                        icon={<CloseIcon />}
                                        onClick={() => handleExitClick()}
                                        text={context.t.translate("CRA_cancel")}
                                    />
                                </Grid>
                                <Grid item>
                                    <ActionButton
                                        variant="contained"
                                        onClick={saveHandler}
                                        /* disabled={!formValid() || isSaved} */
                                        text={context.t.translate("CRA_save")}
                                    />
                                </Grid>

                                <Grid item>
                                    <ActionButton
                                        variant="contained"
                                        onClick={handleBack}
                                        disabled={currentQuestionIndex === 1}
                                        text="Back"
                                    />
                                </Grid>
                                <Grid item>
                                    <ActionButton
                                        variant="contained"
                                        onClick={handleNext}
                                        disabled={currentQuestionIndex >= (type === QuizzType.CRA ? 38 : 9)}
                                        text="Next"
                                    />
                                </Grid>
                            </Grid>

                        </StyledBox>
                    </Grid>
                </Grid>
                <QuizModal
                    open={modalOpen}
                    onClose={handleCloseModal}
                    actions={
                        <ActionButton onClick={handleFinishQuiz} text="Send the results" />
                    }
                />
            </Grid>
        </Grid>
    )
}

export default Quiz
