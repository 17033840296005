import React, { useContext } from "react"
import { MenuItem, TextField } from "@mui/material"
import { SearchInput } from "@components/common/forms/index"
import { ServicesContext } from "@context/index"
import { Assessment, AssessmentStatus, AssessmentType } from "@models/index"
import { FilterOperation, FilterOption } from "@utils/index"
import { I18nContext } from "I18nProvider"
import { AbilityContext } from "@components/permissions"

interface AssessmentSelectorProps{
    value: string
    customFilters?: FilterOption[]
    onSelect: (id: string|undefined, a: Assessment|null)=>void
}
const AssessmentSelector: React.FC<AssessmentSelectorProps> = ({ value, customFilters, onSelect }) => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const ability = useContext(AbilityContext)
    const assessmentService = useContext(ServicesContext).assessmentService
    const [disabled, setDisabled] = React.useState<boolean>(false)

    /* useEffect(() => {
        if ((customFilters !== undefined && customFilters[0].value !== undefined && customFilters[0].value !== "00000000-0000-0000-0000-000000000000") || !ability.can("*", "Client")) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }
    }, [customFilters]) */

    if (disabled) {
        return (
            <TextField margin="normal" select required disabled fullWidth variant="filled" label="Assessment" value="no">
                <MenuItem value="no">
                    <em>No options</em>
                </MenuItem>
            </TextField>
        )
    }

    return (
        <SearchInput<Assessment>
            margin="normal" required label="Assessment"
            variant="filled"
            value={value}
            requesterById={async v => {
                if (v !== undefined) {
                    const assessment = await assessmentService.get(v)
                    return [assessment]
                }
                const pro: Assessment = {
                    id: "",
                    type: AssessmentType.CRT,
                    status: AssessmentStatus.Contracted,
                    contractDate: null,
                    startDate: null,
                    endDate: null,
                    product: { id: "" },
                    client: { id: "" },
                    template_id: "00000000-0000-0000-0000-000000000000",
                    analysis_status: 0
                }
                return [pro]
            } }
            requester={async v => {
                let filter: FilterOption[] = [{ field: "product", operation: FilterOperation.StringContains, value: v }]
                if (customFilters !== undefined && customFilters[0].value !== "") {
                    filter = filter.concat(customFilters)
                }
                const assessment = await assessmentService.getAll()
                return assessment.list
            }}
            getterID={elem => elem?.id}
            getterTitle={(elem) => {
                const productName = elem.product?.name || "" // Nombre del producto
                const productVersion = elem.product?.version ? ` v${elem.product.version}` : "" // Versión del producto
                const assessmentType = elem.type ? ` (${elem.type})` : "" // Tipo de assessment
                const assessmentId = elem.id ? ` ${elem.id.slice(-4)}` : "" // Los últimos 4 caracteres del ID para diferenciación

                // Título completo con información adicional
                return `${productName}${productVersion}${assessmentType} -${assessmentId}`
            }}
            onClickElem={(v) => { onSelect(v?.id, v) }}></SearchInput>
    )
}

export { AssessmentSelector }
export default AssessmentSelector
