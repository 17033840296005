import Assessment, { AssessmentStatus, AssessmentType } from "@models/Assessment"
import { v4 as uuidv4 } from "uuid"

const assessmentGenerator = (totalAssessmentTest: number): Assessment[] => {
    // Constants
    const assessment: Assessment[] = []
    // --------------------------------------------------
    /// Functions
    const getRandomAssessmentType = (): AssessmentType => {
        const testTypes = Object.values(AssessmentType)
        return testTypes[Math.floor(Math.random() * testTypes.length)]
    }
    const getRandomAssessmentStatus = (): AssessmentStatus => {
        const assessmentStatus = Object.values(AssessmentStatus)
        return assessmentStatus[Math.floor(Math.random() * assessmentStatus.length)]
    }
    // --------------------------------------------------

    for (let i = 0; i < totalAssessmentTest; i++) {
        assessment.push({
            id: uuidv4(),
            status: getRandomAssessmentStatus(),
            type: getRandomAssessmentType(),
            contractDate: null,
            template_id: uuidv4(),
            product: { id: uuidv4(), name: "Product" },
            client: { id: uuidv4(), name: "Client" }
        })
    }

    return assessment
}

export default assessmentGenerator
