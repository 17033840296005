import { StyledBox } from "@components/common/Box/BoxContainer"
import GenericModal from "@components/common/modals/GenericModal"
import { Grid, Typography, useTheme } from "@mui/material"
import CustomEditor from "@views/pentest/component/CustomEditor"
import { useState } from "react"

type ConclusionModalProps = {
    open: boolean
    onClose: () => void
    title: string
    question: string
    answer: string
    initialConclusion?: string // La conclusión inicial, si existe
    onSave: (conclusion: string) => void // Función para guardar la conclusión
}

const ConclusionModal = ({
    open,
    onClose,
    title = "Add conclusion",
    question,
    answer,
    initialConclusion = "",
    onSave
}: ConclusionModalProps) => {
    const theme = useTheme()
    const [conclusion, setConclusion] = useState<string>(initialConclusion)

    const handleSaveFromEditor = (content: string) => {
        setConclusion(content) // Actualiza el estado local
        onSave(content) // Llama al callback pasado desde el componente padre
        onClose() // Cierra el modal automáticamente
    }

    return (
        <GenericModal open={open} onClose={onClose} title={title}>
            <Grid
                container
                style={{
                    gap: 16,
                    display: "flex",
                    padding: "0px 40px",
                    flexDirection: "column",
                    width: "100%"
                }}
            >
                <StyledBox style={{ width: "100%" }}>
                    <Typography
                        style={{
                            fontSize: 18,
                            color: theme.palette.text.primary,
                            fontWeight: "bold",
                            wordWrap: "break-word"
                        }}
                    >
                        {question}
                    </Typography>
                    <Typography style={{ wordWrap: "break-word" }}>{answer}</Typography>
                </StyledBox>
                <Typography
                    style={{
                        fontSize: 18,
                        color: theme.palette.text.primary,
                        fontWeight: "bold"
                    }}
                >
                    Conclusion
                </Typography>
                <CustomEditor onSave={handleSaveFromEditor} initialContent={initialConclusion} />
            </Grid>
        </GenericModal>
    )
}

export default ConclusionModal
