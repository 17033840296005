import React, { useContext } from "react"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import highchartsAccessibility from "highcharts/modules/accessibility"
import { useTheme } from "@mui/material"
import { I18nContext } from "I18nProvider"

// Inicializar el módulo de accesibilidad
highchartsAccessibility(Highcharts)

interface DonutChartValues {
    title: string;
    dataType: "array" | "object" | "mitigated-unmitigated" | "done-pending";
    data: Array<{ name: string, color: string, y: number }> | { [key: string]: number } | { mitigated: number, unmitigated: number } | { done: number, pending: number };
    number: number;
    footer?: string;
    size?: "small" | "medium" | "large"; // Propiedad para definir el tamaño
}

const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

const transformName = (name: string) => {
    if (name.toLowerCase() === "crt" || name.toLowerCase() === "sca") {
        return name.toUpperCase()
    }
    return capitalizeFirstLetter(name)
}

const GenericDonutChart: React.FC<DonutChartValues> = ({ title, dataType, data, number, footer, size = "medium" }) => {
    const theme = useTheme()
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }

    let seriesData: Array<{ name: string, color: string, y: number }> = []

    if (dataType === "array") {
        seriesData = (data as Array<{ name: string, color: string, y: number }>).map(serie => ({
            ...serie,
            borderRadius: 0,
            borderColor: theme.palette.secondary.contrastText
        }))
    } else if (dataType === "object") {
        seriesData = Object.keys(data).map((key) => ({
            name: transformName(key),
            y: (data as { [key: string]: number })[key],
            color: getColorForSeverity(key),
            borderRadius: 0,
            borderColor: theme.palette.secondary.contrastText
        })).filter(serie => serie.y > 0)
    } else if (dataType === "mitigated-unmitigated") {
        const mitigated = (data as { mitigated: number, unmitigated: number }).mitigated
        const unmitigated = (data as { mitigated: number, unmitigated: number }).unmitigated
        seriesData = [
            {
                name: context.t.translate("mitigated"),
                y: mitigated,
                color: "#8dd3c7"
            },
            {
                name: context.t.translate("unmitigated"),
                y: unmitigated,
                color: "#A9A9A9"
            }
        ]
    } else if (dataType === "done-pending") {
        const done = (data as { done: number, pending: number }).done
        const pending = (data as { done: number, pending: number }).pending

        seriesData = [
            {
                name: "Done",
                y: done,
                color: "#fae733"
            },
            {
                name: "Pending",
                y: pending,
                color: "#2a292a"
            }
        ]
    }

    // Definir tamaños para cada prop "size"
    const sizeConfig = {
        small: { width: 210, height: 130, fontSize: "16px", legendFontSize: "14px", legendWidth: 100 },
        medium: { width: 290, height: 150, fontSize: "24px", legendFontSize: "16px", legendWidth: 140 },
        large: { width: 400, height: 220, fontSize: "30px", legendFontSize: "20px", legendWidth: 160 }
    }

    const { width, height, fontSize, legendFontSize, legendWidth } = sizeConfig[size]

    const options: Highcharts.Options = {
        title: { text: undefined },
        chart: {
            type: "pie",
            backgroundColor: "transparent",
            height, // Usar el tamaño dinámico
            width, // Usar el tamaño dinámico
            spacingTop: 0,
            spacingRight: 0,
            spacingBottom: 0,
            spacingLeft: 0,
            plotBorderWidth: 0,
            margin: [0, -10, 10, -20],
            events: {
                load () {
                    const chart = this
                    let centerX = chart.chartWidth / 4 // Esto estaba antes, centrado para medium y large
                    let centerY = chart.plotHeight / 2 + 15 // Esto estaba antes, centrado para medium y large

                    if (size === "small") {
                        // Modificar centro cuando es 'small'
                        centerX = chart.chartWidth / 2.8 // Centro horizontal en small
                        centerY = chart.plotHeight / 2 + 25 // Ajuste vertical para small
                    }
                    chart.renderer
                        .text(`${number}`, centerX, centerY)
                        .attr({
                            zIndex: 5,
                            align: "center"
                        })
                        .css({
                            color: theme.palette.primary.contrastText,
                            fontSize, // Usar el tamaño dinámico para el número
                            fontWeight: "bolder",
                            textAlign: "center",
                            fontFamily: "Griff"
                        })
                        .add()

                    if (footer) {
                        chart.renderer
                            .text(footer, centerX, centerY + 10)
                            .attr({
                                zIndex: 5,
                                align: "center"
                            })
                            .css({
                                color: theme.palette.primary.contrastText,
                                fontSize: "8px",
                                textAlign: "center",
                                fontFamily: "Griff"
                            })
                            .add()
                    }
                }
            }
        },
        credits: { enabled: false },
        legend: {
            enabled: true,
            title: { text: title, style: { fontSize: legendFontSize, fontFamily: "Griff", color: theme.palette.primary.contrastText } },
            align: "right",
            layout: "vertical",
            verticalAlign: "top",
            itemStyle: { color: theme.palette.primary.contrastText },
            padding: 3,
            itemMarginTop: 5,
            itemMarginBottom: 5,
            width: legendWidth,
            y: 0,
            x: 20
        },
        plotOptions: {
            pie: {
                innerSize: "48%",
                dataLabels: { enabled: false },
                center: [75, 60]
            }
        },
        exporting: { enabled: false },
        series: [
            {
                showInLegend: true,
                data: seriesData,
                type: "pie"
            }
        ],
        tooltip: {
            pointFormatter: function () {
                const total = seriesData.reduce((acc, point) => acc + point.y, 0)
                const yValue = this.y ?? "N/A"
                const percentage = this.y !== undefined ? Math.round((this.y / total) * 100) : "N/A"
                return `<b>${yValue}</b></br><b>${percentage}%</b>`
            }
        }
    }

    return (
        <div style={{ position: "relative" }}>
            <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
    )
}

const getColorForSeverity = (severity: string) => {
    switch (severity.toLowerCase()) {
    case "critical":
        return "#7940A5"
    case "high":
        return "#D9534F"
    case "medium":
        return "#EC971F"
    case "low":
        return "#F2CC0C"
    case "none":
        return "#A9A9A9"
    default:
        return "#000000"
    }
}

export { GenericDonutChart }
export default GenericDonutChart
