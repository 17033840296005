import React, { useCallback, useEffect, useState } from "react"
import { Editor } from "react-draft-wysiwyg"
import { AtomicBlockUtils, CompositeDecorator, convertFromRaw, convertToRaw, EditorState } from "draft-js"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import ActionButton from "@components/common/Button/ActionButton"

type SimpleEditorProps = {
    initialContent?: string // Contenido inicial en formato JSON
    onSave: (content: string) => void // Función para guardar el contenido
}

// Componente para renderizar imágenes
const Image = (props: any) => {
    const { src } = props.contentState.getEntity(props.entityKey).getData()
    return <img src={src} alt="Uploaded" style={{ maxWidth: "100%", height: "auto" }} />
}

const CustomEditor: React.FC<SimpleEditorProps> = ({ initialContent = "", onSave }) => {
    const getDecorator = useCallback(() => {
        return new CompositeDecorator([{
            strategy: (contentBlock, callback, contentState) => {
                contentBlock.findEntityRanges(
                    (character) => {
                        const entityKey = character.getEntity()
                        return entityKey !== null && contentState.getEntity(entityKey).getType() === "IMAGE"
                    },
                    callback
                )
            },
            component: Image
        }])
    }, [])

    const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty(getDecorator()))

    // Si se recibe `initialContent`, actualizar el estado del editor
    useEffect(() => {
        if (initialContent) {
            try {
                const content = convertFromRaw(JSON.parse(initialContent))
                setEditorState(EditorState.createWithContent(content, getDecorator()))
            } catch (e) {
                console.error("Error parsing the initial content", e)
            }
        }
    }, [initialContent, getDecorator])

    const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0]
        if (!file) return

        const reader = new FileReader()
        reader.onloadend = () => {
            const base64Image = reader.result as string
            const contentState = editorState.getCurrentContent()

            const contentStateWithEntity = contentState.createEntity("IMAGE", "IMMUTABLE", { src: base64Image })
            const entityKey = contentStateWithEntity.getLastCreatedEntityKey()

            const newEditorState = AtomicBlockUtils.insertAtomicBlock(
                EditorState.set(editorState, { currentContent: contentStateWithEntity }),
                entityKey,
                " "
            )

            setEditorState(newEditorState)
        }

        reader.readAsDataURL(file)
    }

    // Manejo de cambios en el editor
    const handleEditorChange = (newEditorState: EditorState) => {
        setEditorState(newEditorState)
    }

    const saveContent = () => {
        const content = convertToRaw(editorState.getCurrentContent())

        const blocksWithIndex = content.blocks.map((block, index) => ({
            ...block,
            index // Añadir índice explícito
        }))

        const contentWithIndex = {
            ...content,
            blocks: blocksWithIndex
        }

        onSave(JSON.stringify(contentWithIndex))
    }

    return (
        <div>
            <input type="file" accept="image/*" onChange={handleImageUpload} style={{ marginBottom: "10px" }} />
            <Editor
                editorState={editorState}
                toolbarClassName="custom-toolbar"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                toolbar={{
                    options: ["inline", "blockType", "fontSize", "list", "textAlign", "colorPicker", "link"]
                }}
                onEditorStateChange={handleEditorChange}
            />
            <ActionButton text="Save Content" onClick={saveContent} style={{ marginTop: "10px", padding: "10px", fontWeight: "bold" }} />
        </div>
    )
}

export default CustomEditor
