import React, { useContext, useEffect, useState } from "react"
import { Button, Grid, Typography, useTheme } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid"
import { useNavigate } from "react-router-dom"
import { convertModelDefToGridColDef, GenericTable } from "@components/common/tables/index"
import { AbilityContext, Can } from "@components/permissions/index"
import { ServicesContext } from "@context/index"
import { I18nContext } from "I18nProvider"
import License from "@models/License"
import LicenseTableDefinition, { LicenseTableDefinitionES } from "@components/license/LicenseTableDefinition"

const LicenseList: React.FC = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const theme = useTheme()
    const licenseService = useContext(ServicesContext).licenseService

    const [cols, setCols] = useState<GridColDef[]>([])

    const ability = useContext(AbilityContext)
    useEffect(() => {
        // Seleccionar la definición de la tabla según el idioma
        const policyTableDefinition = context.language === "es" ? LicenseTableDefinition : LicenseTableDefinitionES
        setCols(convertModelDefToGridColDef(policyTableDefinition, ability))
    }, [context.language, ability]) // Añadir 'context.language' a las dependencias

    const navigate = useNavigate()

    return (
        <Grid item xs container flexDirection="column" spacing="20px" sx={{ position: "relative" }}>
            <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff" sx={{ marginTop: "0px", marginLeft: "20px" }}>{context.t.translate("license_management")}</Typography>
            <Can I="create" a="License">
                <Button variant="contained" sx={{ position: "absolute", top: "40px", right: "0px", color: theme.palette.secondary.contrastText }} onClick={() => navigate("./add")}>{context.t.translate("add_new_license")}</Button>
            </Can>
            <Grid item container flexDirection="column" rowGap="35px">
                <GenericTable<License> entity="License" columns={cols}
                    dataProvider={(filter) => licenseService.getAll(filter)}
                    onEdit={(elem: License) => navigate("./" + elem.id)}
                    onDelete={(elem: License) => licenseService.delete(elem.id)}
                />
            </Grid>
        </Grid>
    )
}

export { LicenseList }
export default LicenseList
