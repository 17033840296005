import Achilles, { MonitorName, MonitorValue, TestType } from "@models/Achilles"
import { v4 as uuidv4 } from "uuid"

const achillesTestGenerator = (totalAchillesTest: number): Achilles[] => {
    // Constants
    const achillesTests: Achilles[] = []
    // --------------------------------------------------
    /// Functions
    const getRandomTestType = (): TestType => {
        const testTypes = Object.values(TestType)
        return testTypes[Math.floor(Math.random() * testTypes.length)]
    }
    const getRandomMonitorName = (): MonitorName => {
        const monitorNames = Object.values(MonitorName)
        return monitorNames[Math.floor(Math.random() * monitorNames.length)]
    }
    const getRandomMonitorValue = (): MonitorValue => {
        const monitorValues = Object.values(MonitorValue)
        return monitorValues[Math.floor(Math.random() * monitorValues.length)]
    }
    // --------------------------------------------------

    for (let i = 0; i < totalAchillesTest; i++) {
        achillesTests.push({
            id: uuidv4(),
            test_type: getRandomTestType(),
            test_name: "Test",
            monitor_name: MonitorName.TCPPortsMonitor,
            monitor_value: getRandomMonitorValue(),
            failure_percentage: `${Math.random() * 100}`,
            assessment_id: { id: uuidv4(), name: "Assessment" },
            product_id: { id: uuidv4(), name: "Product" },
            client_id: { id: uuidv4(), name: "Client" },
            anomaly: 0
        })
    }

    return achillesTests
}

export default achillesTestGenerator
