import React, { useCallback, useContext, useEffect, useState } from "react"
import { AlertColor, Box, Button, Grid, Typography, useTheme } from "@mui/material"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { useNavigate } from "react-router-dom"
import ServicesContext from "@context/ServicesContext"
import { I18nContext } from "I18nProvider"
import Achilles, { MonitorName, MonitorValue, TestType } from "@models/Achilles"
import GenericTable from "@components/common/tables/GenericTable"
import { GridColDef, GridFilterItem, GridRowClassNameParams, GridRowParams, GridRowSelectionModel, GridState, useGridApiRef } from "@mui/x-data-grid"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import AchillesTableDefinition from "@components/achilles/AchillesTableDefinition"
import { AbilityContext } from "@components/permissions"
import { convertModelDefToGridColDef, CustomAction } from "@components/common/tables"
import { FilterOperation, FilterOption, QueryParameters } from "@utils/queryParams"
import ActionButton from "@components/common/Button/ActionButton"
import { useTrack } from "@components/track/TrackContext"

const initValue: Achilles = {
    id: "",
    test_type: TestType.TCP,
    test_name: "",
    monitor_name: MonitorName.Test,
    monitor_value: MonitorValue.TestGreen,
    failure_percentage: "0",
    assessment_id: { id: "", name: "" },
    product_id: { id: "", name: "", version: "" },
    client_id: { id: "", name: "" },
    anomaly: 0
}

const AchillesList: React.FC = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error("The I18n context is not initialized. Make sure you have the provider set up correctly.")
    }
    const theme = useTheme()
    const navigate = useNavigate()
    const ability = useContext(AbilityContext)
    const [cols, setCols] = useState<GridColDef[]>([])
    const [error, setError] = useState<Error | null>(null)
    const achillesService = useContext(ServicesContext).achillesService
    const assessmentService = useContext(ServicesContext).assessmentService
    const [formData, setFormData] = useState<Achilles>(initValue)
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [openSnackbar, setOpenSnackbar] = useState<boolean>(false)
    const [snackbarMessage, setSnackbarMessage] = useState<string>("")
    const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>("success")
    const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([])
    const apiRef = useGridApiRef()
    const [params, setParams] = useState<QueryParameters>({})
    const customActions = (params: GridRowParams<Achilles>) => {
        const actions: CustomAction[] = []
        return actions
    }

    const [registry, setRegistry] = React.useState(0)
    // Tracking
    const { track, trackData } = useTrack()
    useEffect(() => {
        setCols(convertModelDefToGridColDef(AchillesTableDefinition, ability))
        track({ view: "AchillesList" })
    }, [])

    const handleStateChange = useCallback(
        (state: GridState) => {
            if (state.filter.filterModel.items.every((filterItem: GridFilterItem) => filterItem.field !== undefined && filterItem.operator !== undefined && filterItem.value !== undefined)) {
                const newFilters: FilterOption[] = state.filter.filterModel.items.map((filterItem: GridFilterItem) => ({
                    field: filterItem.field.toString(),
                    operation: filterItem.operator as FilterOperation,
                    value: filterItem.value.toString()
                }))
                setParams((prevParams) => {
                    const filtersChanged = JSON.stringify(prevParams.filters) !== JSON.stringify(newFilters)
                    if (filtersChanged) {
                        return {
                            ...prevParams,
                            filters: newFilters
                        }
                    }
                    return prevParams
                })
            }
        },
        []
    )

    interface TabPanelProps {
        children?: any
        index: number[]
        value: number
    }

    const CustomTabPanel: React.FC<TabPanelProps> = ({ children, value, index }) => {
        return index.includes(value) ? children : <div></div>
    }

    const [uploadedFiles, setUploadedFiles] = useState<File[]>([])

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files
        if (files) {
            const xmlFiles = Array.from(files).filter(file => file.name.toLowerCase().endsWith(".xml"))
            setUploadedFiles(prevFiles => [...prevFiles, ...xmlFiles])
        }
    }

    return (
        <Grid item xs container flexDirection="column" spacing="20px" paddingLeft="20px" sx={{ position: "relative" }}>
            {/* Header Section */}
            <Grid container sx={{ justifyContent: "space-between", alignItems: "center", spacing: "20px", paddingLeft: "20px", marginBottom: "0px", paddingBottom: "0px" }}>
                <Typography variant="h4" color={theme.palette.text.secondary} fontWeight="bold">
                    Achilles
                </Typography>
                <Grid item sx={{ gap: 2, display: "flex" }}>
                    <Grid><Button variant="contained" sx={{ color: theme.palette.secondary.contrastText }} component="label">
                        Upload
                        <input
                            type="file"
                            accept=".xml"
                            multiple
                            hidden
                            onChange={handleFileUpload}
                        />
                    </Button></Grid>
                    <Grid><ActionButton text="Add" onClick={() => navigate("./add")} /></Grid>
                </Grid>

            </Grid>

            {/* File Upload Display */}
            <Box paddingLeft="20px" sx={{ marginTop: "20px" }}>
                {uploadedFiles.length > 0
                    ? (
                        uploadedFiles.map((file, index) => (
                            <CustomTabPanel key={index} value={registry} index={[0, 1, 2]}>
                                <Box sx={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                                    <Typography color="primary" fontSize="27px" fontWeight="bolder" sx={{ marginRight: "15px" }}>
                                        {file.name}
                                    </Typography>
                                </Box>
                            </CustomTabPanel>
                        ))
                    )
                    : (
                        <Typography>No files uploaded yet.</Typography>
                    )}
            </Box>

            {/* Snackbar */}
            <CustomSnackbar
                open={openSnackbar}
                onClose={() => setOpenSnackbar(false)}
                message={snackbarMessage}
                severity={snackbarSeverity}
            />

            {/* Table Section */}
            <Grid container direction="column" paddingLeft="20px" sx={{ marginTop: "20px" }}>
                <GenericTable<Achilles>
                    entity="Achilles"
                    columns={cols}
                    dataProvider={(filter) => achillesService.getAll(filter)}
                    onEdit={(elem: Achilles) => navigate("./" + elem.id)}
                    onDelete={(elem: Achilles) => achillesService.delete(elem.id)}
                    customActions={customActions}
                    dataGridProps={{
                        onStateChange: handleStateChange,
                        getRowClassName: (params: GridRowClassNameParams) => params.row.supressed ? "suppressedRow" : "",
                        sx: { "& .suppressedRow .MuiDataGrid-cell": { opacity: 0.5 } },
                        checkboxSelection: true,
                        rowSelectionModel,
                        onRowSelectionModelChange: (newRowSelectionModel: GridRowSelectionModel) => setRowSelectionModel(newRowSelectionModel),
                        apiRef
                    }}
                />
            </Grid>

            {/* Navigation Between Files */}
            {/*  <Grid container justifyContent="space-between" alignItems="center" spacing={3}>
                {uploadedFiles.length > 0 && registry < uploadedFiles.length - 1 && (
                    <Button variant="contained" sx={{ color: theme.palette.secondary.contrastText }} onClick={() => setRegistry((prev) => prev + 1)}>
                        Next &gt;
                    </Button>
                )}
            </Grid> */}
        </Grid>
    )
}

export { AchillesList }
export default AchillesList
