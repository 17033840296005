import React, { useContext } from "react"
import { Image, Page, Text, View } from "@react-pdf/renderer"
import Template from "@models/Template"
import { I18nContext } from "I18nProvider"
import { globalStyles } from "../Styles"
import { Footer, Header } from "./HeaderFooter"

interface introProps {
    title: string;
    description1: string;
    cvss: {
        [version: string]: {
            description: string;
            metrics: {
                key: string;
                value: string;
            }[];
        };
    };
    description2: string;
    table: {
        description: string;
        header: string[];
        body: Array<{ cvss: string; rating: string }>;
        legend: string;
    };
    description3: string;
    list: {
        key: string;
        value: string;
        components: Array<{ key: string; value: string }>;
    }[];
    description4: string;
    figure: string;
}

const i18n: {
    es: introProps;
    en: introProps;
} = {
    es: {
        title: "Identificación de vulnerabilidades",
        description1: "Las vulnerabilidades halladas se evaluarán mediante un estándar denominado Common Vulnerability Scoring System, en sus 3 versiones, la version 4.0, 3.1 y la version 2.0 (CVSS). Este estándar permite establecer una medida de gravedad en un rango de 0 a 10, siendo 10 la criticidad más alta posible. Se han utilizado tres versiones de CVSS debido a que algunas de las herramientas solo proporcionan información en una versión en específico.",
        cvss: {
            "CVSS:3.1": {
                description: "La versión 3.1 considera los sisguientes factores de riesgo:",
                metrics: [
                    { key: "Attack Vector (AV): ", value: "Local (L), Adjacent (A), Network (N), Physical (P)" },
                    { key: "Access Complexity (AC): ", value: "High (H), Low (L)" },
                    { key: "Privileges Required (PR): ", value: "High (H), Low (L), None (N)" },
                    { key: "User Interaction (UI): ", value: "Required (R), None (N)" },
                    { key: "Scope (S): ", value: "Unchanged (U), Changed (C)" },
                    { key: "Confidentiality (C): ", value: "None (N), Low (L), High (H)" },
                    { key: "Integrity (I): ", value: "None (N), Low (L), High (H)" },
                    { key: "Availability (A): ", value: "None (N), Low (L), High (H)" }
                ]
            },
            "CVSS:3.0": {
                description: "La versión 3.0 considera los sisguientes factores de riesgo:",
                metrics: [
                    { key: "Attack Vector (AV): ", value: "Local (L), Adjacent (A), Network (N), Physical (P)" },
                    { key: "Access Complexity (AC): ", value: "High (H), Low (L)" },
                    { key: "Privileges Required (PR): ", value: "High (H), Low (L), None (N)" },
                    { key: "User Interaction (UI): ", value: "Required (R), None (N)" },
                    { key: "Scope (S): ", value: "Unchanged (U), Changed (C)" },
                    { key: "Confidentiality (C): ", value: "None (N), Low (L), High (H)" },
                    { key: "Integrity (I): ", value: "None (N), Low (L), High (H)" },
                    { key: "Availability (A): ", value: "None (N), Low (L), High (H)" }
                ]
            },
            "CVSS:2.0": {
                description: "La versión 2.0 considera los sisguientes factores de riesgo:",
                metrics: [
                    { key: "Attack Vector (AV): ", value: "Local (L), Adjacent (A), Network (N), Physical (P)" },
                    { key: "Access Complexity (AC): ", value: "High (H), Medium (M), Low (L)" },
                    { key: "Authentication (Au): ", value: "Multiple (M), Single (S), None (N)" },
                    { key: "Confidentiality (C): ", value: "None (N), Partial (P), Complete (C)" },
                    { key: "Integrity (I): ", value: "None (N), Partial (P), Complete (C)" },
                    { key: "Availability (A): ", value: "None (N), Partial (P), Complete (C)" }
                ]
            }
        },
        description2: "En este apartado se procederá a describir todas las vulnerabilidades halladas a lo largo del test ejecutado. Para una mejor comprensión del formato de presentación de estas y el detalle que se muestra, se explicarán una serie de conceptos de métricas de seguridad.",
        table: {
            description: "La siguiente tabla muestra las vulnerabilidades encontradas en el test de penetración:",
            header: ["CVSS Score", "Rating"],
            body: [
                { cvss: "0.0", rating: "None" },
                { cvss: "0.1 - 3.9", rating: "Low" },
                { cvss: "4.0 - 6.9", rating: "Medium" },
                { cvss: "7.0 - 8.9", rating: "High" },
                { cvss: "9.0 - 10.0", rating: "Critical" }
            ],
            legend: "Tabla 5: CVSS Rating."
        },
        description3: "Esos vectores se muestran en el apartado CVSS de cada tabla de vulnerabilidades junto al valor obtenido. En base a este sistema, el formato de los resultados está formado por dos tipos de tablas:",
        list: [
            {
                key: "Tabla Resumen. ",
                value: "Es la tabla donde se resumen todas las vulnerabilidades encontradas en el siguiente formato:",
                components: [
                    { key: "Identificador. ", value: "Se mostrará mediante el siguiente formato VULN-nnn, donde nnn será un número que refleje el número de vulnerabilidad." },
                    { key: "Nombre. ", value: "Describirá el título de la vulnerabilidad encontrada." },
                    { key: "Criticidad. ", value: "Este campo contendrá el 'Rating' de la vulnerabilidad encontrada." }
                ]
            },
            {
                key: "Tabla Detalle. ",
                value: "Esta tabla contendrá tres tipos de información:",
                components: [
                    { key: "Cabecera. ", value: "Este tipo de información contendrá el identificador, el nombre, la criticidad, el valor CVSS junto con sus valores y los activos afectados." },
                    { key: "Evidencias. ", value: "Se mostrará un texto descriptivo, así como capturas de pantalla del problema identificado." },
                    { key: "Solución/Referencia. ", value: "Se describirá una primera solución para el problema." }
                ]
            }
        ],
        description4: "Un ejemplo de este tipo de tabla es el que se muestra a continuación:",
        figure: "Figura 1: Ejemplo de tabla."

    },
    en: {
        title: "Vulnerability identification",
        description1: "The vulnerabilities found will be evaluated using a standard called Common Vulnerability Scoring System, in its 3 versions, version 4.0, 3.1 and version 2.0 (CVSS). This standard allows to establish a severity measure in a range from 0 to 10, being 10 the highest possible criticality. Three versions of CVSS have been used because some of the tools only provide information in a specific version.",
        cvss: {
            "CVSS:3.1": {
                description: "Version 3.1 considers the following risk factors:",
                metrics: [
                    { key: "Attack Vector (AV): ", value: "Local (L), Adjacent (A), Network (N), Physical (P)" },
                    { key: "Access Complexity (AC): ", value: "High (H), Low (L)" },
                    { key: "Privileges Required (PR): ", value: "High (H), Low (L), None (N)" },
                    { key: "User Interaction (UI): ", value: "Required (R), None (N)" },
                    { key: "Scope (S): ", value: "Unchanged (U), Changed (C)" },
                    { key: "Confidentiality (C): ", value: "None (N), Low (L), High (H)" },
                    { key: "Integrity (I): ", value: "None (N), Low (L), High (H)" },
                    { key: "Availability (A): ", value: "None (N), Low (L), High (H)" }
                ]
            },
            "CVSS:3.0": {
                description: "Version 3.0 considers the following risk factors:",
                metrics: [
                    { key: "Attack Vector (AV): ", value: "Local (L), Adjacent (A), Network (N), Physical (P)" },
                    { key: "Access Complexity (AC): ", value: "High (H), Low (L)" },
                    { key: "Privileges Required (PR): ", value: "High (H), Low (L), None (N)" },
                    { key: "User Interaction (UI): ", value: "Required (R), None (N)" },
                    { key: "Scope (S): ", value: "Unchanged (U), Changed (C)" },
                    { key: "Confidentiality (C): ", value: "None (N), Low (L), High (H)" },
                    { key: "Integrity (I): ", value: "None (N), Low (L), High (H)" },
                    { key: "Availability (A): ", value: "None (N), Low (L), High (H)" }
                ]
            },
            "CVSS:2.0": {
                description: "Version 2.0 considers the following risk factors:",
                metrics: [
                    { key: "Attack Vector (AV): ", value: "Local (L), Adjacent (A), Network (N), Physical (P)" },
                    { key: "Access Complexity (AC): ", value: "High (H), Medium (M), Low (L)" },
                    { key: "Authentication (Au): ", value: "Multiple (M), Single (S), None (N)" },
                    { key: "Confidentiality (C): ", value: "None (N), Partial (P), Complete (C)" },
                    { key: "Integrity (I): ", value: "None (N), Partial (P), Complete (C)" },
                    { key: "Availability (A): ", value: "None (N), Partial (P), Complete (C)" }
                ]
            }
        },
        description2: "This section will describe all the vulnerabilities found throughout the executed test. For a better understanding of the presentation format of these and the detail shown, a series of security metric concepts will be explained.",
        table: {
            description: "The following table shows the vulnerabilities found in the penetration test:",
            header: ["CVSS Score", "Rating"],
            body: [
                { cvss: "0.0", rating: "None" },
                { cvss: "0.1 - 3.9", rating: "Low" },
                { cvss: "4.0 - 6.9", rating: "Medium" },
                { cvss: "7.0 - 8.9", rating: "High" },
                { cvss: "9.0 - 10.0", rating: "Critical" }
            ],
            legend: "Table 5: CVSS Rating."
        },
        description3: "These vectors are shown in the CVSS section of each vulnerability table along with the obtained value. Based on this system, the results format is formed by two types of tables:",
        list: [
            {
                key: "Summary Table. ",
                value: "It is the table where all the vulnerabilities found are summarized in the following format:",
                components: [
                    { key: "Identifier. ", value: "It will be shown in the following format VULN-nnn, where nnn will be a number reflecting the vulnerability number." },
                    { key: "Name. ", value: "It will describe the title of the vulnerability found." },
                    { key: "Severity. ", value: "This field will contain the vulnerability 'Rating' found." }
                ]
            },
            {
                key: "Detail Table. ",
                value: "This table will contain three types of information:",
                components: [
                    { key: "Header. ", value: "This type of information will contain the identifier, the name, the severity, the CVSS value along with its values and the affected assets." },
                    { key: "Evidence. ", value: "A descriptive text will be shown, as well as screenshots of the identified problem." },
                    { key: "Solution/Reference. ", value: "A first solution for the problem will be described." }
                ]
            }
        ],
        description4: "An example of this type of table is shown below:",
        figure: "Figure 1: Example of table."
    }
}

interface SectionProps {
    lang?: string;
    template?: Template;
    client?: string
}

const IdentificationSection: React.FC<SectionProps> = ({ lang, template, client }) => {
    // Constant
    const context = useContext(I18nContext)
    // --------------------------------------------------

    // lenguaje
    type I18nType = typeof i18n
    let locale: keyof I18nType = "es"
    if (lang) {
        if (lang === "es" || lang === "en") {
            locale = lang as keyof I18nType
        } else {
            console.warn(`Idioma no soportado: ${lang}. Usando idioma por defecto.`)
        }
    } else if (context) {
        if (context.language === "es" || context.language === "en") {
            locale = context.language as keyof I18nType
        } else {
            console.warn(`Idioma no soportado en el contexto: ${context.language}. Usando idioma por defecto.`)
        }
    } else {
        console.error(
            "El contexto I18n no está inicializado. Asegúrese de que tiene el proveedor configurado correctamente."
        )
    }
    // --------------------------------------------------
    return (
        <Page size="A4" style={globalStyles.page}>
            <Header externalData={null} template={template}></Header>
            <View style={{ ...globalStyles.section }}>
                <View style={globalStyles.column}>
                    <View style={globalStyles.containerY}>
                        <Text style={globalStyles.title}>{i18n[locale].title}</Text>
                        <Text style={{ ...globalStyles.texto }}>{i18n[locale].description1}</Text>
                        <View style={{ ...globalStyles.containerY, gap: 2 }}>
                            <Text style={globalStyles.texto}>{i18n[locale].cvss["CVSS:3.1"]?.description}</Text>
                            {i18n[locale].cvss["CVSS:3.1"]?.metrics.map((metric, index) => (
                                <React.Fragment key={index}>
                                    <View style={{ flexDirection: "row", marginLeft: 10 }}>
                                        <Text style={{ ...globalStyles.textoBold, marginRight: 5 }}>•</Text>
                                        <Text style={{ ...globalStyles.textoBold }}>{metric.key}</Text>
                                        <Text style={{ ...globalStyles.texto }}>{metric.value}</Text>
                                    </View>
                                </React.Fragment>
                            ))}
                        </View>
                        <View style={{ ...globalStyles.containerY, gap: 2 }}>
                            <Text style={globalStyles.texto}>{i18n[locale].cvss["CVSS:3.0"]?.description}</Text>
                            {i18n[locale].cvss["CVSS:2.0"]?.metrics.map((metric, index) => (
                                <React.Fragment key={index}>
                                    <View style={{ flexDirection: "row", marginLeft: 10 }}>
                                        <Text style={{ ...globalStyles.textoBold, marginRight: 5 }}>•</Text>
                                        <Text style={{ ...globalStyles.textoBold }}>{metric.key}</Text>
                                        <Text style={{ ...globalStyles.texto }}>{metric.value}</Text>
                                    </View>
                                </React.Fragment>
                            ))}
                        </View>
                    </View>
                </View>
                <View style={globalStyles.column}>
                    <View style={globalStyles.containerY}>
                        <View style={{ ...globalStyles.containerY, gap: 2 }}>
                            <Text style={globalStyles.texto}>{i18n[locale].cvss["CVSS:2.0"]?.description}</Text>
                            {i18n[locale].cvss["CVSS:2.0"]?.metrics.map((metric, index) => (
                                <React.Fragment key={index}>
                                    <View style={{ flexDirection: "row", marginLeft: 10 }}>
                                        <Text style={{ ...globalStyles.textoBold, marginRight: 5 }}>•</Text>
                                        <Text style={{ ...globalStyles.textoBold }}>{metric.key}</Text>
                                        <Text style={{ ...globalStyles.texto }}>{metric.value}</Text>
                                    </View>
                                </React.Fragment>
                            ))}
                        </View>
                        <View style={globalStyles.container}>
                            <Text style={globalStyles.texto}>{i18n[locale].table.description}</Text>
                            <View style={globalStyles.table}>
                                <View style={{ ...globalStyles.row, backgroundColor: "yellow" }} wrap={false}>
                                    {i18n[locale].table.header.map((header, index) => (
                                        <View key={index} style={globalStyles.col}>
                                            <Text style={globalStyles.textoBold}>{header}</Text>
                                        </View>
                                    ))}
                                </View>
                                {i18n[locale].table.body.map((row, index) => (
                                    <View key={index} style={globalStyles.row} wrap={false}>
                                        <View style={globalStyles.col}>
                                            <Text style={globalStyles.texto}>{row.cvss}  </Text>
                                        </View>
                                        <View style={globalStyles.col}>
                                            <Text style={globalStyles.texto}>{row.rating}</Text>
                                        </View>
                                    </View>
                                ))}
                            </View>
                            <View style={globalStyles.table_legend}>
                                <Text style={globalStyles.texto}>{i18n[locale].table.legend}</Text>
                            </View>
                        </View>
                        <View style={{ ...globalStyles.containerY, gap: 2 }}>
                            <Text style={globalStyles.texto}>{i18n[locale].description3}</Text>
                            <View style={{ ...globalStyles.containerY, gap: 2 }}>
                                {i18n[locale].list.slice(0, 1).map((item, index) => (
                                    <View key={index} style={{ ...globalStyles.containerX, gap: 2 }}>
                                        <Text style={{ ...globalStyles.textoBold, marginRight: 5 }}>•</Text>
                                        <View style={{ ...globalStyles.containerY, gap: 2 }}>
                                            <Text style={{ ...globalStyles.textoBold }}>{item.key}</Text>
                                            <Text style={{ ...globalStyles.texto }}>{item.value}</Text>
                                            <View style={{ ...globalStyles.containerY, gap: 2 }}>
                                                {item.components.slice(0, 2).map((component, index) => (
                                                    <View key={index} style={{ ...globalStyles.containerX, gap: 2 }}>
                                                        <Text style={{ ...globalStyles.textoBold, marginRight: 5 }}>•</Text>
                                                        <View style={{ ...globalStyles.containerY, gap: 2 }}>
                                                            <Text style={{ ...globalStyles.textoBold }}>{component.key}</Text>
                                                            <Text style={{ ...globalStyles.texto }}>{component.value}</Text>
                                                        </View>
                                                    </View>
                                                ))}
                                            </View>
                                        </View>
                                    </View>
                                ))}
                            </View>
                        </View>
                    </View>
                </View>
            </View>
            <View style={{ ...globalStyles.section, marginTop: 5 }}>
                <View style={globalStyles.column}>
                    <View style={{ ...globalStyles.containerY, gap: 5 }}>
                        {i18n[locale].list.slice(0, 1).map((item, index) => (
                            <View key={index} style={{ ...globalStyles.containerX, gap: 2 }}>
                                <Text style={{ ...globalStyles.textoBold, marginRight: 5 }}> </Text>
                                <View style={{ ...globalStyles.containerY, gap: 2 }}>
                                    <View style={{ ...globalStyles.containerY, gap: 2 }}>
                                        {item.components.slice(-1).map((component, index) => (
                                            <View key={index} style={{ ...globalStyles.containerX, gap: 2 }}>
                                                <Text style={{ ...globalStyles.textoBold, marginRight: 5 }}>•</Text>
                                                <View style={{ ...globalStyles.containerY, gap: 2 }}>
                                                    <Text style={{ ...globalStyles.textoBold }}>{component.key}</Text>
                                                    <Text style={{ ...globalStyles.texto }}>{component.value}</Text>
                                                </View>
                                            </View>
                                        ))}
                                    </View>
                                </View>
                            </View>
                        ))}
                        {i18n[locale].list.slice(1, 2).map((item, index) => (
                            <View key={index} style={{ ...globalStyles.containerX, gap: 2 }}>
                                <Text style={{ ...globalStyles.textoBold, marginRight: 5 }}>•</Text>
                                <View style={{ ...globalStyles.containerY, gap: 2 }}>
                                    <Text style={{ ...globalStyles.textoBold }}>{item.key}</Text>
                                    <Text style={{ ...globalStyles.texto }}>{item.value}</Text>
                                    <View style={{ ...globalStyles.containerY, gap: 2 }}>
                                        {item.components.map((component, index) => (
                                            <View key={index} style={{ ...globalStyles.containerX, gap: 2 }}>
                                                <Text style={{ ...globalStyles.textoBold, marginRight: 5 }}>•</Text>
                                                <View style={{ ...globalStyles.containerY, gap: 2 }}>
                                                    <Text style={{ ...globalStyles.textoBold }}>{component.key}</Text>
                                                    <Text style={{ ...globalStyles.texto }}>{component.value}</Text>
                                                </View>
                                            </View>
                                        ))}
                                    </View>
                                </View>
                            </View>
                        ))}
                    </View>
                </View>
                <View style={globalStyles.column}>
                    <View style={globalStyles.container}>
                        <Text style={globalStyles.texto}>{i18n[locale].description4}</Text>
                        <Image
                            style={{ ...globalStyles.image, width: "250px", height: "150px" }}
                            src={locale === "es"
                                ? "/assets/img/report/custom_tableDetail2.png"
                                : "/assets/img/report/custom_tableDetail2en.png"}
                        />
                        <View style={globalStyles.table_legend}>
                            <Text style={globalStyles.texto}>{i18n[locale].figure}</Text>
                        </View>
                    </View>
                </View>
            </View>
            <Footer externalData={{ clientName: client }} template={template}></Footer>
        </Page>
    )
}

export { IdentificationSection }
