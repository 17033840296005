import ServicesContext from "@context/ServicesContext"
import { I18nContext } from "I18nProvider"
import React, { useContext, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Box, Grid, Typography, useTheme } from "@mui/material"
import { Chrono } from "react-chrono"
import { format } from "date-fns"
import ActionButton from "@components/common/Button/ActionButton"
import UserLog from "@models/UserLog"
import { useTrack } from "@components/track/TrackContext"

const HistoricUserLogView: React.FC = () => {
    // I18n
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    // -------------------------------

    // Constants
    const { id } = useParams<{ id: string }>()
    const theme = useTheme()
    const navigate = useNavigate()
    const userLogService = useContext(ServicesContext).userLogService
    const [data, setData] = React.useState<UserLog[]>()
    // -------------------------------
    const { track, trackData } = useTrack()
    // Initial Effects
    useEffect(() => {
        track({ view: "HistoricUserLogView" })
        const fetchData = async () => {
            try {
                // const queryParameters: QueryParameters = {
                //     filters: [{
                //         field: "modified",
                //         operation: FilterOperation.UUIDEqual,
                //         value: id as string
                //     }]
                // }
                const response = await userLogService.getAll()
                setData(response.list)
                setChronoItems(convertDataToChronoItems(response.list))
            } catch (error) {
                console.error(error)
            }
        }
        fetchData()
    }, [])
    // -------------------------------

    // Chrono Items
    interface ChronoItem {
        title: string
        cardTitle: React.ReactNode
        url: string
        cardSubtitle: React.ReactNode
        cardDetailedText: string | string[] | React.ReactNode
        media: {
            type: string
            source: {
                url: string
            }
        }
    }

    const formatChanges = (changes: string): React.ReactNode => {
        if (!changes) {
            return <Typography ml={2}>No changes available</Typography>
        }

        try {
            const parsedChanges = JSON.parse(changes)
            if (typeof parsedChanges !== "object" || parsedChanges === null) {
                return <Typography ml={2}>Invalid changes format</Typography>
            }

            return (
                <Box>
                    {Object.entries(parsedChanges).map(([key, value]) => (
                        <Box key={key} display="flex" alignItems="flex-start" mb={1} ml={2}>
                            {/* Clave (key) */}
                            <Typography sx={{ mr: 1 }}>
                                {key}:
                            </Typography>
                            {/* Valores */}
                            {Array.isArray(value)
                                ? (
                                    <Box>
                                        {value.map((item, index) => (
                                            <Typography key={index} component="div">
                                                • {item}
                                            </Typography>
                                        ))}
                                    </Box>
                                )
                                : (typeof value === "string" || typeof value === "number")
                                    ? (
                                        <Typography component="div">
                                            {value || ""}
                                        </Typography>
                                    )
                                    : (value && typeof value === "object")
                                        ? (
                                            <Typography component="div">
                                                This value is an object and cannot be displayed directly.
                                            </Typography>
                                        )
                                        : (
                                            <Typography component="div">
                                                No valid value found
                                            </Typography>
                                        )}
                        </Box>
                    ))}
                </Box>
            )
        } catch (error) {
            console.error("Error parsing changes:", error)
            return <Typography color="error" ml={2}>Invalid changes format</Typography>
        }
    }

    const [chronoItems, setChronoItems] = React.useState<ChronoItem[]>([])
    const convertDataToChronoItems = (data: UserLog[]): ChronoItem[] => {
        return data.map((item) => ({
            title: format(new Date(item.timestamp), "dd/MM/yyyy HH:mm"),
            cardTitle: <Grid container sx={{ display: "flex", justifyContent: "space-between", borderRadius: "10px", p: 1, alignItems: "center" }}>Vulnerability has been modified<ActionButton onClick={() => item.action ? navigate(item.id) : null} text="Details"/></Grid>,
            cardSubtitle: <Grid sx={{ p: 1 }}>Previous values shown below:</Grid>,
            cardDetailedText: (
                <>
                    <Typography>Service: {item.service_name}</Typography>
                    <Typography>Action: {item.action}</Typography>
                    <Typography>Changes: {formatChanges(item.changes)}</Typography>
                    <Typography>Entity: {item.entity_id}</Typography>
                </>
            )
        })) as ChronoItem[]
    }

    return (
        <Grid item container flexDirection="column" rowGap="25px">
            <Grid item xs container justifyContent="space-between" alignItems="center">
                <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                        Logs
                </Typography>
                <Grid item><ActionButton onClick={() => { navigate(-1) }} text="Back" /></Grid>
            </Grid>
            <Grid item xs container flexDirection="column" spacing="20px">
                <Grid item container flexDirection="column" rowGap="35px">
                    <Grid item container>
                        {data && (
                            <Chrono items={chronoItems} mode="VERTICAL"
                                theme={{
                                    primary: "#999900", // Elementos principales
                                    secondary: "yellow", // Elementos secundarios
                                    cardBgColor: "#1a1a1a", // Color de fondo de las tarjetas
                                    cardTitleColor: "yellow", // Título de las tarjetas
                                    cardSubtitleColor: "white", // Subtítulo de las tarjetas
                                    cardDetailsColor: "white", // Detalles de las tarjetas
                                    textColor: "red", // Texto de las tarjetas
                                    titleColor: "yellow", // Títulos generales
                                    titleColorActive: "black", // Título activo
                                    toolbarBgColor: "#1a1a1a", // Barra de herramientas
                                    toolbarBtnBgColor: "#333333", // Botones de la barra de herramientas
                                    toolbarTextColor: "black" // Texto de la barra de herramientas
                                }}
                            />

                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default HistoricUserLogView
