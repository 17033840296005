/* eslint-disable multiline-ternary */
import React from "react"
import { Font, Page, Text, View } from "@react-pdf/renderer"
import Compliance, { Question } from "@models/Compliance"
import RenderContent from "@views/vulnerability/RenderContent"
import { Footer, Header } from "@components/report/GenericVulnerability/sections/HeaderFooter"
import styles from "../../Styles"
import VexDocumentStyles from "../../VexStyleSheet"

Font.register({ family: "Griff", src: "/assets/fonts/Griff-Regular.otf" })
Font.register({ family: "Griff", src: "/assets/fonts/Griff-Bold.otf", fontWeight: "bold" })
Font.register({ family: "Griff", src: "/assets/fonts/Griff-RegularItalic.otf", fontStyle: "italic" })

const ComplianceListDetail: React.FC<{ compliance: Compliance; questionData: Question[] }> = ({ compliance, questionData }) => {
    return (
        <Page
            size="A4"
            style={{ paddingBottom: 80 }}
        >
            <Header></Header>
            <View wrap={true}>
                {questionData.map((question, index) => (
                    <ComplianceHeaderDetail
                        key={index}
                        question={question}
                        compliance={compliance}
                        index={index}
                    />
                ))}
            </View>
            <Footer></Footer>
        </Page>
    )
}

const ComplianceHeaderDetail: React.FC<{ question: Question; compliance: Compliance; index: number }> = ({ question, compliance, index }) => {
    const questionId = `q${index + 1}` // ID de la pregunta (q1, q2, q3, etc.)
    const justificationId = String(index + 1) // ID de la justificación (1, 2, 3, etc.)
    const answer = compliance.registry.find((reg) => reg[questionId]) // Buscar respuesta en el registro

    const justification = compliance.justification ? compliance.justification[justificationId] : null
    const conclusion = compliance.conclusions ? compliance.conclusions[questionId] : null

    return (
        <View key={index} style={{ ...VexDocumentStyles.section }} wrap={false}>
            <Text
                id={`QUESTION-${String(index + 1).padStart(3, "0")}`}
                style={{
                    ...styles.textua,
                    fontFamily: "Bold",
                    fontSize: 12,
                    marginRight: 0,
                    paddingLeft: 5,
                    paddingBottom: 5
                }}
            >
                {index + 1}. {question.category}
            </Text>

            {/* Pregunta */}
            <View style={{ ...VexDocumentStyles.row }}>
                <View
                    style={{
                        ...VexDocumentStyles.colHeader,
                        borderBottomWidth: 0,
                        textAlign: "left",
                        padding: 0,
                        paddingTop: 5,
                        paddingBottom: 5,
                        paddingLeft: 5
                    }}
                >
                    <Text
                        style={{
                            ...VexDocumentStyles.info,
                            fontFamily: "Bold",
                            fontSize: 10,
                            marginBottom: 0
                        }}
                    >
                        Question
                    </Text>
                </View>
            </View>
            <View style={VexDocumentStyles.row}>
                <View
                    style={{
                        ...VexDocumentStyles.col,
                        borderBottomWidth: 0,
                        textAlign: "left",
                        padding: 0,
                        paddingTop: 5,
                        paddingBottom: 5,
                        paddingLeft: 5,
                        paddingRight: 5
                    }}
                >
                    <Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9 }}>
                        {question.text || "N/A"}
                    </Text>
                </View>
            </View>

            {/* Respuesta */}
            <View style={{ ...VexDocumentStyles.row }}>
                <View
                    style={{
                        ...VexDocumentStyles.colHeader,
                        borderBottomWidth: 0,
                        textAlign: "left",
                        padding: 0,
                        paddingTop: 5,
                        paddingBottom: 5,
                        paddingLeft: 5
                    }}
                >
                    <Text
                        style={{
                            ...VexDocumentStyles.info,
                            fontFamily: "Bold",
                            fontSize: 10,
                            marginBottom: 0
                        }}
                    >
                        Answer
                    </Text>
                </View>
            </View>
            <View style={VexDocumentStyles.row}>
                <View
                    style={{
                        ...VexDocumentStyles.col,
                        borderBottomWidth: 0,
                        textAlign: "left",
                        padding: 0,
                        paddingTop: 5,
                        paddingBottom: 5,
                        paddingLeft: 5,
                        paddingRight: 5
                    }}
                >
                    <Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9 }}>
                        {answer ? answer[questionId] : "N/A"}
                    </Text>
                </View>
            </View>

            {/* Justificación */}
            <View style={{ ...VexDocumentStyles.row }}>
                <View
                    style={{
                        ...VexDocumentStyles.colHeader,
                        borderBottomWidth: 0,
                        textAlign: "left",
                        padding: 0,
                        paddingTop: 5,
                        paddingBottom: 5,
                        paddingLeft: 5
                    }}
                >
                    <Text
                        style={{
                            ...VexDocumentStyles.info,
                            fontFamily: "Bold",
                            fontSize: 10,
                            marginBottom: 0
                        }}
                    >
                        Justification
                    </Text>
                </View>
            </View>
            <View style={VexDocumentStyles.row}>
                <View
                    style={{
                        ...VexDocumentStyles.col,
                        borderBottomWidth: 0,
                        textAlign: "left",
                        padding: 0,
                        paddingTop: 5,
                        paddingBottom: 5,
                        paddingLeft: 5,
                        paddingRight: 5
                    }}
                >
                    {justification ? (
                        <RenderContent description={justification} />
                    ) : (
                        <Text
                            style={{
                                ...VexDocumentStyles.info,
                                fontFamily: "Griff",
                                fontSize: 9,
                                marginBottom: 1,
                                flexWrap: "wrap"
                            }}
                        >
                            {/* Si la justificación es null o vacía, lo dejamos vacío */}
                        </Text>
                    )}
                </View>
            </View>

            {/* Conclusión */}
            <View style={{ ...VexDocumentStyles.row }}>
                <View
                    style={{
                        ...VexDocumentStyles.colHeader,
                        borderBottomWidth: 0,
                        textAlign: "left",
                        padding: 0,
                        paddingTop: 5,
                        paddingBottom: 5,
                        paddingLeft: 5
                    }}
                >
                    <Text
                        style={{
                            ...VexDocumentStyles.info,
                            fontFamily: "Bold",
                            fontSize: 10,
                            marginBottom: 0
                        }}
                    >
                        Conclusion
                    </Text>
                </View>
            </View>
            <View style={VexDocumentStyles.row}>
                <View
                    style={{
                        ...VexDocumentStyles.col,
                        textAlign: "left",
                        padding: 0,
                        paddingTop: 5,
                        paddingBottom: 5,
                        paddingLeft: 5,
                        paddingRight: 5
                    }}
                >
                    {conclusion ? (
                        <RenderContent description={conclusion} />
                    ) : (
                        <Text
                            style={{
                                ...VexDocumentStyles.info,
                                fontFamily: "Griff",
                                fontSize: 9,
                                marginBottom: 1,
                                flexWrap: "wrap"
                            }}
                        >
                            {/* Si la conclusión es null o vacía, lo dejamos vacío */}
                        </Text>
                    )}
                </View>
            </View>
        </View>
    )
}

export { ComplianceListDetail }
