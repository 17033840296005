import React, { useContext } from "react"
import { Route, Routes } from "react-router-dom"
import { AbilityContext } from "@components/permissions/Can"
// import AchillesForm from "./AchillesForm"
import AchillesList from "./AchillesList"
import AchillesDetail from "./AchillesDetail"
import AchillesForm from "./AchillesForm"
// import AchillesDetail from "./AchillesDetail"

const entity = "Achilles"
const Achilles: React.FC = () => {
    const ability = useContext(AbilityContext)
    const routes = []

    if (ability.can("read", entity)) {
        routes.push(...[
            {
                route: "",
                component: <AchillesList/>
            }
        ])
    }

    if (ability.can("update", entity)) {
        routes.push(...[
            {
                route: ":id",
                component: <AchillesDetail/>
            },
            {
                route: ":id/component/*"
            }
        ])
    }

    if (ability.can("create", entity)) {
        routes.push(...[
            {
                route: "/add",
                component: <AchillesForm/>
            }

        ])
    }

    return (
        <Routes>
            {
                routes.map((item, idx) => (
                    <Route key={idx} path={item.route} element={item.component} />
                ))
            }
        </Routes>
    )
}

export { Achilles }
export default Achilles
