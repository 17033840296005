import { Box, CircularProgress, Grid, IconButton, Tab, Tabs, Tooltip, Typography, useTheme } from "@mui/material"
import React, { useContext, useEffect, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { ServicesContext } from "@context/index"
import { Assessment, AssessmentStatus, AssessmentType, Product, ProductType, StatsVulnerabilities } from "@models/index"
import { VulnReportDownloader, VulnReportUploader } from "@components/report/VulnReportUpDownloader"
import { convertModelDefToGridColDef, CustomAction } from "@components/common/tables"
import { GridColDef, GridRowParams } from "@mui/x-data-grid"
import { AbilityContext, Can } from "@components/permissions/index"
import { BiSolidComponent } from "react-icons/bi"
import CalculateIcon from "@mui/icons-material/Calculate"
import { FilterOperation, FilterOption, QueryParameters } from "@utils/queryParams"
import { TbAnalyze } from "react-icons/tb"
import Hierarchy from "@models/Hierarchy"
import { MAX_DEEP_LEVEL, PATH_SEPARATOR } from "@views/hierarchy/hierarchyUtils"
import { I18nContext } from "I18nProvider"
import TrackList from "@views/track/trackList"
import { AssessmentTableDefinition } from "@components/assessment"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import IssueList from "@views/issue/IssueList"
import { useTrack } from "@components/track/TrackContext"
import Details from "./Components/Details"
import StatusComponent from "./Components/Status"
import AssessmentComponent from "./Components/AssessmentsComponent"
import ComponentsLicense from "./Components/ComponentsLicense"

const initValue = {
    id: "",
    name: "",
    type: ProductType.Application,
    description: "",
    version: "",
    hierarchy: "",
    inheritance: false,
    supressed: false,
    client: { id: "00000000-0000-0000-0000-000000000000", name: "" },
    created_at: ""
}
const initLicenseData = {
    licenses: {},
    total: 0
}
export interface LicenseData {
    licenses: Record<string, number>;
    total: number;
  }
export interface TimeLineValues {
    x: number;
    name: string;
    description: string;
    version: string;
    id: string;
}

const ProductDetail: React.FC = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const theme = useTheme()
    const productService = useContext(ServicesContext).productService
    const vulnerabilityService = useContext(ServicesContext).vulnerabilityService
    const hierarchyService = useContext(ServicesContext).hierarchyService
    const statsService = useContext(ServicesContext).statsService
    const componenteService = useContext(ServicesContext).componentService
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [error, setError] = useState<Error | null>(null)
    const { id } = useParams<{ id: string }>()
    const [data, setData] = useState<Product>(initValue)
    const [formData, setFormData] = useState<Product>(initValue)
    const [dataTime, setDataTime] = useState<TimeLineValues[]>([])
    const [cols, setCols] = useState<GridColDef[]>([])
    const [vulnStats, setVulnStats] = useState<StatsVulnerabilities | undefined>(undefined)
    const [filter, setFilter] = useState({})
    const [selectedHierarchies, setSelectedHierarchies] = useState<(Hierarchy | null)[]>(Array.from({ length: MAX_DEEP_LEVEL }, () => null))
    const ability = useContext(AbilityContext)
    const [successfulMessage, setSuccessfulMessage] = useState<string>()
    const [tabIndex, setTabIndex] = useState(0)
    const [licenseComponents, setLicenseComponents] = useState<LicenseData>(initLicenseData)
    const { track, trackData } = useTrack()
    useEffect(() => {
        track({ view: "ProductDetail" })
    }, [])
    // Fetch product data from API
    // useEffects

    useEffect(() => {
        const fecthData = async () => {
            try {
                const newCol = convertModelDefToGridColDef(AssessmentTableDefinition, ability)
                setCols(newCol)
                const val = await productService.get(id as string)
                setData(val)
                setFormData(val)

                if (val.hierarchy && val.hierarchy !== "") {
                    const pathIds = val.hierarchy.split(PATH_SEPARATOR)
                    const fetchedHierarchies = await Promise.all(pathIds.map(id => hierarchyService.get(id)))
                    setSelectedHierarchies(fetchedHierarchies.map(h => h || null))
                }

                const filterssss: QueryParameters = { filters: [{ field: "product_id", operation: FilterOperation.EnumEqual, value: id as string }] }
                const vulnsStats = await statsService.getStatsVulnerabilitiesWithParams(filterssss)

                const componentes = await componenteService.getLicenseByProductID(id as string)
                setLicenseComponents(componentes)
                setVulnStats(vulnsStats)
                const initialFilter = {
                    sortField: "name",
                    sortOrder: "asc"
                }
                setFilter(initialFilter)
            } catch (e: any) {
                setError({ message: e.error } as Error)
                setSnackbarOpen(true)
            }
        }
        fecthData()
    }, [id])
    // Fetch product data from API - END
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = formData.name
                const filter: QueryParameters = { filters: [{ field: "name", operation: FilterOperation.EnumEqual, value: data as string }] }
                const allVal = await productService.getAllExt(filter)
                const timeLineValues: TimeLineValues[] = allVal.list.map(item => {
                    const date = new Date(item.created_at)
                    const year = date.getUTCFullYear()
                    const month = date.getUTCMonth()
                    const day = date.getUTCDate()

                    return {
                        x: Date.UTC(year, month, day), // Convertir la fecha a un array [year, month, day]
                        name: item.name,
                        description: item.description,
                        version: item.version,
                        id: item.id
                    }
                })
                setDataTime(timeLineValues)
            } catch (e: any) {
                setError({ message: e.error } as Error)
                setSnackbarOpen(true)
            }
        }
        fetchData()
    }, [formData])

    const navigate = useNavigate()
    const handleOpenComponents = async (id: string) => {
        const filters: QueryParameters = { sortField: "name", sortMode: "desc" }

        navigate(`../../assessment/${id}/component`)
    }
    // API Call save product

    // API Call save product - END

    const RequestAnalyze = async (id: string) => {
        try {
            const response = await vulnerabilityService.analyzeByAssessment(id)
            setSuccessfulMessage(response.message)
            setSnackbarOpen(true)
            setError(null)
        } catch (e: any) {
            console.error(e)
            setError({ message: e.error } as Error)
            setSnackbarOpen(true)
        }
    }

    const customActions = (params: GridRowParams<Assessment>) => {
        const actions: CustomAction[] = [
            {
                icon: <Box key="analyze" sx={{ position: "relative", display: "inline-flex", height: "100%", width: "100%", mr: "15px", top: "0px" }}>
                    <CircularProgress variant="determinate" value={params.row.analysis_status === undefined ? 0 : params.row.analysis_status! * 16.6666} size={32} style={{
                        position: "absolute" as "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)"
                    }} />
                    <Box
                        sx={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: "absolute",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                    >
                        <Tooltip title="Reanalyze"><IconButton key="analyze" onClick={() => { RequestAnalyze(params.row.id) }} sx={{ width: 33, height: 33 }} ><TbAnalyze size={20} /></IconButton ></Tooltip>
                    </Box>
                </Box>,
                visualize: true

            },
            { icon: <Can key="create" I="create" an="File" ><VulnReportUploader setError={setError} simplified={false} key="vexUploader" assessment={params.row} /></Can>, visualize: true },
            // <Can key="read" I="read" an="Vulnerability" ><VulnReportDownloader simplified={true} key="vexDownloader" assessment={params.row} /></Can>,
            { icon: <Can key="read" I="read" an="Vulnerability" ><VulnReportDownloader setError={setError} simplified={false} key="vexDownloader" assessment={params.row} /></Can>, visualize: true },

            {
                icon: <Can key="components" I="read" an="Vulnerability">
                    <Tooltip title="Components">
                        <IconButton size="small" component="label" onClick={() => handleOpenComponents(params.id as string)}>
                            <BiSolidComponent />
                        </IconButton>
                    </Tooltip>
                </Can>,
                visualize: true
            }
        ]
        if (params.row && params.row.status === AssessmentStatus.Ended && params.row.type === AssessmentType.SCA) {
            actions.push(
                {
                    icon: <Can key="score" I="create" an="File" >
                        <Tooltip title="SBOM Metrics">
                            <IconButton size="small" component="label" onClick={() => navigate(`../../assessment/${params.row.id}/sbomQuality`)}>
                                <CalculateIcon />
                            </IconButton>
                        </Tooltip>
                    </Can>,
                    visualize: true
                }
            )
        }
        return actions
    }

    // Hierarchies

    // --------------------------------------------

    // --------------------------------------------
    const externalFilters = useMemo<FilterOption[]>(() => [
        { field: "registry", operation: FilterOperation.JSONBContains, value: `[{"ProductID": "${id}"}]` }
    ], [id])
    // --------------------------------------------
    const handleTabChange = (event: any, newIndex: React.SetStateAction<number>) => {
        setTabIndex(newIndex)
    }
    return (<Grid item xs container spacing="20px">
        <CustomSnackbar
            open={snackbarOpen}
            onClose={() => setSnackbarOpen(false)}
            message={error?.message || successfulMessage || "An error occurred."}
            severity={successfulMessage ? "success" : "error"}
        />
        <Grid container sx={{ justifyContent: "space-between", alignItems: "center", spacing: "20px", paddingLeft: "20px", marginBottom: "0px", paddingBottom: "0px" }}>
            <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                {context.t.translate("product")}
            </Typography>
        </Grid>

        <Details id={id}/>
        <Grid item xs={12} marginTop={3}>
            <Tabs value={tabIndex} onChange={handleTabChange} centered>
                <Tab label={context.t.translate("status")} />
                {/* <Tab label={context.t.translate("hierarchy")} /> */}
                <Tab label={context.t.translate("dash_assess")} />
                <Tab label="Policies Triggered" />
                <Tab label="Issues" />
                {licenseComponents.total > 0 && <Tab label="Components Licenses" />}
            </Tabs>
        </Grid>
        <Grid item xs={12} padding="30px">
            {tabIndex === 0 && <Grid><StatusComponent id={id} vulnStats={vulnStats} dataTime={dataTime} /></Grid>}
            {/* {tabIndex === 1 && <Grid><HierarchyCard hierarchies={selectedHierarchies} products={[formData]}></HierarchyCard></Grid>} */}
            {tabIndex === 1 && <AssessmentComponent cols={cols} id={id} customActions={customActions} />}
            {tabIndex === 2 && <TrackList invoke="policy" externalFilters={externalFilters}/>}
            {tabIndex === 3 && <IssueList row={ { productName: formData.name, version: formData.version }} />}
            {tabIndex === 4 && licenseComponents.total > 0 && <ComponentsLicense licenses={licenseComponents} />}
        </Grid>

    </Grid>
    )
}

export { ProductDetail }
export default ProductDetail
