import { createTheme } from "@mui/material/styles"
import "App.css"
import { colorScheme } from "theme/colorSchema"
function getCSSVariableValue (variableName: string): string {
    const root = document.documentElement
    const computedStyle = getComputedStyle(root)
    return computedStyle.getPropertyValue(variableName).trim()
}
export const darkTheme = createTheme({
    palette: {
        mode: "dark",
        primary: {
            main: colorScheme.dark.primary,
            contrastText: colorScheme.dark.onPrimary,
            light: colorScheme.dark.primaryFixedDim,
            dark: colorScheme.dark.onPrimaryFixedVariant
        },
        secondary: {
            main: colorScheme.dark.secondary,
            contrastText: "#000",
            light: colorScheme.dark.secondaryFixedDim,
            dark: colorScheme.dark.onSecondaryFixedVariant
        },
        error: {
            main: colorScheme.dark.error,
            contrastText: colorScheme.dark.onError,
            light: colorScheme.dark.errorContainer,
            dark: colorScheme.dark.onErrorContainer
        },
        background: {
            default: colorScheme.dark.background,
            paper: colorScheme.dark.surface
        },
        text: {
            primary: "#fff",
            secondary: colorScheme.dark.onSurfaceVariant
        }
    },
    components: {
        MuiTextField: {
            defaultProps: {
                // The props to change the default for.
                fullWidth: true
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: "8px"
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    ":not(.Mui-selected)": {
                        color: "#E8E7ED",
                        borderBottom: "2px solid #E8E7ED"
                    }
                }
            }
        },
        MuiStepIcon: {
            styleOverrides: {
                root: {
                    color: "black", // Color del fondo del ícono en pasos no activos
                    "& .MuiStepIcon-text": {
                        fill: "black" // Color negro solo para el número en el ícono
                    }
                }
            }
        },
        MuiStepLabel: {
            styleOverrides: {
                root: {
                    color: "#E8E7ED" // Color del fondo del ícono en pasos no activos

                }
            }
        }
    }
})

export const lightTheme = createTheme({
    palette: {
        mode: "light",
        primary: {
            main: colorScheme.light.primary,
            contrastText: colorScheme.light.onPrimary,
            light: colorScheme.light.primaryFixedDim,
            dark: colorScheme.light.onPrimaryFixedVariant
        },
        secondary: {
            main: colorScheme.light.secondary,
            contrastText: colorScheme.light.onSecondary,
            light: colorScheme.light.secondaryFixedDim,
            dark: colorScheme.light.onSecondaryFixedVariant
        },
        error: {
            main: colorScheme.light.error,
            contrastText: colorScheme.light.onError,
            light: colorScheme.light.errorContainer,
            dark: colorScheme.light.onErrorContainer
        },
        background: {
            default: colorScheme.light.background,
            paper: colorScheme.light.surface
        },
        text: {
            primary: colorScheme.light.onBackground,
            secondary: colorScheme.light.onSurfaceVariant
        }
    },
    components: {
        MuiTextField: {
            defaultProps: {
                // The props to change the default for.
                fullWidth: true
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    background: "#fff",
                    border: "2px solid #6D6C6F",
                    borderRadius: "10px"
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    ":not(.Mui-selected)": {
                        color: "#E8E7ED",
                        borderBottom: "2px solid #E8E7ED"
                    }
                }
            }
        }
    }
})
/* export const darkTheme: any = {
    typography: {
        fontFamily: "Roboto"
    },
    palette: {
        mode: "dark",
        primary: {
            light: "#184d60", // 184d60    4a6952
            main: "#FAE733", // 2099C6    25ee32
            dark: "#104c63", // 104c63   2F6140,
            contrastText: "#E8E7ED"
        },
        secondary: {
            main: "#E8E7ED",
            light: "#CFF2F2",
            dark: "6D6C6F",
            contrastText: "#000"
        }
    },
    components: {
        MuiTextField: {
            defaultProps: {
                // The props to change the default for.
                fullWidth: true
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    background: "#000",
                    border: "2px solid #6D6C6F",
                    borderRadius: "10px"
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    ":not(.Mui-selected)": {
                        color: "#E8E7ED",
                        borderBottom: "2px solid #E8E7ED"
                    }
                }
            }
        },
        MuiStepIcon: {
            styleOverrides: {
                root: {
                    color: "black", // Color del fondo del ícono en pasos no activos
                    "& .MuiStepIcon-text": {
                        fill: "black" // Color negro solo para el número en el ícono
                    }
                }
            }
        },
        MuiStepLabel: {
            styleOverrides: {
                root: {
                    color: "#E8E7ED" // Color del fondo del ícono en pasos no activos

                }
            }
        }
    }
} as const

export const lightTheme: any = {
    typography: {
        fontFamily: "Roboto"
    },
    palette: {
        mode: "light",
        primary: {
            light: "#EFC3CA", // 184d60    4a6952
            main: "#FAE733", // 2099C6    25ee32
            dark: "#104c63" // 104c63   2F6140
        },
        secondary: {
            main: "#7d788d",
            light: "#931F32",
            dark: "6D6C6F",
            contrastText: "#fff"
        }
    },
    components: {
        MuiTextField: {
            defaultProps: {
                // The props to change the default for.
                fullWidth: true
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    background: "#fff",
                    border: "2px solid #6D6C6F",
                    borderRadius: "10px"
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    ":not(.Mui-selected)": {
                        color: "#E8E7ED",
                        borderBottom: "2px solid #E8E7ED"
                    }
                }
            }
        }
    }
} as const */

export const createOrbikTheme = () => createTheme({ ...darkTheme })
export const createLightOrbikTheme = () => createTheme({ ...lightTheme })
