import React, { useContext } from "react"
import { SearchInput } from "@components/common/forms/index"
import { ServicesContext } from "@context/index"
import { FilterOperation } from "@utils/index"
import { I18nContext } from "I18nProvider"
import { AbilityContext } from "@components/permissions"
import Component from "@models/Component"
import { ProductType } from "@models/Product"

interface ComponentSelectorProps{
    value: string
    disabled?: boolean
    onSelect: (id: string|undefined)=>void
}
const ComponentSelector: React.FC<ComponentSelectorProps> = ({ value, disabled, onSelect }) => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const ability = useContext(AbilityContext)
    const componentService = useContext(ServicesContext).componentService

    return (
        <SearchInput<Component>
            margin="normal" label="Affected component"
            variant="filled"
            value={value}
            disabled={disabled}
            requesterById={async v => {
                if (v !== undefined && v !== "00000000-0000-0000-0000-000000000000") {
                    const component = await componentService.get(v)
                    return [component]
                }
                const com: Component = {
                    id: "",
                    name: "",
                    bom_ref: "",
                    type: ProductType.Library,
                    version: "",
                    license: "",
                    supressed: false,
                    outdated: false,
                    cpe: "",
                    assessment_id: "",
                    client_id: ""
                }
                return [com]
            } }
            requester={async v => {
                const client = ability.can("*", "Component") && v !== "" ? await componentService.getAll({ filters: [{ field: "name", operation: FilterOperation.StringContains, value: v }] }) : await componentService.getAll()
                return client.list
            }} getterID={elem => elem?.id} getterTitle={elem => `${elem.name}`} onClickElem={(v) => onSelect(v?.id)}></SearchInput>
    )
}

export { ComponentSelector }
export default ComponentSelector
