import { KEV } from "@models/KEV"
import { MediaTypes } from "@services/HttpService"
import Service from "@services/abstract/BaseService"

class KEVService extends Service<KEV> {
    public constructor () {
        super(process.env.REACT_APP_API, "api/v1/kev", MediaTypes.JSON)
    }

    public getKEVById = (id: string) => {
        return this.http.get(`/${this.path}/vulnerability/` + id) as any
    }
}

export { KEVService }
export default KEVService
