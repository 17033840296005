import React from "react"
import { Image, Page, Text, View } from "@react-pdf/renderer"
import moment from "moment"
import Assessment from "@models/Assessment"
import styles from "../Styles"

const SimplifiedFrontPage: React.FC<{assessment: Assessment}> = ({ assessment }) => (
    <Page size="A4">
        <View fixed>
            <Image style={styles.image} src="/assets/img/report/portada.jpg" />
            <View style={styles.tablea}>
                <View style={{ ...styles.tableRowa, borderWidth: 0 }}>
                    <View style={{ ...styles.tableCola2, borderRightWidth: 0 }}>
                        <Text style={{ ...styles.titulopage1, fontFamily: "Bold" }}>{assessment.client.name}</Text>
                        <Text style={{ ...styles.titulo2page1, fontFamily: "Griff" }}>Ciberseguridad</Text>
                        <Text style={{ ...styles.titulo2page1, fontFamily: "Griff" }}>Informe de Vulnerabilidades de Producto</Text>
                    </View>
                    <View style={{ ...styles.tableCola2 }}>
                        {/* <Text style={styles.titulo2page1}>Jefe/a del proyecto:                                         Odei Olalde</Text>
                    <Text style={styles.titulo2page1}>Responsable de Informe:                                Xabier García</Text>
                    <Text style={styles.titulo2page1}>Ref. y nº de la oferta:                                        00XX.000Y</Text>
                    <Text style={styles.titulo2page1}>Rev.:                                                                1.0</Text> */}
                        {/* <Text style={styles.titulo2page1}>Mes y año:                                                      Enero 2024</Text> */}
                        <View style={{ ...styles.tableRowa, borderWidth: 0 }}>
                            <View style={{ ...styles.column, marginLeft: 0, marginTop: 0 }}>
                                <Text style={{ ...styles.titulo2page1, marginLeft: 0, marginTop: -25, paddingBottom: 0, fontFamily: "Griff" }}>Mes y año:</Text>
                            </View>
                            <View style={{ ...styles.column, marginLeft: -45, marginTop: -25 }}>
                                <Text style={{ ...styles.titulo2page1, paddingBottom: 2, fontFamily: "Griff" }}>{moment(assessment.endDate).format("MMMM YYYY")}</Text>
                            </View>
                        </View>
                    </View>
                </View>
            </View>
            <FooterFrontPage></FooterFrontPage>
        </View>
    </Page>
)

const FooterFrontPage: React.FC<{}> = () => (
    <View>
        <View>
            <Text style={{ paddingLeft: 32, fontFamily: "Griff", paddingRight: 260, paddingBottom: 32, paddingTop: -80, fontSize: 5.5, position: "absolute", textAlign: "justify" }}>
            The intellectual and industrial property rights over this document are the exclusive property of ORBIK Cybersecurity, S.L.
            Likewise, this report and, where applicable, any document annexed to it, contains confidential information exclusively addressed to its recipient.
                <Text style={{ fontFamily: "Bold", color: "#FFC000", backgroundColor: "black" }}>TLP-AMBER</Text> Its disclosure, copy or distribution to third parties is prohibited without the prior written authorization of ORBIK Cybersecurity.
            If you have received this report in error, proceed to delete it and immediately notify ORBIK Cybersecurity, S.L.
            </Text>
        </View>
    </View>
)

export { SimplifiedFrontPage }
