import React from "react"
import { KEV } from "@models/KEV"
import { ModelDefinition } from "@models/utils"
import { Button, Chip, Typography, useTheme } from "@mui/material"
import { useNavigate } from "react-router-dom"

interface KEVNavigateProps {
    value: string;
    id: string;
}

const KEVcveIdNavigate: React.FC<KEVNavigateProps> = ({ value, id }) => {
    const theme = useTheme()
    const navigate = useNavigate()
    const handleClick = () => {
        navigate(`./${id}`)
    }

    return (
        <Button
            onClick={handleClick}
            style={{
                padding: 0,
                minWidth: 0,
                background: "none",
                border: "none",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center"
            }}
        >
            <Typography
                style={{
                    fontWeight: "bolder",
                    fontFamily: "Griff",
                    fontSize: "14px",
                    color: theme.palette.primary.main
                }}
            >
                {value}
            </Typography>
        </Button>
    )
}

const formatDate = (dateString: string) => {
    if (!dateString) return "N/A"
    const date = new Date(dateString)
    if (isNaN(date.getTime())) {
        return "Invalid Date"
    }
    return new Intl.DateTimeFormat("en-GB").format(date)
}

interface KEVDateCellProps {
    value: string
}

const KEVDateCell: React.FC<KEVDateCellProps> = ({ value }) => {
    return <Typography>{formatDate(value)}</Typography>
}

interface KEVChipCellProps {
    value: string
}

// Componente para renderizar el Chip
const KEVChipCell: React.FC<KEVChipCellProps> = ({ value }) => {
    const theme = useTheme()
    const backgroundColor = value === "Known" ? "#A3D9A5" : "#D3D3D3" // Colores suaves
    const textColor = theme.palette.getContrastText(backgroundColor)

    return (
        <Chip
            label={value}
            style={{
                backgroundColor,
                color: textColor,
                fontWeight: "bold",
                fontSize: "12px"
            }}
        />
    )
}

export const KEVTableDefinition: ModelDefinition<KEV> = {
    properties: [
        { name: "id", label: "Id", type: "string", flex: 0.1, isId: true },
        { name: "cve_id", label: "CVE ID", type: "string", flex: 0.2, render: KEVcveIdNavigate },
        { name: "vendor_project", label: "Vendor / Project", type: "string", flex: 0.2 },
        { name: "product", label: "Product", type: "string", flex: 0.2 },
        { name: "vulnerability_name", label: "Vulnerability Name", type: "string", flex: 0.3 },
        { name: "date_added", label: "Date Added", type: "string", flex: 0.2, render: KEVDateCell },
        { name: "due_date", label: "Due Date", type: "string", flex: 0.2, render: KEVDateCell },
        {
            name: "known_ransomware_campaign_use",
            label: "Known Ransomware Campaign Use",
            type: "string",
            flex: 0.2,
            render: KEVChipCell // Renderizando el Chip
        }
    ]
}
