import React from "react"
import { Document, Font } from "@react-pdf/renderer"
import { Compliance } from "@models/Compliance"
import { BackPage } from "./Compliance/sections/99_BackPage"
import { FrontPageCompliance, IndexCompliance, IntroCompliance, VulnsList } from "./Compliance/sections"
import { FrontPageComplianceFDA, IndexComplianceFDA, IntroComplianceFDA, VulnsListFDA } from "./Compliance/fda"
import { ConclusionsFDA } from "./Compliance/fda/3_ Conclusions"
import { Conclusions } from "./Compliance/sections/3_ Conclusions"
import { Justifications } from "./Compliance/sections/2_2_Justifications"
Font.register({ family: "Griff", src: "/assets/fonts/Griff-Regular.otf" })
Font.register({ family: "Bold", src: "/assets/fonts/Griff-Bold.otf" })

const hyphenationCallback = (word: string) => {
    // Devuelve las sílabas de la palabra en un array
    // Este es solo un ejemplo simple y puede necesitar mejoras
    return word.split("-")
}

// Registra la función de separación de sílabas
Font.registerHyphenationCallback(hyphenationCallback)

interface VEXDocumentProps {
    compliance: Compliance;
    type: "CRA" | "FDA"; // Añadimos el prop type para distinguir entre los tipos de informe
}

const VulnReportDocumentCompliance: React.FC<VEXDocumentProps> = ({ compliance, type }) => {
    return (
        <Document pageLayout="twoColumnRight">
            {/* Usamos if-else para condicionar las secciones basándonos en el valor de `type` */}
            {type === "CRA" && (
                <>
                    <FrontPageCompliance />
                    <IndexCompliance />
                    <IntroCompliance />
                    <VulnsList compliance={compliance} />
                    <Justifications externalData={compliance}/>
                    <Conclusions compliance={compliance} />
                </>
            )}

            {type === "FDA" && (
                <>
                    <FrontPageComplianceFDA />
                    <IndexComplianceFDA />
                    <IntroComplianceFDA />
                    <VulnsListFDA compliance={compliance} />
                    <ConclusionsFDA compliance={compliance} />
                </>
            )}

            <BackPage />
        </Document>
    )
}

export { VulnReportDocumentCompliance }
export default VulnReportDocumentCompliance
