import React, { useContext, useEffect, useState } from "react"
import {
    Box,
    Button,
    CircularProgress,
    Typography,
    useTheme
} from "@mui/material"
import { useTrack } from "@components/track/TrackContext"
import ServicesContext from "@context/ServicesContext"
import ActionButton from "@components/common/Button/ActionButton"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import { I18nContext } from "I18nProvider"

interface Props {
    assessmentID?: string
    onUploadSuccess?: () => void // Callback para cerrar el modal
}

const AchillesForm: React.FC<Props> = ({ assessmentID, onUploadSuccess }) => {
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([])
    const [mockedData, setMockedData] = useState<Record<string, any[]>>({})
    const achillesService = useContext(ServicesContext).achillesService
    const fileService = useContext(ServicesContext).fileService
    const theme = useTheme()
    const { track } = useTrack()
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const context = useContext(I18nContext)
    const [isUploading, setIsUploading] = useState(false) // Estado para controlar la carga

    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }

    useEffect(() => {
        track({ view: "AchillesForm" })
    }, [track])

    const mockPostToApi = (file: File) => {
        return new Promise<any[]>((resolve) => {
            setTimeout(() => {
                resolve(
                    Array.from({ length: 5 }, (_, i) => ({
                        id: `${file.name}-item-${i + 1}`,
                        name: `Item ${i + 1} for ${file.name}`,
                        selected: true
                    }))
                )
            }, 1000)
        })
    }

    const handleFileSelection = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files
        if (files) {
            const newFiles = Array.from(files)
            const combinedFiles = [...uploadedFiles, ...newFiles].slice(0, 3)
            setUploadedFiles(combinedFiles)

            const newFileData = await Promise.all(
                newFiles
                    .filter((file) => !mockedData[file.name])
                    .map(async (file) => {
                        const items = await mockPostToApi(file)
                        return { fileName: file.name, items }
                    })
            )

            const newMockedData = newFileData.reduce((acc, { fileName, items }) => {
                acc[fileName] = items
                return acc
            }, {} as Record<string, any[]>)

            setMockedData((prev) => ({
                ...prev,
                ...newMockedData
            }))
        }
    }

    const handleFileUpload = async () => {
        if (assessmentID) {
            setIsUploading(true) // Activar el estado de carga
            try {
                await fileService.uploadFiles(assessmentID, "achilles", uploadedFiles)
                if (onUploadSuccess) {
                    onUploadSuccess() // Cerrar el modal
                }
            } catch (e: any) {
                setSnackbarOpen(true) // Mostrar mensaje de error
            } finally {
                setIsUploading(false) // Desactivar el estado de carga
            }
        } else {
            console.error("UPSS something went wrong")
        }
    }

    return (
        <Box
            sx={{
                width: "fit-content",
                margin: "0 auto",
                mt: 2,
                p: 4,
                boxShadow: theme.shadows[1],
                borderRadius: 2,
                backgroundColor: theme.palette.background.paper
            }}
        >
            <CustomSnackbar
                open={snackbarOpen}
                onClose={() => setSnackbarOpen(false)}
                message={context.t.translate("an_error")}
            />
            {/* File Upload Section */}
            <Typography variant="h5" align="center" sx={{ mb: 3 }}>
                Upload Achilles Files
            </Typography>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 2
                }}
            >
                <Button
                    variant="contained"
                    sx={{
                        color: theme.palette.secondary.contrastText,
                        textTransform: "none"
                    }}
                    component="label"
                >
                    Select Files (Max 3)
                    <input
                        type="file"
                        accept=".xml"
                        multiple
                        hidden
                        onChange={handleFileSelection}
                    />
                </Button>
                {uploadedFiles.length === 0 && (
                    <Typography
                        align="center"
                        color="text.secondary"
                        sx={{ mt: 4 }}
                    >
                    No files selected yet.
                    </Typography>
                )}
                <Box sx={{ width: "100%", textAlign: "left" }}>
                    {uploadedFiles.length > 0 &&
                        uploadedFiles.map((file, index) => (
                            <Typography
                                key={index}
                                sx={{ fontSize: 14, color: theme.palette.text.secondary }}
                            >
                                {file.name}
                            </Typography>
                        ))}
                </Box>

                {/* Upload Action */}
                <ActionButton
                    text="Upload Files"
                    onClick={handleFileUpload}
                    disabled={uploadedFiles.length === 0 || isUploading} // Deshabilitar el botón mientras se suben los archivos
                />
                {isUploading && (
                    <CircularProgress sx={{ mt: 2 }} /> // Spinner de carga
                )}
            </Box>

        </Box>
    )
}

export default AchillesForm
