import { ModelRelation } from "@models/utils"
import { CVSS3Severity } from "./Vulnerability"
type Assessment = {
 id: string;
 type: AssessmentType;
 status: AssessmentStatus;
 contractDate: string| null;
 startDate?: string | null;
 endDate?: string | null;
 product: ProductRelation;
 client: ModelRelation;
 template_id: string;
 analysis_status?: number | null;
}

type AssessmentExt = Assessment & {
    // VulnerabilityNumber: number;
}

type ProductRelation = {
    id: string,
    name?: string,
    version?: string
}

type TemplateRelation = {
    id: string,
    name?: string
}

enum AssessmentType {
 CRT = "crt",
 LogicalPentest = "logical_pentest",
 FunctionalTest = "functional_test",
 SCA = "sca",
 PortScan = "port_scan",
 NetworkScan = "network_scan",
 WebScan = "web_scan",
}

enum AssessmentStatus {
 Contracted = "contracted",
 Planned = "planned",
 Started = "started",
 Ended = "ended",
 Reported = "reported",
}
type TotalAssessment = Record<CVSS3Severity, number>;
export { AssessmentType, AssessmentStatus }
export type { Assessment, AssessmentExt, TotalAssessment }
export default Assessment
