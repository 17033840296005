import { Grid, Typography, useTheme } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import { StyledBox } from "@components/common/Box/BoxContainer"
import ServicesContext from "@context/ServicesContext"
import { I18nContext } from "I18nProvider"
import { useContext, useEffect, useState } from "react"
import SecurityAdvisory from "@models/SecurityAdvisory"
import { Link } from "@mui/icons-material"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"

type SecurityAdvisoriesTableProps = {
    id: string | undefined
}

const SecurityAdvisoriesTable = ({ id }: SecurityAdvisoriesTableProps) => {
    const hardwareComponentsService = useContext(ServicesContext).hardwareComponentService
    const [rows, setRows] = useState<SecurityAdvisory[]>([])
    const [error, setError] = useState<Error | null>(null)
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const theme = useTheme()
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { list } = await hardwareComponentsService.getSecurityAdvisories(id as string)
                setRows(list)
            } catch (e:any) {
                setError(new Error(e.error || "Unknown error occurred"))
                setSnackbarOpen(true)
            }
        }

        fetchData()
    }, [])

    const columns = [
        { field: "name", headerName: context.t.translate("name"), flex: 3 },
        { field: "version", headerName: context.t.translate("affectedVersions"), flex: 2 },
        { field: "details", headerName: context.t.translate("details"), flex: 2 },
        {
            field: "url",
            headerName: context.t.translate("remediation"),
            flex: 1,
            renderCell: (params: any) => {
                return params.value
                    ? (
                        <a
                            href={params.value}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ display: "flex", alignItems: "center", textDecoration: "none" }}
                        >
                            <Link />
                        </a>
                    )
                    : null
            }
        },
        {
            field: "title",
            headerName: context.t.translate("sourceDocument"),
            flex: 4,
            renderCell: (params: any) => {
                const row = params.row as SecurityAdvisory
                return (
                    <a
                        href={row.html}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ display: "flex", alignItems: "center", textDecoration: "none" }}
                    >
                        {row.title}
                    </a>
                )
            }
        }
    ]

    const handleSnackbarClose = () => {
        setSnackbarOpen(false)
    }

    return (
        <Grid item xs container flexDirection="column" rowGap="25px" aria-description="Security Advisories Section">
            <Grid item xs sx={{ position: "sticky", top: "0px", backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
                <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                    {context.t.translate("securityAdvisories")}
                </Typography>
            </Grid>
            <Grid item xs container flexDirection="column" spacing="20px">
                <Grid item container flexDirection="column" rowGap="35px">
                    <Grid item container>
                        <StyledBox>
                            <CustomSnackbar
                                open={snackbarOpen}
                                onClose={handleSnackbarClose}
                                message={error?.message || "An error occurred."} // Mostrar el mensaje de error
                            />
                            <DataGrid
                                rows={rows}
                                columns={columns}
                                filterMode="client"
                                sortingMode="client"
                                paginationMode="client"
                                disableRowSelectionOnClick
                                getRowId={(row) => row.id}
                                sx={{
                                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
                                        outline: "none "
                                    },
                                    "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within": { outline: "none" }
                                }}
                            />
                        </StyledBox>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>)
}

export default SecurityAdvisoriesTable
