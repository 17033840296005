import React from "react"
import { Font, Image, Text, View } from "@react-pdf/renderer"
Font.register({ family: "Bold", src: "/assets/fonts/Griff-Bold.otf" })
interface ContentBlock {
  key: string;
  text: string;
  type: string;
  depth: number;
  inlineStyleRanges: { offset: number; length: number; style: string }[];
  entityRanges: { offset: number; length: number; key: string }[];
  data: any;
}

interface Content {
  blocks: ContentBlock[];
  entityMap: {
    [key: string]: {
      type: string;
      mutability: string;
      data: {
        src: string;
      };
    };
  };
}

const RenderContent = ({ description }: { description: string }) => {
    let content: Content | undefined

    try {
        content = JSON.parse(description)
    } catch (error) {
        console.error("Error parsing description:", error)
    }

    if (!content) {
        return <Text>Error parsing content.</Text>
    }

    const defaultTextStyle = {
        color: "black",
        fontSize: 9,
        marginBottom: 5
    }

    const mapStyle = (style: string) => {
        switch (style) {
        case "BOLD":
            return { fontFamily: "Bold", whiteSpace: "nowrap" as const }
        case "ITALIC":
            return { fontStyle: "italic" as const }
        case "UNDERLINE":
            return { textDecoration: "underline" as const }
        default:
            if (style.startsWith("fontsize-")) {
                const fontSize = parseInt(style.replace("fontsize-", ""), 10)
                return { fontSize }
            }
            return {}
        }
    }

    const getStyledText = (block: ContentBlock) => {
        const styledSegments: React.ReactNode[] = []
        let cursor = 0

        const ranges = block.inlineStyleRanges.map((range) => ({
            start: range.offset,
            end: range.offset + range.length,
            style: range.style
        }))

        const combinedRanges: { start: number; end: number; styles: string[] }[] = []

        ranges.forEach((range) => {
            let merged = false
            combinedRanges.forEach((combined) => {
                if (
                    (range.start >= combined.start && range.start < combined.end) ||
                    (range.end > combined.start && range.end <= combined.end) ||
                    (range.start <= combined.start && range.end >= combined.end)
                ) {
                    combined.start = Math.min(combined.start, range.start)
                    combined.end = Math.max(combined.end, range.end)
                    combined.styles.push(range.style)
                    merged = true
                }
            })

            if (!merged) {
                combinedRanges.push({
                    start: range.start,
                    end: range.end,
                    styles: [range.style]
                })
            }
        })

        combinedRanges.sort((a, b) => a.start - b.start)

        const textSegments: React.ReactNode[] = []

        combinedRanges.forEach((range, idx) => {
            if (cursor < range.start) {
                textSegments.push(
                    <Text key={`${block.key}-plain-${idx}`} style={defaultTextStyle}>
                        {block.text.slice(cursor, range.start)}
                    </Text>
                )
            }

            const combinedStyle = range.styles.reduce(
                (acc, style) => ({ ...acc, ...mapStyle(style) }),
                { ...defaultTextStyle }
            )

            textSegments.push(
                <Text key={`${block.key}-styled-${idx}`} style={combinedStyle}>
                    {block.text.slice(range.start, range.end)}
                </Text>
            )

            cursor = range.end
        })

        if (cursor < block.text.length) {
            textSegments.push(
                <Text key={`${block.key}-end`} style={defaultTextStyle}>
                    {block.text.slice(cursor)}
                </Text>
            )
        }

        return <View style={{ flexDirection: "row", flexWrap: "wrap" }}>{textSegments}</View>
    }

    return (
        <View>
            {content.blocks.map((block, idx) => {
                if (block.type === "atomic" && block.entityRanges.length > 0) {
                    return (
                        <View key={idx} style={{ flexDirection: "column", marginBottom: 10 }}>
                            {block.entityRanges.map((entityRange, entityIdx) => {
                                const entityKey = entityRange.key
                                const entity = content?.entityMap?.[entityKey]

                                if (entity && entity.type === "IMAGE") {
                                    const base64Image = entity.data.src

                                    const byteArray = Uint8Array.from(
                                        atob(base64Image.split(",")[1]),
                                        (c) => c.charCodeAt(0)
                                    )
                                    const blob = new Blob([byteArray], { type: "image/png" })
                                    const imageUrl = URL.createObjectURL(blob)

                                    return (
                                        <Image
                                            key={`${idx}-${entityIdx}`}
                                            src={imageUrl}
                                            style={{
                                                width: "auto",
                                                height: "auto",
                                                objectFit: "contain",
                                                marginBottom: 5
                                            }}
                                        />
                                    )
                                }

                                return null
                            })}
                        </View>
                    )
                }

                if (block.type === "unordered-list-item" || block.type === "ordered-list-item") {
                    return (
                        <View
                            key={idx}
                            style={{
                                flexDirection: "row",
                                alignItems: "flex-start",
                                marginBottom: 2
                            }}
                        >
                            {block.type === "ordered-list-item" && (
                                <Text style={{ ...defaultTextStyle, marginRight: 5 }}>
                                    {idx + 1}.
                                </Text>
                            )}
                            {block.type === "unordered-list-item" && (
                                <Text style={{ ...defaultTextStyle, marginRight: 5 }}>•</Text>
                            )}
                            <Text style={defaultTextStyle}>{getStyledText(block)}</Text>
                        </View>
                    )
                }

                return (
                    <View key={idx}>
                        {getStyledText(block)}
                    </View>
                )
            })}
        </View>
    )
}

export default RenderContent
