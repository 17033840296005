import React, { useEffect, useState } from "react"
import { Page, Text, View } from "@react-pdf/renderer"
import { Achilles, MonitorValue } from "@models/Achilles" // Importa tus modelos
import Template from "@models/Template"
import { achillesStyles } from "../Styles"
import { Footer, Header } from "./HeaderFooter"

interface SectionProps {
    achillesData: Achilles[];
    externalData: any; // Actualiza esto al tipo de datos correcto
    customConclusion?: string
    template?: Template
}

const Conclusion: React.FC<SectionProps> = ({ achillesData, customConclusion, template }) => {
    const [conclusionMessage, setConclusionMessage] = useState<string>("No se ha encontrado ninguna anomalía en la red.")
    const [resumenAnomalias, setResumenAnomalias] = useState<string[]>([])
    const [numAnomalias, setNumAnomalias] = useState<number>(0)

    useEffect(() => {
        // Función para detectar y resumir anomalías
        const detectarAnomalias = () => {
            const protocolosConAnomalias: { [protocol: string]: number } = {}
            let totalAnomalias = 0

            achillesData.forEach(entry => {
                // Verifica si el valor del monitor es "monitor_yellow" o "test_error"
                if (entry.monitor_value === MonitorValue.MonitorYellow || entry.monitor_value === MonitorValue.TestError) {
                    if (!protocolosConAnomalias[entry.test_type]) {
                        protocolosConAnomalias[entry.test_type] = 0
                    }
                    protocolosConAnomalias[entry.test_type]++
                    totalAnomalias++
                }
            })

            setNumAnomalias(totalAnomalias)

            if (totalAnomalias > 0) {
                const protocolosResumen = Object.keys(protocolosConAnomalias)
                    .map(protocol => `${protocol}`)
                    .join(", ")
                    .replace(/, ([^,]*)$/, " y $1") // Formatea la lista de protocolos con "y"

                setResumenAnomalias([protocolosResumen])
                return `Se ha detectado un total de ${totalAnomalias} anomalías en la red. Los protocolos más afectados son: ${protocolosResumen}.`
            }
            return "No se ha encontrado ninguna anomalía en la red."
        }

        // Actualiza el mensaje de conclusión y el resumen de anomalías
        setConclusionMessage(detectarAnomalias())
    }, [achillesData])

    return (
        <Page size="A4" style={{ backgroundColor: !template || template.color === "#fffa37" ? "" : template.color }}>
            <Header template={template}/>
            <View style={{ ...achillesStyles.section, flexDirection: "row" }}>
                <View style={{ width: "100%" }}>
                    <Text style={{ ...achillesStyles.titulo }}>5.3.3. Conclusiones</Text>
                    <Text style={{ ...achillesStyles.textua }}>{conclusionMessage}</Text>
                    {numAnomalias > 0 && (
                        <View style={{ marginTop: 10 }}>
                            <Text style={{ ...achillesStyles.textua }}>
                                Se ha visto que hay {numAnomalias} anomalías en la red que deben corregirse, especialmente en los protocolos con mayor número de incidencias: {resumenAnomalias.join(", ")}. Es importante abordar estas anomalías para asegurar el correcto funcionamiento de la red.
                            </Text>
                        </View>
                    )}
                    <Text style={{ ...achillesStyles.textua }}>{customConclusion}</Text>
                </View>
            </View>
            <Footer color={template?.color}/>
        </Page>
    )
}

export { Conclusion }
