import React, { useContext } from "react"
import { Page, View } from "@react-pdf/renderer"
import Template from "@models/Template"
import { I18nContext } from "I18nProvider"
import RenderContent from "@views/vulnerability/RenderContent"
import { globalStyles } from "../Styles"
import { Footer, Header } from "./HeaderFooter"

interface introProps {
    sufix: string;
    columns: {
        identifier: string;
        name: string;
        criticity: string;
        cvss: string;
        affected_asset: string;
    };
    rows: {
        description: string;
        solution: string;
    };
    references: string;
}

const i18n: {
    es: introProps;
    en: introProps;
} = {
    es: {
        sufix: "VULN - ",
        columns: {
            identifier: "Identificador",
            name: "Nombre",
            criticity: "Criticidad",
            cvss: "CVSS",
            affected_asset: "Activo afectado"
        },
        rows: {
            description: "Descripción",
            solution: "Solución"

        },
        references: "Referencias"
    },
    en: {
        sufix: "VULN - ",
        columns: {
            identifier: "Identifier",
            name: "Name",
            criticity: "Criticity",
            cvss: "CVSS",
            affected_asset: "Affected asset"
        },
        rows: {
            description: "Description",
            solution: "Solution"
        },
        references: "References"
    }
}

interface SectionProps {
    lang?: string;
    template?: Template;
    externalData: any
    client?: string
}

const ExecutionSection: React.FC<SectionProps> = ({ lang, template, externalData, client }) => {
    // Constant
    const context = useContext(I18nContext)
    // --------------------------------------------------

    // lenguaje
    type I18nType = typeof i18n
    let locale: keyof I18nType = "es"
    if (lang) {
        if (lang === "es" || lang === "en") {
            locale = lang as keyof I18nType
        } else {
            console.warn(`Idioma no soportado: ${lang}. Usando idioma por defecto.`)
        }
    } else if (context) {
        if (context.language === "es" || context.language === "en") {
            locale = context.language as keyof I18nType
        } else {
            console.warn(`Idioma no soportado en el contexto: ${context.language}. Usando idioma por defecto.`)
        }
    } else {
        console.error(
            "El contexto I18n no está inicializado. Asegúrese de que tiene el proveedor configurado correctamente."
        )
    }
    // --------------------------------------------------

    // Validar si externalData.Ejecucion existe y no está vacío
    if (!externalData?.Ejecucion || typeof externalData.Ejecucion !== "string" || externalData.Ejecucion.trim() === "") {
        return null // No renderizar nada si no cumple con los criterios
    }

    return (
        <Page size="A4" style={globalStyles.page}>
            <Header externalData={null} template={template}></Header>
            <View style={{ ...globalStyles.section }}>
                <View style={globalStyles.container}>
                    <RenderContent description={externalData.Ejecucion} />
                </View>
            </View>
            <Footer externalData={{ clientName: client }} template={template}></Footer>
        </Page>
    )
}

export { ExecutionSection }
