import React from "react"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import highchartsAccessibility from "highcharts/modules/accessibility"

highchartsAccessibility(Highcharts)
interface DonutValues {
    values: {
        done: number;
        pending: number;
    }
}

const DonutChart: React.FC<DonutValues> = (props: DonutValues) => {
    const options: Highcharts.Options = {
        title: {
            text: undefined
        },
        chart: {
            type: "pie",
            backgroundColor: "transparent",
            height: 95,
            width: 95,
            spacingTop: 0,
            spacingRight: 0,
            spacingBottom: 0,
            spacingLeft: 0,
            plotBorderWidth: 0,
            margin: [0, 0, 0, 0]
        },
        credits: { enabled: false },
        exporting: {
            enabled: false // Esto ocultará el botón de menú de opciones de exportación
        },
        series: [{
            // colorByPoint: true,
            data: [{
                name: "Done",
                y: props.values.done,
                color: "#fae733",
                borderColor: "#fae733"
            }, {
                name: "Pending",
                y: props.values.pending,
                color: "#2a292a",
                borderColor: "#2a292a"
            }],
            type: "pie"
        }],
        tooltip: {
            pointFormat: ""
        }
    }
    return (
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
        </div>
    )
}

export { DonutChart }
export default DonutChart
