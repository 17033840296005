import React, { useCallback, useEffect, useState } from "react"
import { Button, Grid, TextField, Typography, useTheme } from "@mui/material"
import { MdOutlineDragIndicator } from "react-icons/md"
import { SectionFormProps } from "./SectionFormProps"
import "./CustomContentStyle.css"

type ContentData = {
    name: string;
    content: string;
};

const initCustomValue: ContentData[] = [
    {
        name: "",
        content: ""
    }
]

const CustomContents: React.FC<SectionFormProps<ContentData[]>> = ({ id, data = [], onSectionChange }) => {
    const theme = useTheme()
    const [contentData, setContentData] = useState<ContentData[]>(data)

    const validData = Array.isArray(data) ? data : []

    useEffect(() => {
        setContentData(validData)
    }, [validData])

    const sectionValid = (): boolean => {
        if (validData.length === 0) return true
        const formData = validData[validData.length - 1]
        const isNotNullrules = [formData.name !== "", formData.content !== ""]
        return isNotNullrules.every((rule) => rule)
    }

    const handleNameChange = (index: number, value: string) => {
        const newSections = contentData.map((section, idx) =>
            idx === index ? { ...section, name: value } : section
        )
        setContentData(newSections) // Actualizamos el estado local
        onSectionChange(id, newSections, true) // Propagamos los cambios al padre
    }

    const handleContentChange = (index: number, value: string) => {
        const newSections = contentData.map((section, idx) =>
            idx === index ? { ...section, content: value } : section
        )
        setContentData(newSections) // Actualizamos el estado local
        onSectionChange(id, newSections, true) // Propagamos los cambios al padre
    }

    const handleAddSection = useCallback(() => {
        const newSections = [
            ...contentData.map((section) => ({
                ...section
            })),
            {
                name: "",
                content: ""
            }
        ]
        setContentData(newSections) // Actualizamos el estado local
        onSectionChange(id, newSections, true) // Propagamos los cambios al padre
    }, [id, contentData, onSectionChange])

    return (
        <Grid item container my={2}>
            <Grid
                item
                container
                xs={12}
                justifyContent="space-between"
                alignItems="center"
                bgcolor={theme.palette.primary.main}
                height="40px"
                sx={{ borderRadius: "5px", cursor: "grab", padding: "0 10px" }}
            >
                <Typography color="Black" fontSize="22px" fontWeight="bolder">
                    Custom Content
                </Typography>
                <MdOutlineDragIndicator size={24} color="black" />
            </Grid>
            {contentData.map((section, index) => (
                <Grid key={index} item container direction="column" spacing={2}>
                    <Grid item>
                        <TextField
                            margin="normal"
                            required
                            variant="standard"
                            label="Name"
                            value={section.name}
                            onChange={(e) => handleNameChange(index, e.target.value)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            margin="normal"
                            required
                            variant="outlined"
                            label="Content"
                            value={section.content}
                            onChange={(e) => handleContentChange(index, e.target.value)}
                            multiline
                            rows={4}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            ))}
            <Grid item>
                <Button
                    fullWidth
                    variant="contained"
                    sx={{ fontWeight: "bold", color: "black" }}
                    onClick={handleAddSection}
                    disabled={!sectionValid()}
                >
                    Add Section
                </Button>
            </Grid>
        </Grid>
    )
}

export type { ContentData }
export { CustomContents, initCustomValue }
export default CustomContents
