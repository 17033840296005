import React, { useEffect, useState } from "react"
import { Page, Text, View } from "@react-pdf/renderer"
import { MonitorValue, TestType } from "@models/Achilles"
import Template from "@models/Template"
import { achillesStyles } from "../Styles"
import { Footer, Header } from "./HeaderFooter"

type OSILayer = "physical" | "datalink" | "network" | "transport" | "application";
type AchillesData = {
    [osi in OSILayer]?: {
        [protocol in TestType]?: {
            [test: string]: {
                [monitor: string]: {
                    alert: string,
                    value?: string
                }
            }
        }
    }
}

interface SectionProps {
    achillesData: AchillesData
    monitors: string[]
    template?: Template
}

const Resultado: React.FC<SectionProps> = ({ achillesData, monitors, template }) => {
    // Protocol Monitor Value Map
    const [protocolMonitorValueMap, setProtocolMonitorValueMap] = useState<{ [key in TestType]?: { [key: string]: string } }>({})
    useEffect(() => {
        const tempProtocolMonitorValueMap: { [key in TestType]?: { [key: string]: string } } = {}
        Object.keys(achillesData).forEach(osi => {
            Object.keys(achillesData[osi as OSILayer]!).forEach(protocol => {
                Object.keys(achillesData[osi as OSILayer]![protocol as TestType]!).forEach(test => {
                    Object.keys(achillesData[osi as OSILayer]![protocol as TestType]![test]).forEach(monitor => {
                        if (!tempProtocolMonitorValueMap[protocol as TestType]) {
                            tempProtocolMonitorValueMap[protocol as TestType] = {}
                        }
                        if (!tempProtocolMonitorValueMap[protocol as TestType]![monitor]) {
                            tempProtocolMonitorValueMap[protocol as TestType]![monitor] = achillesData[osi as OSILayer]![protocol as TestType]![test][monitor].alert
                        }
                    })
                })
            })
        })
        setProtocolMonitorValueMap(tempProtocolMonitorValueMap)
    }, [achillesData])
    // ------------------------------------------------

    // Generate the result message based on the status, key, protocolList and num
    const generateResultMessage = (status: boolean, key: string, protocolList?: string[], num?: number): string => {
        const formatProtocols = (protocols: string[]): string => {
            if (protocols.length === 0) return ""
            if (protocols.length === 1) return protocols[0]
            if (protocols.length === 2) return protocols.join(" y ")
            return `${protocols.slice(0, -1).join(", ")} y ${protocols[protocols.length - 1]}`
        }
        const result: {
            [key: string]: {
                true: string;
                false: (protocols: string[], numAnomalies: number) => string;
            }
        } = {
            NCG: {
                true: "No se han detectado anomalías. Ver que el monitor L1 está en estado Normal.",
                false: (protocols: string[], numAnomalies: number) => `Se han detectado ${numAnomalies} anomalías en los siguientes protocolos: ${formatProtocols(protocols)}.`
            },
            NCL: {
                true: "No se han detectado anomalías. Ver que los monitores A1, I1 y TP1 están en estado Normal.",
                false: (protocols: string[], numAnomalies: number) => `Se han detectado ${numAnomalies} anomalías en los siguientes protocolos: ${formatProtocols(protocols)}.`
            },
            AA: {
                true: "No se han detectado anomalías. Ver que los monitores A1, I1 y TP1 están en estado Normal.",
                false: (protocols: string[], numAnomalies: number) => `Se han detectado ${numAnomalies} anomalías en los siguientes protocolos: ${formatProtocols(protocols)}.`
            },
            EE: {
                true: "No se han detectado anomalías. Ver que el monitor T está en estado Normal.",
                false: (protocols: string[], numAnomalies: number) => `Se han detectado ${numAnomalies} anomalías en los siguientes protocolos: ${formatProtocols(protocols)}.`
            }
        }

        if (status) {
            return result[key]?.true || "No se ha detectado anomalía."
        }
        if (protocolList && num !== undefined) {
            if (result[key]) {
                return result[key].false(protocolList, num)
            }
        }
        return "Test no encontrado o faltan datos."
    }
    const EE: string[] = Array.from(new Set(
        Object.values(achillesData).flatMap(layer =>
            Object.entries(layer || {}).flatMap(([protocolName, protocol]) =>
                Object.entries(protocol || {}).flatMap(([testName, monitors]) =>
                    Object.entries(monitors).filter(([monitor, value]) =>
                        monitor === "T" &&
                        value.alert === MonitorValue.TestError
                    ).map(() => protocolName)
                )
            )
        )
    ))

    const NCG: string[] = Array.from(new Set(
        Object.values(achillesData).flatMap(layer =>
            Object.entries(layer || {}).flatMap(([protocolName, protocol]) =>
                Object.entries(protocol || {}).flatMap(([testName, monitors]) =>
                    Object.entries(monitors).filter(([monitor, value]) =>
                        monitor === "L1" &&
                        value.alert === MonitorValue.MonitorYellow &&
                        !EE.includes(protocolName)
                    ).map(() => protocolName)
                )
            )
        )
    ))
    const NCL: string[] = Array.from(new Set(
        Object.values(achillesData).flatMap(layer =>
            Object.entries(layer || {}).flatMap(([protocolName, protocol]) =>
                Object.entries(protocol || {}).flatMap(([testName, monitors]) =>
                    Object.entries(monitors).filter(([monitor, value]) =>
                        value.alert === MonitorValue.MonitorYellow &&
                        parseFloat(value.value as string) >= 50.00 &&
                        !EE.includes(protocolName)
                    ).map(() => protocolName)
                )
            )
        )
    ))
    const AA: string[] = Array.from(new Set(
        Object.values(achillesData).flatMap(layer =>
            Object.entries(layer || {}).flatMap(([protocolName, protocol]) =>
                Object.entries(protocol || {}).flatMap(([testName, monitors]) =>
                    Object.entries(monitors).filter(([monitor, value]) =>
                        value.alert === MonitorValue.MonitorYellow &&
                        parseFloat(value.value as string) < 50.00 &&
                        !EE.includes(protocolName)
                    ).map(() => protocolName)
                )
            )
        )
    ))
    // --------------------------------------------------

    // Functions
    const getColor = (values: MonitorValue[]): string => {
        if (values.includes(MonitorValue.MonitorRed) || values.includes(MonitorValue.TestRed)) {
            return "#ff9a9a" // Red
        } else if (values.includes(MonitorValue.MonitorYellow) || values.includes(MonitorValue.TestYellow)) {
            return "#ffffad" // Yellow
        } else if (values.every(value => value === MonitorValue.MonitorGreen || value === MonitorValue.TestGreen)) {
            return "#c2f09d" // Green
        }
        return "#ff9a9a" // White
    }
    // --------------------------------------------------

    return (
        <Page size="A4" style={{ paddingBottom: 80, backgroundColor: !template || template.color === "#fffa37" ? "" : template.color }} wrap>
            <Header template={template}/>
            <View style={{ ...achillesStyles.section }}>
                <View>
                    <Text style={{ ...achillesStyles.titulo }}>5.2. Resultados</Text>
                    <Text style={{ ...achillesStyles.textua }}>
                        La Tabla 3 muestra los resultados agregados por protocolo y monitor (T: Test monitor, A1: ARP Monitor, I1: ICMP Monitor, L1: LinkState monitor, TP1: TCP Ports Monitor) de la ejecución final, donde los colores significan:
                    </Text>
                    <Text style={{ ...achillesStyles.textua, marginLeft: 15 }}>
                        • &nbsp;<Text style={{ ...achillesStyles.textua, color: "green" }}>Verde: </Text>En todas las ejecuciones todos los tests del protocolo han tenido resultado Normal.
                    </Text>
                    <Text style={{ ...achillesStyles.textua, marginLeft: 15 }}>
                        • &nbsp;<Text style={{ ...achillesStyles.textua, color: "orange" }}>Ambar: </Text>Se ha producido al menos un Warning en al menos una de las ejecuciones de al menos uno de los tests.
                    </Text>
                    <Text style={{ ...achillesStyles.textua, marginLeft: 15 }}>
                        • &nbsp;<Text style={{ ...achillesStyles.textua, color: "red" }}>Rojo: </Text>: Se ha producido al menos un Failure en al menos una de las ejecuciones de al menos uno de los tests. Un Failure en el monitor T indica que el test no puede reconectarse al DUT para continuar su ejecución.
                    </Text>

                    <View style={{ ...achillesStyles.tableScope, marginTop: 15 }}>
                        <View style={{ ...achillesStyles.tableRowScope, backgroundColor: "yellow" }}>
                            <View style={{ ...achillesStyles.tableColScope }}>
                                <Text style={{ ...achillesStyles.texto, fontFamily: "Bold" }}>Monitor</Text>
                            </View>
                        </View>
                        <View style={{ ...achillesStyles.tableRowScope }}>
                            <View style={{ ...achillesStyles.tableColScope, backgroundColor: "white" }}>
                                <Text style={{ ...achillesStyles.texto, fontFamily: "Bold" }}>Protocol</Text>
                            </View>
                            {monitors && monitors.map((monitor, idx) => (
                                <View key={idx} style={{
                                    ...achillesStyles.tableColScope,
                                    backgroundColor: "white"
                                }}>
                                    <Text wrap style={{ ...achillesStyles.texto, fontFamily: "Bold" }}>{monitor}</Text>
                                </View>
                            ))}
                        </View>
                        {protocolMonitorValueMap && Object.entries(protocolMonitorValueMap).map(([protocol, monitorList], pIndex) => (
                            <View key={pIndex} style={{ ...achillesStyles.tableRowScope }} break={pIndex === 10}>
                                <View style={{ ...achillesStyles.tableColScope, backgroundColor: "white" }}>
                                    <Text wrap={false} style={{ ...achillesStyles.texto, fontFamily: "Bold" }}>{protocol}</Text>
                                </View>
                                {monitors.map((monitor, idx) => {
                                    const monitorValue = monitorList[monitor]
                                    const color = monitorValue ? getColor([monitorValue as MonitorValue]) : "#ffffff"

                                    return (
                                        <View key={idx} style={{ ...achillesStyles.tableColScope, backgroundColor: color }}>
                                            <Text style={{ ...achillesStyles.texto, fontFamily: "Bold", fontSize: 9 }}>
                                                {color === "#ffffff" && monitor === "T" ? "N/A" : " "}
                                            </Text>
                                        </View>
                                    )
                                })}
                            </View>
                        ))}

                    </View>
                    <Text style={{ ...achillesStyles.textua, marginBottom: 30, marginLeft: "25%", marginRight: "-50%", fontFamily: "Griff", fontSize: 8, marginTop: 3, color: "gray" }}>
                        Tabla 3. Resultados agregados por protocolo y monitor
                    </Text>

                    <Text style={{ ...achillesStyles.textua }}>
                        En resumen, las anomalías detectadas según categoría son:
                    </Text>
                    <Text style={{ ...achillesStyles.textua, marginLeft: 15 }}>
                        • No conformidades graves: {generateResultMessage(NCG.length === 0, "NCG", NCG, NCG.length)}
                    </Text>
                    <Text style={{ ...achillesStyles.textua, marginLeft: 15 }}>
                        • No conformidades leves: {generateResultMessage(NCL.length === 0, "NCL", NCL, NCL.length)}
                    </Text>
                    <Text style={{ ...achillesStyles.textua, marginLeft: 15 }}>
                        • Anomalías asumibles: {generateResultMessage(AA.length === 0, "AA", AA, AA.length)}
                    </Text>
                    <Text style={{ ...achillesStyles.textua, marginLeft: 15 }}>
                        • Errores de ejecución: {generateResultMessage(EE.length === 0, "EE", EE, EE.length)}
                    </Text>
                    <Text style={{ ...achillesStyles.textua }}>
                        Para mayor detalle en los resultados, consulte el apartado 5.3.
                    </Text>
                </View>
            </View>
            <Footer color={template?.color}/>
        </Page>
    )
}

export { Resultado }
