import React, { useEffect, useState } from "react"
import Chip from "@mui/material/Chip"

type RiskType = "critical" | "high" | "medium" | "low" | "none";

interface ColorAndBg {
    color: string;
    backgroundColor: string;
}

// Define un mapeo de colores para cada tipo de riesgo
const getRiskTypeColor = (risk: RiskType): ColorAndBg => {
    const riskColorMap: Record<RiskType, ColorAndBg> = {
        critical: { color: "white", backgroundColor: "#7940A5" },
        high: { color: "white", backgroundColor: "#D9534F" },
        medium: { color: "white", backgroundColor: "#EC971F" },
        low: { color: "white", backgroundColor: "#F2CC0C" },
        none: { color: "white", backgroundColor: "#A9A9A9" }
    }

    return riskColorMap[risk]
}

interface ProductRiskColoredProps {
    value: RiskType; // El nivel de riesgo que se renderizará
}

const ProductRiskColored: React.FC<ProductRiskColoredProps> = ({ value }) => {
    const [color, setColor] = useState<ColorAndBg | null>(null)

    useEffect(() => {
        // Actualiza el color al cambiar el valor
        setColor(getRiskTypeColor(value))
    }, [value])

    return (
        <Chip
            size="small"
            style={{
                fontWeight: "bolder",
                color: color?.color,
                backgroundColor: color?.backgroundColor
            }}
            label={value.charAt(0).toUpperCase() + value.slice(1)} // Capitaliza el valor
        />
    )
}

export { ProductRiskColored }
export type { RiskType }
export default ProductRiskColored
