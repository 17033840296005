import React, { useContext, useState } from "react"
import { FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import ActionButton from "@components/common/Button/ActionButton"
import { I18nContext } from "I18nProvider"

interface MetadataGridProps {
    metadataList: Record<string, string> // Parent should pass metadata as Record
    setMetadataList: (updatedMetadata: Record<string, string>) => void // Set function that will accept Record
}

const MetadataGrid: React.FC<MetadataGridProps> = ({ metadataList, setMetadataList }) => {
    const [error, setError] = useState<string | null>(null)
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }

    // Convert metadataList into an array of entries for easier manipulation
    const metadataArray = Object.entries(metadataList).map(([key, value]) => ({ key, value }))

    const allOptions = [
        { value: "name", label: "Name" },
        { value: "actives", label: "Actives" }
    ]

    const handleInputChange = (
        index: number,
        field: "title" | "value",
        value: string
    ) => {
        const updatedMetadataArray = [...metadataArray]
        if (field === "title") {
            // Verificar si la clave ya existe en el objeto
            if (updatedMetadataArray.some((meta, idx) => idx !== index && meta.key === value)) {
                setError(
                    `${context.t.translate("the_metadata_title")} "${value}" ${context.t.translate("is_already_in_use")}`
                )
                return
            }
            setError(null)
        }

        updatedMetadataArray[index] = {
            ...updatedMetadataArray[index],
            [field === "title" ? "key" : "value"]: value
        }

        // Convert array back to Record and pass to parent
        const updatedMetadataRecord = updatedMetadataArray.reduce((acc, { key, value }) => {
            acc[key] = value
            return acc
        }, {} as Record<string, string>)

        setMetadataList(updatedMetadataRecord)
    }

    const handleDelete = (index: number) => {
        const updatedMetadataArray = metadataArray.filter((_, idx) => idx !== index)

        // Convert array back to Record and pass to parent
        const updatedMetadataRecord = updatedMetadataArray.reduce((acc, { key, value }) => {
            acc[key] = value
            return acc
        }, {} as Record<string, string>)

        setMetadataList(updatedMetadataRecord)
    }

    const handleAddMetadata = () => {
        if (metadataArray.length >= 1) {
            setError(context.t.translate("you_can_only_add"))
            return
        }

        // Find the first available option
        const availableOptions = allOptions.filter(option =>
            !metadataArray.some(meta => meta.key === option.value)
        )

        if (availableOptions.length > 0) {
            const newMetadataKey = availableOptions[0].value // Take the first available option
            const updatedMetadataArray = [
                ...metadataArray,
                { key: newMetadataKey, value: "" }
            ]

            // Convert array back to Record and pass to parent
            const updatedMetadataRecord = updatedMetadataArray.reduce((acc, { key, value }) => {
                acc[key] = value
                return acc
            }, {} as Record<string, string>)

            setMetadataList(updatedMetadataRecord)
        }
    }

    return (
        <Grid container spacing={2}>
            {metadataArray.map(({ key, value }, index) => {
                return (
                    <Grid key={key} item spacing={2} alignItems="center" display="flex" xs={8} gap={2}>
                        <Grid item xs={2}>
                            <FormControl fullWidth>
                                <InputLabel>{context.t.translate("type")}</InputLabel>
                                <Select
                                    value={key}
                                    label={key}
                                    onChange={(e) => handleInputChange(index, "title", e.target.value)}
                                >
                                    {allOptions.map((option) => (
                                        <MenuItem
                                            key={option.value}
                                            value={option.value}
                                            disabled={metadataArray.some(meta => meta.key === option.value)} // Disable selected options
                                        >
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                fullWidth
                                label={context.t.translate("value")}
                                value={value}
                                onChange={(e) => handleInputChange(index, "value", e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton onClick={() => handleDelete(index)} color="error">
                                <DeleteIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                )
            })}
            {metadataArray.length === 0 && ( // Show the button only if no metadata has been added
                <Grid item xs={12}>
                    <ActionButton text={context.t.translate("add_new_metadata")} onClick={handleAddMetadata} />
                </Grid>
            )}
            {error && (
                <Grid item xs={12}>
                    <span style={{ color: "red" }}>{error}</span>
                </Grid>
            )}
        </Grid>
    )
}

export default MetadataGrid
