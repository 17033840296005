import React, { useContext } from "react"
import { Font, Image, Page, Text, View } from "@react-pdf/renderer"
import Assessment from "@models/Assessment"
import Template from "@models/Template"
import { I18nContext } from "I18nProvider"
import moment from "moment"
import styles from "../../Styles"
Font.register({ family: "Bold", src: "/assets/fonts/Griff-Bold.otf" })
// Traducciones
const i18n = {
    es: {
        coverHeader: "Encabezado de portada",
        coverSubheader: "Subencabezado de portada",
        coverMetadata: "Metadatos de portada",
        clientName: "Nombre del cliente",
        monthAndYear: "Mes y año:",
        cybersecurity: "Ciberseguridad",
        intellectualProperty: "Los derechos de propiedad intelectual e industrial sobre este documento son propiedad exclusiva de ORBIK Cybersecurity, S.L.",
        confidentiality: "Este informe contiene información confidencial dirigida exclusivamente a su destinatario.",
        tlpAmber: "TLP-AMBER"
    },
    en: {
        coverHeader: "Cover Header",
        coverSubheader: "Cover Subheader",
        coverMetadata: "Cover Metadata",
        clientName: "Client Name",
        monthAndYear: "Month and year:",
        cybersecurity: "Cybersecurity",
        intellectualProperty: "The intellectual and industrial property rights over this document are the exclusive property of ORBIK Cybersecurity, S.L.",
        confidentiality: "This report contains confidential information exclusively addressed to its recipient.",
        tlpAmber: "TLP-AMBER"
    }
} as const

interface CustomFrontPageProps {
    assessment?: Assessment;
    template: Template;
}

const CustomFrontPage: React.FC<CustomFrontPageProps> = ({ assessment, template }) => {
    let imagen
    let isCustomLogo = false
    let hasHeader = false
    if (template.logo && template.logo !== "") {
        const byteArray = Uint8Array.from(atob(template.logo.toString()), c => c.charCodeAt(0))
        const blob = new Blob([byteArray], { type: "image/png" })
        imagen = URL.createObjectURL(blob)
        isCustomLogo = true
    } else if (template.metadata.data?.cover_page?.header?.trim()) {
        imagen = "/assets/img/report/portada_custom_template.png"
        hasHeader = true
    } else {
        imagen = "/assets/img/report/portada_template.png"
    }

    const context = useContext(I18nContext)
    let locale: keyof typeof i18n = "en" // Idioma por defecto
    if (template.language) {
        if (template.language === "es" || template.language === "en") {
            locale = template.language as keyof typeof i18n // Usamos el valor de `lang`
        } else {
            console.warn(`Idioma no soportado: ${template.language}. Usando idioma por defecto.`)
        }
    } else if (context) {
        locale = context.language as keyof typeof i18n // Usamos el valor del contexto si existe
    } else {
        console.error("El contexto I18n no está inicializado. Asegúrese de que tiene el proveedor configurado correctamente.")
    }

    const formatDate = (date: string | undefined, locale: keyof typeof i18n): string => {
        const momentDate = date ? moment(date) : moment()
        const format = locale === "es" ? "MMMM YYYY" : "YYYY MMMM"
        return momentDate.locale(locale).format(format)
    }
    return (
        <Page size="A4" style={{ backgroundColor: template.color ?? "#FAE733" }}>
            <View fixed >
                {hasHeader
                    ? <View
                        style={{
                            position: "absolute",
                            top: -35,
                            left: 0,
                            width: "100%",
                            height: 400, // Define una altura suficiente para la imagen
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        <Image
                            style={{
                                maxWidth: "100%",
                                maxHeight: "100%",
                                objectFit: "contain" // Mantén las proporciones
                            }}
                            src={imagen}
                        />
                    </View>
                    : <Image style={
                        isCustomLogo
                            ? { position: "absolute", top: -50, left: 20, width: 250, height: 250, objectFit: "contain" }
                            : styles.template_image
                    } src={imagen} />}
                <View style={{ position: "absolute", left: 20, top: 150, fontFamily: "Bold", fontSize: 40, fontWeight: "bold", color: "#000", marginRight: 10, paddingRight: 20 }}>
                    <Text>
                        { template.metadata.data?.cover_page?.header?.trim()
                            ? template.metadata.data.cover_page.header
                            : ""}
                    </Text>
                </View>
                <View style={isCustomLogo
                    ? {
                        position: "absolute",
                        top: 700,
                        marginLeft: 37,
                        marginRight: 36
                    }
                    : styles.tablea}>
                    <View style={{ ...styles.tableRowa, borderWidth: 0 }}>
                        <View style={{ ...styles.tableCola2, borderRightWidth: 0 }}>
                            <Text style={{ ...styles.titulo2page1, fontFamily: "Griff", paddingBottom: 0 }}>

                                {i18n[locale].cybersecurity}
                            </Text>
                            <Text style={{ ...styles.titulo2page1, fontFamily: "Griff", paddingTop: 5 }}>
                                {
                                    template.metadata.data?.cover_page?.subheader?.trim()
                                        ? template.metadata.data.cover_page.subheader
                                        : ""}
                            </Text>

                        </View>
                        <View style={{ ...styles.tableCola2 }}>
                            <View style={{ ...styles.tableRowa, borderWidth: 0 }}>
                                <View style={{ ...styles.column, marginLeft: 0, marginTop: 0 }}>
                                    <Text style={{ ...styles.titulo2page1, marginLeft: 0, marginTop: 0, paddingBottom: 0, fontFamily: "Griff" }}>
                                        {i18n[locale].monthAndYear}
                                    </Text>
                                    <Text style={{ ...styles.titulo2page1, fontFamily: "Griff", paddingTop: 5 }}>
                                        {
                                            template.metadata.data?.cover_page?.metadata?.trim()
                                                ? template.metadata.data.cover_page.metadata
                                                : ""}
                                    </Text>
                                </View>
                                <View style={{ ...styles.column, marginLeft: -60, marginTop: 0 }}>
                                    <Text style={{ ...styles.titulo2page1, paddingBottom: 2, fontFamily: "Griff" }}>
                                        {formatDate(assessment?.endDate ?? undefined, locale)}
                                    </Text>
                                </View>
                            </View>
                        </View>
                    </View>
                    <FooterFrontPage locale={locale} isCustomLogo={isCustomLogo}></FooterFrontPage>
                </View>

            </View>
        </Page>
    )
}

const FooterFrontPage: React.FC<{ locale: keyof typeof i18n, isCustomLogo: boolean }> = ({ locale, isCustomLogo }) => (
    <View
        style={{
            position: "absolute",
            bottom: isCustomLogo ? -80 : -200,
            left: 32,
            right: 32
        }}
    >
        <View
            style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center"
            }}
        >
            <Text style={{
                color: "black",
                fontFamily: "Griff",
                fontSize: 6,
                textAlign: "justify",
                flex: 1,
                marginRight: isCustomLogo ? 0 : 40
            }}>
                {i18n[locale].intellectualProperty} {" "}
                {i18n[locale].confidentiality}{" "}
                <Text style={{ fontFamily: "Bold", color: "#FFC000", backgroundColor: "black" }}>
                    {i18n[locale].tlpAmber}
                </Text>
            </Text>

            {isCustomLogo === false && <Text style={{
                fontSize: 50,
                fontFamily: "Bold",
                fontWeight: "bold",
                textAlign: "right",
                flexShrink: 0
            }}>
                Orbik
            </Text>}
        </View>
    </View>
)

export { CustomFrontPage }
