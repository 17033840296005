import React, { useContext, useState } from "react"
import { Grid, IconButton, TextField } from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import ActionButton from "@components/common/Button/ActionButton"
import { I18nContext } from "I18nProvider"

interface CWEGridProps {
    initialReferences?: number[]; // Opcional para iniciar con datos existentes
    onChange?: (updatedReferences: number[]) => void; // Prop para pasar el estado actualizado al padre
}

const CWEGrid: React.FC<CWEGridProps> = ({ initialReferences = [], onChange }) => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const [cwe, setCWE] = useState<number[]>(initialReferences)

    const handleAddCWE = () => {
        const updatedReferences = [...cwe, 0]
        setCWE(updatedReferences)
        onChange?.(updatedReferences) // Notificar al padre
    }

    const handleInputChange = (value: string, index: number) => {
        const updatedReferences = [...cwe]
        const parsedValue = parseInt(value, 10)
        updatedReferences[index] = value === "" ? 0 : (isNaN(parsedValue) ? cwe[index] : parsedValue)

        setCWE(updatedReferences)
        onChange?.(updatedReferences) // Notificar al padre
    }

    const handleDeleteRef = (index: number) => {
        const updatedReferences = cwe.filter((_, i) => i !== index)
        setCWE(updatedReferences)
        onChange?.(updatedReferences) // Notificar al padre
    }

    return (
        <Grid container spacing={2}>
            {cwe.map((ref, index) => (
                <Grid item key={index} alignItems="center" spacing={2} display="flex" gap={1} xs={8}>
                    <Grid item xs={10}>
                        <TextField
                            fullWidth
                            type="number"
                            label={`cwe${index + 1}`}
                            value={cwe[index] === 0 ? "" : cwe[index]} // Mostrar vacío si es 0
                            onChange={(e) => handleInputChange(e.target.value, index)}
                        />

                    </Grid>
                    <Grid item xs={2}>
                        <IconButton color="error" onClick={() => handleDeleteRef(index)}>
                            <DeleteIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            ))}
            <Grid item xs={12}>
                <ActionButton text={context.t.translate("add_new_cwe")} onClick={handleAddCWE} />
            </Grid>
        </Grid>
    )
}

export default CWEGrid
