import React, { useEffect, useState } from "react"
import Chip from "@mui/material/Chip"
import { ProductType } from "@models/Product"
import { ProductTypeColors } from "@components/common/colors/ProductTypeColors"
import { IconButton, Tooltip } from "@mui/material"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import { ProductTypeValueOps } from "./ProductTableDefinition"

interface ColorAndBg {
    color: string;
    "background-color": string;
}

type ProductTypeColorMap = {
  [key in ProductType]: ColorAndBg;
};

type ProductTypeDescriptionMap = {
  [key in ProductType]: string;
};

// Map de colores para cada tipo de producto.
const getProductTypeColor = (status: ProductType): ColorAndBg => {
    const assessmentStatusColorMap: ProductTypeColorMap = {
        [ProductType.Application]: { color: "#000000", "background-color": ProductTypeColors.application },
        [ProductType.Library]: { color: "#000000", "background-color": ProductTypeColors.library },
        [ProductType.Firmware]: { color: "#000000", "background-color": ProductTypeColors.firmware },
        [ProductType.OS]: { color: "#000000", "background-color": ProductTypeColors["operating-system"] },
        [ProductType.Container]: { color: "#000000", "background-color": ProductTypeColors.container },
        [ProductType.Device]: { color: "#000000", "background-color": ProductTypeColors.device }
    }
    return assessmentStatusColorMap[status]
}

// Map de descripciones para cada tipo de producto.
const productTypeDescriptions: ProductTypeDescriptionMap = {
    [ProductType.Application]: "A product of the “Application” type refers to a software application that runs on an operating system or a specific environment. These products are designed to perform specific tasks and can include web, desktop, or mobile applications.",
    [ProductType.Library]: "A product of the “Library” type is a code library that is used to add specific functionalities to other applications or products. Libraries are not executable by themselves but provide functions, classes, or methods that can be used by applications integrating them.",
    [ProductType.Firmware]: "A product of the “Firmware” type refers to specialized software integrated into hardware devices. Firmware is designed to control and manage specific hardware and is commonly found in electronic devices such as routers, printers, cameras, and more.",
    [ProductType.OS]: "A product of the “Operating System” type refers to a complete operating system, such as Linux, Windows, macOS, etc. The operating system manages the hardware and system resources, allowing applications and users to interact with the device.",
    [ProductType.Container]: "A product of the “Container” type refers to a software unit that packages an application and its dependencies into a single container. This allows applications to run consistently in any environment, whether local or in the cloud. Containers are popular in microservices-based infrastructures.",
    [ProductType.Device]: "A product of the “Device” type refers to a physical device, such as a server, computer, IoT (Internet of Things) device, etc. These devices may be subject to firmware updates or interact with specific applications and operating systems."
}

interface ProductTypeColoredProps {
    value: ProductType;
}

const ProductTypeColored: React.FC<ProductTypeColoredProps> = ({ value }) => {
    const [color, setColor] = useState<ColorAndBg | null>(null)
    const [label, setLabel] = useState<string | undefined>("")
    const [description, setDescription] = useState<string>("")

    useEffect(() => {
        setLabel(ProductTypeValueOps.find(v => v.value === value)?.label)
        setColor(getProductTypeColor(value))
        setDescription(productTypeDescriptions[value])
    }, [value])

    return (
        <>
            <Chip
                size="small"
                style={{
                    fontWeight: "bolder",
                    color: color?.color,
                    backgroundColor: color?.["background-color"]
                }}
                label={label}
            />
            <Tooltip title={description}>
                <IconButton>
                    <HelpOutlineIcon />
                </IconButton>
            </Tooltip>
        </>
    )
}

export { ProductTypeColored }
export default ProductTypeColored
