/* eslint-disable multiline-ternary */
import React, { useContext, useEffect, useMemo, useState } from "react"
import { Grid, SelectChangeEvent, TextField, Typography } from "@mui/material"
import ActionButton from "@components/common/Button/ActionButton"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import { useNavigate, useParams } from "react-router-dom"
import ServicesContext from "@context/ServicesContext"
import Assessment, { AssessmentType } from "@models/Assessment"
import { CustomText } from "@models/CustomText"
import { FilterOperation } from "@utils/queryParams"
import { IterableList } from "@models/iterable"
import { I18nContext } from "I18nProvider"
import CustomEditor from "@views/pentest/component/CustomEditor"
import SectionSelector from "./Components/SectionSelector"
import SectionEditor from "./Components/SectionEditor"
import SystemDiscoverySection, { serviceCategories } from "./Components/SystemDiscovery"
import ScopeSectionExtended from "./Components/ScopeCustomize"

const MAX_CHARACTERS = 250
interface CustomizeTextProps {
    assessment: Assessment;
    onSectionChange?: (updatedText: CustomText) => void;
}

const CustomizeText: React.FC<CustomizeTextProps> = ({ assessment, onSectionChange }) => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const sectionsLogicalPentest = [
        "Front Page",
        "Intro",
        "Alcance",
        "Ejecucion",
        "Resumen",
        "Descubrimiento e identificacion del sistema"
    ]
    const [selectedSection, setSelectedSection] = useState(sectionsLogicalPentest[0])
    const { id } = useParams()
    const [localText, setLocalText] = useState<CustomText>({
        id: "",
        assessment_id: assessment.id,
        client_id: assessment.client.id,
        custom_text: {
        }
    })
    const [activosUrls, setActivosUrls] = useState<{ activo: string, url: string }[]>([
        { activo: "", url: "" }
    ])
    const [extraInfo, setExtraInfo] = useState<string>("")
    const [originalText, setOriginalText] = useState<CustomText>({
        id: "",
        assessment_id: assessment.id,
        client_id: assessment.client.id,
        custom_text: {
        }
    })
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState("")
    const navigate = useNavigate()
    const [servicesData, setServicesData] = useState<Record<string, any[]>>({
        "Protocolos de la capa de aplicación": [{ puerto: "", protocolo: "", estado: "", cpe: "", comentarios: "" }]
    })
    const [applicationsData, setApplicationsData] = useState<
    { activo: string; aplicacion: string }[]
>([{ activo: "", aplicacion: "" }])
    const [technologyData, setTechnologyData] = useState<
    { tecnologia: string; implementacion: string }[]
>([{ tecnologia: "", implementacion: "" }])
    const [ejecucionData, setEjecucionData] = useState<{ ejecucion?: string}>({
        ejecucion: ""
    })
    const [resumenData, setResumenData] = useState<{ resumen?: string}>({
        resumen: ""
    })
    interface Field {
        label: string;
        key: string;
        multiline?: boolean;
        length?: number;
        defaultValue?: string;
    }
    interface SectionConfig {
        [section: string]: Field[]
    }

    const [selectedCategory, setSelectedCategory] = useState<string>(serviceCategories[0])

    const handleSaveContent = (section: string, content: string) => {
        if (section === "ejecucion") {
            setEjecucionData((prev) => ({
                ...prev,
                ejecucion: content
            }))
        } else if (section === "resumen") {
            setResumenData((prev) => ({
                ...prev,
                resumen: content
            }))
        }
    }

    const handleCategoryChange = (event: SelectChangeEvent<string>) => {
        const category = event.target.value as string

        if (!servicesData[category]) {
            setServicesData((prev) => ({
                ...prev,
                [category]: [{ puerto: "", protocolo: "", estado: "", cpe: "", comentarios: "" }]
            }))
        }
        setSelectedCategory(category)

        setLocalText((prev) => ({
            ...prev,
            custom_text: {
                ...prev.custom_text,
                servicesData: {
                    ...prev.custom_text.servicesData,
                    [category]: servicesData[category]
                }
            }
        }))
    }
    const sections: SectionConfig = {
        "Front Page": [
            { label: "Jefe del proyecto:", key: "proyectOwner", length: 50 },
            { label: "Responsables de informe:", key: "assessmentLeader", length: 50 },
            { label: "Ref y nº del informe:", key: "ref", length: 50 },
            { label: "Rev:", key: "rev", length: 50 },
            { label: "Mes y año:", key: "date", length: 50 }
        ],
        Intro: [
            { label: "Device name", key: "device", length: 50 },
            { label: "Side title", key: "side_title", defaultValue: "El test de intrusión tiene como objetivo identificar y caracterizar los fallos de diseño, debilidades y vulnerabilidades del sistema." },
            { label: "Methodology", key: "methodology", multiline: true, defaultValue: "(solo en manual): Se describe la metodología aplicada y la planificación de las condiciones, objetivos y el entorno en la que se realizan las pruebas." },
            { label: "Scope", key: "scope", multiline: true, defaultValue: "Se describe el alcance inicial desde el cual se parte a la hora de realizar este ejercicio." },
            { label: "Executive summary", key: "exec_summary", multiline: true, defaultValue: "Se muestra un resumen general de los resultados obtenidos durante el test de intrusión." },
            { label: "System discovery and identification", key: "system_identification", multiline: true, defaultValue: "Se describe el proceso de descubrimiento e identificación del dispositivo, sistema operativo y servicios de red del sistema." },
            { label: "Vulnerability identification", key: "vuln", multiline: true, defaultValue: "Se identifican las vulnerabilidades y debilidades del sistema y se proponen una serie de contramedidas para mitigar las vulnerabilidades y debilidades identificadas y/o explotadas." }
        ],
        Alcance: [
            { label: "Tipo de activo", key: "activo" },
            { label: "URL/IP", key: "url" }
        ]
    }

    const customTextService = useContext(ServicesContext).customTextService

    const isEqual = (obj1: any, obj2: any): boolean => {
        if (obj1 === obj2) return true
        if (typeof obj1 !== "object" || typeof obj2 !== "object") return false
        if (obj1 === null || obj2 === null) return false

        if (Array.isArray(obj1) && Array.isArray(obj2)) {
            if (obj1.length !== obj2.length) return false
            for (let i = 0; i < obj1.length; i++) {
                if (!isEqual(obj1[i], obj2[i])) return false
            }
            return true
        }

        const keys1 = Object.keys(obj1)
        const keys2 = Object.keys(obj2)

        if (keys1.length !== keys2.length) return false

        for (const key of keys1) {
            if (!keys2.includes(key)) return false
            if (!isEqual(obj1[key], obj2[key])) return false
        }

        return true
    }

    const isModified = useMemo(() => {
        if (!originalText) return false
        return !isEqual(originalText.custom_text, localText.custom_text)
    }, [localText, originalText])

    const handleUpdateReport = () => {
        if (onSectionChange && !isEqual(originalText?.custom_text, localText.custom_text)) {
            onSectionChange(localText)
        }
    }

    const handleChange = (field: keyof CustomText["custom_text"], value: string) => {
        if (value.length <= MAX_CHARACTERS) {
            setLocalText((prev) => ({
                ...prev,
                custom_text: {
                    ...prev.custom_text,
                    [field]: value
                }
            }))
        }
    }

    useEffect(() => {
        if (assessment.id) {
            setLocalText(prevText => ({
                ...prevText, // Conservar las propiedades existentes
                assessment_id: assessment.id, // Actualizar assessment_id
                client_id: assessment.client.id // Actualizar client_id
            }))
            customTextService
                .getAll({
                    filters: [{ field: "assessment_id", operation: FilterOperation.UUIDEqual, value: assessment.id }]
                })
                .then((texts: IterableList<CustomText>) => {
                    const customTextsArray = texts.list as CustomText[]
                    if (customTextsArray.length > 0) {
                        const customText = customTextsArray[0]
                        const initialData = {
                            ...customText,
                            custom_text: { ...customText.custom_text }
                        }

                        setLocalText(initialData)
                        setOriginalText(initialData)

                        if (customText.custom_text.Alcance) {
                            setActivosUrls(customText.custom_text.Alcance)
                        }
                        if (customText.custom_text.customScope) {
                            setAdditionalText(customText.custom_text.customScope)
                        }
                        if (customText.custom_text.servicesData) {
                            setServicesData(customText.custom_text.servicesData)
                        }
                        if (customText.custom_text.applicationsData) {
                            setApplicationsData(customText.custom_text.applicationsData)
                        }
                        if (customText.custom_text.technologyData) {
                            setTechnologyData(customText.custom_text.technologyData)
                        }
                        if (onSectionChange && customText.custom_text) {
                            onSectionChange(customText)
                        }
                        if (customText.custom_text.Ejecucion) {
                            setEjecucionData(customText.custom_text.Ejecucion)
                        }
                        if (customText.custom_text.Resumen) {
                            setResumenData(customText.custom_text.Resumen)
                        }
                    }
                })
        }
    }, [assessment])

    const handleSave = async () => {
        try {
            localText.id !== ""
                ? await customTextService.update(localText.id, localText)
                : await customTextService.create(localText)

            setOriginalText(localText)
            setSnackbarMessage(context.t.translate("save_success"))
            setSnackbarOpen(true)
            handleNavigate()
        } catch (error) {
            setSnackbarMessage(context.t.translate("error_saving"))
            setSnackbarOpen(true)
        }
    }

    const handleNavigate = () => {
        navigate("../")
    }

    const handleClear = async () => {
        try {
            if (localText.id) {
                await customTextService.delete(localText.id)
                setSnackbarMessage(context.t.translate("custom_text"))
            }
            setLocalText({
                ...localText,
                id: "",
                custom_text: {
                }
            })
            setOriginalText({
                id: "",
                assessment_id: "",
                client_id: "",
                custom_text: {
                }
            })
            setSnackbarOpen(true)
        } catch (error) {
            setSnackbarMessage(context.t.translate("error_clearing"))
            setSnackbarOpen(true)
        }
    }

    const handleSectionChange = (sectionName: string, fieldKey: string, value: string) => {
        setLocalText((prev) => ({
            ...prev,
            custom_text: {
                ...prev.custom_text,
                [sectionName]: {
                    ...prev.custom_text[sectionName],
                    [fieldKey]: value
                }
            }
        }))
    }
    const [additionalText, setAdditionalText] = useState<string>("")
    const handleUpdate = (
        updater: React.Dispatch<React.SetStateAction<any[]>>, // Función de actualización del estado
        updatedData: Record<string, string>[],
        sectionKey: string // Clave del estado correspondiente en localText.custom_text
    ) => {
        updater(updatedData.map(item => ({ ...item })))
        setLocalText(prev => ({
            ...prev,
            custom_text: {
                ...prev.custom_text,
                [sectionKey]: updatedData // Actualiza la sección correspondiente
            }
        }))
    }
    const handleUpdateCategoryData = (category: string, updatedData: any[]) => {
        setServicesData((prev) => ({
            ...prev,
            [category]: updatedData
        }))
        setLocalText(prev => ({
            ...prev,
            custom_text: {
                ...prev.custom_text,
                servicesData: { ...prev.custom_text.servicesData, [category]: updatedData }
            }
        }))
    }

    const updateExtraInfo = (value: string) => {
        setExtraInfo(value)
        setLocalText(prev => ({
            ...prev,
            custom_text: {
                ...prev.custom_text,
                servicesData: { ...prev.custom_text.servicesData, additionalInfo: value }
            }
        }))
    }
    return (
        <Grid item xs container flexDirection="column" spacing="20px" rowGap="25px">
            <Grid
                container
                sx={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    spacing: "20px",
                    paddingLeft: "20px",
                    marginBottom: "0px",
                    paddingBottom: "0px"
                }}
            >
                <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">
                    {context.t.translate("customize_this")}
                </Typography>
                <Grid sx={{ display: "flex", gap: 2 }}>
                    <ActionButton variant="outlined" onClick={handleClear} text="Clear data" />
                    <ActionButton onClick={handleUpdateReport} text="Reload report" disabled={!isModified} />
                    <ActionButton onClick={handleSave} text="Save" disabled={!isModified} />
                </Grid>
            </Grid>

            {assessment.type === AssessmentType.LogicalPentest && (
                <SectionSelector
                    selectedSection={selectedSection}
                    onSectionChange={setSelectedSection}
                    sections={sectionsLogicalPentest}
                />
            )}

            <Grid item width="80%">
                {assessment.type === AssessmentType.LogicalPentest ? (
                    selectedSection === "Alcance" ? (
                        <ScopeSectionExtended
                            alcanceData={activosUrls}
                            initialText={additionalText}
                            onSectionChange={(updatedText) => {
                                setLocalText(updatedText)
                            }}
                        />
                    ) : selectedSection === "Descubrimiento e identificacion del sistema" ? (
                        <>
                            <SystemDiscoverySection
                                selectedCategory={selectedCategory}
                                servicesData={servicesData}
                                extraInfo={extraInfo}
                                applicationsData={applicationsData}
                                technologyData={technologyData}
                                onCategoryChange={handleCategoryChange}
                                updateExtraInfo={updateExtraInfo}
                                onUpdateCategoryData={handleUpdateCategoryData}
                                onUpdateApplications={(updatedData) =>
                                    handleUpdate(setApplicationsData, updatedData, "applicationsData")
                                }
                                onUpdateTechnology={(updatedData) =>
                                    handleUpdate(setTechnologyData, updatedData, "technologyData")
                                }
                            />
                        </>
                    ) : selectedSection === "Ejecucion" || selectedSection === "Resumen" ? (
                        <CustomEditor
                            initialContent={localText.custom_text?.[selectedSection] || ""}
                            onSave={(content: string) => {
                                setLocalText((prev) => ({
                                    ...prev,
                                    custom_text: {
                                        ...prev.custom_text,
                                        [selectedSection]: content
                                    }
                                }))
                            }}
                        />
                    ) : (
                        <SectionEditor
                            sectionName={selectedSection}
                            fields={sections[selectedSection] || []}
                            sectionData={localText.custom_text[selectedSection] || {}}
                            onChange={(fieldKey, value) => handleSectionChange(selectedSection, fieldKey, value)}
                        />
                    )
                ) : (
                    sections[selectedSection].map((field) => (
                        <TextField
                            key={field.key}
                            label={field.label}
                            value={
                                localText.custom_text[field.key as keyof CustomText["custom_text"]] ||
                    field.defaultValue ||
                    ""
                            }
                            onChange={(e) =>
                                handleChange(field.key as keyof CustomText["custom_text"], e.target.value)
                            }
                            rows={field.multiline ? 4 : 1}
                            multiline={field.multiline || false}
                            variant="filled"
                            helperText={`${
                                localText.custom_text[field.key as keyof CustomText["custom_text"]]?.length || 0
                            }/${MAX_CHARACTERS} characters`}
                        />
                    ))
                )}
            </Grid>

            <CustomSnackbar
                message={snackbarMessage}
                open={snackbarOpen}
                onClose={() => setSnackbarOpen(false)}
                severity="success"
            />
        </Grid>
    )
}

export default CustomizeText
