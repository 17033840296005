// ScopeSectionExtended.tsx
import React, { useEffect, useState } from "react"
import { Grid, TextField } from "@mui/material"
import { CustomText } from "@models/CustomText"
import ScopeSection from "./ScopeSection"

const MAX_CHARACTERS = 250

interface ScopeSectionExtendedProps {
    alcanceData: { activo: string; url: string }[];
    initialText: string;
    onBlur?: () => void;
    onSectionChange: (updatedText: CustomText) => void;
}

const ScopeSectionExtended: React.FC<ScopeSectionExtendedProps> = ({
    alcanceData,
    initialText,
    onBlur,
    onSectionChange
}) => {
    const [additionalText, setAdditionalText] = useState<string>(initialText)
    const [localText, setLocalText] = useState<CustomText>({
        id: "",
        assessment_id: "",
        client_id: "",
        custom_text: {
            Alcance: alcanceData,
            customScope: initialText
        }
    })

    const handleAdditionalTextChange = (value: string) => {
        if (value.length <= MAX_CHARACTERS) {
            setAdditionalText(value)
            setLocalText((prev) => ({
                ...prev,
                custom_text: {
                    ...prev.custom_text,
                    customScope: value
                }
            }))
        }
    }

    const handleAlcanceUpdate = (updatedAlcance: { activo: string; url: string }[]) => {
        setLocalText((prev) => ({
            ...prev,
            custom_text: {
                ...prev.custom_text,
                Alcance: updatedAlcance
            }
        }))
    }

    // Trigger parent onSectionChange if the text is modified
    useEffect(() => {
        if (onSectionChange) {
            onSectionChange(localText)
        }
    }, [localText, onSectionChange])

    return (
        <Grid container direction="column" spacing={2}>
            <ScopeSection
                alcanceData={localText.custom_text.Alcance} // Pass the updated alcance data
                onUpdate={handleAlcanceUpdate} // Handle updates here
                onBlur={onBlur}
            />
            <TextField
                style={{ marginTop: 20 }}
                label="Texto adicional"
                value={additionalText}
                onChange={(e) => handleAdditionalTextChange(e.target.value)}
                rows={4}
                multiline
                variant="filled"
                helperText={`${additionalText.length}/${MAX_CHARACTERS} caracteres`}
                onBlur={onBlur}
            />
        </Grid>
    )
}

export default ScopeSectionExtended
