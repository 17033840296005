import React, { useContext } from "react"
import { Page, Text, View } from "@react-pdf/renderer"
import Template from "@models/Template"
import { I18nContext } from "I18nProvider"
import Assessment from "@models/Assessment"
import { globalStyles } from "../Styles"
import { Footer, Header } from "./HeaderFooter"

interface introProps {
    title: string;
    description1: string;
    description_1_1: string;
    description_1_2: string;
    table: {
        header: string[];
        body: Array<{ id: string; content: string }>;
        legend: string;
    }
    description_2: string;
    owasp_sections: Array<{ id: string; content: string }>
}

const i18n: {
    es: introProps;
    en: introProps;
} = {
    es: {
        title: "Metodología",
        description1: "La metodología utilizada para la ejecución de este test de intrusión está basada en los estándares tecnológicos más relevantes del sector y se divide en cuatro fases. Estas etapas están basadas en los estándares ",
        description_1_1: "La Documentación del Estándar de Ejecución de Pruebas de Penetración y el Estándar de Seguridad de Datos PCI (PCI DSS).",
        description_1_2: "La Tabla 1 describe cada fase.",
        table: {
            header: ["Fase", "Descripción"],
            body: [
                { id: "Planificación", content: "En esta fase se definen las condiciones y reglas para la ejecución de las pruebas, así como los objetivos de esta." },
                { id: "Descubrimiento", content: "En la fase de descubrimiento se refiere a la etapa de recolección de información y escaneo (tanto pasiva como activa) para descubrir e identificar el equipo, los dominios, los servicios expuestos, así como las aplicaciones." },
                { id: "Ataque", content: "En base a los resultados previamente obtenidos, se procede a realizar el ataque. Esto es, intentar explotar cualquier debilidad y/o vulnerabilidad del sistema para comprometer el máximo posible las propiedades de operación de esta." },
                { id: "Documentación", content: "La fase de documentación se realiza de forma paralela a las demás fases. El objetivo es documentar el trabajo realizado y los pasos ejecutados para alcanzar los objetivos marcados en la fase de planificación." }
            ],
            legend: "Tabla 1: Fases en la metodología de test de intrusión."
        },
        description_2: "La fase de Descubrimiento se ha realizado teniendo como base la OWASP Testing Guide. Esta guía define las pruebas que se han de realizar a lo largo de un test de intrusión, catalogándolas en diferentes grupos dependiendo de la naturaleza de cada ataque.  Las categorías que componen esta guía de OWASP se describen a continuación de forma general:",
        owasp_sections: [
            { id: "Information gathering: ", content: "Consiste en obtener información sobre el servidor y los servicios expuestos. Se trata de identificar las tecnologías y versiones usadas en las aplicaciones y servicios expuestos." },
            { id: "Configuration and deployment management testing: ", content: "Se tratará de obtener información sobre la configuración del despliegue realizado en el servidor y respecto al funcionamiento de la aplicación en sí." },
            { id: "Identity management testing: ", content: "El objetivo es identificar errores o vulnerabilidades en todos los procesos que dependan de la gestión de roles y aprovisionamiento de usuarios dentro de la aplicación." },
            { id: "Authentication testing: ", content: "Se analiza el funcionamiento del proceso de autenticación y los mecanismos que la hacen posible. Los mecanismos auxiliares de recuperación y cambio de contraseña estarían incluidos en esta categoría." },
            { id: "Authorization testing: ", content: "En esta categoría se hace un análisis de los permisos y la forma en la que están implementados en una búsqueda de superar las limitaciones que tienen en la aplicación." },
            { id: "Session management testing: ", content: "Se analizarán todos los parámetros y configuraciones de las sesiones, como pueden ser las cookies para verificar que están integradas en la aplicación de forma segura." },
            { id: "Input validation testing: ", content: "Los valores introducidos por los usuarios son los puntos de entrada más habituales en cualquier ataque, por este motivo se han de revisar todos los parámetros de entrada de la aplicación." },
            { id: "Testing for error handling: ", content: "La gestión incorrecta de errores puede dar lugar a posibles explotaciones por lo que se fuerza la generación de errores para la verificación de la gestión de estos." },
            { id: "Testing for weak cryptography: ", content: "La criptografía de las comunicaciones garantiza la privacidad y seguridad de las aplicaciones, esto será verificado en esta categoría frente a las debilidades criptográficas conocidas y sus incorrectas implementaciones." },
            { id: "Business logic testing: ", content: "Esta categoría recoge funcionalidades a mayor nivel, intentando afectar a funcionamiento determinado de las funciones para verificar el tipo de respuesta frente a acciones no previstas." },
            { id: "Client side testing: ", content: "Estas pruebas se refieren a la ejecución de código en el cliente, normalmente de forma nativa dentro de un navegador web o de un complemento del navegador." }
        ]
    },
    en: {
        title: "Methodology",
        description1: "The methodology used for the execution of this penetration test is based on the most relevant technological standards in the industry and is divided into four phases. These stages are based on the standards ",
        description_1_1: "The Penetration Testing Execution Standard Documentation and the PCI Data Security Standard (PCI DSS).",
        description_1_2: "Table 1 describes each phase.",
        table: {
            header: ["Phase", "Description"],
            body: [
                { id: "Planning", content: "In this phase, the conditions and rules for conducting the tests are defined, as well as the objectives." },
                { id: "Discovery", content: "The discovery phase refers to the stage of information gathering and scanning (both passive and active) to discover and identify equipment, domains, exposed services, as well as applications." },
                { id: "Attack", content: "Based on the previously obtained results, the attack is carried out. This involves attempting to exploit any weakness and/or vulnerability of the system to compromise its operational properties as much as possible." },
                { id: "Documentation", content: "The documentation phase is carried out in parallel with the other phases. The objective is to document the work done and the steps executed to achieve the goals set during the planning phase." }
            ],
            legend: "Table 1: Phases in the penetration testing methodology."
        },
        description_2: "The Discovery phase was carried out based on the OWASP Testing Guide. This guide defines the tests to be performed during a penetration test, categorizing them into different groups depending on the nature of each attack. The categories that make up this OWASP guide are generally described below:",
        owasp_sections: [
            { id: "Information gathering: ", content: "This involves obtaining information about the server and exposed services. It is about identifying the technologies and versions used in the exposed applications and services." },
            { id: "Configuration and deployment management testing: ", content: "This involves obtaining information about the configuration of the deployment made on the server and regarding the functioning of the application itself." },
            { id: "Identity management testing: ", content: "The objective is to identify errors or vulnerabilities in all processes that depend on role management and user provisioning within the application." },
            { id: "Authentication testing: ", content: "This analyzes the functioning of the authentication process and the mechanisms that make it possible. Auxiliary mechanisms for password recovery and change are included in this category." },
            { id: "Authorization testing: ", content: "This category analyzes the permissions and the way they are implemented in an effort to bypass limitations in the application." },
            { id: "Session management testing: ", content: "All session parameters and configurations, such as cookies, will be analyzed to verify that they are securely integrated into the application." },
            { id: "Input validation testing: ", content: "User-provided values are the most common entry points for any attack, so all input parameters of the application must be reviewed." },
            { id: "Testing for error handling: ", content: "Incorrect error handling can lead to potential exploitation, so error generation is forced to verify how errors are managed." },
            { id: "Testing for weak cryptography: ", content: "Cryptography in communications ensures the privacy and security of applications. This will be verified in this category against known cryptographic weaknesses and their incorrect implementations." },
            { id: "Business logic testing: ", content: "This category addresses higher-level functionalities, attempting to disrupt the specific operation of functions to test responses to unexpected actions." },
            { id: "Client side testing: ", content: "These tests refer to the execution of code on the client side, usually natively within a web browser or a browser plugin." }
        ]
    }
}

interface SectionProps {
    externalData: any
    lang?: string;
    assessment?: Assessment
    template?: Template
    client?: string
}

const Methodology: React.FC<SectionProps> = ({ externalData, lang, assessment, template, client }) => {
    // Constant
    const context = useContext(I18nContext)
    // --------------------------------------------------

    // lenguaje
    type I18nType = typeof i18n
    let locale: keyof I18nType = "es"
    if (lang) {
        if (lang === "es" || lang === "en") {
            locale = lang as keyof I18nType
        } else {
            console.warn(`Idioma no soportado: ${lang}. Usando idioma por defecto.`)
        }
    } else if (context) {
        if (context.language === "es" || context.language === "en") {
            locale = context.language as keyof I18nType
        } else {
            console.warn(`Idioma no soportado en el contexto: ${context.language}. Usando idioma por defecto.`)
        }
    } else {
        console.error(
            "El contexto I18n no está inicializado. Asegúrese de que tiene el proveedor configurado correctamente."
        )
    }
    // --------------------------------------------------

    return (
        <Page size="A4" style={globalStyles.page}>
            <Header externalData={null} template={template}></Header>
            <View style={{ ...globalStyles.section }}>
                <View style={globalStyles.column}>
                    <View style={globalStyles.container}>
                        <Text style={globalStyles.title}>{i18n[locale].title}</Text>
                        <Text style={ globalStyles.texto }>
                            {i18n[locale].description1}
                            <Text style={{ fontStyle: "italic" }}>{i18n[locale].description_1_1}</Text>
                            {i18n[locale].description_1_2}
                        </Text>
                        <View style={{ ...globalStyles.container, marginTop: "10px" }}>
                            <View style={globalStyles.table}>
                                <View style={globalStyles.row}>
                                    <View style={{ ...globalStyles.col, flex: 4, backgroundColor: "yellow" }}>
                                        <Text style={{ ...globalStyles.textoBold, alignSelf: "center" }}>{i18n[locale].table.header[0]}</Text>
                                    </View>
                                    <View style={{ ...globalStyles.col, flex: 8, backgroundColor: "yellow" }}>
                                        <Text style={{ ...globalStyles.textoBold, alignSelf: "center" }}>{i18n[locale].table.header[1]}</Text>
                                    </View>
                                </View>
                                {i18n[locale].table.body.map((row, index) => (
                                    <View key={index} style={globalStyles.row} wrap={false}>
                                        <View style={{ ...globalStyles.col, flex: 4 }}>
                                            <Text style={ globalStyles.textoBold }>{row.id}</Text>
                                        </View>
                                        <View style={{ ...globalStyles.col, flex: 8 }}>
                                            <Text style={ globalStyles.texto }>{row.content}</Text>
                                        </View>
                                    </View>
                                ))}
                            </View>
                            <View style={globalStyles.table_legend}>
                                <Text style={ globalStyles.texto }>{i18n[locale].table.legend}</Text>
                            </View>
                        </View>
                    </View>
                </View>
                <View style={globalStyles.column}>
                    <View style={globalStyles.container}>
                        <Text style={globalStyles.texto}>
                            {i18n[locale].description_2}
                        </Text>
                        {i18n[locale].owasp_sections.slice(0, 6).map((section, index) => (
                            <React.Fragment key={index}>
                                <View style={{ flexDirection: "column", marginLeft: 20, paddingTop: 5 }} wrap={false}>
                                    <Text style={{ ...globalStyles.textoBold, marginRight: 5 }}>• {section.id}</Text>
                                    <Text style={{ ...globalStyles.texto }}>{section.content}</Text>
                                </View>
                            </React.Fragment>
                        ))}
                    </View>
                </View>
            </View>
            <View style={{ ...globalStyles.section }}>
                <View style={globalStyles.column}>
                    {i18n[locale].owasp_sections.slice(-4).map((section, index) => (
                        <React.Fragment key={index}>
                            <View style={{ flexDirection: "column", marginLeft: 20, paddingTop: 5 }} wrap={false}>
                                <Text style={{ ...globalStyles.textoBold, marginRight: 5 }}>• {section.id}</Text>
                                <Text style={{ ...globalStyles.texto }}>{section.content}</Text>
                            </View>
                        </React.Fragment>
                    ))}
                </View>
                <View style={globalStyles.column}>

                </View>
            </View>
            <Footer externalData={{ clientName: client }} template={template}></Footer>
        </Page>
    )
}

export { Methodology }
