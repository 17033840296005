import React, { useCallback, useMemo } from "react"
import { Card, CardContent, Grid, Typography, useTheme } from "@mui/material"
import { DragDropContext, Draggable, Droppable, DropResult, ResponderProvided } from "react-beautiful-dnd"
import { StyledBox } from "@components/common/Box/BoxContainer"
import CoverPage, { initCoverValue } from "../sections/CoverPage"
import TableOfContents, { initTOCValue } from "../sections/TableOfContents"
import CustomContents, { initCustomValue } from "../sections/CustomContents"

type FormValue = {
    id: string,
    type: string,
    value: any,
    valid: boolean
}

type Item = {
    id: string;
    headerName: any;
    initValue: any;
    defaultValid: boolean;
    type: React.FC<any>;
    multiple: boolean;
}

const availableSections: Item[] = [
    {
        id: "1",
        headerName: "Cover Page",
        type: CoverPage,
        initValue: initCoverValue,
        defaultValid: false,
        multiple: false
    },
    {
        id: "2",
        headerName: "Table of Contents",
        type: TableOfContents,
        initValue: initTOCValue,
        defaultValid: true,
        multiple: false
    },
    {
        id: "3",
        headerName: "Custom Content",
        type: CustomContents,
        initValue: initCustomValue,
        defaultValid: false,
        multiple: true
    }
]

const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    userSelect: "none",
    ...draggableStyle
})

const getListStyle = (isDraggingOver: any) => ({})

type TemplateContentSectionProps = {
    sectionData: FormValue[];
    setSectionData: (sections: FormValue[]) => void;
    loadPreview: () => void;
    openRequest: (url: string) => void;
}

const TemplateContentSection: React.FC<TemplateContentSectionProps> = ({
    sectionData,
    setSectionData,
    loadPreview,
    openRequest
}) => {
    const theme = useTheme()
    const draggSectionsHeight = availableSections.length * 150

    const onDragEnd = (result: DropResult, provided: ResponderProvided) => {
        const { source, destination, draggableId } = result
        if (!destination) return

        if (source.droppableId === destination.droppableId) {
            if (source.droppableId === "selectedSections") { // Reorder
                const result = [...sectionData]
                const [removed] = result.splice(source.index, 1)
                result.splice(destination.index, 0, removed)
                setSectionData(result)
            }
        } else {
            if (source.droppableId === "availableSections") {
                const draggedItem = availableSections.find(section => section.id === draggableId) as Item
                const result = [...sectionData]

                result.splice(destination.index, 0, {
                    id: draggedItem.id + "-" + Math.floor(Math.random() * (50000)),
                    type: draggedItem.type.name,
                    value: JSON.parse(JSON.stringify(draggedItem.initValue)),
                    valid: draggedItem.defaultValid
                })
                setSectionData(result)
            } else {
                const result = [...sectionData]
                result.splice(source.index, 1)
                setSectionData([...result])
            }
        }
    }
    const handleSectionChange = useCallback(
        (id: string, updatedValue: any, valid: boolean) => {
            const updatedSections = sectionData.map(section =>
                section.id === id
                    ? { ...section, value: updatedValue, valid }
                    : section
            )
            setSectionData(updatedSections)
        },
        [sectionData, setSectionData]
    )
    const renderElement = (item: Item|undefined, fvalue: FormValue) => {
        if (item) {
            const Component = item.type
            if (!Component) return null

            return (
                <Component
                    key={item.id}
                    id={fvalue.id}
                    data={fvalue.value}
                    onSectionChange={handleSectionChange} // Pasa el método correcto desde el padre
                    openRequest={openRequest} // Pasa el método correcto desde el padre
                />
            )
        }
        return null
    }

    const contents = sectionData.map((sectionDataVal) =>
        renderElement(availableSections.find(section => section.id === sectionDataVal.id.split("-")[0]), sectionDataVal)
    )

    const availableSectionsSelectable = useMemo(() =>
        availableSections.filter((section) =>
            section.multiple ||
            (section.id === "1" && !sectionData.some(s => s.id.split("-")[0] === "1")) ||
            (section.id === "2" && !sectionData.some(s => s.id.split("-")[0] === "2")) ||
            (section.id === "3" && !sectionData.some(s => s.id.split("-")[0] === "3")) ||
            !sectionData.some(s => s.id.split("-")[0] === section.id)
        ),
    [sectionData]
    )

    const previewValid = (): boolean => {
        if (sectionData.length === 0) {
            return false
        }
        return !sectionData.some(section => !section.valid)
    }

    return (
        <StyledBox>
            <Typography sx={{ fontSize: "27px", fontFamily: "Griff", fontWeight: "bolder", color: theme.palette.primary.main }}>Content</Typography>
            <Typography sx={{ fontSize: "18px", fontFamily: "Griff", color: theme.palette.text.secondary }}>Drag and Drop the contents to create a custom assessment</Typography>

            <Grid item container mt={2}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="availableSections">
                        {(provided, snapshot) => (
                            <Grid
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                style={{
                                    height: draggSectionsHeight,
                                    ...getListStyle(snapshot.isDraggingOver),
                                    overflowY: "auto",
                                    border: `1px solid ${theme.palette.primary.main}`,
                                    borderRadius: "5px"
                                }}
                                item xs={12} md={3}
                            >
                                <CardContent>
                                    <Grid mb="20px">
                                        <Typography sx={{ fontSize: "27px", fontFamily: "Griff", fontWeight: "bolder", color: theme.palette.primary.main }}>Available Items</Typography>
                                        <Typography sx={{ fontSize: "18px", fontFamily: "Griff", color: theme.palette.text.secondary }}>Choose from the available pages</Typography>
                                    </Grid>
                                    <Grid container item direction="column" spacing={2}>
                                        {availableSectionsSelectable.map((item, index) => (
                                            <Draggable key={item.id} draggableId={item.id} index={index}>
                                                {(provided, snapshot) => (
                                                    <Grid item ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                                                        <Card style={{ backgroundColor: "#222" }}>
                                                            <CardContent>
                                                                <Typography color="primary" fontSize="22px" fontWeight="bolder">{item.headerName}</Typography>
                                                            </CardContent>
                                                        </Card>
                                                    </Grid>
                                                )}
                                            </Draggable>
                                        ))}
                                    </Grid>
                                    {provided.placeholder}
                                </CardContent>
                            </Grid>
                        )}
                    </Droppable>

                    <Droppable droppableId="selectedSections">
                        {(provided, snapshot) => (
                            <Grid
                                item
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                style={{
                                    minHeight: draggSectionsHeight,
                                    ...getListStyle(snapshot.isDraggingOver),
                                    borderStyle: "dotted",
                                    borderWidth: "1px 1px 1px 0px",
                                    borderColor: "white",
                                    borderRadius: "5px"
                                }}
                                xs={12} md={9}
                            >
                                <CardContent>
                                    <Grid mb="20px">
                                        <Typography sx={{ fontSize: "27px", fontFamily: "Griff", fontWeight: "bolder", color: theme.palette.primary.main }}>Dropped Items</Typography>
                                        <Typography sx={{ fontSize: "18px", fontFamily: "Griff", color: theme.palette.text.secondary }}>This is where the items are dropped</Typography>
                                    </Grid>
                                    {sectionData.map((item, index) => (
                                        <Draggable key={item.id} draggableId={item.id} index={index}>
                                            {(provided, snapshot) => (
                                                <Grid
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                                    spacing={2}
                                                >
                                                    {contents[index]}
                                                </Grid>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}

                                </CardContent>
                            </Grid>
                        )}
                    </Droppable>
                </DragDropContext>
            </Grid>
        </StyledBox>
    )
}

export default TemplateContentSection
