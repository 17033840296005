import { ClientSelector } from "@components/client"
import { AbilityContext, Can } from "@components/permissions"
import ServicesContext from "@context/ServicesContext"
import Compliance, { ComplianceResult, getComplianceTypeByClientSector, QuizzStatus, QuizzType, RegistryData } from "@models/Compliance"
import { AlertColor, Grid, Typography, useTheme } from "@mui/material"
import { FilterOperation, QueryParameters } from "@utils/queryParams"
import { I18nContext } from "I18nProvider"
import React, { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { StyledBox } from "@components/common/Box/BoxContainer"
import VulnReportDocumentCompliance from "@components/report/VulnReportDocumentCompliance"
import { pdf } from "@react-pdf/renderer"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import { useTrack } from "@components/track/TrackContext"
import { useClient } from "@context/useClient"
import ActionButton from "@components/common/Button/ActionButton"
import License from "@models/License"
import QuizCard from "./components/QuizCard"
import { countNonEmptyQValues } from "./ComplianceSummary"
/* eslint-disable multiline-ternary */

const initRegistry: RegistryData = Object.fromEntries(
    Array.from({ length: 39 }, (_, i) => i === 0 ? "time" : `q${i}`).map(key => [key, ""])
) as RegistryData

const initValue = {
    id: "",
    registry: [initRegistry],
    type: QuizzType.CRA,
    conclusion: "",
    client_id: "",
    created_at: "",
    updated_at: "",
    justification: {},
    score: 0,
    conclusions: {}
}

const ComplianceList: React.FC = () => {
    // Constants
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const [error, setError] = useState<Error | null>(null)
    const theme = useTheme()
    const navigate = useNavigate()
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [quizzes, setQuizzes] = useState<Compliance[]>([initValue])
    const ability = useContext(AbilityContext)
    const complianceService = useContext(ServicesContext).complianceService
    const licenseService = useContext(ServicesContext).licenseService
    const clientService = useContext(ServicesContext).clientService
    const [selectedClient, setSelectedClient] = useState<string>("")
    const [availableClientQuizzTypes, setAvailableClientQuizzTypes] = useState<QuizzType[]>([])
    const [resultCounts, setResultCounts] = useState<Record<ComplianceResult, number>>({
        [ComplianceResult.NonCompliant]: 0,
        [ComplianceResult.RequiredImprovements]: 0,
        [ComplianceResult.PartiallyCompliant]: 0,
        [ComplianceResult.FullyCompliant]: 0,
        [ComplianceResult.NoDocument]: 0,
        [ComplianceResult.PartialDocument]: 0,
        [ComplianceResult.FullDocument]: 0,
        [ComplianceResult.NotApplicable]: 0,
        [ComplianceResult.Yes]: 0,
        [ComplianceResult.No]: 0,
        [ComplianceResult.NoKnown]: 0
    })
    const { track, trackData } = useTrack()
    const [snackbarMessage, setSnackbarMessage] = useState<string>("")
    const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>("error")
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [license, setLicense] = useState<License | null>(null)
    const { setClientid } = useClient()
    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
    }
    // --------------------------------------------------

    // useEffect
    useEffect(() => {
        track({ view: "ComplianceList" })
        const fetch = async () => {
            try {
                const quizzes = await complianceService.getAll()
                const complianceArray = quizzes.list
                if (complianceArray.length > 0) {
                    setQuizzes(complianceArray)
                    setError(null)
                }
            } catch (e: any) {
                setSnackbarMessage(e.message)
                setSnackbarSeverity("error")
                setSnackbarOpen(true)
            }
        }
        const fetchClient = async () => {
            try {
                const client = await clientService.getAll()
                if (client.list[0].sector) {
                    setAvailableClientQuizzTypes(getComplianceTypeByClientSector(client.list[0].sector))
                }
            } catch (e: any) {
                setSnackbarMessage(e.message)
                setSnackbarSeverity("error")
                setSnackbarOpen(true)
            }
        }
        if (!ability.can("*", "Client")) {
            fetchClient()
            fetch()
        }
    }, [])
    useEffect(() => {
        const fetch = async () => {
            try {
                const queryParameters: QueryParameters = {
                    filters: [{ field: "client_id", operation: FilterOperation.UUIDEqual, value: selectedClient }]
                }
                const quizzes = await complianceService.getAll(queryParameters)
                const complianceArray = quizzes.list

                if (complianceArray.length > 0) {
                    setQuizzes(complianceArray)
                    countComplianceResults(complianceArray.flatMap((quiz) => quiz.registry))
                    setError(null)
                } else {
                    setQuizzes([]) // Limpiar el estado de quizzes
                    countComplianceResults([]) // Limpiar los conteos de resultados
                    setError({ message: "No data found" } as Error)
                    setSnackbarOpen(true)
                }
            } catch (e: any) {
                setSnackbarMessage(e.message)
                setSnackbarSeverity("error")
                setSnackbarOpen(true)
            }
        }

        const fetchClient = async (selectedClient: string) => {
            try {
                const client = await clientService.get(selectedClient)
                if (client.sector) {
                    setAvailableClientQuizzTypes(getComplianceTypeByClientSector(client.sector))
                }
            } catch (e: any) {
                setSnackbarMessage(e.message)
                setSnackbarSeverity("error")
                setSnackbarOpen(true)
            }
        }

        if (selectedClient !== "") {
            fetchClient(selectedClient)
            fetch()
        }
    }, [selectedClient])
    const fetchLicense = async () => {
        const params = { filters: [{ field: "client_id", operation: FilterOperation.UUIDEqual, value: selectedClient }] }
        const license = await licenseService.getAll(params)
        if (license.count > 0) { setLicense(license.list[0]) }
    }
    useEffect(() => {
        setClientid(selectedClient)
        fetchLicense()
    }, [selectedClient])
    // --------------------------------------------------
    // Functions
    const getComplianceType = (type: QuizzType) => {
        return quizzes.filter((q) => q.type === type)[0] || initValue
    }
    const countComplianceResults = (registry: RegistryData[]): Record<ComplianceResult, number> => {
        const resultCounts: Record<ComplianceResult, number> = {
            [ComplianceResult.NonCompliant]: 0,
            [ComplianceResult.RequiredImprovements]: 0,
            [ComplianceResult.PartiallyCompliant]: 0,
            [ComplianceResult.FullyCompliant]: 0,
            [ComplianceResult.NoDocument]: 0,
            [ComplianceResult.PartialDocument]: 0,
            [ComplianceResult.FullDocument]: 0,
            [ComplianceResult.NotApplicable]: 0,
            [ComplianceResult.Yes]: 0,
            [ComplianceResult.No]: 0,
            [ComplianceResult.NoKnown]: 0
        }

        registry.forEach(entry => {
            for (let i = 1; i <= 57; i++) {
                const key = `q${i}` as keyof RegistryData // Ensure correct key type
                const result = entry[key]

                // Ensure result is of type ComplianceResult
                if (Object.prototype.hasOwnProperty.call(resultCounts, result)) {
                    resultCounts[result as ComplianceResult]++ // Cast result to ComplianceResult
                }
            }
        })
        setResultCounts(resultCounts)

        return resultCounts
    }
    // --------------------------------------------------
    const getStatus = (quiz: Compliance) => {
        const nonEmpty = countNonEmptyQValues(quiz.registry, quiz.type) // Filtra según el tipo (CRA o FDA)
        if (quiz.type === QuizzType.CRA) {
            return nonEmpty === 38 ? QuizzStatus.Finished : QuizzStatus.Pending // 38 principales para CRA
        }
        if (quiz.type === QuizzType.FDA) {
            return nonEmpty === 9 ? QuizzStatus.Finished : QuizzStatus.Pending // FDA sigue con 9 preguntas
        }
        return QuizzStatus.Pending
    }
    const handleExport = async (quiz: Compliance) => {
        try {
            let report

            // Si el tipo es CRA
            if (quiz.type === QuizzType.CRA.toString()) {
                report = pdf(<VulnReportDocumentCompliance compliance={quiz} type="CRA" />)

                // Si el tipo es FDA
            } else if (quiz.type === QuizzType.FDA.toString()) {
                report = pdf(<VulnReportDocumentCompliance compliance={quiz} type="FDA" />)
            }

            // Generar y descargar el archivo PDF
            if (report) {
                const blob = await report.toBlob()
                const url = window.URL.createObjectURL(blob)
                window.open(url)
            }
        } catch (error: any) {
            console.error(error)
            setSnackbarMessage(error.message)
            setSnackbarSeverity("error")
            setSnackbarOpen(true)
        }
    }
    const handleCreateQuiz = async (type: QuizzType) => {
        try {
            const response = await complianceService.create({ type, registry: [initRegistry], score: 0 } as Compliance)
            const newCompliance = await response.json()
            navigate("./" + type)
        } catch (e: any) {
            setSnackbarMessage(e.message)
            setSnackbarSeverity("error")
            setSnackbarOpen(true)
        }
    }

    return (
        <Grid item xs container flexDirection="column" spacing={2} sx={{ position: "relative" }} gap={1}>
            <CustomSnackbar
                open={snackbarOpen}
                onClose={() => setSnackbarOpen(false)}
                message={snackbarMessage}
                severity={snackbarSeverity}
            />
            <Grid container sx={{ justifyContent: "space-between", alignItems: "center", paddingLeft: 2, marginBottom: 0, paddingBottom: 0 }}>
                <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                    {context.t.translate("compliance")}
                </Typography>
            </Grid>
            <Can key="client" I="*" a="Client">
                <Grid item container flexDirection="row" alignItems='center' sx={{ marginTop: 0, paddingTop: 0 }}>
                    <Grid item style={{ width: "30%" }}>
                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("client_picker")}</Typography>
                        <ClientSelector value={selectedClient} onSelect={(id) => setSelectedClient(id || "")}></ClientSelector>
                    </Grid>
                </Grid>
            </Can>
            <Grid container spacing={2} sx={{ display: "flex", flexDirection: "column", paddingLeft: 2 }}>
                {(selectedClient !== "" || !ability.can("*", "Client")) && (
                    <Grid container sx={{ paddingLeft: 2, paddingTop: 2 }} >
                        {availableClientQuizzTypes.map((quizType, index) => {
                            // Buscar si el quiz ya existe en la lista de quizzes
                            const existingQuiz = quizzes.find(quiz => quiz.type === quizType)
                            return existingQuiz?.id ? (
                                <StyledBox key={index}>
                                    <QuizCard
                                        score={existingQuiz.score}
                                        status={getStatus(existingQuiz)}
                                        resultCounts={resultCounts}
                                        totalQuestions={existingQuiz.registry.length}
                                        title={`${existingQuiz.type} ${context.t.translate("compliance")}`}
                                        onEditConclusion={() => navigate(`./${existingQuiz.type}`)}
                                        onStartQuiz={() => navigate(`./${existingQuiz.type}`)}
                                        onExport={() => handleExport(existingQuiz)}
                                        contextType="list"
                                    />
                                </StyledBox>
                            ) : (

                                <Grid key={index} container justifyContent="center" alignItems="center" marginTop={2}>
                                    <StyledBox sx={{ padding: 4, display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "center", width: "100%" }}>
                                        <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff" align="left">
                                            {`${quizType} ${context.t.translate("compliance")}`}
                                        </Typography>
                                        <Grid container direction="column" alignItems="center" sx={{ marginTop: 2 }}>
                                            <Typography variant="h5" color="textSecondary" align="center">
                                                {context.t.translate("CRA_noquiz")}
                                            </Typography>
                                            <Typography color="textSecondary" align="center">
                                                {context.t.translate("CRA_noquiz2")}
                                            </Typography>
                                            <ActionButton onClick={() => handleCreateQuiz(quizType)} text={context.t.translate("CRA_start")} style={{ marginTop: 4 }}/>
                                        </Grid>
                                    </StyledBox>
                                </Grid>

                            )
                        })}
                    </Grid>
                )}
            </Grid>

        </Grid>
    )
}

export { ComplianceList }
export default ComplianceList
