import CircularWithValueLabel from "@components/common/forms/CircularProgressWithLabel"
import QualityIndicator from "@components/common/indicator/QualityIndicator"
import GenericModal from "@components/common/modals/GenericModal"
import { useTrack } from "@components/track/TrackContext"
import ServicesContext from "@context/ServicesContext"
import BomQuality from "@models/BomQuality"
import { ExpandLess, ExpandMore } from "@mui/icons-material"
import { Card, CardContent, Chip, Divider, Grid, IconButton, Typography, useTheme } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers"
import { capitalizeFirstLowercaseRest } from "@utils/capitalizeFirstLowercaseRest"
import { I18nContext } from "I18nProvider"
import moment from "moment"
import React, { useContext, useEffect, useState } from "react"
type MyModalProps = {
    open: boolean;
    onClose: () => void;
    qualityMetrics: BomQuality
}
// eslint-disable-next-line react/display-name
export const MyModal = React.forwardRef((props: MyModalProps) => {
    const theme = useTheme()
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    // Tracking
    const { track, trackData } = useTrack()
    useEffect(() => {
        track({ view: "SBOMQuality" })
    }, [])
    const fileService = useContext(ServicesContext).fileService
    const styleScroll = {
        overflowY: "auto",
        height: "60%",
        "&::-webkit-scrollbar": {
            width: "12px"
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.primary.main,
            borderRadius: "6px"
        },
        "&::-webkit-scrollbar-track": {
            backgroundColor: "transparent"
        }
    }

    const scoresByCategory = props.qualityMetrics.scores.reduce((acc: { [key: string]: any[] }, score: any) => {
        if (!acc[score.category]) {
            acc[score.category] = []
        }
        acc[score.category].push(score)
        return acc
    }, {})
    const averageScoresByCategory: { [key: string]: number } = {}
    React.useEffect(() => {
        for (const category in scoresByCategory) {
            const scores = scoresByCategory[category]
            const totalScores = scores.length
            const sumScores = scores.reduce((total: number, score: any) => total + score.score, 0)
            const averageScore = sumScores / totalScores
            averageScoresByCategory[category] = averageScore
        }
    }, [props.qualityMetrics])
    const categories = ["Structural", "NTIA-minimum-elements", "Semantic", "Quality", "Sharing"] as const
    type Category = typeof categories[number]
    const [expandedCategories, setExpandedCategories] = useState({
        Structural: false,
        "NTIA-minimum-elements": false,
        Semantic: false,
        Quality: false,
        Sharing: false
    })

    const handleExpandClick = (category: Category) => {
        setExpandedCategories((prev) => ({
            ...prev,
            [category]: !prev[category]
        }))
    }

    interface ScoreChipProp {
        value: number
    }

    const ScoreChip: React.FC<ScoreChipProp> = ({ value }) => {
        return (
            <Typography variant="h6" color={theme.palette.secondary.contrastText} fontWeight="bolder" sx={{ textAlign: "end", alignItems: "center" }}>{value}</Typography>
        )
    }
    return (
        <GenericModal title= {context.t.translate("SBOM_quality")} open={props.open} onClose={props.onClose}>
            <Grid container item flexDirection="column" spacing="20px" padding="25px">
                <Divider sx={{ height: "1px", width: "100%", background: theme.palette.secondary.dark, mb: "25px" }} />
                <Grid container item xs={12} spacing={2} direction="row" justifyContent="space-between">
                    <Grid item xs={6} mx="0px" mb="25px">
                        <Card sx={{ minWidth: 275, minHeight: 100 }}>
                            <CardContent>
                                <Grid container item xs={12} spacing={2} direction="row" justifyContent="space-between">
                                    <Grid item xs={6} style={{ alignContent: "center" }}>
                                        <Typography variant="h5" component="div" >{context.t.translate("quality_score")}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <CircularWithValueLabel progress={props.qualityMetrics.avg_score} />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={6} mx="0px" mb="25px">
                        <Card sx={{ minWidth: 275, minHeight: 100 }}>
                            <CardContent>
                                <Grid container item xs={12} spacing={2} direction="row" justifyContent="space-between">
                                    <Grid item xs={6} style={{ alignContent: "center" }}>
                                        <Typography variant="h5" component="div">{context.t.translate("scanned_at")}</Typography>
                                    </Grid>
                                    <Grid item xs={6} style={{ alignContent: "center" }}>
                                        <DatePicker
                                            label=""
                                            disabled
                                            value={props.qualityMetrics.timestamp != null ? moment(props.qualityMetrics.timestamp, "YYYY-MM-DDTHH:mm:ssZ") : null}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Divider sx={{ height: "1px", width: "100%", background: theme.palette.secondary.dark }} />
            </Grid>
            <Grid container item className="scroll-container" sx={{ ...styleScroll, paggingLeft: "50px" }}>
                {Object.entries(scoresByCategory).map(([category, scores]) => (
                    <Grid key={"category_" + category} container item flexDirection="column" spacing="10px" mr={2}>
                        <Grid container item xs={12} spacing={2} direction="row" justifyContent="space-between">
                            <Grid item xs={12} mx="0px" mb="25px">
                                <Card sx={{
                                    minWidth: 275,
                                    marginLeft: "22px",
                                    marginRight: "7px"
                                }}>
                                    <CardContent sx={{
                                        padding: "3px",
                                        maxHeight: expandedCategories[category as Category] ? "auto" : "22px",
                                        overflow: expandedCategories[category as Category] ? "auto" : "hidden",
                                        transition: "max-height 0.3s ease-in-out"
                                    }}>
                                        <Grid container item xs={12} spacing={2} direction="row" justifyContent="space-between" padding="20px" sx={{ marginTop: "-30px" }}>
                                            <Grid item xs={10} >
                                                <Typography variant="h5" component="div" color="primary" fontWeight="bolder">{category}
                                                    <IconButton size="small" component="span" onClick={() => handleExpandClick(category as Category)}>
                                                        {expandedCategories[category as Category] ? <ExpandLess /> : <ExpandMore />}
                                                    </IconButton>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Chip style={{ width: "80px" }} color="primary" label={<ScoreChip value={parseFloat(
                                                    (props.qualityMetrics.scores.filter((score: any) => score.category === category).reduce((total: any, score: any) => total + score.score, 0) /
                                                            props.qualityMetrics.scores.filter((score: any) => score.category === category).length).toFixed(2)
                                                )} />} />
                                            </Grid>
                                        </Grid>
                                        {expandedCategories[category as Category] &&
                                                scores.map((score, idx) => (
                                                    <Grid key={"score_" + idx} container item xs={12} spacing={2} direction="row" justifyContent="space-between" paddingLeft="20px">
                                                        <Grid item xs={9}>
                                                            <Typography component="div" color="secondary">
                                                                {capitalizeFirstLowercaseRest(score.description)}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Typography
                                                                variant="h5"
                                                                color="primary"
                                                                fontWeight="bolder"
                                                                sx={{ textAlign: "end", paddingRight: "20px", alignItems: "center" }}
                                                            >
                                                                <QualityIndicator value={score.score} />
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                ))}
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </GenericModal>
    )
})
MyModal.displayName = "Mymodal"
