import React, { useContext, useEffect, useState } from "react"
import { Image, Page, Text, View } from "@react-pdf/renderer"
import Template from "@models/Template"
import { I18nContext } from "I18nProvider"
import Vulnerability, { getVulnerabilitySeverity, VulnerabilitySeverity } from "@models/Vulnerability"
import styles, { globalStyles } from "../Styles"
import { Footer, Header } from "./HeaderFooter"
import ProgressCircle from "./ProgressCircle"

type introProps = {
    executiveSummaryTitle: string;
    objective: string;
    assessment: string;
    findings: string;
    critical: string;
    high: string;
    medium: string;
    low: string;
    riskLevel: string;
    vulnerabilityDetails: string;
    vulnerabilitySection: string;
    suggestion: {
        [key in VulnerabilitySeverity]: { color: string; label: string; subtext: string };
    }
}

const i18n: {
    es: introProps;
    en: introProps;
} = {
    es: {
        executiveSummaryTitle: "Resumen Ejecutivo",
        objective: "El objetivo de la evaluación es utilizar nuestra metodología de análisis para identificar vulnerabilidades del sistema y evaluar su nivel de ciberseguridad.",
        assessment: "La evaluación proporciona información sobre los mecanismos de seguridad de la aplicación para resistir ataques de usuarios no autorizados y la posibilidad de que los usuarios válidos abusen de sus privilegios y acceso.",
        findings: "El número de vulnerabilidades descubiertas durante el ejercicio fue ",
        critical: "críticas",
        high: "altas",
        medium: "medias",
        low: "bajas",
        riskLevel: "El estado actual de las vulnerabilidades identificadas establece el riesgo en un nivel ",
        vulnerabilityDetails: "Una descripción más detallada de las vulnerabilidades se puede encontrar en la sección ",
        vulnerabilitySection: "Identificación de vulnerabilidades",
        suggestion: {
            critical: { color: "black", label: "CRÍTICO", subtext: ", que si no se aborda lo antes posible, podría desencadenar una brecha de ciberseguridad." },
            high: { color: "#D9534F", label: "ALTO", subtext: ", que si no se aborda a tiempo, podría desencadenar una brecha de ciberseguridad." },
            medium: { color: "#FF8537", label: "MEDIO", subtext: ", que si no se corrige, podría desencadenar una brecha de ciberseguridad." },
            low: { color: "#A8D1E7", label: "BAJO", subtext: ", que si no se evalúa más a fondo, podría desencadenar una brecha de ciberseguridad." },
            none: { color: "grey", label: "PROTEGIDO", subtext: ", y se recomienda un análisis más exhaustivo." },
            unknown: { color: "grey", label: "DESCONOCIDO", subtext: ", y se recomienda un análisis más exhaustivo." }
        }
    },
    en: {
        executiveSummaryTitle: "Executive Summary",
        objective: "The objective of the evaluation is to use our analysis methodology to identify system vulnerabilities and evaluate its level of cybersecurity.",
        assessment: "The assessment provides insight into the application’s security mechanisms to resist attacks from unauthorized users and the potential for valid users to abuse their privileges and access.",
        findings: "The number of vulnerabilities discovered during the exercise was ",
        critical: "critical",
        high: "high",
        medium: "medium",
        low: "low",
        riskLevel: "The current state of the identified vulnerabilities establishes the risk at a level ",
        vulnerabilityDetails: "A more detailed description of the vulnerabilities can be found in the section ",
        vulnerabilitySection: "Identification of vulnerabilities",
        suggestion: {
            critical: { color: "black", label: "CRITICAL", subtext: ", which, if not addressed as soon as possible, these vulnerabilities could trigger a cybersecurity breach." },
            high: { color: "#D9534F", label: "HIGH", subtext: ", which, if not addressed in time, these vulnerabilities could trigger a cybersecurity breach." },
            medium: { color: "#FF8537", label: "MEDIUM", subtext: ", which, if not corrected, these vulnerabilities could trigger a cybersecurity breach." },
            low: { color: "#A8D1E7", label: "LOW", subtext: ", which, if not evaluated more thoroughly, these vulnerabilities could trigger a cybersecurity breach." },
            none: { color: "grey", label: "PROTECTED", subtext: ", and a more thorough analysis is recommended." },
            unknown: { color: "grey", label: "UNKNOWN", subtext: ", and a more thorough analysis is recommended." }
        }
    }
} as const

interface SectionProps {
    lang?: string;
    template?: Template;
    vulnerabilities: Vulnerability[];
    client?: string
}

type SeverityCount = {
    [key in VulnerabilitySeverity]: number;
}

const ExecutiveSection: React.FC<SectionProps> = ({ vulnerabilities, template, lang, client }) => {
    const context = useContext(I18nContext)
    let locale: keyof typeof i18n = "en" // Idioma por defecto
    if (lang) {
        if (lang === "es" || lang === "en") {
            locale = lang as keyof typeof i18n // Usamos el valor de `lang`
        } else {
            console.warn(`Idioma no soportado: ${lang}. Usando idioma por defecto.`)
        }
    } else if (context) {
        locale = context.language as keyof typeof i18n // Usamos el valor del contexto si existe
    } else {
        console.error("El contexto I18n no está inicializado. Asegúrese de que tiene el proveedor configurado correctamente.")
    }

    // Definir el objeto 'suggestion' basado en el idioma actual
    const suggestion = i18n[locale].suggestion

    const [severityCount, setSeverityCount] = useState<SeverityCount>({ critical: 0, high: 0, medium: 0, low: 0, none: 0, unknown: 0 })
    const [severityLevel, setSeverityLevel] = useState<keyof SeverityCount>(VulnerabilitySeverity.Unknown)
    useEffect(() => {
        const counts: SeverityCount = { critical: 0, high: 0, medium: 0, low: 0, none: 0, unknown: 0 }
        let maxSeverityRegistered: keyof SeverityCount = VulnerabilitySeverity.None
        vulnerabilities.forEach((vulnerability) => {
            counts[getVulnerabilitySeverity(vulnerability.score)]++
        })
        for (const severity in counts) {
            if (counts[severity as keyof SeverityCount] > 0) {
                maxSeverityRegistered = severity as keyof SeverityCount
                break
            }
        }
        setSeverityCount(counts)
        setSeverityLevel(maxSeverityRegistered)
    }, [vulnerabilities])

    return (
        <Page size="A4">
            <Header externalData={null} template={template}></Header>
            <View style={{ ...globalStyles.section }}>
                <View style={globalStyles.column}>
                    <View style={globalStyles.container}>
                        <Text style={{ ...styles.titulo, fontFamily: "Bold" }}>{i18n[locale].executiveSummaryTitle}</Text>
                        <Text style={{ ...styles.textua, marginRight: 20, fontFamily: "Griff", fontSize: 10 }}>{i18n[locale].objective}</Text>
                        <Text style={{ ...styles.textua, marginRight: 20, fontFamily: "Griff", fontSize: 10 }}>{i18n[locale].assessment}</Text>
                        <Text style={{ ...styles.textua, marginRight: 20, fontFamily: "Griff", fontSize: 10 }}>
                            {i18n[locale].findings}
                            <Text style={{ fontFamily: "Bold" }}>{severityCount.critical} {i18n[locale].critical}</Text>,
                            <Text style={{ fontFamily: "Bold", color: "red" }}> {severityCount.high} {i18n[locale].high}</Text>,
                            <Text style={{ fontFamily: "Bold", color: "#FF8537" }}> {severityCount.medium} {i18n[locale].medium}</Text> and
                            <Text style={{ fontFamily: "Bold", color: "#A8D1E7" }}> {severityCount.low} {i18n[locale].low}</Text>.
                        </Text>
                        <Text style={{ ...styles.textua, marginRight: 20, fontFamily: "Griff", fontSize: 10 }}>
                            {i18n[locale].riskLevel}
                            <Text style={{ fontFamily: "Bold", color: suggestion[severityLevel].color }}>{suggestion[severityLevel].label}</Text>
                            {suggestion[severityLevel].subtext}
                        </Text>
                        <Text style={{ ...styles.textua, marginRight: 20, fontFamily: "Griff", fontSize: 10 }}>
                            {i18n[locale].vulnerabilityDetails} <Text style={{ fontFamily: "Bold" }}>{i18n[locale].vulnerabilitySection}</Text>.
                        </Text>
                    </View>
                </View>
                <View style={{ ...globalStyles.column, marginRight: "-20px", marginTop: "-30px", marginBottom: "-300px", backgroundColor: "#fffa37" }}>
                    <View style={globalStyles.container}>
                        <View> <Image style={{ ...styles.rightImage, marginLeft: 20, paddingTop: 0, marginTop: 39.5, height: 90, width: 48, backgroundColor: "yellow" }} src="/assets/img/report/Arrows.png" /></View>
                        <View> <Text style={{ ...styles.titulo, paddingLeft: 70, marginTop: 6, fontFamily: "Bold", fontSize: 16 }}>Vulnerabilities: </Text>
                            <Text style={{ ...styles.titulo, paddingLeft: 70, fontFamily: "Griff", fontSize: 12, paddingTop: 0 }}><Text style={{ fontFamily: "Bold", fontSize: 22 }}>{severityCount.critical}</Text> {i18n[locale].critical}</Text>
                            <Text style={{ ...styles.titulo, paddingLeft: 70, fontFamily: "Griff", fontSize: 12, paddingTop: 0 }}><Text style={{ fontFamily: "Bold", fontSize: 22 }}>{severityCount.high}</Text> {i18n[locale].high}</Text>
                            <Text style={{ ...styles.titulo, paddingLeft: 70, fontFamily: "Griff", fontSize: 12, paddingTop: 0 }}><Text style={{ fontFamily: "Bold", fontSize: 22 }}>{severityCount.medium}</Text> {i18n[locale].medium}</Text>
                            <Text style={{ ...styles.titulo, paddingLeft: 70, fontFamily: "Griff", fontSize: 12, paddingTop: 0 }}><Text style={{ fontFamily: "Bold", fontSize: 22 }}>{severityCount.low}</Text> {i18n[locale].low}</Text></View>

                        <Text style={{ marginLeft: 70, marginRight: 0, paddingTop: 20, paddingRight: 30, textAlign: "left", fontFamily: "Griff", fontSize: 15 }}>
                            {i18n[locale].riskLevel}
                            <Text style={{ fontFamily: "Bold", color: suggestion[severityLevel].color }}>{suggestion[severityLevel].label}</Text>
                        </Text>
                        <View style={{ ...globalStyles.container, paddingTop: 10 }}>
                            <ProgressCircle size={140} innerCircleRatio={0.5} segments={[
                                { label: "Critical", color: "#000000", value: severityCount.critical },
                                { label: "High", color: "#D9534F", value: severityCount.high },
                                { label: "Medium", color: "#FF8537", value: severityCount.medium },
                                { label: "Low", color: "#A8D1E7", value: severityCount.low }
                            ]} />
                        </View>
                    </View>
                </View>
            </View>
            <Footer externalData={{ clientName: client }} template={template}></Footer>
        </Page>
    )
}

export { ExecutiveSection }
