import React, { useContext, useEffect, useState } from "react"
import { AlertColor, Chip, FormGroup, Grid, IconButton, Tooltip, Typography, useTheme } from "@mui/material"
import { GridColDef, GridRowClassNameParams, GridRowParams } from "@mui/x-data-grid"
import { useNavigate } from "react-router-dom"
import { useAuth } from "react-oidc-context"
import { FaTags } from "react-icons/fa"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import { CustomAction, GenericTable } from "@components/common/tables/index"
import { convertModelDefToGridColDef } from "@components/common/tables/utils"
import { AbilityContext, Can } from "@components/permissions/index"
import { ServicesContext } from "@context/index"
import { Product } from "@models/index"
import Tag from "@models/Tag"
import { IterableList } from "@models/iterable"
import { FilterOperation, QueryParameters } from "@utils/index"
import { useTrack } from "@components/track/TrackContext"
import { I18nContext } from "I18nProvider"
import ProductTableDefinition, { ProductTableDefinitionES } from "@components/product/ProductTableDefinition"
import { MdCloudDownload } from "react-icons/md"
import VulnReportDocumentGeneric from "@components/report/VulnReportDocumentGeneric"
import { pdf } from "@react-pdf/renderer"
import ActionButton from "@components/common/Button/ActionButton"
import StatsCard from "@components/common/stats/StatsCard"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import CreateTagModal from "./Modals/CreateTagModal"
import AddTagModal from "./Modals/AddTagModal"
import VisualizeTagModal from "./Modals/VisualizeTagModal"

const initValue: Tag = {
    id: "",
    name: "",
    description: "",
    color: "",
    products: []
}

const ProductList: React.FC = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const theme = useTheme()
    const productService = useContext(ServicesContext).productService
    const tagService = useContext(ServicesContext).tagService
    const vulnerabilityService = useContext(ServicesContext).vulnerabilityService
    const [tagNameList, setTagNameList] = useState<{ id: string; name: string; color: string; description: string; client_id: string; products: string[] }[]>([])
    const [createTagOpen, setCreateTagOpen] = React.useState(false)
    const [addTagOpen, setAddTagOpen] = React.useState(false)
    const [visualizeTagOpen, setvisualizeTagOpen] = React.useState(false)
    const [updateTags, setUpdateTags] = useState(false)
    const [formTagData, setFormTagData] = useState<Tag>(initValue)
    const [productId, setProductID] = useState<string>("")
    const [productName, setProductName] = useState<string>("")
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState<string>("")
    const [severity, setSeverity] = useState<AlertColor>()
    const [selectedRow, setSelectedRow] = useState<string[]>([])
    const [isFormValid, setIsFormValid] = useState(false)

    const [cols, setCols] = useState<GridColDef[]>([])
    const [error, setError] = useState<Error | null>(null)
    const navigate = useNavigate()
    const auth = useAuth()
    const ability = useContext(AbilityContext)

    // Tracking
    const { track, trackData } = useTrack()
    useEffect(() => {
        track({ view: "ProductList" })
    }, [])

    useEffect(() => {
        // Seleccionar la definición de la tabla según el idioma
        const policyTableDefinition = context.language === "es" ? ProductTableDefinitionES : ProductTableDefinition
        setCols(convertModelDefToGridColDef(policyTableDefinition, ability))
    }, [context.language, ability]) // Añadir 'context.language' a las dependencias

    const handleCreateTagOpen = () => setCreateTagOpen(true)
    const handleCreateTagClose = () => {
        setCreateTagOpen(false)
        setFormTagData(initValue)
    }

    const handleAddTagOpen = async (params: any) => {
        setAddTagOpen(true)
        setProductID(params.id)
    }

    const handleAddTagClose = () => {
        setAddTagOpen(false)
    }
    const handleTagCreated = (message: string, severity: AlertColor) => {
        setSnackbarMessage(message)
        setSeverity(severity)
        setSnackbarOpen(true)
        setCreateTagOpen(false)
    }
    const handleVisualizeTagOpen = async (params: any) => {
        try {
            const filters: QueryParameters = { sortField: "name" }
            const data = await tagService.getAll(filters)
            const filteredList = data.list.filter(item => item.products && item.products.includes(params.id))
            const filteredTagNames = filteredList.map(item => ({
                name: item.name,
                color: item.color,
                products: item.products,
                client_id: params.client.id,
                description: item.description,
                id: item.id
            }))
            setTagNameList(filteredTagNames)
            setSelectedRow(params)
            setProductName(params.name)
            setvisualizeTagOpen(true) // Abrir modal solo después de cargar los datos
        } catch (e) {
            setError(e as Error)
        }
    }

    const handleVisualizeTagClose = () => {
        setvisualizeTagOpen(false)
        setTagNameList([])
    }

    const fetchData = async (): Promise<IterableList<Tag> | undefined> => {
        try {
            const filters: QueryParameters = { sortField: "name" }
            return await tagService.getAll(filters)
        } catch (e) {
            setError(e as Error)
            return undefined
        }
    }
    useEffect(() => {
        const { name, description, color } = formTagData
        if (name && description && color) {
            setIsFormValid(true)
        } else {
            setIsFormValid(false)
        }
    }, [formTagData])
    useEffect(() => {
        const init = async () => {
            // setLoadingPromises(true)
            try {
                const result = await fetchData()
                let tagList: Tag[]

                if (result !== undefined) {
                    tagList = result?.list
                }

                const newCol = convertModelDefToGridColDef(ProductTableDefinition, ability)
                newCol.push({
                    field: "Tags",
                    type: "tags",
                    headerName: "Tags",
                    flex: 0.1,
                    minWidth: 280,
                    renderCell: (params) => {
                        if (ability.can("read", "Product")) {
                            const tagList2 = tagList && tagList.filter(tag => tag.products && tag.products.some(item => item === params.id))
                            return (
                                <Grid style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    {tagList2 && tagList2.length >= 1
                                        ? (
                                            <>
                                                {tagList2.slice(0, 2).map((chip, index) => (
                                                    <Chip
                                                        key={index}
                                                        label={chip.name}
                                                        variant="outlined"
                                                        style={{ color: theme.palette.secondary.contrastText, backgroundColor: chip.color, marginRight: "5px" }}
                                                        deleteIcon={<HighlightOffIcon style={{ color: theme.palette.secondary.contrastText }} />}
                                                        onDelete={() => handleDelete(chip, params.row)}
                                                    />
                                                ))}
                                                <MoreVertIcon onClick={() => handleVisualizeTagOpen && handleVisualizeTagOpen(params.row)} sx={{ color: theme.palette.primary.main, cursor: "pointer", "&:hover": { backgroundColor: theme.palette.primary.main, color: theme.palette.secondary.contrastText, borderRadius: "50%" } }} fontSize="small" />
                                            </>
                                        )
                                        : (
                                            tagList2 && tagList2.map((chip, index) => (
                                                <Chip
                                                    key={index}
                                                    label={chip.name}
                                                    variant="outlined"
                                                    style={{ color: theme.palette.secondary.contrastText, backgroundColor: chip.color }}
                                                    deleteIcon={<HighlightOffIcon style={{ color: theme.palette.secondary.contrastText }} />}
                                                    onDelete={() => handleDelete(chip, params)}
                                                />
                                            ))
                                        )}
                                </Grid>
                            )
                        }
                        return (
                            <div>
                                {params.value}
                            </div>

                        )
                    }

                })
                setCols(newCol)
            } catch (e: any) {
                setError(e as Error)
            }
            // setLoadingPromises(false)
        }
        init()
    }, [updateTags])
    const handleDelete = async (chip: any, params: any) => {
        const { id: productId } = params
        const updatedProducts = chip.products.filter((productID: string) => productID !== productId)
        const updatedChip = { ...chip, products: updatedProducts }
        try {
            await tagService.update(updatedChip.id, updatedChip)
            setTagNameList((prevList) => prevList.filter(tag => tag.id !== chip.id))
            setError(null)
        } catch (e: any) {
            setError({ message: e.error } as Error)
        }
        setUpdateTags(prev => !prev)
    }

    const handleGenerateReport = async (id: string) => {
        const params: QueryParameters = { filters: [{ field: "product_id", operation: FilterOperation.UUIDEqual, value: id }] }
        const vulnerabilityList = await vulnerabilityService.getAllType(params, "csv")
        const report = pdf(<VulnReportDocumentGeneric data={vulnerabilityList.list} />)
        const blob = await report.toBlob()
        const url = window.URL.createObjectURL(blob)
        window.open(url)
    }

    const customActions = (params: GridRowParams<Product>) => {
        const actions: CustomAction[] = []

        if ((params.row as any).num_vulnerability > 0) {
            actions.push(
                {
                    icon: <Tooltip title="Generate report">
                        <IconButton size="small" onClick={() => handleGenerateReport(params.row.id)}>
                            <MdCloudDownload />
                        </IconButton>
                    </Tooltip>,
                    visualize: true
                }
            )
        }

        actions.push(
            {
                icon: <Can key="update" I="update" an="Product" >
                    <Tooltip title="Add tags">
                        <IconButton size="small" component="label" onClick={() => handleAddTagOpen(params.row)}>
                            <FaTags />
                        </IconButton>
                    </Tooltip>
                </Can>,
                visualize: true
            }
        )

        return actions
    }

    return (
        <><Grid container sx={{ justifyContent: "space-between", alignItems: "center", spacing: "20px", paddingLeft: "20px", marginBottom: "0px", paddingBottom: "0px" }}>
            <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                {context.t.translate("products")}
            </Typography>
            <FormGroup>
                <Grid item sx={{ display: "flex", gap: 2 }}>
                    <ActionButton onClick={handleCreateTagOpen} text={context.t.translate("product_newtag")} />
                    <Can I="create" a="Product">
                        <ActionButton onClick={() => navigate("./add")} text={context.t.translate("product_newproduct")} />
                    </Can>
                </Grid>
            </FormGroup>
        </Grid>
        <CustomSnackbar
            open={snackbarOpen}
            onClose={() => setSnackbarOpen(false)}
            message={snackbarMessage}
            severity={severity}
        />
        <StatsCard title={context.t.translate("products")} entity="Product" />
        <Grid item xs container flexDirection="column" spacing="20px" sx={{ position: "relative" }}>
            <Grid item container flexDirection="column" rowGap="35px">

                <GenericTable<Product> entity="Product" columns={cols}
                    dataProvider={(filter) => {
                        filter.sortField = filter.sortField === "" || filter.sortField === undefined ? "updated_at" : filter.sortField
                        filter.sortMode = filter.sortMode === undefined ? "desc" : filter.sortMode
                        return productService.getAllExt(filter)
                    }}
                    onEdit={(elem: Product) => navigate("./" + elem.id)}
                    onDelete={(elem: Product) => productService.delete(elem.id)}
                    handleAddTagOpen={handleAddTagOpen}
                    handleVisualizeTagOpen={handleVisualizeTagOpen}
                    withTags={true}
                    customActions={customActions}
                    dataGridProps={{
                        getRowClassName: (params: GridRowClassNameParams) =>
                            params.row.supressed ? "suppressedRow" : "",
                        sx: { "& .suppressedRow .MuiDataGrid-cell": { opacity: 0.5 } }
                    }}/>
            </Grid>
            <CreateTagModal
                open={createTagOpen}
                onClose={handleCreateTagClose}
                formTagData={formTagData}
                setFormTagData={setFormTagData}
                isFormValid={isFormValid}
                onTagDeleted={() => setUpdateTags(prev => !prev)}
                onTagCreated={handleTagCreated} />
            <AddTagModal
                open={addTagOpen}
                onClose={handleAddTagClose}
                productId={productId}
                onTagAdded={() => setUpdateTags(prev => !prev)}
                setError={setError} />
            <VisualizeTagModal
                open={visualizeTagOpen}
                onClose={handleVisualizeTagClose}
                selectedRow={selectedRow}
                tagNameList={tagNameList}
                onDeleteTag={handleDelete}
                productName={productName} />
        </Grid></>
    )
}

export { ProductList }
export default ProductList
