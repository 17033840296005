import React, { useCallback, useContext, useEffect, useState } from "react"
import { NavigateFunction, useNavigate } from "react-router-dom"
import Grid from "@mui/material/Grid"
import { GridColDef, GridFilterItem, GridRowClassNameParams, GridRowSelectionModel, GridState, useGridApiRef } from "@mui/x-data-grid"
import { Typography, useTheme } from "@mui/material"
import { format } from "date-fns"
import { convertModelDefToGridColDef, GenericTable } from "@components/common/tables/index"
import { AbilityContext } from "@components/permissions/index"
import { VulnerabilityTableDefinition } from "@components/vulnerability/index"
import { ServicesContext } from "@context/index"
import { Vulnerability } from "@models/index"
import { FilterOperation, FilterOption, QueryParameters } from "@utils/queryParams"
import { useTrack } from "@components/track/TrackContext"
import { pdf } from "@react-pdf/renderer"
import VulnReportDocumentGeneric from "@components/report/VulnReportDocumentGeneric"
import { I18nContext } from "I18nProvider"
import ModalGeneric from "@components/common/modals/GenericModal"
import ActionButton from "@components/common/Button/ActionButton"
import StatsCard from "@components/common/stats/StatsCard"

const VulnerabilityList: React.FC = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const theme = useTheme()
    const vulnerabilityService = useContext(ServicesContext).vulnerabilityService

    const navigate: NavigateFunction = useNavigate()
    const [cols, setCols] = useState<GridColDef[]>([])
    const { track, trackData } = useTrack()

    const ability = useContext(AbilityContext)
    useEffect(() => {
        track({ view: "VulnerabilityList" })
        setCols(convertModelDefToGridColDef(VulnerabilityTableDefinition, ability, ["rank"]))
    }, [])

    const RequestAnalyze = async () => {
        try {
            const response = await vulnerabilityService.analyze()
        } catch (error) {
            console.error(error)
        }
    }

    const apiRef = useGridApiRef()
    const [params, setParams] = useState<QueryParameters>({})

    const handleStateChange = useCallback(
        (state: GridState) => {
            if (state.filter.filterModel.items.every((filterItem: GridFilterItem) => filterItem.field !== undefined && filterItem.operator !== undefined && filterItem.value !== undefined)) {
                const newFilters: FilterOption[] = state.filter.filterModel.items.map((filterItem: GridFilterItem) => ({
                    field: filterItem.field.toString(),
                    operation: filterItem.operator as FilterOperation,
                    value: filterItem.value.toString()
                }))
                setParams((prevParams) => {
                    const filtersChanged = JSON.stringify(prevParams.filters) !== JSON.stringify(newFilters)
                    if (filtersChanged) {
                        return {
                            ...prevParams,
                            filters: newFilters
                        }
                    }
                    return prevParams
                })
            }
        },
        []
    )

    useEffect(() => {
        const unsubscribe = apiRef.current.subscribeEvent("stateChange", handleStateChange)
        return () => unsubscribe()
    }, [apiRef, handleStateChange])

    const HandleExport = async () => {
        try {
            if (rowSelectionModel.length > 0) {
                params.filters = [{
                    field: "id",
                    operation: FilterOperation.UUIDArrayContains,
                    value: rowSelectionModel.join(",")
                }]
            }
            const vulnerabilityList = await vulnerabilityService.getAllType(params, "csv")
            const report = pdf(<VulnReportDocumentGeneric data={vulnerabilityList.list}/>)
            const blob = await report.toBlob()
            const url = window.URL.createObjectURL(blob)
            window.open(url)
        } catch (error) {
            console.error(error)
        }
    }

    const getAllVulns = async () => {
        try {
            const filters: QueryParameters = { sortField: "name", sortMode: "desc" }
            const response = await vulnerabilityService.getAllType(filters, "csv")

            if (response.list.length === 0) {
                console.error("No data to export 1.")
                return
            }
            // Generar encabezados CSV
            const headers = ["identifier", "state", "score", "revised_score", "priority", "epss", "kev", "vector", "client", "product", "description"].join(";")

            const safeValue = (value: any) => (value === null || value === undefined ? "" : value)

            // Generar contenido CSV
            const rows = response.list.map((vuln: { identifier: any; state: any; score: any; revised_score: any; priority: any; epss: any; kev: any; vector: any; client: { name: any }; product: { name: any }; description: string }) =>
                [safeValue(vuln.identifier), safeValue(vuln.state), safeValue(vuln.score), safeValue(vuln.revised_score), safeValue(vuln.priority), safeValue(vuln.epss), safeValue(vuln.kev), safeValue(vuln.vector), safeValue(vuln.client.name), safeValue(vuln.product.name), safeValue(vuln.description.replace(/;/g, "."))].join(";")
            ).join("\n")

            const csvContent = `data:text/csv;charset=utf-8,${headers}\n${rows}`
            const encodedUri = encodeURI(csvContent)
            const link = document.createElement("a")
            link.setAttribute("href", encodedUri)
            const date = Date.now()
            const time = format(date, "MMMM do, yyyy H:mma")
            link.setAttribute("download", "cves_" + time + ".csv")
            document.body.appendChild(link) // Requerido para Firefox
            link.click()
            document.body.removeChild(link)
        } catch (error) {
            console.error(error)
            // return undefined; // Retorna undefined en caso de error
        }
    }
    const exportToCSV = () => {
        getAllVulns()
    }

    // Tracking
    useEffect(() => {
        track({ view: "VulnerabilityList" })
    }, [])
    const [pruebaOpen, setPruebaOpen] = React.useState(false)
    const handleCreateTagOpen = () => setPruebaOpen(true)
    const handlePruebaClose = () => setPruebaOpen(false)
    const productService = useContext(ServicesContext).productService
    const assessmentService = useContext(ServicesContext).assessmentService
    const dataProvider = (filter: any) => {
        return assessmentService.getAllExt(filter)
    }
    const [column, setColumn] = useState<GridColDef[]>([])
    const fields = [
        { type: "select", name: "field1", options: [{ value: "1", label: "Option 1" }] },
        { type: "select", name: "field2", options: [{ value: "2", label: "Option 2" }] },
        { type: "text", name: "field3", options: [{ value: "3", label: "Option 3" }] }
    ]

    // const ability = useContext(AbilityContext)
    // useEffect(() => {
    //     setColumn(convertModelDefToGridColDef(VulnerabilityTableDefinition, ability))
    // }, [])

    const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([])

    const handleExportPDF = async () => {
        try {
            const customParams = { ...params }
            if (rowSelectionModel.length > 0) {
                customParams.filters = [{ field: "id", operation: FilterOperation.UUIDArrayContains, value: rowSelectionModel.join(",") }]
            } else {
                customParams.page_number = 0
                customParams.page_size = 100
                customParams.filters = [{ field: "supressed", operation: FilterOperation.StringEqual, value: "false" }]
            }

            const vulnerabilityList = await vulnerabilityService.getAll(customParams)

            const report = pdf(<VulnReportDocumentGeneric data={vulnerabilityList.list}/>)
            const blob = await report.toBlob()
            const url = window.URL.createObjectURL(blob)
            window.open(url)
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <Grid item xs container flexDirection="column" spacing="20px" sx={{ position: "relative" }}>
            <Grid container sx={{ justifyContent: "space-between", alignItems: "center", spacing: "20px", paddingLeft: "20px", marginBottom: "0px", paddingBottom: "0px" }}>
                <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                    {context.t.translate("vulnerabilities")}
                </Typography>
                <Grid item sx={{ display: "flex", gap: 2 }}>
                    <ActionButton onClick={handleExportPDF} text="Export to PDF" /> {/* TODO: Translate */}
                    {/* <SplitButton options={[
                        { label: "Export to PDF", action: handleExportPDF }
                        // { label: "Export to XML", action: handleExportXML } // TODO: Implementar exportación a XML
                    ]}></SplitButton> */}
                </Grid>
            </Grid>
            <StatsCard title= {context.t.translate("vulnerabilities")} entity="Vulnerability"/>
            {/* <Button variant="contained" sx={{ position: "absolute", top: "-25px", right: "0px", color: theme.palette.secondary.contrastText }} onClick={RequestAnalyze} startIcon={<TbAnalyze/>}>Reanalyze</Button> "170px" */}
            {/* <Button variant="contained" sx={{ position: "absolute", top: "-25px", right: "0px", color: theme.palette.secondary.contrastText }} onClick={HandleExport} startIcon={<FaFileExport />}>{context.t.translate("dash_export")}</Button> "170px" */}
            {/* <ButtonMenu mainActions={} additionalActions={}></ButtonMenu> */}
            {/* <Button variant="contained" sx={{ position: "absolute", top: "-25px", right: "0px" }} onClick={exportToCSV} startIcon={<FaFileExport/>}>Export Data</Button> */}
            <Grid item container flexDirection="column">
                <Grid item container>
                    <GenericTable<Vulnerability> entity="Vulnerability" columns={cols}
                        dataProvider={(filter) => {
                            filter.sortField = filter.sortField === "" || filter.sortField === undefined ? "revised_score" : filter.sortField
                            filter.sortMode = filter.sortMode === undefined ? "desc" : filter.sortMode
                            return vulnerabilityService.getAll(filter)
                        }}
                        onEdit={(elem: Vulnerability) => navigate("./" + elem.id, { state: { row: elem } })}
                        onDelete={(elem: Vulnerability) => vulnerabilityService.delete(elem.id)}
                        dataGridProps={{
                            onStateChange: handleStateChange,
                            getRowClassName: (params: GridRowClassNameParams) =>
                                params.row.supressed ? "suppressedRow" : "",
                            sx: { "& .suppressedRow .MuiDataGrid-cell": { opacity: 0.5 } },
                            checkboxSelection: true,
                            rowSelectionModel,
                            onRowSelectionModelChange: (newRowSelectionModel: GridRowSelectionModel) => { setRowSelectionModel(newRowSelectionModel) },
                            apiRef
                        }} />
                </Grid>
            </Grid >
            <Grid>
                {/* <ModalGeneric open={pruebaOpen} onClose={handlePruebaClose} title="Prueba" buttonLabel="Close" entity="Assessment" columns={column} dataProvider={dataProvider} tableDefinition={AssessmentTableDefinition as ModelDefinition<Assessment>}></ModalGeneric> */}
                <ModalGeneric open={pruebaOpen} onClose={handlePruebaClose} title="Prueba">
                    <Grid>
                        <Typography>Test</Typography>
                    </Grid>
                </ModalGeneric>
            </Grid>
        </Grid>
    )
}

export { VulnerabilityList }
export default VulnerabilityList
