import React, { useContext, useEffect, useState } from "react"
import { Image, Link, Page, Text, View } from "@react-pdf/renderer"
import { ComponentCycloneDX, VulnerabilityCycloneDX } from "@models/VulnerabilityReport"
import VexDocumentStyles from "@components/report/VexStyleSheet"
import styles from "@components/report/Styles"
import { Compliance, ComplianceResult, Question, QuestionType, RegistryData } from "@models/Compliance"
import { CRAQuestions } from "@components/compliance/CRAQuestionData"
import { I18nContext } from "I18nProvider"
import { Footer, Header } from "./HeaderFooter"

const i18n = {
    es: {
        title: "2. Resultados de la herramienta de autoevaluación",
        description: "Esta tabla muestra los resultados de la autoevaluación previa. Mostrará el número de la pregunta, el tema al que se refiere y el nivel de la respuesta. El nivel de la respuesta puede ser alto, medio, bajo o nulo.",
        requirement: "Requisito",
        question: "Pregunta",
        high: "Alto",
        medium: "Medio",
        low: "Bajo",
        none: "Ninguno",
        result: "Resultado",
        title2: "CVE",
        name: "Nombre",
        criticality: "Criticidad",
        cvss: "CVSS",
        affectedAssets: "Activos afectados",
        description2: "Descripción",
        solution: "Solución",
        reference: "Referencia",
        insufficientInformation: "Información insuficiente",
        yes: "Si",
        no: "No",
        idontknow: "No se",
        writtenAnswer: "Respuesta escrita"
    },
    en: {
        title: "2. Self-assessment tool results",
        description: "This table shows the results of the previous self-assessment results. It will show the number of the question, the subject to which it refers and the level of the answer. The level of the answer can be high, medium, low or null.",
        requirement: "Requirement",
        question: "Question",
        high: "High",
        medium: "Medium",
        low: "Low",
        none: "None",
        result: "Result",
        title2: "CVE",
        name: "Name",
        criticality: "Criticality",
        cvss: "CVSS",
        affectedAssets: "Affected assets",
        description2: "Description",
        solution: "Solution",
        reference: "Reference",
        insufficientInformation: "Insufficient Information",
        yes: "Yes",
        no: "No",
        idontknow: "I dont know",
        writtenAnswer: "Written answer"
    }
} as const

interface CustomVulnsList {
    compliance:Compliance;
    lang?: string; // Nuevo prop para seleccionar el idioma
}

const VulnsList: React.FC<CustomVulnsList> = ({ compliance, lang }) => {
    const context = useContext(I18nContext)
    let locale: keyof typeof i18n = "en" // Idioma por defecto
    if (lang) {
        if (lang === "es" || lang === "en") {
            locale = lang as keyof typeof i18n // Usamos el valor de `lang`
        } else {
            console.warn(`Idioma no soportado: ${lang}. Usando idioma por defecto.`)
        }
    } else if (context) {
        locale = context.language as keyof typeof i18n // Usamos el valor del contexto si existe
    } else {
        console.error("El contexto I18n no está inicializado. Asegúrese de que tiene el proveedor configurado correctamente.")
    }
    return (
        <Page size="A4">
            <Header />
            <View style={VexDocumentStyles.section}>
                <Text style={styles.titulo}>
                    {i18n[locale].title}
                </Text>
                <Text style={{ ...styles.textua, paddingLeft: 40, marginRight: 40, fontFamily: "Griff", fontSize: 10, marginBottom: 7 }}>
                    {i18n[locale].description}
                </Text>
                <Text style={{ ...styles.titulo, fontFamily: "Bold", fontSize: 14, marginTop: 20 }}>2.1. Situation Question</Text>
                <View style={{ ...styles.tableScope, marginTop: 12, width: "90%" }}>
                    <View style={{ ...styles.tableRowScope, fontFamily: "Bold", textAlign: "center" }}>
                        <Text style={{ ...styles.tableColScope, backgroundColor: "transparent", borderWidth: 0, borderBottomWidth: 0, borderRightWidth: 0, width: "90%" }}></Text>
                        <Text style={{ ...styles.tableColScope, backgroundColor: "yellow", borderBottomWidth: 0, borderRightWidth: 1 }}>{i18n[locale].result}</Text>
                    </View>
                    <View style={{ ...styles.tableRowScope, fontFamily: "Bold", textAlign: "center" }}>
                        <Text style={{ ...styles.tableColScope, backgroundColor: "yellow", borderBottomWidth: 0, borderRightWidth: 0, width: "25%" }}>{i18n[locale].requirement}</Text>
                        <Text style={{ ...styles.tableColScope, backgroundColor: "yellow", borderBottomWidth: 0, borderRightWidth: 0 }}>{i18n[locale].question}</Text>
                        <Text style={{ ...styles.tableColScope, backgroundColor: "yellow", borderBottomWidth: 0, borderRightWidth: 1, width: "60%" }}>{i18n[locale].writtenAnswer}</Text>
                    </View>
                    <View style={{ borderBottomWidth: 1 }}>
                        {CRAQuestions.slice(0, 4).map((question) => (
                            <View key={question.id} style={{ ...styles.tableRowScope, backgroundColor: "transparent", fontFamily: "Griff" }} wrap={false}>
                                <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "transparent", width: "34%", justifyContent: "center", fontWeight: "bold" }}>{question.id.toUpperCase()}</Text>
                                <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "transparent", width: "109%", justifyContent: "center", fontWeight: "bold", textAlign: "left" }}>{question.text}</Text>
                                <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 1, backgroundColor: "transparent", justifyContent: "center", fontWeight: "bold", width: "81.6%" }}>
                                    {question.type === QuestionType.Text
                                        ? compliance.registry[compliance.registry.length - 1][question.id as keyof RegistryData]
                                        : <></>}
                                </Text>
                            </View>
                        ))}
                    </View>
                </View>
                <View style={VexDocumentStyles.section} break={true}></View>
                <Text style={{ ...styles.titulo, fontFamily: "Bold", fontSize: 12, marginTop: 20 }}>2.2. Cybersecurity requirements</Text>
                <View style={{ ...styles.tableScope, marginTop: 13, width: "90%" }}>
                    <View style={{ ...styles.tableRowScope, fontFamily: "Bold", textAlign: "center" }}>
                        <Text style={{ ...styles.tableColScope, backgroundColor: "transparent", borderWidth: 0, borderBottomWidth: 0, borderRightWidth: 0, width: "105%" }}></Text>
                        <Text style={{ ...styles.tableColScope, backgroundColor: "yellow", borderBottomWidth: 0, borderRightWidth: 1 }}>{i18n[locale].result}</Text>
                    </View>
                    <View style={{ ...styles.tableRowScope, fontFamily: "Bold", textAlign: "center" }}>
                        <Text style={{ ...styles.tableColScope, backgroundColor: "yellow", borderBottomWidth: 0, borderRightWidth: 0, width: "25%" }}>{i18n[locale].requirement}</Text>
                        <Text style={{ ...styles.tableColScope, backgroundColor: "yellow", borderBottomWidth: 0, borderRightWidth: 0 }}>{i18n[locale].question}</Text>
                        <Text style={{ ...styles.tableColScope, backgroundColor: "yellow", borderBottomWidth: 0, borderRightWidth: 0, width: "20%" }}>{i18n[locale].yes}</Text>
                        <Text style={{ ...styles.tableColScope, backgroundColor: "yellow", borderBottomWidth: 0, borderRightWidth: 0, width: "20%" }}>{i18n[locale].no}</Text>
                        <Text style={{ ...styles.tableColScope, backgroundColor: "yellow", borderBottomWidth: 0, borderRightWidth: 1, width: "20%" }}>{i18n[locale].idontknow}</Text>
                        <Text style={{ ...styles.tableColScope, backgroundColor: "yellow", borderBottomWidth: 0, borderRightWidth: 1, width: "20%" }}>{i18n[locale].writtenAnswer}</Text>
                    </View>
                    <View style={{ borderBottomWidth: 1 }}>
                        {CRAQuestions.slice(4, 42).map((question) => (
                            <View key={question.id} style={{ ...styles.tableRowScope, backgroundColor: "transparent", fontFamily: "Griff" }} wrap={false}>
                                <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 1, borderRightWidth: 0, backgroundColor: "transparent", width: "34%", justifyContent: "center", fontWeight: "bold" }}>{question.id.toUpperCase()}</Text>
                                <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 1, borderRightWidth: 0, backgroundColor: "transparent", width: "109%", justifyContent: "center", fontWeight: "bold", textAlign: "left" }}>{question.text}</Text>
                                {question.type === QuestionType.Text
                                    ? (
                                        <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 1, borderRightWidth: 1, backgroundColor: "transparent", justifyContent: "center", fontWeight: "bold", width: "108.8%", textAlign: "left" }}>
                                            {compliance.registry[compliance.registry.length - 1][question.id as keyof RegistryData]}
                                        </Text>
                                    )
                                    : (
                                        <>
                                            <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 1, borderRightWidth: 0, backgroundColor: "transparent", justifyContent: "center", fontWeight: "bold", width: "27.2%" }}>
                                                {compliance.registry[compliance.registry.length - 1][question.id as keyof RegistryData] === ComplianceResult.Yes ? <Image src="/../assets/img/report/check.png" /> : <></>}
                                            </Text>
                                            <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 1, borderRightWidth: 0, backgroundColor: "transparent", justifyContent: "center", fontWeight: "bold", width: "27.2%" }}>
                                                {compliance.registry[compliance.registry.length - 1][question.id as keyof RegistryData] === ComplianceResult.No ? <Image src="/../assets/img/report/check.png" /> : <></>}
                                            </Text>
                                            <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 1, borderRightWidth: 0, backgroundColor: "transparent", justifyContent: "center", fontWeight: "bold", width: "27.2%" }}>
                                                {compliance.registry[compliance.registry.length - 1][question.id as keyof RegistryData] === ComplianceResult.NoKnown ? <Image src="/../assets/img/report/check.png" /> : <></>}
                                            </Text>
                                            <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 1, borderRightWidth: 1, backgroundColor: "transparent", justifyContent: "center", fontWeight: "bold", width: "27.2%" }}>
                                                {compliance.registry[compliance.registry.length - 1][question.id as keyof RegistryData]}
                                            </Text>
                                        </>
                                    )}
                            </View>

                        ))}
                    </View>
                </View>
                <View style={VexDocumentStyles.section} break={true}></View>
                <Text style={{ ...styles.titulo, fontFamily: "Bold", fontSize: 12, marginTop: 20 }}>2.3. Vulnerabilities requirements</Text>
                <View style={{ ...styles.tableScope, marginTop: 13, width: "90%" }}>
                    <View style={{ ...styles.tableRowScope, fontFamily: "Bold", textAlign: "center" }}>
                        <Text style={{ ...styles.tableColScope, backgroundColor: "transparent", borderWidth: 0, borderBottomWidth: 0, borderRightWidth: 0, width: "105%" }}></Text>
                        <Text style={{ ...styles.tableColScope, backgroundColor: "yellow", borderBottomWidth: 0, borderRightWidth: 1 }}>{i18n[locale].result}</Text>
                    </View>
                    <View style={{ ...styles.tableRowScope, fontFamily: "Bold", textAlign: "center" }}>
                        <Text style={{ ...styles.tableColScope, backgroundColor: "yellow", borderBottomWidth: 0, borderRightWidth: 0, width: "25%" }}>{i18n[locale].requirement}</Text>
                        <Text style={{ ...styles.tableColScope, backgroundColor: "yellow", borderBottomWidth: 0, borderRightWidth: 0 }}>{i18n[locale].question}</Text>
                        <Text style={{ ...styles.tableColScope, backgroundColor: "yellow", borderBottomWidth: 0, borderRightWidth: 0, width: "20%" }}>{i18n[locale].yes}</Text>
                        <Text style={{ ...styles.tableColScope, backgroundColor: "yellow", borderBottomWidth: 0, borderRightWidth: 0, width: "20%" }}>{i18n[locale].no}</Text>
                        <Text style={{ ...styles.tableColScope, backgroundColor: "yellow", borderBottomWidth: 0, borderRightWidth: 1, width: "20%" }}>{i18n[locale].idontknow}</Text>
                        <Text style={{ ...styles.tableColScope, backgroundColor: "yellow", borderBottomWidth: 0, borderRightWidth: 1, width: "20%" }}>{i18n[locale].writtenAnswer}</Text>
                    </View>
                    <View style={{ borderBottomWidth: 1 }}>
                        {CRAQuestions.slice(42, 57).map((question) => (
                            <View key={question.id} style={{ ...styles.tableRowScope, backgroundColor: "transparent", fontFamily: "Griff" }} wrap={false}>
                                <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 1, borderRightWidth: 1, backgroundColor: "transparent", width: "34%", justifyContent: "center", fontWeight: "bold" }}>{question.id.toUpperCase()}</Text>
                                <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 1, borderRightWidth: 1, backgroundColor: "transparent", width: "109%", justifyContent: "center", fontWeight: "bold", textAlign: "left" }}>{question.text}</Text>
                                {question.type === QuestionType.Text
                                    ? (
                                        <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 1, borderRightWidth: 1, backgroundColor: "transparent", justifyContent: "center", fontWeight: "bold", width: "108.8%", textAlign: "left" }}>
                                            {compliance.registry[compliance.registry.length - 1][question.id as keyof RegistryData]}
                                        </Text>
                                    )
                                    : (
                                        <>
                                            <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 1, borderRightWidth: 1, backgroundColor: "transparent", justifyContent: "center", fontWeight: "bold", width: "27.2%" }}>
                                                {compliance.registry[compliance.registry.length - 1][question.id as keyof RegistryData] === ComplianceResult.Yes ? <Image src="/../assets/img/report/check.png" /> : <></>}
                                            </Text>
                                            <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 1, borderRightWidth: 1, backgroundColor: "transparent", justifyContent: "center", fontWeight: "bold", width: "27.2%" }}>
                                                {compliance.registry[compliance.registry.length - 1][question.id as keyof RegistryData] === ComplianceResult.No ? <Image src="/../assets/img/report/check.png" /> : <></>}
                                            </Text>
                                            <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 1, borderRightWidth: 0, backgroundColor: "transparent", justifyContent: "center", fontWeight: "bold", width: "27.2%" }}>
                                                {compliance.registry[compliance.registry.length - 1][question.id as keyof RegistryData] === ComplianceResult.NoKnown ? <Image src="/../assets/img/report/check.png" /> : <></>}
                                            </Text>
                                            <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 1, borderRightWidth: 1, backgroundColor: "transparent", justifyContent: "center", fontWeight: "bold", width: "27.2%" }}>
                                                {compliance.registry[compliance.registry.length - 1][question.id as keyof RegistryData]}
                                            </Text>
                                        </>
                                    )}
                            </View>

                        ))}
                    </View>
                </View>
            </View>
            <Footer />
        </Page>
    )
}
// TODO: modificar los campos que se muestran en las preguntas
const QuestionRow: React.FC<{ question: Question, index: number }> = ({ question, index }) => (
    <View style={VexDocumentStyles.row}>
        <View style={{ ...VexDocumentStyles.col, width: "10%", borderRightWidth: 0, borderTopWidth: 0 }}>
            <Link src={`#SCA-${String(index + 1).padStart(3, "0")}`} style={{ textDecoration: "none", color: "black" }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", borderBottomWidth: 0 }}>{`SCA-${String(index + 1).padStart(3, "0")} `}</Text></Link>
        </View>
    </View>
)

const VulnsListDetail: React.FC<{ vulnerabilities:VulnerabilityCycloneDX[], components: ComponentCycloneDX[] }> = ({ vulnerabilities, components }) => {
    const [componentsByMap, setComponentsByMap] = useState<{[key: string]: ComponentCycloneDX}>({})

    useEffect(() => {
        setComponentsByMap(components.reduce((acc, current) => {
            acc[current["bom-ref"]] = current
            return acc
        }, {} as {[key: string]: ComponentCycloneDX}))
    }, [components])

    return (
        <Page size="A4">
            <Header></Header>
            <View wrap={true}>
                {vulnerabilities.map((vulnerability, index) => (
                    <VulnerabilityHeaderDetail key={index} vulnerability={vulnerability} index={index} component={componentsByMap[vulnerability.affects[0].ref]}/>
                ))}
            </View>
            <Footer></Footer>
        </Page>
    )
}
interface CustomVulnsList2 {
    vulnerability: VulnerabilityCycloneDX;
    index: number;
    lang?: string; // Nuevo prop para seleccionar el idioma
    component: ComponentCycloneDX;
}
const VulnerabilityHeaderDetail: React.FC<CustomVulnsList2> = ({ vulnerability, index, component, lang }) => {
    const context = useContext(I18nContext)
    let locale: keyof typeof i18n = "en" // Idioma por defecto
    if (lang) {
        if (lang === "es" || lang === "en") {
            locale = lang as keyof typeof i18n // Usamos el valor de `lang`
        } else {
            console.warn(`Idioma no soportado: ${lang}. Usando idioma por defecto.`)
        }
    } else if (context) {
        locale = context.language as keyof typeof i18n // Usamos el valor del contexto si existe
    } else {
        console.error("El contexto I18n no está inicializado. Asegúrese de que tiene el proveedor configurado correctamente.")
    }

    return (
        <View key={index + 1} style={{ ...VexDocumentStyles.section }} wrap={false}>
            <Text id={`SCA-${String(index + 1).padStart(3, "0")}`} style={{ ...styles.textua, fontFamily: "Bold", fontSize: 12, marginRight: 0, paddingLeft: 5, paddingBottom: 5 }}>
                4.2.{index} {`SCA-${String(index + 1).padStart(3, "0")} `}
            </Text>
            <View style={VexDocumentStyles.row}>
                <View style={{ ...VexDocumentStyles.colHeader, width: "15%", borderRightWidth: 0, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 10, paddingBottom: -3, paddingLeft: 5 }}>
                    <Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 10, marginBottom: 0 }}>{i18n[locale].title2}</Text>
                </View>
                <View style={{ ...VexDocumentStyles.col, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 12, paddingBottom: 0, paddingLeft: 5 }}>
                    <Link href={`https://nvd.nist.gov/vuln/detail/${vulnerability.id}`} style={{ textDecoration: "none", color: "black" }}>
                        <Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9 }}> {vulnerability.id} </Text>
                    </Link>
                </View>
            </View>
            {/* Resto del componente... */}
            <View style={VexDocumentStyles.row}>
                <View style={{ ...VexDocumentStyles.colHeader, width: "15%", borderRightWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5 }}>
                    <Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 10, marginBottom: 0 }}>{i18n[locale].reference}</Text>
                </View>
                <View style={{ ...VexDocumentStyles.col, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 0, paddingLeft: 5 }}>
                    <Link href={`https://nvd.nist.gov/vuln/detail/${vulnerability.id}`} style={{ textDecoration: "none", color: "black" }}>
                        <Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9, marginBottom: 2 }}>
                            {`https://nvd.nist.gov/vuln/detail/${vulnerability.id}`}
                        </Text>
                    </Link>
                </View>
            </View>
        </View>
    )
}

export { VulnsList, VulnsListDetail }
