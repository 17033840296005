import React from "react"
import { Image, Page, Text, View } from "@react-pdf/renderer"
import Assessment from "@models/Assessment"
import Template from "@models/Template"
import styles from "../../Styles"
import VexDocumentStyles from "../../VexStyleSheet"
import { Footer, Header } from "./HeaderFooter"

export const scope = [
    ["Identification, Documentation and Management of Software Components", "SBOM analysis involves identifying all software components used in an application or system, including libraries, frameworks, modules, plugins, and any other elements that contribute to the operation of the software."],
    ["Vulnerability scan", "Once a complete SBOM has been generated, a vulnerability scan can be performed to identify potential security gaps in software components."],
    ["Vulnerability Prioritization", "This analysis involves evaluating the severity of each identified vulnerability, followed by the generation of a list ordered by its level of criticality."]
]

type TestType = "Fuzzing" | "Network Stress";

interface SectionProps {
    externalData: any
    assessment: Assessment
    testType: TestType
    customScope?: string
    template?: Template
}

const ScopeSection:React.FC<SectionProps> = ({ assessment, testType, externalData, customScope, template }) => {
    return (
        <Page size="A4" style= {{ backgroundColor: !template || template.color === "#fffa37" ? "" : template.color }}>

            <Header template={template}></Header>
            <View style={{ ...VexDocumentStyles.section, flexDirection: "row" }}>
                <View style={styles.column}>
                    <Text style={{ ...styles.titulo, fontFamily: "Bold" }}>2. Alcance</Text>
                    <Text style={{ ...styles.textua, marginRight: 30, fontFamily: "Griff", fontSize: 10, textAlign: "justify" }}>En el presente documento se presentan resultados obtenidos tras la ejecución de los test de robustez de comunicaciones sobre la plataforma AchillesDEMO, en adelante Device Under Test (DUT).</Text>
                    {externalData && (
                        <Text style={{ ...styles.textua, marginRight: 30, fontFamily: "Griff", fontSize: 10, textAlign: "justify" }}>{externalData.descripcion}</Text>
                    )}
                    <Text style={{ ...styles.textua, marginRight: 30, fontFamily: "Griff", fontSize: 10, textAlign: "justify" }}>En el transcurso de este test, se realiza una ejecución completa de todos los tests, aquellos que pertenecen a los de tipo {testType}, lo que permite identificar los tests problemáticos, entendiendo como tal aquellos en los que se producen no conformidades graves. Después, se realizan más ejecuciones de esos tests para confirmar y en su caso diagnosticar la causa de los problemas.</Text>
                    <Text style={{ ...styles.textua, marginRight: 30, fontFamily: "Griff", fontSize: 10, textAlign: "justify" }}>{customScope}</Text>
                </View>
                <View style={styles.column}>
                    <Image style={styles.rightImageFull} src="/assets/img/report/octogono.jpg" />
                </View>
            </View>
            <Footer color={template?.color}></Footer>
        </Page>
    )
}

export { ScopeSection }
