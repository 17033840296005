export enum RansomwareCampaignUse {
    Unknown = "Unknown",
    Known = "Known"
}

export interface KEV {
    id: string;
    cve_id: string;
    vendor_project: string;
    product: string;
    vulnerability_name: string;
    date_added: string;
    short_description: string;
    required_action: string;
    due_date: string;
    known_ransomware_campaign_use: RansomwareCampaignUse;
    notes: string;
}
