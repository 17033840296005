import React, { ChangeEvent, useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Box, FormControl, Grid, IconButton, MenuItem, TextField, Tooltip, Typography, useTheme } from "@mui/material"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { AssessmentType, CVSS3Severity, getCVSS3Criticality, Template, TMetadataSCA } from "@models/index"
import { HexColorPicker } from "react-colorful"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import ServicesContext from "@context/ServicesContext"
import "./draganddropstyle.css"
import LanguageSelector from "@components/common/switches/LanguageSelector"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import { CVSS3SeverityKeysValueOps } from "@components/vulnerability"
import { AssessmentTypeKeysValueOps } from "@components/assessment"
import { ClientSelector } from "@components/client"
import { AssessmentTypeColors } from "@components/common/colors/AssessmentTypeColors"
import { CVSS3SeverityColors } from "@components/common/colors/CVSS3SeverityColors"
import ActionButton from "@components/common/Button/ActionButton"
import Can from "@components/permissions/Can"
import { useConfirmationDialog } from "@components/dialogs/ConfirmationDialog"
import { I18nContext } from "I18nProvider"
import { useTrack } from "@components/track/TrackContext"
export const emptySCAMetadata: TMetadataSCA = {
    cover_page: {
        header: "",
        subheader: "",
        metadata: ""
    },
    table_of_contents: {
        table_of_content: true,
        introduction: true,
        scope: true,
        executive_summary: true
    },
    custom_content: [{
        name: "",
        content: ""
    }]
}
const initValue: Template = {
    id: "",
    name: "",
    language: "en",
    color: "#fffa37",
    logo: null,
    type: AssessmentType.CRT,
    client: { id: "" },
    config: {
        type: AssessmentType.CRT,
        data: {
            cvss_criticality: CVSS3Severity.Unknown,
            cvss_minimum: 0,
            limit_vulnerability_count: 100
        }

    },
    metadata: {
        type: AssessmentType.SCA,
        data: emptySCAMetadata
    }
}
export const getLuminance = (hex: string) => {
    const rgb = hexToRgb(hex)

    const [r, g, b] = [rgb.r / 255, rgb.g / 255, rgb.b / 255].map((c) =>
        c <= 0.03928 ? c / 12.92 : Math.pow((c + 0.055) / 1.055, 2.4)
    )

    return 0.2126 * r + 0.7152 * g + 0.0722 * b
}
export const hexToRgb = (hex: string) => {
    const bigint = parseInt(hex.replace("#", ""), 16)
    return {
        r: (bigint >> 16) & 255,
        g: (bigint >> 8) & 255,
        b: bigint & 255
    }
}
export const isColorBright = (hex: string) => getLuminance(hex) > 0.1
export const getSeverityFromScore = (cvssScore: number): CVSS3Severity => {
    if (cvssScore === 0) {
        return CVSS3Severity.None
    } else if (cvssScore >= 0.1 && cvssScore <= 3.9) {
        return CVSS3Severity.Low
    } else if (cvssScore >= 4.0 && cvssScore <= 6.9) {
        return CVSS3Severity.Medium
    } else if (cvssScore >= 7.0 && cvssScore <= 8.9) {
        return CVSS3Severity.High
    } else if (cvssScore >= 9.0 && cvssScore <= 10.0) {
        return CVSS3Severity.Critical
    }
    return CVSS3Severity.Unknown
}

export const getCVSSMinimumFromSeverity = (severity: CVSS3Severity): number => {
    switch (severity) {
    case CVSS3Severity.None:
        return 0
    case CVSS3Severity.Low:
        return 0.1
    case CVSS3Severity.Medium:
        return 4
    case CVSS3Severity.High:
        return 7
    case CVSS3Severity.Critical:
        return 9
    default:
        return 0
    }
}

const TemplateForm: React.FC = () => {
    // Constants
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const theme = useTheme()
    const templateService = useContext(ServicesContext).templateService
    const [fileId, _] = useState(`file-input-${Math.random().toString(36).substring(7)}`)
    const [error, setError] = useState<Error|null>(null)
    const [file, setFile] = useState<File>(new File([], "logo"))
    const [previewUrl, setPreviewUrl] = useState<string | ArrayBuffer | null>(null)
    const { id } = useParams<{ id: string }>()
    const [formData, setFormData] = useState<Template>(initValue)
    const [prevFormData, setPrevFormData] = useState<Template>(initValue)
    const [color, setColor] = useState(formData.color)
    const { showDialog } = useConfirmationDialog()
    const navigate = useNavigate()
    const { track, trackData } = useTrack()
    useEffect(() => {
        track({ view: "TemplateForm" })
    }, [])
    // Functions

    const formValid = (): boolean => {
        const isNotNullrules = [
            formData.name === "",
            formData.client.id === undefined
        ]
        if (isNotNullrules.some(rule => rule)) {
            return false
        }
        const isNotSameOldValues = [
            formData.name === prevFormData.name,
            formData.language === prevFormData.language,
            formData.type === prevFormData.type,
            formData.config === prevFormData.config,
            formData.metadata === prevFormData.metadata,
            formData.color === prevFormData.color,
            formData.logo === prevFormData.logo
        ]
        if (isNotSameOldValues.every(rule => rule)) {
            return false
        }
        return true
    }

    const handleInputChange = (e: any) => {
        const { name, value } = e.target
        setFormData((prevFormData) => {
            if (name.includes(".")) {
                const keys = name.split(".")
                const [parentKey, childKey] = keys
                return {
                    ...prevFormData,
                    [parentKey]: {
                        ...(prevFormData[parentKey as keyof Template] as Record<string, any>),
                        [childKey]: value
                    }
                }
            }
            if (name === "type") {
                // Actualizamos también `metadata.type` y `config.type` además de `type`
                return {
                    ...prevFormData,
                    [name]: value,
                    metadata: {
                        ...prevFormData.metadata,
                        type: value
                    },
                    config: {
                        ...prevFormData.config,
                        type: value
                    }
                }
            }

            return {
                ...prevFormData,
                [name]: value
            }
        })
    }

    const handleChange = (e:any) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }
    const handleColorChange = (selectedColor: string) => {
        if (isColorBright(selectedColor)) {
            setColor(selectedColor)
            setFormData((prevFormData) => ({
                ...prevFormData,
                color: selectedColor
            }))
        } else {
            showDialog(
                context.t.translate("color_dialog.title"),
                context.t.translate("color_dialog.content"),
                () => {},
                context.t.translate("color_dialog.button")
            )
        }
    }
    const handleSubmit = async () => {
        try {
            await templateService.create(formData)
            setError(null)
            navigate(-1)
        } catch (e: any) {
            setError({ message: e.error } as Error)
            setSnackbarOpen(true)
        }
    }

    useEffect(() => {
        setFormData((prevData) => ({
            ...prevData,
            config: {
                ...prevData.config,
                cvss_criticality: getCVSS3Criticality(prevData.config.data.cvss_minimum || 0)
            }
        }))
    }, [formData.config.data.cvss_minimum])

    useEffect(() => {
        const fecthData = async () => {
            try {
                const val = await templateService.get(id as string)
                setFormData(val)
                setColor(val.color)
                setPrevFormData(val)
                setPreviewUrl("data:image/png;base64," + val.logo)
            } catch (e: any) {
                setError({ message: e.error } as Error)
            }
        }
        fecthData()
    }, [])

    const [snackbarOpen, setSnackbarOpen] = useState(false)

    const handleFileInputClick = (e: React.MouseEvent<HTMLInputElement>) => {
        const input = e.target as HTMLInputElement
        input.value = ""
    }
    const handleFileInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files && e.target.files[0]
        if (selectedFile) {
            try {
                const reader = new FileReader()
                reader.onloadend = () => {
                    if (reader.result) {
                        handleInputChange({ target: { name: "logo", value: reader.result as string } })
                        setPreviewUrl(reader.result as string)
                    }
                }

                reader.readAsDataURL(selectedFile)
                setFile(selectedFile)
            } catch (e: any) {
                setError({ message: e.error } as Error)
                setSnackbarOpen(true)
            }
        }
    }

    const handleLanguageChange = (langId: string) => {
        setFormData({ ...formData, language: langId })
    }

    useEffect(() => {
        setFormData({ ...formData, color })
    }, [color])
    const handleCVSSChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: "cvss_criticality" | "cvss_minimum") => {
        const { value } = e.target
        const decimalValue = parseFloat(value).toFixed(1)

        if (field === "cvss_criticality") {
            const severity = value as CVSS3Severity
            const min = getCVSSMinimumFromSeverity(severity)

            setFormData((prevFormData) => ({
                ...prevFormData,
                config: {
                    ...prevFormData.config,
                    data: {
                        ...prevFormData.config.data,
                        cvss_criticality: severity,
                        cvss_minimum: min
                    }
                }
            }))
        } else if (field === "cvss_minimum") {
            // Limitar el valor de cvss_minimum a 10 como máximo
            let cvssScore = parseFloat(decimalValue)

            // Si el valor es mayor que 10, ajustarlo a 10
            if (cvssScore > 10) {
                cvssScore = 10
            }

            // Obtener la severidad correspondiente con el nuevo valor ajustado
            const severity = getSeverityFromScore(cvssScore)

            setFormData((prevFormData) => ({
                ...prevFormData,
                config: {
                    ...prevFormData.config,
                    data: {
                        ...prevFormData.config.data,
                        cvss_criticality: severity,
                        cvss_minimum: cvssScore
                    }
                }
            }))
        }
    }

    return (
        <Grid
            container
            sx={{
                height: "100vh",
                justifyContent: "center",
                alignItems: "center",
                padding: "25px"
            }}
        >
            <Box
                sx={{
                    width: "100%",
                    maxWidth: "800px",
                    padding: "20px",
                    borderRadius: "8px",
                    boxShadow: 3
                }}
            >
                <CustomSnackbar
                    open={snackbarOpen}
                    onClose={() => setSnackbarOpen(false)}
                    message={error?.message || "An error occurred"}
                    severity="error"
                />
                <Grid item flexDirection="row">
                    <Grid container flexDirection="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ marginTop: "10px" }}>
                        <Grid item>
                            <Typography fontSize="37px" fontFamily="Griff" color="primary" fontWeight="bolder">
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container flexDirection="row" alignItems='center' spacing="40px">
                    <Grid item container flexDirection="row" alignItems='center' spacing="40px">
                        <Grid item xs={12}>
                            <Can I="create" key="create" a="Template">
                                <ClientSelector value={formData.client.id} onSelect={(id) => handleChange({ target: { name: "client", value: { id } } })}></ClientSelector>
                            </Can>
                        </Grid>
                    </Grid>
                </Grid>
                <TextField
                    margin="normal"
                    required
                    variant="filled"
                    label="Template Name"
                    name="name" value={formData.name} onChange={handleChange}
                />
                <Grid container item xs={12} spacing={2} alignItems="center" justifyContent="space-between">
                    <Grid item xs={6}>
                        <TextField
                            margin="normal"
                            select
                            required
                            fullWidth
                            variant="filled"
                            label="Assessment type"
                            name="type"
                            value={formData.type}
                            onChange={handleInputChange}>
                            {AssessmentTypeKeysValueOps.map((opt, idx) => (
                                <MenuItem key={idx} value={opt.value}>
                                    <Typography fontFamily="Griff" fontWeight="bolder" sx={{ color: AssessmentTypeColors[AssessmentType[opt.label]] }}>
                                        {opt.label}
                                    </Typography>
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth margin="normal">
                            <LanguageSelector onLanguageChange={handleLanguageChange} />
                        </FormControl>
                    </Grid>
                </Grid>

                <Typography sx={{ fontSize: "27px", fontFamily: "Griff", mt: "20px", fontWeight: "bolder", color: theme.palette.primary.main }}>Findings filters</Typography>
                <Grid item container flexDirection="row" textAlign="left" spacing={2}>
                    {/* Primer campo de texto */}
                    <Grid item xs={6} container flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                        <Typography
                            fontSize="20px"
                            fontFamily="Griff"
                            color={theme.palette.text.secondary}
                            fontWeight="bolder"
                            marginBottom="8px"
                        >
        Criticality
                        </Typography>
                        <TextField
                            margin="normal"
                            select
                            required
                            fullWidth
                            variant="filled"
                            label="Type"
                            name="config.data.cvss_criticality"
                            value={formData.config.data.cvss_criticality}
                            onChange={(e) => handleCVSSChange(e, "cvss_criticality")} // Usamos la nueva función
                            sx={{ margin: "0px" }}
                        >
                            {CVSS3SeverityKeysValueOps.map((opt, idx) => (
                                <MenuItem key={idx} value={opt.value}>
                                    <Typography fontFamily="Griff" fontWeight="bolder" sx={{ color: CVSS3SeverityColors[CVSS3Severity[opt.label]] }}>
                                        {opt.label}
                                    </Typography>
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                    <Grid item xs={6} container flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                        <Typography
                            fontSize="20px"
                            fontFamily="Griff"
                            color={theme.palette.text.secondary}
                            fontWeight="bolder"
                            marginBottom="8px"
                        >
        CVSS minimum:
                        </Typography>
                        <TextField
                            name="config.data.cvss_minimum"
                            variant="outlined"
                            value={formData.config.data.cvss_minimum}
                            onChange={(e) => handleCVSSChange(e, "cvss_minimum")}
                            fullWidth
                            type="number"
                            inputProps={{
                                max: 10,
                                min: 0,
                                step: "0.1",
                                pattern: "^[0-9]*(.[0-9]{1})?$"
                            }}
                            sx={{ marginBottom: "10px" }}
                        />

                    </Grid>

                </Grid>
                <Typography sx={{ fontSize: "27px", fontFamily: "Griff", mt: "20px", fontWeight: "bolder", color: theme.palette.primary.main }}>Theme</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography
                            fontSize="20px"
                            fontFamily="Griff"
                            color={theme.palette.text.secondary}
                            fontWeight="bolder"
                        >
                            Color
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography
                            fontSize="20px"
                            fontFamily="Griff"
                            color={theme.palette.text.secondary}
                            fontWeight="bolder"
                        >
                            Custom logo
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <HexColorPicker color={color} onChange={handleColorChange} />
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container alignItems="center">
                            <input
                                type="file"
                                accept=".jpg"
                                style={{ display: "none" }}
                                onClick={handleFileInputClick}
                                onChange={handleFileInputChange}
                                id={fileId}
                            />
                            <label htmlFor={fileId}>
                                <Tooltip title="Upload Logo">
                                    <IconButton size="small" component="span" onClick={(e) => e.stopPropagation()}>
                                        <CloudUploadIcon />
                                    </IconButton>
                                </Tooltip>
                            </label>
                        </Grid>
                        {previewUrl !== null && (
                            <img
                                style={{ width: "50%", height: "50%", marginTop: "8px" }}
                                src={previewUrl.toString()}
                                alt="Preview"
                            />
                        )}
                    </Grid>
                </Grid>

                <Grid item mt={2}><ActionButton text="Add Template" style={{ width: "100%", marginTop: 3 }} onClick={handleSubmit} disabled={!formValid()} /></Grid>
            </Box>
        </Grid>
    )
}

export default TemplateForm
