import { Achilles, TestType } from "@models/index"
import { getEnumKeys, ModelDefinition } from "@models/utils"
import AchillesStatusColored from "./AchillesStatusColored"
import AchillesFailureRender from "./AchillesFailureRender"

const AchillesTypeKeys = getEnumKeys(TestType)
const AchillesTypeKeysValueOps = AchillesTypeKeys.map((key) => ({ label: key, value: TestType[key] }))

const AchillesTableDefinition: ModelDefinition<Achilles> = {
    properties: [
        { name: "id", label: "Id", type: "string", flex: 0.05, isId: true },
        { name: "test_type", label: "Type", type: "singleSelect", flex: 0.15, allowedValueOptions: AchillesTypeKeysValueOps, render: AchillesStatusColored },
        { name: "test_name", label: "Test Name", type: "string", flex: 0.5 },
        { name: "anomaly", label: "Anomaly", type: "string", flex: 0.1 },
        { name: "monitor_value", label: "Monitor Value", type: "string", flex: 0.35 },
        { name: "monitor_name", label: "Monitor Name", type: "string", flex: 0.5 },
        { name: "failure_percentage", label: "Failure %", type: "string", flex: 0.1, render: AchillesFailureRender }
    ]
}

export { AchillesTableDefinition, AchillesTypeKeys, AchillesTypeKeysValueOps }
export default AchillesTableDefinition
