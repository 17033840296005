import React from "react"
import { Grid, TextField } from "@mui/material"

interface SectionEditorProps {
    sectionName: string;
    fields: {
        label: string;
        key: string;
        multiline?: boolean;
        defaultValue?: string,
        length?: number
    }[];
    sectionData: Record<string, string>;
    onChange: (fieldKey: string, value: string) => void;
    onBlur?: (fieldKey: string, value: string) => void;
}

const SectionEditor: React.FC<SectionEditorProps> = ({
    sectionName,
    fields,
    sectionData,
    onChange,
    onBlur
}) => {
    // Función para obtener el límite máximo de caracteres por campo
    const getMaxCharacters = (field: { key: string, length?: number }): number => {
        // Prioriza el length específico del campo
        if (field.length !== undefined) {
            return field.length
        }
        // Fallback anterior
        return field.key === "side_title" ? 190 : 200
    }

    return (
        <Grid container direction="column" spacing={2}>
            {fields.map((field) => {
                const maxChars = getMaxCharacters(field) // Obtener el límite máximo

                return (
                    <TextField
                        key={field.key}
                        label={field.label}
                        value={sectionData[field.key] || field.defaultValue || ""}
                        onChange={(e) => {
                            // Validar que el valor no exceda el límite máximo
                            if (e.target.value.length <= maxChars) {
                                onChange(field.key, e.target.value)
                            }
                        }}
                        onBlur={(e) => onBlur && onBlur(field.key, e.target.value)}
                        variant="filled"
                        fullWidth
                        helperText={`${(sectionData[field.key]?.length || 0)}/${maxChars} characters`}
                        multiline={field.multiline}
                        rows={field.multiline ? 4 : 1}
                        inputProps={{ maxLength: maxChars }}
                    />
                )
            })}
        </Grid>
    )
}

export default SectionEditor
