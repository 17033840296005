import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import React, { useContext, useEffect, useState } from "react"
import { Checkbox, Divider, FormControl, FormControlLabel, MenuItem, TextField, useTheme } from "@mui/material"
import { StyledTextarea } from "@components/common/textareas"
import ClientSelector from "@components/client/ClientSelector"
import { AbilityContext } from "@components/permissions/Can"
import { ServicesContext } from "@context/index"
import Email, { EmailFrequency, EmailFrequencyKeys } from "@models/Email"
import { FilterOperation } from "@utils/queryParams"
import { I18nContext } from "I18nProvider"
import { useNavigate } from "react-router-dom"
import { MdOutlinePolicy } from "react-icons/md"
import ActionButton from "@components/common/Button/ActionButton"
import { StyledBox } from "@components/common/Box/BoxContainer"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import { LiaClipboardCheckSolid } from "react-icons/lia"
import { LicenseType } from "@models/License"
import { useTrack } from "@components/track/TrackContext"

const initValue: Email = {
    id: "",
    emails: [],
    email_enabled: false,
    email_frequency: EmailFrequency.Day,
    notification_new_cve: false,
    notification_updated_cve: false,
    notification_score_flag: 0,
    client_id: ""
}

const EmailDetail: React.FC = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const theme = useTheme()
    const navigate = useNavigate()
    const emailService = useContext(ServicesContext).emailService
    const licenseService = useContext(ServicesContext).licenseService
    const [error, setError] = React.useState<Error | null>(null)
    const [email, setEmail] = React.useState(initValue)
    const [prevFormData, setPrevFormData] = React.useState<Email>(initValue)
    const [licenseType, setLicenseType] = React.useState<LicenseType>(LicenseType.Golden)
    const { track, trackData } = useTrack()
    useEffect(() => {
        track({ view: "EmailDetail" })
    }, [])
    /* TODO: This function doesn't work as intended */
    const formValid = (): boolean => {
        const isNotSameOldValues = [
            email.email_enabled === prevFormData.email_enabled,
            email.email_frequency === prevFormData.email_frequency,
            email.notification_new_cve === prevFormData.notification_new_cve,
            email.notification_updated_cve === prevFormData.notification_updated_cve,
            email.notification_score_flag === prevFormData.notification_score_flag,
            email.emails?.join(";") === prevFormData.emails?.join(";")
        ]
        if (isNotSameOldValues.every(rule => rule)) {
            return false
        }
        return true
    }
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const handleSaveChanges = async () => {
        try {
            const response = await emailService.update(email.id, email)
        } catch (e: any) {
            if (e.error) {
                setError({ message: e.error } as Error)
                setSnackbarOpen(true)
            }
        }
    }

    const getEmailDetail = async (id: string) => {
        if (id === "") {
            setEmail(initValue)
            return
        }
        try {
            const response = await emailService.getAll({ filters: [{ field: "client_id", operation: FilterOperation.EnumEqual, value: id }] })
            if (response.count < 1) {
                setEmail(initValue)
                setPrevFormData(initValue)
                return
            }
            setEmail(response.list[0])
            setPrevFormData(response.list[0])
            throw new Error(context.t.transtale("error_testing"))
        } catch (e: any) {
            if (e.error) {
                setError({ message: e.error } as Error)
                setSnackbarOpen(true)
            }
        }
    }

    const handleChange = (event: any) => {
        const { name, value, type, checked } = event.target
        let newValue = type === "checkbox" ? checked : value
        if (name === "emails") {
            newValue = newValue.split(/;/).map((email:any) => email.trim())
        } else if (name === "notification_score_flag") {
            newValue = (value.match(/\d+/) ? parseInt(value.match(/\d+/)[0], 10) : 0)
        }
        setEmail({ ...email, [name]: newValue })
    }

    let formattedEmail = ""
    if (Array.isArray(email.emails)) {
        formattedEmail = email.emails.join(";\n")
    }

    // Not Admin User
    const ability = useContext(AbilityContext)
    if (!ability.can("read", "Client")) {
        useEffect(() => {
            const fetchData = async () => {
                const email = await emailService.getAll()
                if (email.count > 0) {
                    setEmail(email.list[0] as Email)
                    setPrevFormData(email.list[0])
                } else {
                    setEmail(initValue)
                }
            }
            const fetchLicense = async () => {
                const licenses = await licenseService.getAll()
                if (licenses.count > 0) {
                    setLicenseType(licenses.list[0].type)
                }
            }
            fetchData()
            fetchLicense()
        }, [])
    }

    return (
        <Grid item xs container flexDirection="column" spacing="20px">
            <Grid container sx={{ justifyContent: "space-between", alignItems: "center", spacing: "20px", paddingLeft: "20px", marginBottom: "0px", paddingBottom: "0px" }}>

                <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                    {context.t.translate("notifications_title")}
                </Typography>
                {(licenseType !== LicenseType.Golden || ability.can("*", "Client")) && (
                    <Grid item sx={{ display: "flex", gap: 2 }}>
                        <ActionButton icon={<LiaClipboardCheckSolid/>} onClick={() => navigate("../settings/sla")} text={context.t.translate("sla_management")} />
                        <ActionButton icon={<MdOutlinePolicy/>} onClick={() => navigate("../manage/policy")} text={context.t.translate("policy_management")} />
                    </Grid>
                )}
            </Grid>
            <Grid item container flexDirection="column" rowGap="35px">
                <Grid item container>
                    <CustomSnackbar
                        open={snackbarOpen}
                        onClose={() => setSnackbarOpen(false)}
                        message={error?.message || context.t.translate("an_error")}
                    />
                    <StyledBox>

                        <Grid container flexDirection="column" spacing="20px">
                            <Grid item container justifyContent="space-between" alignItems="center" flexDirection="row">
                                {ability.can("*", "Email") && (
                                    <Grid xs={3}>
                                        <ClientSelector value={email.client_id} onSelect={(id) => getEmailDetail(id || "")} />
                                    </Grid>
                                )}
                                <Grid item>
                                    <ActionButton text={context.t.translate("save_changes")} disabled={!formValid()} onClick={handleSaveChanges} />
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Divider sx={{ height: "1px", width: "100%", background: "#6D6C6F" }} />
                            </Grid>
                            <Grid item container justifyContent="space-around" alignItems="flex-start" mt={2}>
                                <Grid item container xs={12} md={4} textAlign="center">
                                    <Grid item>
                                        <Typography fontSize="37px" fontFamily="Griff" color="primary" fontWeight="bolder">{context.t.translate("report_filters")}</Typography>
                                    </Grid>
                                    <Grid item container flexDirection="row" textAlign="left" spacing="10px" py={2}>
                                        <Grid item xs={12}>
                                            <Typography fontSize="20px" fontFamily="Griff" fontWeight="bolder" >{context.t.translate("recieve_alerts")}</Typography>
                                        </Grid>
                                        <Grid item xs={12} container direction="column">
                                            <Grid item>
                                                <FormControlLabel name="notification_new_cve" checked={email.notification_new_cve} onChange={handleChange} control={<Checkbox />} label={<Typography >{context.t.translate("new_CVEs")}</Typography>} labelPlacement="end"/>
                                            </Grid>
                                            <Grid item>
                                                <FormControlLabel name="notification_updated_cve" checked={email.notification_updated_cve} onChange={handleChange} control={<Checkbox />} label={<Typography >{context.t.translate("CVEs_are")}</Typography>} labelPlacement="end"/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item container flexDirection="row" textAlign="left" spacing="10px">
                                        <Grid item xs={12} container justifyContent="flex-start" alignItems="center">
                                            <Typography fontSize="20px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("recieve_alerts_when_CVSS")}
                                                <TextField name="notification_score_flag" size="small" variant="outlined" value={email.notification_score_flag} onChange={handleChange}/>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} md={4}>
                                    <Grid item>
                                        <Typography fontSize="37px" fontFamily="Griff" color="primary" fontWeight="bolder">{context.t.translate("alerts")}</Typography>
                                    </Grid>
                                    <Grid item container textAlign="left" spacing="10px" py={2}>
                                        <Grid item xs={12} container alignItems="center">
                                            <FormControlLabel name="email_enabled" checked={email.email_enabled} onChange={handleChange} control={<Checkbox />} label={<Typography fontSize="20px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("enable")}</Typography>} labelPlacement="end"/>
                                        </Grid>
                                    </Grid>
                                    <Grid item container alignItems="center" spacing="40px">
                                        <Grid item xs={12} md={12} container>
                                            <Grid item xs={12}>
                                                <Typography fontSize="20px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("recipient")}</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <StyledTextarea minRows={3} name="emails" value={formattedEmail} onChange={handleChange} placeholder="example@orbik-cybersecurity.com ; test@orbik-cybersecurity.com" style={{ width: "100%", boxSizing: "border-box", backgroundColor: "transparent" }}/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item container textAlign="left" spacing="10px" py={2}>
                                        <Grid item xs={12} container alignItems="center">
                                            <FormControl fullWidth>
                                                <Grid item xs={12}>
                                                    <Typography fontSize="20px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("email_frequency")}</Typography>
                                                </Grid>
                                                <TextField margin="normal" select name="email_frequency" value={email.email_frequency} onChange={handleChange}>
                                                    {EmailFrequencyKeys.map((key, idx) =>
                                                        (<MenuItem key={idx} value={EmailFrequency[key]}><Typography fontFamily="Griff" fontWeight="bolder">{key}</Typography></MenuItem>)
                                                    )}
                                                </TextField>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </StyledBox>
                </Grid>
            </Grid >
        </Grid >
    )
}

export { EmailDetail }
export default EmailDetail
