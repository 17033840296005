import { Box, Grid, MenuItem, TextField, Typography, useTheme } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { ServicesContext } from "@context/index"
import { Client } from "@models/index"
import { ClientSectorKeysValueOps } from "@components/client/ClientTableDefinition"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import ActionButton from "@components/common/Button/ActionButton"
import { useTrack } from "@components/track/TrackContext"

const initValue: Client = {
    id: "",
    name: "",
    description: "",
    sector: undefined
}

const ClientForm: React.FC = () => {
    const theme = useTheme()
    const clientService = useContext(ServicesContext).clientService
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [error, setError] = useState<Error|null>(null)

    const [formData, setFormData] = useState<Client>(initValue)
    const isFormValid = formData.name.trim() !== "" && formData.description.trim() !== ""
    const handleInputChange = (e: any) => {
        let events: {target :{name: string, value: string}}[] = e
        if (!Array.isArray(e)) {
            events = [e]
        }
        setFormData(events.reduce((result, { target: { name, value } }) => {
            return { ...result, [name]: value }
        }, formData))
    }
    const { track, trackData } = useTrack()
    useEffect(() => {
        track({ view: "ClientForm" })
    }, [])
    const navigate = useNavigate()
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        try {
            await clientService.create(formData)
            navigate(-1)
        } catch (e) {
            setError(e as Error)
            setSnackbarOpen(true)
        }
    }

    return (
        <Box sx={{ width: "100%" }}>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1, width: "50%", alignSelf: "center", margin: "50px auto 50px auto" }}>
                <CustomSnackbar
                    open={snackbarOpen}
                    onClose={() => setSnackbarOpen(false)}
                    message={error?.message || "An error occurred."}
                />
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <TextField margin="normal" required fullWidth variant="filled" label="Client Name" name="name"
                            value={formData.name} onChange={handleInputChange}/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField margin="normal" select required fullWidth variant="filled" label="Sector" name="sector"
                            value={formData.sector} onChange={handleInputChange}>
                            {ClientSectorKeysValueOps.map((opt, idx) =>
                                (<MenuItem key={idx} value={opt.value}><Typography fontFamily="Griff" fontWeight="bolder">{opt.label === "EnergyAndElectricNetworks" ? "Energy And Electric Networks" : opt.label}</Typography></MenuItem>)
                            )}
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField margin="normal" required fullWidth variant="filled" label="Description" name="description"
                            value={formData.description} onChange={handleInputChange}/>
                    </Grid>
                    <Grid item container xs={12} spacing={1}>
                        <Grid item xs={5.5}>
                            <ActionButton
                                type="submit"
                                variant="contained"
                                text="Licenses"
                            />
                        </Grid>
                        <Grid item xs={5.5}>
                            <ActionButton
                                type="submit"
                                variant="contained"
                                text="Add new Client"
                                disabled={!isFormValid}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export { ClientForm }
export default ClientForm
