import Vulnerability, { VulnerabilityJustification, VulnerabilityResponse, VulnerabilityState } from "@models/Vulnerability"
import { v4 as uuidv4 } from "uuid"

const random = (min: number, max: number) => Math.floor(Math.random() * (1 + max - min)) + min

const severities = ["none", "low", "medium", "high", "critical"] as const

const vulnerabilityGenerator = (totalVulnerabilities: number = 50): Vulnerability[] => {
    // Constants
    const vulnerabilities: Vulnerability[] = []
    // --------------------------------------------------
    /// Functions
    const getRandomJustification = (): VulnerabilityJustification => {
        const justifications = Object.values(VulnerabilityJustification)
        return justifications[Math.floor(Math.random() * justifications.length)]
    }
    const getRandomResponse = (): VulnerabilityResponse => {
        const responses = Object.values(VulnerabilityResponse)
        return responses[Math.floor(Math.random() * responses.length)]
    }
    const getRandomState = (): VulnerabilityState => {
        const states = Object.values(VulnerabilityState)
        return states[Math.floor(Math.random() * states.length)]
    }
    // --------------------------------------------------
    for (let i = 0; i < totalVulnerabilities; i++) {
        vulnerabilities.push({
            id: uuidv4(),
            identifier: `VULN-${random(1000, 9999)}`,
            score: parseFloat((Math.random() * 10).toFixed(1)),
            revised_score: parseFloat((Math.random() * 10).toFixed(1)),
            priority: random(1, 5),
            epss: parseFloat((Math.random() * 100).toFixed(2)),
            percentile: parseFloat((Math.random() * 100).toFixed(2)),
            kev: Boolean(random(0, 1)),
            supressed: Boolean(random(0, 1)),
            vector: "CVSS:3.0/AV:N/AC:L/PR:N/UI:N/S:U/C:H/I:H/A:H",
            description: `Generated vulnerability with severity ${severities[random(0, severities.length - 1)]}`,
            state: getRandomState(),
            justification: getRandomJustification(),
            response: [getRandomResponse()],
            revised_severity: random(0, 5),
            time_spent: random(1, 100),
            cmp_name: `Component-${random(1, 100)}`,
            cmp_version: `v${random(1, 5)}.${random(0, 9)}`,
            assessment: { id: uuidv4(), name: "Assessment" },
            solution: Math.random() > 0.5 ? "Update to the latest version" : undefined,
            product: { id: uuidv4(), name: `Product-${random(1, 100)}` },
            client: { id: uuidv4(), name: `Client-${random(1, 100)}` },
            cwe: Math.random() > 0.5 ? [random(100, 999)] : undefined,
            rank: random(1, 10),
            origin: "internal",
            metadata: { additionalInfo: "Generated data" },
            references: Math.random() > 0.5 ? "https://example.com/reference" : undefined,
            generator: "automated-script",
            generator_time: new Date().toISOString(),
            affected_component: Math.random() > 0.5 ? `Component-${random(1, 100)}` : undefined,
            product_id: Math.random() > 0.5 ? uuidv4() : undefined
        })
    }

    return vulnerabilities
}

export default vulnerabilityGenerator
