import React from "react"
import { Box, Grid, TextField } from "@mui/material"
import ActionButton from "@components/common/Button/ActionButton"
import { View } from "@react-pdf/renderer"

interface ScopeSectionProps {
    alcanceData: { activo: string; url: string }[];
    onUpdate: (updatedAlcance: { activo: string; url: string }[]) => void;
    onBlur?: (updatedAlcance: { activo: string; url: string }) => void;
}

const ScopeSection: React.FC<ScopeSectionProps> = ({ alcanceData, onUpdate, onBlur }) => {
    const addActivoUrl = () => {
        const updatedData = [...alcanceData, { activo: "", url: "" }]
        onUpdate(updatedData)
    }

    const removeActivoUrl = (index: number) => {
        const updatedData = alcanceData.filter((_, i) => i !== index)
        onUpdate(updatedData)
    }

    const updateActivoUrl = (index: number, field: "activo" | "url", value: string) => {
        const updatedData = [...alcanceData]
        updatedData[index][field] = value
        onUpdate(updatedData)
    }

    const handleBlur = (index: number) => {
        if (onBlur) {
            onBlur(alcanceData[index])
        }
    }

    return (
        <Box style={{ display: "flex", gap: 2, flexDirection: "column" }}>
            {alcanceData.map((item, index) => (
                <Grid container spacing={2} key={index} alignItems="center" sx={{ marginBottom: 2 }}>
                    <Grid item xs={5}>
                        <TextField
                            fullWidth
                            label="Tipo de activo"
                            value={item.activo}
                            onChange={(e) => updateActivoUrl(index, "activo", e.target.value)}
                            onBlur={() => handleBlur(index)}
                            variant="filled"
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            fullWidth
                            label="URL/IP"
                            value={item.url}
                            onChange={(e) => updateActivoUrl(index, "url", e.target.value)}
                            onBlur={() => handleBlur(index)}
                            variant="filled"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        {index > 0 && (
                            <ActionButton
                                variant="outlined"
                                onClick={() => removeActivoUrl(index)}
                                text="Remove"
                            />
                        )}
                    </Grid>
                </Grid>
            ))}
            <View>
                <ActionButton onClick={addActivoUrl} text="Add" />
            </View>
        </Box>
    )
}

export default ScopeSection
