import { ClientSelector } from "@components/client"
import { StyledBox } from "@components/common/Box/BoxContainer"
import ActionButton from "@components/common/Button/ActionButton"
import { ProductTypeColors } from "@components/common/colors/ProductTypeColors"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import { StyledTextarea } from "@components/common/textareas"
import HierarchySelector from "@components/hierarchy/HierarchySelector"
import { AbilityContext } from "@components/permissions"
import { ProductTypeKeys } from "@components/product/ProductTableDefinition"
import ServicesContext from "@context/ServicesContext"
import Hierarchy from "@models/Hierarchy"
import Product, { ProductType } from "@models/Product"
import { Box, Checkbox, Divider, Grid, MenuItem, TextField, Typography, useTheme } from "@mui/material"
import { FilterOperation, FilterOption, QueryParameters } from "@utils/queryParams"
import { MAX_DEEP_LEVEL, PATH_SEPARATOR } from "@views/hierarchy/hierarchyUtils"
import { I18nContext } from "I18nProvider"
import { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { StatsVulnerabilities } from "@models/Stats"
import ExpiredLicenses from "./ExpiredLicenses"

const initValue = {
    id: "",
    name: "",
    type: ProductType.Application,
    description: "",
    version: "",
    hierarchy: "",
    inheritance: false,
    supressed: false,
    client: { id: "00000000-0000-0000-0000-000000000000", name: "" },
    created_at: ""
}

const Details = ({ id }: {id: string | undefined}) => {
    // Constants
    const context = useContext(I18nContext)
    if (context === null) throw new Error("The I18n context is not initialized. Make sure you have the provider set up correctly.")
    const theme = useTheme()
    const productService = useContext(ServicesContext).productService
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [error, setError] = useState<Error | null>(null)
    const [data, setData] = useState<Product>(initValue)
    const [formData, setFormData] = useState<Product>(initValue)
    const [selectedHierarchies, setSelectedHierarchies] = useState<(Hierarchy | null)[]>(Array.from({ length: MAX_DEEP_LEVEL }, () => null))
    const ability = useContext(AbilityContext)
    const hierarchyService = useContext(ServicesContext).hierarchyService
    const statsService = useContext(ServicesContext).statsService
    const [successfulMessage, setSuccessfulMessage] = useState<string>()
    const navigate = useNavigate()
    const [vulnStats, setVulnStats] = useState<StatsVulnerabilities | undefined>(undefined)
    const [filter, setFilter] = useState({})
    const componentService = useContext(ServicesContext).componentService
    const [expiredLicenses, setExpiredLicenses] = useState<
    { license: string; license_expiration_date: string }[]
  >([])
    // --------------------------------------------------
    // useEffect
    useEffect(() => {
        const fecthData = async () => {
            try {
                const val = await productService.get(id as string)
                setData(val)
                setFormData(val)

                if (val.hierarchy && val.hierarchy !== "") {
                    const pathIds = val.hierarchy.split(PATH_SEPARATOR)
                    const fetchedHierarchies = await Promise.all(pathIds.map(id => hierarchyService.get(id)))
                    setSelectedHierarchies(fetchedHierarchies.map(h => h || null))
                }

                const filterssss: QueryParameters = { filters: [{ field: "product_id", operation: FilterOperation.EnumEqual, value: id as string }] }
                const vulnsStats = await statsService.getStatsVulnerabilitiesWithParams(filterssss)
                const expiredLicensesData = await componentService.getExpiredLicensesByProductID(id as string)

                // Filtrar licencias expiradas
                const validExpiredLicenses = expiredLicensesData.expired_components.filter((license: any) => {
                    const expirationDate = new Date(license.license_expiration_date)
                    return (
                        license.license && !isNaN(expirationDate.getTime()) && expirationDate < new Date()
                    )
                })

                setExpiredLicenses(validExpiredLicenses)
                setVulnStats(vulnsStats)
                const initialFilter = {
                    sortField: "name",
                    sortOrder: "asc"
                }
                setFilter(initialFilter)
            } catch (e: any) {
                setError({ message: e.error } as Error)
                setSnackbarOpen(true)
            }
        }
        if (id) fecthData()
    }, [id])
    // --------------------------------------------------
    // Functions
    const handleSelectorChange = (index: number, id: string | undefined, hierarchy: Hierarchy | null) => {
        const newSelectedHierarchies = [...selectedHierarchies]
        newSelectedHierarchies[index] = hierarchy
        newSelectedHierarchies.fill(null, index + 1, MAX_DEEP_LEVEL)
        setSelectedHierarchies(newSelectedHierarchies)

        const newPath = newSelectedHierarchies.filter(h => h !== null).map(h => h!.id).join(";")
        setFormData({ ...formData, hierarchy: newPath })
    }
    const getCustomFilters = (index: number): FilterOption[] | undefined => {
        if (index === 0) {
            if (ability.can("*", "Client")) {
                if (formData.client.id !== "00000000-0000-0000-0000-000000000000") {
                    return [
                        { field: "client_id", operation: FilterOperation.UUIDEqual, value: formData.client.id },
                        { field: "path", operation: FilterOperation.StringNotContains, value: `%${PATH_SEPARATOR}%` }
                    ]
                }
                return undefined
            }
            return [{ field: "path", operation: FilterOperation.StringNotContains, value: `%${PATH_SEPARATOR}%` }]
        }
        const parentHierarchy = selectedHierarchies[index - 1]
        if (!parentHierarchy) return []
        return [
            { field: "path", operation: FilterOperation.StringContains, value: `${parentHierarchy.path}${PATH_SEPARATOR}%` },
            { field: "path", operation: FilterOperation.StringNotContains, value: `${parentHierarchy.path}${PATH_SEPARATOR}%${PATH_SEPARATOR}%` }
        ]
    }
    const handleHistoricButton = async () => {
        navigate("./evolution")
    }
    const saveHandler = async () => {
        try {
            await productService.update(formData.id, formData)
            setData(formData)
            navigate(-1)
        } catch (e: any) {
            setError({ message: e.error } as Error)
            setSnackbarOpen(true)
        }
    }
    const formValid = (): boolean => {
        const isNotNullrules = [
            formData.name === "",
            formData.version === "",
            formData.description === ""
        ]
        ability.can("*", "Client") ?? isNotNullrules.push(formData.client.id === "00000000-0000-0000-0000-000000000000")
        if (isNotNullrules.some(rule => rule)) {
            return false
        }
        const isNotSameOldValues = [
            formData.name === data.name,
            formData.version === data.version,
            formData.description === data.description,
            formData.type === data.type,
            formData.hierarchy === data.hierarchy,
            formData.inheritance === data.inheritance,
            formData.supressed === data.supressed
        ]
        ability.can("*", "Client") ?? isNotSameOldValues.push(formData.client.id === data.client.id)
        if (isNotSameOldValues.every(rule => rule)) {
            return false
        }
        return true
    }
    const handleChange = (e: any) => {
        let events: { target: { name: string, value?: any, checked?: boolean, type: string } }[] = e
        if (!Array.isArray(e)) {
            events = [e]
        }

        setFormData(events.reduce((result, { target: { name, value, checked, type } }) => {
            if (type === "checkbox") {
                value = checked
            }
            return { ...result, [name]: value }
        }, formData))
    }

    // --------------------------------------------------
    return (<Grid item xs container flexDirection="column" spacing="20px">
        <Grid item container flexDirection="column" rowGap="35px">
            <Grid item container>
                <StyledBox>
                    <CustomSnackbar
                        open={snackbarOpen}
                        onClose={() => setSnackbarOpen(false)}
                        message={error?.message || successfulMessage || context.t.translate("an_error")}
                        severity={successfulMessage ? context.t.translate("succes") : "error"}
                    />
                    <Grid container flexDirection="column" spacing="20px">
                        <Grid item flexDirection="row">
                            <Grid container flexDirection="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ marginTop: "10px" }}>
                                <Grid item>
                                    <Typography fontSize="37px" fontFamily="Griff" color="primary" fontWeight="bolder">{context.t.translate("historic")}
                                    </Typography>
                                </Grid>
                                <Grid item container justifyContent="space-between">
                                    <Grid item container spacing={1} xs>
                                        <Grid item><ActionButton variant="outlined" onClick={handleHistoricButton} text={context.t.translate("product_details")} /></Grid></Grid>
                                    <ActionButton onClick={saveHandler} disabled={!formValid()} text={context.t.translate("product_save")} />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item>
                            <Divider sx={{ height: "1px", width: "100%", background: theme.palette.primary.main }} />
                        </Grid>
                        <Grid item container flexDirection="row" alignItems='center' spacing="40px">
                            {ability.can("*", "Product") && (

                                <Grid item xs={6}>
                                    <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("client_name")}</Typography>
                                    <ClientSelector value={formData.client.id} onSelect={(id) => handleChange({ target: { name: "client", value: { id } } })}></ClientSelector>
                                </Grid>

                            )}
                            <Grid item xs={6}>
                                {/* SHOW EXPIRED LICENSE */}
                                {expiredLicenses.length > 0 && <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder" >
                                    {context.t.translate("expired_licenses")}
                                </Typography>
                                }
                                <ExpiredLicenses licenses={expiredLicenses} />
                            </Grid>
                        </Grid>

                        <Grid item container flexDirection="row" alignItems='center' spacing="40px">
                            <Grid item xs={3}>
                                <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("product_name")}</Typography>
                                <TextField disabled={!ability.can("*", "Product")} name="name" value={formData.name} onChange={handleChange} variant="standard" style={{ fontFamily: "Griff", color: theme.palette.secondary.main }} />
                            </Grid>
                            <Grid item xs={3}>
                                <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("product_version")}</Typography>
                                <TextField disabled={!ability.can("*", "Product")} name="version" value={formData.version} onChange={handleChange} variant="standard" style={{ fontFamily: "Griff", color: theme.palette.secondary.main }} />
                            </Grid>
                            <Grid item xs={3}>
                                <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("type")}</Typography>
                                <TextField disabled={!ability.can("*", "Product")} margin="normal" select required variant="standard" name="type" style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}
                                    value={formData.type} onChange={handleChange}>
                                    {ProductTypeKeys.map((key, index) =>
                                        (<MenuItem key={index} value={ProductType[key]}><Typography fontFamily="Griff" fontWeight="bolder" sx={{ color: ProductTypeColors[ProductType[key]] }}>{key}</Typography></MenuItem>)
                                    )}
                                </TextField>
                            </Grid>
                        </Grid>
                        <Grid item container flexDirection="row" alignItems='center' spacing="40px">
                            <Grid item xs={3}>
                                <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("inheritance")}</Typography>
                                <Checkbox checked={formData.inheritance} onChange={handleChange} name="inheritance"/>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("supressed")}</Typography>
                                <Checkbox checked={formData.supressed} onChange={handleChange} name="supressed"/>
                            </Grid>
                        </Grid>
                        <Grid item container flexDirection="row" alignItems='center' spacing="40px">
                            <Grid item xs={3}>
                                <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("hierarchy")}</Typography>
                                <HierarchySelector
                                    value={selectedHierarchies[0]?.id || ""}
                                    customFilters={getCustomFilters(0)}
                                    onSelect={(id, hierarchy) => handleSelectorChange(0, id, hierarchy)}
                                    index={0}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                {selectedHierarchies[0] && (
                                    <Box sx={{ mt: "40px" }}>
                                        <HierarchySelector
                                            value={selectedHierarchies[1]?.id || ""}
                                            customFilters={getCustomFilters(1)}
                                            onSelect={(id, hierarchy) => handleSelectorChange(1, id, hierarchy)}
                                            index={1}
                                        />
                                    </Box>

                                )}
                            </Grid>
                            <Grid item xs={3}>
                                {selectedHierarchies[1] && (
                                    <Box sx={{ mt: "40px" }}>
                                        <HierarchySelector
                                            value={selectedHierarchies[2]?.id || ""}
                                            customFilters={getCustomFilters(2)}
                                            onSelect={(id, hierarchy) => handleSelectorChange(2, id, hierarchy)}
                                            index={2}
                                        />
                                    </Box>
                                )}
                            </Grid>
                            <Grid item xs={3}>
                                {selectedHierarchies[2] && (
                                    <Box sx={{ mt: "40px" }}>
                                        <HierarchySelector
                                            value={selectedHierarchies[3]?.id || ""}
                                            customFilters={getCustomFilters(3)}
                                            onSelect={(id, hierarchy) => handleSelectorChange(3, id, hierarchy)}
                                            index={3}
                                        />
                                    </Box>
                                )}
                            </Grid>
                        </Grid>

                        <Grid item>
                            <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("product_description")}</Typography>
                            <StyledTextarea minRows={3} name="description" placeholder={context.t.translate("enter_description")} value={formData.description} onChange={handleChange} style={{ width: "100%", boxSizing: "border-box", backgroundColor: "transparent" }} />
                        </Grid>

                        <Grid item>
                            <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("product_versions")}</Typography>
                        </Grid>
                    </Grid>
                </StyledBox>
                {/* <ProductTree/> */}
            </Grid>
        </Grid >
    </Grid >)
}

export default Details
