import React, { useEffect, useState } from "react"
import Chip from "@mui/material/Chip"
import { AchillesStatusColors } from "@components/common/colors/AchillesStatusColors"
import { TestType } from "@models/Achilles"
import { AchillesTypeKeysValueOps } from "./AchillesTableDefinition"

interface ColorAndBg{
    color: string;
    "background-color": string;
}

type AchillesStatusColorMap = {
  [key in TestType]: ColorAndBg;
};

const getAchillesStatusColor = (status: TestType): ColorAndBg => {
    const assessmentStatusColorMap: Record<TestType, ColorAndBg> = {
        [TestType.Ethernet]: { color: "#FFFFFF", "background-color": AchillesStatusColors.Ethernet },
        [TestType.ARP]: { color: "#FFFFFF", "background-color": AchillesStatusColors.ARP },
        [TestType.LLDP]: { color: "#FFFFFF", "background-color": AchillesStatusColors.LLDP },
        [TestType.IP]: { color: "#FFFFFF", "background-color": AchillesStatusColors.IP },
        [TestType.ICMP]: { color: "#FFFFFF", "background-color": AchillesStatusColors.ICMP },
        [TestType.IGMP]: { color: "#FFFFFF", "background-color": AchillesStatusColors.IGMP },
        [TestType.TCP]: { color: "#FFFFFF", "background-color": AchillesStatusColors.TCP },
        [TestType.HTTP]: { color: "#FFFFFF", "background-color": AchillesStatusColors.HTTP },
        [TestType.UDP]: { color: "#FFFFFF", "background-color": AchillesStatusColors.UDP },
        [TestType.FTP]: { color: "#FFFFFF", "background-color": AchillesStatusColors.FTP },
        [TestType.NTP]: { color: "#FFFFFF", "background-color": AchillesStatusColors.NTP },
        [TestType.RDP]: { color: "#FFFFFF", "background-color": AchillesStatusColors.RDP },
        [TestType.RPC]: { color: "#FFFFFF", "background-color": AchillesStatusColors.RPC },
        [TestType.SNMP]: { color: "#FFFFFF", "background-color": AchillesStatusColors.SNMP },
        [TestType.TELNET]: { color: "#FFFFFF", "background-color": AchillesStatusColors.TELNET },
        [TestType.DNP3]: { color: "#FFFFFF", "background-color": AchillesStatusColors.DNP3 },
        [TestType.EtherNetIP]: { color: "#FFFFFF", "background-color": AchillesStatusColors["EtherNet/IP"] },
        [TestType.CIP]: { color: "#FFFFFF", "background-color": AchillesStatusColors.CIP },
        [TestType.FFHSE]: { color: "#FFFFFF", "background-color": AchillesStatusColors["FF-HSE"] },
        [TestType.GOOSE]: { color: "#FFFFFF", "background-color": AchillesStatusColors.GOOSE },
        [TestType.IEC104]: { color: "#FFFFFF", "background-color": AchillesStatusColors["IEC 104"] },
        [TestType.MMS]: { color: "#FFFFFF", "background-color": AchillesStatusColors.MMS },
        [TestType.OPCUA]: { color: "#FFFFFF", "background-color": AchillesStatusColors["OPC UA"] },
        [TestType.MODBUSTCP]: { color: "#FFFFFF", "background-color": AchillesStatusColors["MODBUS/TCP"] },
        [TestType.PROFINET]: { color: "#FFFFFF", "background-color": AchillesStatusColors.PROFINET }
    }

    return assessmentStatusColorMap[status]
}

interface TestTypeColoredProps {
    value: TestType;
}

const AchillesStatusColored: React.FC<TestTypeColoredProps> = ({ value }) => {
    const [color, setColor] = useState<ColorAndBg|null>(null)
    const [label, setLabel] = useState<string|undefined>("")

    useEffect(() => {
        setLabel(AchillesTypeKeysValueOps.find(v => v.value === value)?.label)
        setColor(getAchillesStatusColor(value))
    }, [value])

    return (
        <Chip size="small" style={{
            fontWeight: "bolder",
            color: color?.color,
            backgroundColor: color?.["background-color"]
        }} label={label}
        />
    )
}

export { AchillesStatusColored }
export default AchillesStatusColored
